import { VFC } from 'react';
import { css } from '@emotion/react';
import FormLabel from '@material-ui/core/FormLabel';

type Props = {
  label: string;
};

const InvalidDataLabel: VFC<Props> = ({ label }) => (
  <div
    css={css`
      margin-bottom: 10px;
    `}
  >
    <FormLabel
      css={css`
        display: inline-block;
        font-size: 0.9rem;
      `}
    >
      {label}
    </FormLabel>
  </div>
);

export default InvalidDataLabel;
