import { VFC } from 'react';
import TreeNodeEmpty from 'components/01.atoms/Organization/TreeNodeEmpty';

type EmptyBlockProps = {
  depth: number;
  displayedDepth: number;
};

const RecursionEmptyBlock: VFC<EmptyBlockProps> = ({
  depth,
  displayedDepth,
}) => (
  <>
    {Array.from({ length: displayedDepth - depth })?.map((_, index) => (
      <TreeNodeEmpty
        gridColumn={`${depth + index + 1} / ${depth + index + 2}`}
        key={`emptyNode${depth + index}`}
      />
    ))}
  </>
);

export default RecursionEmptyBlock;
