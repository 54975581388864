import { VFC, useState } from 'react';
import SnackBars from 'components/02.molecules/Common/SnackBars';
import Button from '@material-ui/core/Button';
import { OrganizationState, useResetEditMainOrganization } from 'domains/user';
import BasicConfirmFormDialog from 'components/03.organisms/Common/BasicConfirmFormDialog';
import CommonOrgSelector from 'containers/02.molecules/User/CommonOrgSelector';
import PeriodInputSet from 'containers/02.molecules/User/CommonOrgPeriodInputSet';
import ErrorResponse from 'domains/common/models/error';

type Props = {
  viewUserId: number;
};

const ResetEditMainOrganizationButton: VFC<Props> = ({ viewUserId }) => {
  const initialState = {
    isContinued: true,
    start: new Date(),
    end: null,
    orgId: 0,
    orgName: '',
  } as OrganizationState;

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [organizationState, setOrganizationState] = useState(initialState);

  const handleReset = () => {
    setOrganizationState(initialState);
  };

  const {
    mutate,
    status,
    reset: queryReset,
    error = {
      response: {
        data: {} as ErrorResponse,
      },
    },
  } = useResetEditMainOrganization(viewUserId, setIsModalOpen, handleReset);

  const handleCancel = () => {
    setIsModalOpen(false);
    queryReset();
  };

  const handleOk = () => {
    mutate({ ...organizationState, userId: viewUserId });
  };

  return (
    <>
      <Button
        variant="outlined"
        size="small"
        color="primary"
        onClick={() => setIsModalOpen(true)}
      >
        編集
      </Button>
      <SnackBars
        status={status}
        loadingMsg="更新中..."
        successMsg="更新が完了しました"
        errorMsg="更新に失敗しました"
      />
      <BasicConfirmFormDialog
        title="所属組織（主務）の編集"
        confirmMsg="指定期間のユーザーの所属組織（主務）を更新します。"
        okButtonName="更新"
        cancelButtonName="キャンセル"
        textField={
          <>
            <PeriodInputSet
              organizationState={organizationState}
              setOrganizationState={setOrganizationState}
              errorData={error?.response?.data}
              viewUserId={viewUserId}
            />
            <CommonOrgSelector
              organizationState={organizationState}
              setOrganizationState={setOrganizationState}
              errorData={error?.response?.data}
              isMain
            />
          </>
        }
        isOpen={isModalOpen}
        handleOk={handleOk}
        handleCancel={handleCancel}
      />
    </>
  );
};

export default ResetEditMainOrganizationButton;
