import { VFC, Dispatch, SetStateAction } from 'react';
import {
  DataGrid,
  GridRowData,
  GridColDef,
  GridOverlay,
  GridCellParams,
} from '@material-ui/data-grid';
import { css } from '@emotion/react';
import { OrganizationUserState } from 'domains/user';
import OrganizationUserPeriodInputGroup from 'containers/02.molecules/User/OrganizationUserPeriodInputGroup';
import OrganizationUserSelectInput from 'containers/02.molecules/User/OrganizationUserSelectInput';
import IsMainToggleButtons from 'containers/02.molecules/User/IsMainToggleButtons';

const customStyle = css`
  &.MuiDataGrid-root .MuiDataGrid-columnHeader:focus,
  &.MuiDataGrid-root .MuiDataGrid-columnHeader:focus-within,
  &.MuiDataGrid-root .MuiDataGrid-cell:focus,
  &.MuiDataGrid-root .MuiDataGrid-cell:focus-within {
    outline: none;
  }
  &.MuiDataGrid-root .MuiDataGrid-window {
    overflow-y: overlay;
  }
`;

type Props = {
  rows: OrganizationUserState[];
  setOrganizationState: Dispatch<SetStateAction<OrganizationUserState[]>>;
  scopeEnd?: Date | null;
  emptyMessage: string;
};

const columns = (
  rows: OrganizationUserState[],
  setUsersState: Dispatch<SetStateAction<OrganizationUserState[]>>,
  scopeEnd?: Date | null,
): GridColDef[] => [
  {
    field: 'period',
    headerName: '適用期間',
    width: 500,
    sortable: false,
    renderCell: (params: GridCellParams) => {
      const { getValue, id } = params;

      return (
        <OrganizationUserPeriodInputGroup
          rowNumber={Number(getValue(id, 'rowNumber'))}
          rows={rows}
          setUsersState={setUsersState}
          scopeEnd={scopeEnd}
        />
      );
    },
  },
  {
    field: 'positionSelect',
    headerName: '所属者',
    flex: 1,
    sortable: false,
    renderCell: (params: GridCellParams) => {
      const { getValue, id } = params;

      return (
        <OrganizationUserSelectInput
          rowNumber={Number(getValue(id, 'rowNumber'))}
          rows={rows}
          setUsersState={setUsersState}
          scopeEnd={scopeEnd}
        />
      );
    },
  },
  {
    field: 'isMain',
    headerName: '主務兼務',
    width: 120,
    sortable: false,
    renderCell: (params: GridCellParams) => {
      const { getValue, id } = params;
      const rowNumber = Number(getValue(id, 'rowNumber'));

      return (
        <IsMainToggleButtons
          rowNumber={rowNumber}
          isMain={rows[rowNumber].isMain}
          setUsersState={setUsersState}
        />
      );
    },
  },
  { field: 'id', hide: true },
  { field: 'rowNumber', hide: true },
];

const convertOrganizationOptions = (
  original: OrganizationUserState[],
): GridRowData[] =>
  original.length === 0
    ? ([] as GridRowData[])
    : original.map((data, index) => ({
        id: `organizationUserSelect${index}`,
        start: data.start,
        end: data.end,
        isContinued: data.isContinued,
        rowNumber: data.rowNumber,
        orgName: data.userName,
        isMain: data.isMain,
      }));

const OrganizationUserInputDataGrid: VFC<Props> = ({
  rows,
  setOrganizationState,
  scopeEnd,
  emptyMessage,
}) => {
  const CustomNoRowsOverlay: VFC = () => (
    <GridOverlay>
      <div>{emptyMessage}</div>
    </GridOverlay>
  );

  return (
    <div
      css={css`
        width: 100%;
      `}
    >
      <DataGrid
        css={customStyle}
        rows={!rows.length ? [] : convertOrganizationOptions(rows)}
        columns={columns(rows, setOrganizationState, scopeEnd)}
        autoHeight
        hideFooter
        disableColumnMenu
        disableColumnSelector
        disableSelectionOnClick
        components={{
          NoRowsOverlay: CustomNoRowsOverlay,
        }}
      />
      <div id="portal-root" />
    </div>
  );
};
export default OrganizationUserInputDataGrid;
