import { VFC, useState, useEffect, forwardRef, MouseEventHandler } from 'react';
import Button from '@material-ui/core/Button';
import SnackBars from 'components/02.molecules/Common/SnackBars';
import { PositionPeriod, useEditPositionPeriod } from 'domains/position';
import EditPeriodDialog from 'containers/02.molecules/Position/EditPeriodDialog';
import CustomInput from 'components/01.atoms/Common/Basic/CustomInputInDateSelect';
import dateSelectWidth from 'baseCss/emotion/dateSelectWidth';

type Props = {
  viewPeriod: PositionPeriod;
  setViewPeriod: (arg: PositionPeriod) => void;
};

const EditPeriodButton: VFC<Props> = ({ viewPeriod, setViewPeriod }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [start, setStart] = useState(null as Date | null);
  const [end, setEnd] = useState(null as Date | null);

  const resetForm = () => {
    setStart(null);
    setEnd(null);
  };
  useEffect(() => {
    resetForm();
  }, [viewPeriod]);
  const {
    mutate,
    status,
    reset: resetError,
    error = {
      response: {
        data: { errors: { start: '', end: '' } },
      },
    },
  } = useEditPositionPeriod(setIsOpen, setViewPeriod, resetForm);
  const handleOk = () => {
    mutate({
      periodId: viewPeriod.id,
      start,
      end,
    });
  };
  const handleCancel = () => {
    setIsOpen(false);
    resetError();
  };

  type CustomProps = {
    onClick?: MouseEventHandler<HTMLDivElement>;
  };

  const CustomStart = forwardRef<HTMLDivElement, CustomProps>(
    ({ onClick }, ref) => (
      <CustomInput
        addCss={dateSelectWidth}
        label="変更後の期間の開始日"
        value={start}
        errorMsg={error?.response?.data?.errors.start}
        ref={ref}
        onClick={onClick}
      />
    ),
  );
  const CustomEnd = forwardRef<HTMLDivElement, CustomProps>(
    ({ onClick }, ref) => (
      <CustomInput
        addCss={dateSelectWidth}
        label="変更後の期間の終了日"
        value={end}
        errorMsg={error?.response?.data?.errors.end}
        ref={ref}
        onClick={onClick}
      />
    ),
  );

  return (
    <>
      <Button
        fullWidth
        variant="outlined"
        color="primary"
        disableElevation
        onClick={() => setIsOpen(true)}
      >
        期間の編集
      </Button>
      <EditPeriodDialog
        viewPeriod={viewPeriod}
        isOpen={isOpen}
        handleOk={handleOk}
        handleCancel={handleCancel}
        start={start}
        end={end}
        setStart={setStart}
        setEnd={setEnd}
        customStart={<CustomStart />}
        customEnd={<CustomEnd />}
      />
      <SnackBars
        status={status}
        loadingMsg="変更中..."
        successMsg="変更が完了しました"
        errorMsg="変更に失敗しました"
      />
    </>
  );
};

export default EditPeriodButton;
