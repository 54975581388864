import { VFC, ReactElement } from 'react';
import { css } from '@emotion/react';

const linkButtonGroup = css`
  display: flex;
  justify-content: space-between;
  & > div {
    flex: 1;
    margin: 1rem;
  }
`;

type Props = {
  headerArea: ReactElement;
  alertBar: ReactElement;
  linkGroup: ReactElement;
};

const MainContentsArea: VFC<Props> = ({ headerArea, alertBar, linkGroup }) => (
  <div>
    {headerArea}
    <div
      css={css`
        margin: 1rem 1rem 0;
      `}
    >
      {alertBar}
    </div>
    <div css={linkButtonGroup}>{linkGroup}</div>
  </div>
);

export default MainContentsArea;
