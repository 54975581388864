export type OwnTask = {
  taskId: number;
  taskName: string;
  parentTaskName: string | null;
  description: string;
  dueDateTime: string;
  isLeafNode: boolean;
  isCompleted: boolean;
  isDisabled: boolean;
  taskStatus: number;
  taskType: number;
};
const isOwnTask = (arg: unknown): arg is OwnTask => {
  const o = arg as OwnTask;

  return (
    typeof o?.taskId === 'number' &&
    typeof o?.taskName === 'string' &&
    (o?.parentTaskName === null || typeof o?.parentTaskName === 'string') &&
    typeof o?.description === 'string' &&
    typeof o?.dueDateTime === 'string' &&
    typeof o?.isLeafNode === 'boolean' &&
    typeof o?.isCompleted === 'boolean' &&
    typeof o?.isDisabled === 'boolean' &&
    typeof o?.taskStatus === 'number' &&
    typeof o?.taskType === 'number'
  );
};

const isOwnTasks = (args: unknown[]): args is OwnTask[] =>
  !args.some((arg) => !isOwnTask(arg));

export { isOwnTask, isOwnTasks };
