import { VFC } from 'react';
import { OrgMatch } from 'domains/user';
import DetailListWithButton from 'components/02.molecules/Common/DetailListWithButton';
import convertPeriodText from 'utils/function/convertPeriodText';
import ResetEditMainOrganizationButton from 'containers/01.atoms/User/ResetEditMainOrganizationButton';

export type Props = {
  mainOrgs: OrgMatch[];
  viewUserId: number;
};

const MainOrgDetailList: VFC<Props> = ({ mainOrgs, viewUserId }) => {
  const dataLists = mainOrgs?.map((org, index) => ({
    key: `mainOrgDetail${index}`,
    title: convertPeriodText({
      start: org.start,
      end: org.end,
    }),
    content: org.orgName,
  }));

  return (
    <DetailListWithButton
      label="所属組織（主務）"
      dataLists={dataLists}
      buttonElements={
        <ResetEditMainOrganizationButton viewUserId={viewUserId} />
      }
    />
  );
};

export default MainOrgDetailList;
