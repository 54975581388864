import { useCallback, VFC } from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import { css } from '@emotion/react';
import HeaderText from 'components/01.atoms/Auth/LoginHeaderText';
import Logininput from 'components/01.atoms/Auth/LoginInput';
import LoginButton from 'components/01.atoms/Auth/LoginButton';
import LoginLink from 'components/01.atoms/Auth/LoginLink';
import FooterText from 'components/01.atoms/Auth/LoginFooterText';
import backgroundpicture from 'components/assets/background_login.jpg';
import { FaUserAlt } from 'react-icons/fa';
import { RiLock2Fill } from 'react-icons/ri';
import { useLogin } from 'domains/auth';

const overall = css`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-image: url(${backgroundpicture});
`;

const adjustCenter = css`
  display: flex;
  justify-content: center;
`;

const marginYMini = css`
  margin-top: 0.4rem;
  margin-bottom: 0.6rem;
`;
const marginMiddle = css`
  margin-top: 2.5rem;
`;

const marginY = css`
  margin-top: 2rem;
  margin-bottom: 3rem;
`;

type IFormValues = {
  email: string;
  password: string;
};

const LoginPage: VFC = () => {
  const { register, handleSubmit } = useForm<IFormValues>();

  const { mutate } = useLogin();

  const handleLogin: SubmitHandler<IFormValues> = useCallback(
    (data) => {
      mutate(data);
    },
    [mutate],
  );

  return (
    <form onSubmit={handleSubmit(handleLogin)}>
      <div css={overall}>
        <div>
          <div>
            <HeaderText text="DailyReportOnline" />
          </div>
          <div css={[adjustCenter, marginMiddle]}>
            <Logininput
              type="email"
              placeholder="メールアドレス"
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...register('email')}
            >
              <FaUserAlt />
            </Logininput>
          </div>
          <div css={[adjustCenter, marginYMini]}>
            <Logininput
              type="password"
              placeholder="パスワード"
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...register('password')}
            >
              <RiLock2Fill />
            </Logininput>
          </div>
          <div css={[adjustCenter]}>
            <LoginButton>ログイン</LoginButton>
          </div>
          <div css={[adjustCenter, marginY]}>
            <LoginLink link="/">パスワードを忘れた方はこちら</LoginLink>
          </div>
          <div>
            <FooterText text="Copyright © Banian Lab Co., Ltd. All Rights Reserved." />
          </div>
        </div>
      </div>
    </form>
  );
};

export default LoginPage;
