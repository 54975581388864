import { useMemo, VFC } from 'react';
import ReactTableBasic from 'components/02.molecules/Common/ReactTableBasic';
import { Column } from 'react-table';
import { RequestingTask } from 'domains/task/common';
import convertDateHourMinute from 'utils/function/convertDateHourMinute';
import { css } from '@emotion/react';
import OperateMenuButton from 'containers/01.atoms/Task/RequestingTask/OperateMenuButton';
import { FormProps as FilterProps } from 'domains/task/common/services/getRequestingTasks';

type ButtonProps = {
  detail: RequestingTask;
};

type DataProps = RequestingTask & {
  id: number;
  iconButton: ButtonProps;
};

type Props = {
  data: DataProps[];
  isLoading: boolean;
  filterAndSortInfo: FilterProps;
};

const RequestingTaskTable: VFC<Props> = ({
  data,
  isLoading,
  filterAndSortInfo,
}) => {
  const columns = useMemo(
    (): Column<DataProps>[] => [
      {
        accessor: 'id',
      },
      {
        Header: 'タスク名称',
        accessor: 'taskName',
        maxWidth: 20,
      },
      {
        Header: '内容説明',
        accessor: 'description',
        width: 30,
      },
      {
        Header: '期限',
        accessor: 'dueDateTime',
        width: 200,
        minWidth: 200,
        maxWidth: 10,
        Cell: ({ value }) => convertDateHourMinute(value),
      },
      {
        Header: '操作',
        accessor: 'iconButton',
        width: 70,
        minWidth: 70,
        maxWidth: 1,
        Cell: ({ value }) => (
          <OperateMenuButton
            detail={value.detail}
            filterAndSortInfo={filterAndSortInfo}
          />
        ),
      },
    ],
    [filterAndSortInfo],
  );

  return (
    <div>
      <ReactTableBasic
        columns={columns}
        data={data}
        isLoading={isLoading}
        addBodyCss={css`
          height: calc(100vh - 250px);
        `}
      />
    </div>
  );
};

export default RequestingTaskTable;
