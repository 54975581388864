import { ReactElement, VFC } from 'react';
import BasicSelectDialog from 'components/03.organisms/Common/BasicSelectDialog';

type Props = {
  isOpen: boolean;
  handleCancel: () => void;
  orgList: ReactElement;
};

const MainOrgSelectDialog: VFC<Props> = ({ isOpen, handleCancel, orgList }) => (
  <BasicSelectDialog
    title="所属組織（主務）の選択"
    isOpen={isOpen}
    handleCancel={handleCancel}
    list={orgList}
  />
);

export default MainOrgSelectDialog;
