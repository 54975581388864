import { VFC } from 'react';
import { Dialog, DialogTitle, DialogContent } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { css } from '@emotion/react';

type Props = {
  title: string;
  confirmMsg: string;
  isOpen: boolean;
  handleCancel: () => void;
};

const BasicAlertDialog: VFC<Props> = ({
  title,
  confirmMsg,
  isOpen,
  handleCancel,
}) => (
  <Dialog aria-labelledby="alert-dialog" onClose={handleCancel} open={isOpen}>
    <DialogTitle id="alert-dialog">
      <Typography variant="h6" component="span">
        {title}
      </Typography>
      <IconButton
        aria-label="close"
        onClick={handleCancel}
        css={css`
          position: absolute;
          top: 8px;
          right: 8px;
          color: #9e9e9e;
        `}
      >
        <CloseIcon />
      </IconButton>
    </DialogTitle>
    <DialogContent
      css={css`
        white-space: pre-wrap;
      `}
      dividers
    >
      {confirmMsg}
    </DialogContent>
  </Dialog>
);

export default BasicAlertDialog;
