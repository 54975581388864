import { ReactElement, VFC } from 'react';
import { Dialog, DialogTitle } from '@material-ui/core';
import { css } from '@emotion/react';

type Props = {
  title: string;
  isOpen: boolean;
  handleCancel: () => void;
  list: ReactElement;
  maxWidth?: false | 'xs' | 'sm' | 'md' | 'lg' | 'xl';
};

const BasicSelectDialog: VFC<Props> = ({
  title,
  isOpen,
  handleCancel,
  maxWidth,
  list,
}) => (
  <Dialog
    aria-labelledby="select-dialog"
    onClose={handleCancel}
    open={isOpen}
    maxWidth={maxWidth}
    css={css`
      & .MuiDialog-paper {
        overflow-y: initial;
      }
    `}
  >
    <DialogTitle id="select-dialog">{title}</DialogTitle>
    {list}
  </Dialog>
);

export default BasicSelectDialog;
