import { VFC, useState, useMemo } from 'react';
import SnackBars from 'components/02.molecules/Common/SnackBars';
import Button from '@material-ui/core/Button';
import {
  OrganizationUserState,
  useAddUsersForEmptyOrganization,
} from 'domains/user';
import BasicConfirmFormDialog from 'components/03.organisms/Common/BasicConfirmFormDialog';
import ErrorResponse from 'domains/common/models/error';
import FormHelperText from '@material-ui/core/FormHelperText';
import { css } from '@emotion/react';
import Box from '@material-ui/core/Box';
import convertPeriodText from 'utils/function/convertPeriodText';
import getOrganizationUsersError from 'utils/function/error/getOrganizationUsersError';
import OrganizationUserInputDataGrid from 'containers/02.molecules/User/OrganizationUserInputDataGrid';

type Props = {
  viewOrgId: number;
  start: string;
  end: string;
};

type PeriodStateProps = {
  isContinued: boolean;
  start: Date;
  end: Date;
};

const AddUsersForEmptyOrganizationButton: VFC<Props> = ({
  viewOrgId,
  start,
  end,
}) => {
  const entirePeriod = useMemo(
    () =>
      ({
        isContinued: false,
        start: new Date(start),
        end: new Date(end),
      } as PeriodStateProps),
    [end, start],
  );

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [usersState, setUsersState] = useState([] as OrganizationUserState[]);

  const handleReset = () => {
    setUsersState([]);
  };

  const {
    mutate,
    status,
    reset: queryReset,
    error = {
      response: {
        data: {} as ErrorResponse,
      },
    },
  } = useAddUsersForEmptyOrganization(setIsModalOpen, handleReset);

  const handleCancel = () => {
    setIsModalOpen(false);
    queryReset();
  };

  const handleOk = () => {
    mutate({
      orgId: viewOrgId,
      users: usersState,
      emptyPeriodsEnd: end,
    });
  };
  const usersError = getOrganizationUsersError(error, usersState);

  return (
    <>
      <Button
        variant="outlined"
        size="small"
        color="primary"
        onClick={() => setIsModalOpen(true)}
      >
        登録
      </Button>
      <SnackBars
        status={status}
        loadingMsg="登録中..."
        successMsg="登録が完了しました"
        errorMsg="登録に失敗しました"
      />
      <BasicConfirmFormDialog
        title="組織の所属者の登録（未登録期間）"
        confirmMsg="下記の未登録期間について、所属者を登録します。"
        maxWidth="xl"
        okButtonName="登録"
        cancelButtonName="キャンセル"
        textField={
          <div
            css={css`
              width: 1000px;
            `}
          >
            <Box pb={2} pt={1} pl={2}>
              <span>{convertPeriodText({ start, end })}</span>
            </Box>
            {!!usersError && (
              <Box pb={1} pt={2}>
                <FormHelperText error>{usersError}</FormHelperText>
              </Box>
            )}
            <OrganizationUserInputDataGrid
              scopeStart={entirePeriod.start}
              scopeEnd={entirePeriod.end}
              usersState={usersState}
              setUsersState={setUsersState}
              emptyMessage="対象期間が取得できません"
            />
          </div>
        }
        isOpen={isModalOpen}
        handleOk={handleOk}
        handleCancel={handleCancel}
      />
    </>
  );
};

export default AddUsersForEmptyOrganizationButton;
