import { VFC, useEffect } from 'react';
import BasicConfirmFormDialog from 'components/03.organisms/Common/BasicConfirmFormDialog';
import InputPassword from 'containers/01.atoms/Common/InputPassword';
import SnackBars from 'components/02.molecules/Common/SnackBars';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { css } from '@emotion/react';
import { useForm } from 'react-hook-form';
import { StrOnlyForm } from 'domains/common';
import ErrorResponse from 'domains/common/models/error';
import { useEditPassword } from 'domains/auth';

export type Props = {
  isOpen: boolean;
  setIsOpen: (arg: boolean) => void;
};

const EnhancedEditPasswordDialog: VFC<Props> = ({ isOpen, setIsOpen }) => {
  const {
    getValues,
    control,
    reset: resetForm,
  } = useForm<StrOnlyForm>({
    defaultValues: {
      oldPassword: '',
      newPassword: '',
    },
  });
  const {
    mutate,
    status,
    isLoading,
    reset: resetError,
    error = {
      response: {
        data: {} as ErrorResponse,
      },
    },
  } = useEditPassword(setIsOpen);
  const handleOk = () => {
    mutate({
      oldPassword: getValues('oldPassword'),
      newPassword: getValues('newPassword'),
    });
  };
  const handleCancel = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    resetForm();
    resetError();
  }, [isOpen, resetError, resetForm]);

  return (
    <>
      <BasicConfirmFormDialog
        title="パスワードの変更"
        confirmMsg="ユーザー認証用のパスワードを変更します。"
        isOpen={isOpen}
        handleOk={handleOk}
        handleCancel={handleCancel}
        okButtonName="変更"
        cancelButtonName="キャンセル"
        textField={
          <>
            <InputPassword
              label="現在のパスワード"
              isFullWidth
              variant="standard"
              margin="normal"
              name="oldPassword"
              control={control}
              errorMsg={error?.response?.data?.errors?.oldPassword}
            />
            <InputPassword
              label="変更後のパスワード"
              isFullWidth
              variant="standard"
              margin="normal"
              name="newPassword"
              control={control}
              errorMsg={error?.response?.data?.errors?.newPassword}
            />
          </>
        }
      />
      <Backdrop
        css={css`
          z-index: 1301;
          color: white;
        `}
        open={isLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <SnackBars
        status={status}
        loadingMsg="パスワード変更中..."
        successMsg="パスワードの変更が完了しました"
        errorMsg="パスワードの変更に失敗しました"
      />
    </>
  );
};

export default EnhancedEditPasswordDialog;
