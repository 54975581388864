export type OrganizationOption = {
  orgId: number;
  orgName: string;
  masterName: string;
  orgType: number;
  hasCapacity: boolean;
};

export type OrganizationState = {
  rowNumber: number;
  orgId: number;
  orgName: string;
  start: Date;
  end: Date | null;
  isContinued: boolean;
};

const isOrganizationOption = (arg: unknown): arg is OrganizationOption => {
  const o = arg as OrganizationOption;

  return (
    typeof o?.orgId === 'number' &&
    typeof o?.orgName === 'string' &&
    typeof o?.masterName === 'string' &&
    typeof o?.orgType === 'number' &&
    typeof o?.hasCapacity === 'boolean'
  );
};

const isOrganizationOptions = (args: unknown[]): args is OrganizationOption[] =>
  !args.some((arg) => !isOrganizationOption(arg));

export { isOrganizationOption, isOrganizationOptions };
