export type OrgMatch = {
  matchId: number;
  start: string;
  end: string;
  orgType: number;
  orgName: string;
};
const isOrgMatch = (arg: unknown): arg is OrgMatch => {
  const o = arg as OrgMatch;

  return (
    typeof o?.matchId === 'number' &&
    typeof o?.start === 'string' &&
    typeof o?.end === 'string' &&
    typeof o?.orgType === 'number' &&
    typeof o?.orgName === 'string'
  );
};
const isOrgMatches = (args: unknown[]): args is OrgMatch[] =>
  !args.some((arg) => !isOrgMatch(arg));

export { isOrgMatch, isOrgMatches };
