import axios, { AxiosError } from 'axios';
import camelcaseKeys from 'camelcase-keys';
import { format } from 'date-fns';
import ErrorResponse from 'domains/common/models/error';
import { useMutation, UseMutationResult } from 'react-query';
import { OrganizationOption, isOrganizationOptions } from '../models/orgOption';

type FormProps = {
  start: Date;
  end: Date | null;
  isContinued: boolean;
  maxEnd?: Date | null;
};

const getOrganizationOptions = async (
  formData: FormProps,
): Promise<OrganizationOption[]> => {
  const response = await axios.post(
    `${
      process.env.REACT_APP_API_URL ?? ''
    }/api/system/user/getOrganizationOptions`,
    {
      ...formData,
      start: format(formData.start, 'yyyy-M-d'),
      end: formData.end ? format(formData.end, 'yyyy-M-d') : null,
      maxEnd: formData.maxEnd ? format(formData.maxEnd, 'yyyy-M-d') : null,
    },
  );

  const options = (await camelcaseKeys(response.data, {
    deep: true,
  })) as unknown[];

  if (!isOrganizationOptions(options)) {
    throw Error('API type error');
  }

  return options;
};

const useGetOrganizationOptions = (
  setOrgLists: (data: OrganizationOption[]) => void,
): UseMutationResult<
  OrganizationOption[],
  AxiosError<ErrorResponse>,
  FormProps,
  undefined
> =>
  useMutation(getOrganizationOptions, {
    onSuccess: (data) => {
      setOrgLists(data);
    },
  });

export default useGetOrganizationOptions;
