import { VFC } from 'react';
import DetailList from 'components/02.molecules/Common/DetailList';
import { SubOrg } from 'domains/organization';

export type Props = {
  subOrgs: SubOrg[];
};

const SubOrgDetailList: VFC<Props> = ({ subOrgs }) => {
  const dataLists = subOrgs?.map((subOrg, index) => ({
    key: `subOrgDetail${index}`,
    title: '',
    content: subOrg.orgName,
  }));

  return <DetailList label="サブ組織" dataLists={dataLists} />;
};

export default SubOrgDetailList;
