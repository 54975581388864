import { useCallback, VFC } from 'react';
import OriginalContentsArea from 'components/03.organisms/Home/OriginalContentsArea';
import DescriptionWithIcon from 'components/02.molecules/Home/DescriptionWithIcon';
import AssignmentLateIcon from '@material-ui/icons/AssignmentLate';
import ListViewButton from 'components/01.atoms/Home/ListViewButton';
import { css } from '@emotion/react';
import { useNavigate } from 'react-router-dom';
import ApproachingOwnTaskTable from 'containers/02.molecules/Home/ApproachingOwnTaskTable';

const iconStyle = css`
  box-sizing: border-box;
  display: inline-block;
  margin-right: 0.25rem;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 900;
  font-variant: normal;
  line-height: 1;
  color: #0070c0;
  text-align: left;
  word-wrap: break-word;
  transition: ease-in-out 300ms;
  -webkit-font-smoothing: antialiased;
  text-rendering: auto;
`;

const ApproachingTaskArea: VFC = () => {
  const navigate = useNavigate();
  const handleClick = useCallback(() => navigate('/task/ownTask'), [navigate]);

  return (
    <OriginalContentsArea
      headerButton={<ListViewButton onClick={handleClick}>一覧</ListViewButton>}
      headerTitle={
        <DescriptionWithIcon
          text="締切間近のタスク（7日以内）"
          icon={<AssignmentLateIcon css={iconStyle} />}
        />
      }
      dataList={<ApproachingOwnTaskTable />}
    />
  );
};

export default ApproachingTaskArea;
