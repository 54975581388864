import { AxiosError } from 'axios';
import ErrorResponse from 'domains/common/models/error';
import { OrganizationUserState } from 'domains/user';

type ErrorType =
  | AxiosError<ErrorResponse>
  | {
      response: {
        data: ErrorResponse;
      };
    }
  | null;

const getOrganizationUsersError = (
  error: ErrorType,
  organizationState: OrganizationUserState[],
): string => {
  if (error?.response?.data?.errors.users) {
    return error?.response?.data?.errors.users;
  }

  const userIdIndex = organizationState.findIndex((_, i) => {
    if (error?.response?.data?.errors[`users.${i}.userId`]) {
      return true;
    }

    return false;
  });
  if (userIdIndex !== -1) {
    return error?.response?.data?.errors[`users.${userIdIndex}.userId`] ?? '';
  }

  const endIndex = organizationState.findIndex((_, i) => {
    if (error?.response?.data?.errors[`users.${i}.end`]) {
      return true;
    }

    return false;
  });
  if (endIndex !== -1) {
    return error?.response?.data?.errors[`users.${endIndex}.end`] ?? '';
  }

  return '';
};

export default getOrganizationUsersError;
