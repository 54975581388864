import axios, { AxiosError } from 'axios';
import camelcaseKeys from 'camelcase-keys';
import ErrorResponse from 'domains/common/models/error';
import { UseMutationResult, useMutation, useQueryClient } from 'react-query';
import { isUsers, User } from '../models/user';

type FormProps = {
  userId: number;
};

const deleteUser = async (formData: FormProps): Promise<User[]> => {
  const response = await axios.post(
    `${process.env.REACT_APP_API_URL ?? ''}/api/system/user/deleteUser`,
    formData,
  );

  const users = (await camelcaseKeys(response.data, {
    deep: true,
  })) as unknown[];

  if (!isUsers(users)) {
    throw Error('API type error');
  }

  return users;
};

const useDeleteUser = (
  setIsOpen: (arg: boolean) => void,
): UseMutationResult<
  User[],
  AxiosError<ErrorResponse>,
  FormProps,
  undefined
> => {
  const queryClient = useQueryClient();

  return useMutation(deleteUser, {
    onSuccess: (data) => {
      queryClient.setQueryData(['system', 'users'], data);
      setIsOpen(false);
    },
  });
};

export default useDeleteUser;
