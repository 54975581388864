import axios, { AxiosError } from 'axios';
import camelcaseKeys from 'camelcase-keys';
import { isSuccessOnly, SuccessOnly } from 'domains/common';
import ErrorResponse from 'domains/common/models/error';
import {
  UseMutationResult,
  useMutation,
  useQueryClient,
  QueryKey,
} from 'react-query';
import { PositionState } from '../models/positionOption';

type FormProps = {
  userId: number;
  positions: PositionState[];
};

const resetEditPositions = async (
  formData: FormProps,
): Promise<SuccessOnly> => {
  const response = await axios.post(
    `${process.env.REACT_APP_API_URL ?? ''}/api/system/user/resetEditPositions`,
    formData,
  );

  const success = (await camelcaseKeys(response.data, {
    deep: true,
  })) as unknown[];

  if (!isSuccessOnly(success)) {
    throw Error('API type error');
  }

  return success;
};

const useResetEditPositions = (
  setIsOpen: (arg: boolean) => void,
  queryKeys: QueryKey[],
  reset: () => void,
): UseMutationResult<
  SuccessOnly,
  AxiosError<ErrorResponse>,
  FormProps,
  undefined
> => {
  const queryClient = useQueryClient();

  return useMutation(resetEditPositions, {
    onSuccess: async () => {
      reset();
      setIsOpen(false);
      const promises = queryKeys.map((key) =>
        queryClient.invalidateQueries(key),
      );
      await Promise.all(promises);
    },
  });
};

export default useResetEditPositions;
