import axios, { AxiosError } from 'axios';
import camelcaseKeys from 'camelcase-keys';
import { SuccessOnly, isSuccessOnly } from 'domains/common';
import ErrorResponse from 'domains/common/models/error';
import { UseMutationResult, useMutation } from 'react-query';
import { TASK_STATUS_FOR_WORKER } from 'utils/const';

type FormProps = {
  taskId: number;
};

const finishOwnTask = async (formData: FormProps): Promise<SuccessOnly> => {
  const response = await axios.post(
    `${process.env.REACT_APP_API_URL ?? ''}/api/task/common/finishOwnTask`,
    formData,
  );

  const success = (await camelcaseKeys(response.data, {
    deep: true,
  })) as unknown;

  if (!isSuccessOnly(success)) {
    throw Error('API type error');
  }

  return success;
};

const useFinishOwnTask = (
  setIsOpen: (arg: boolean) => void,
  setIsAlertOpen: (arg: boolean) => void,
  setStatus: (arg: number) => void,
): UseMutationResult<
  SuccessOnly,
  AxiosError<ErrorResponse>,
  FormProps,
  undefined
> =>
  useMutation(finishOwnTask, {
    onSuccess: () => {
      setIsOpen(false);
      setStatus(TASK_STATUS_FOR_WORKER.DONE);
    },
    onError: (error) => {
      if (
        error?.response?.data?.errors.taskId[0] ===
        '完了未承認のサブタスクがあるため、完了に更新できません。'
      ) {
        setIsAlertOpen(true);
      }
    },
  });

export default useFinishOwnTask;
