import { VFC, useState } from 'react';
import MainContentsArea from 'components/03.organisms/Common/MainContentsAreaMainOnly';
import MainContentsHeader from 'containers/03.organisms/Task/NewSpotTask/MainContentsHeader';
import AddTaskInputArea from 'components/03.organisms/Task/NewSpotTask/AddTaskInputArea';
import FormTextField from 'containers/01.atoms/Common/FormTextFiled';
import DateTimeSelectInput from 'components/02.molecules/WorkReport/Common/DateTimeSelectInput';
import OwnerSelectRadios from 'containers/02.molecules/Task/Project/OwnerSelectRadios';
import SnackBars from 'components/02.molecules/Common/SnackBars';
import { useForm } from 'react-hook-form';
import { useCreateTask } from 'domains/task/spot';
import { useGetMemberOptions } from 'domains/task/projectTree';
import { FormProps } from 'domains/task/spot/services/createTask';
import ErrorResponse from 'domains/common/models/error';
import { css } from '@emotion/react';
import CustomDate from 'components/01.atoms/Common/Basic/CustomDate';

const EnhancedMainContentsArea: VFC = () => {
  const [dueDateTime, setDueDateTime] = useState(null as Date | null);
  const [ownerIds, setOwnerIds] = useState([] as number[]);

  const {
    control,
    getValues,
    reset: formReset,
  } = useForm<FormProps>({
    defaultValues: {
      taskName: '',
      description: '',
    },
    shouldUnregister: false,
  });

  const handleReset = () => {
    formReset();
    setDueDateTime(null);
    setOwnerIds([]);
  };

  const {
    mutate,
    status,
    error = {
      response: {
        data: {} as ErrorResponse,
      },
    },
  } = useCreateTask(handleReset);

  const { data: ownerOptions = [], isFetching } = useGetMemberOptions(false);

  const handleOk = () => {
    mutate({
      taskName: String(getValues('taskName')),
      description: String(getValues('description')),
      dueDateTime,
      ownerId: ownerIds[0],
    });
  };

  return (
    <>
      <MainContentsArea
        headerArea={<MainContentsHeader handleOk={handleOk} />}
        mainArea={
          <AddTaskInputArea
            leftSide={
              <>
                <FormTextField
                  label="タスク名"
                  placeholder=""
                  isMultiLine={false}
                  isFullWidth
                  variant="outlined"
                  margin="normal"
                  name="taskName"
                  control={control}
                  errorMsg={error?.response?.data?.errors.taskName}
                />
                <DateTimeSelectInput
                  setViewDate={setDueDateTime}
                  viewDate={dueDateTime}
                  customInput={
                    <CustomDate
                      label="期限"
                      variant="outlined"
                      dateValue={dueDateTime}
                      errorMsg={error?.response?.data?.errors.dueDateTime}
                    />
                  }
                  addWrapperCss={css`
                    width: 100%;
                  `}
                />
                <FormTextField
                  label="内容説明"
                  placeholder=""
                  isMultiLine
                  isFullWidth
                  variant="outlined"
                  margin="normal"
                  name="description"
                  control={control}
                  errorMsg={error?.response?.data?.errors.description}
                />
              </>
            }
            rightSide={
              <OwnerSelectRadios
                ownerId={ownerIds[0]}
                setOwnerIds={setOwnerIds}
                ownerOptions={ownerOptions}
                errorMessage={error?.response?.data.errors.ownerId}
                isFetching={isFetching}
              />
            }
          />
        }
      />
      <SnackBars
        status={status}
        loadingMsg="登録中..."
        successMsg="登録が完了しました"
        errorMsg="登録に失敗しました"
      />
    </>
  );
};

export default EnhancedMainContentsArea;
