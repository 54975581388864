import axios, { AxiosError } from 'axios';
import camelcaseKeys from 'camelcase-keys';
import { StrOrNumForm, UpdatePositionForm } from 'domains/common';
import ErrorResponse from 'domains/common/models/error';
import { UseFormReset } from 'react-hook-form';
import { UseMutationResult, useMutation, useQueryClient } from 'react-query';
import { isPositions, Position } from '../models/position';

const updatePosition = async (
  formData: UpdatePositionForm,
): Promise<Position[]> => {
  const response = await axios.post(
    `${process.env.REACT_APP_API_URL ?? ''}/api/system/position/updatePosition`,
    formData,
  );

  const positions = (await camelcaseKeys(response.data, {
    deep: true,
  })) as unknown[];

  if (!isPositions(positions)) {
    throw Error('API type error');
  }

  return positions;
};

const useUpdatePosition = (
  periodId: number,
  setIsOpen: (arg: boolean) => void,
  formReset: UseFormReset<StrOrNumForm>,
): UseMutationResult<
  Position[],
  AxiosError<ErrorResponse>,
  UpdatePositionForm,
  undefined
> => {
  const queryClient = useQueryClient();

  return useMutation(updatePosition, {
    onSuccess: (data) => {
      queryClient.setQueryData(['positions', periodId], data);
      setIsOpen(false);
      formReset();
    },
  });
};

export default useUpdatePosition;
