import { VFC, useState } from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import { css } from '@emotion/react';
import { Staff, useGetNowDirectStaffs } from 'domains/staff';

type Props = {
  viewStaffId: number | null;
  setViewStaffId: (arg: number) => void;
};

const StaffMenu: VFC<Props> = ({ viewStaffId, setViewStaffId }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClickListItem = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuItemClick = (
    event: React.MouseEvent<HTMLElement>,
    index: number,
  ) => {
    setViewStaffId(index);
    setAnchorEl(null);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const { data: staffs = [] as Staff[] } = useGetNowDirectStaffs(false);

  return (
    <>
      <List
        css={css`
          padding-bottom: 0;
        `}
        component="nav"
        aria-label="Device settings"
      >
        <ListItem
          button
          id="lock-button"
          aria-haspopup="listbox"
          aria-controls="lock-menu"
          aria-label="閲覧対象"
          aria-expanded={open ? 'true' : undefined}
          onClick={handleClickListItem}
        >
          <ListItemText
            primary="閲覧対象のメンバー"
            secondary={
              viewStaffId
                ? staffs.find((staff) => staff.staffId === viewStaffId)
                    ?.staffName
                : '未選択'
            }
          />
        </ListItem>
      </List>
      <Menu
        id="lock-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'lock-button',
          role: 'listbox',
        }}
      >
        {staffs?.map((staff) => (
          <MenuItem
            key={staff.staffId}
            selected={staff.staffId === viewStaffId}
            onClick={(event) => handleMenuItemClick(event, staff.staffId)}
          >
            {staff.staffName}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default StaffMenu;
