import { css } from '@emotion/react';

const inputStyle = css`
  flex: 1 0 auto;
  max-width: 100%;
  margin: 0;
  overflow: visible;
  font-family: Lato, 'Helvetica Neue', Arial, Helvetica, sans-serif;
  line-height: 1.21428571em;
  color: rgba(0, 0, 0, 0.87);
  text-align: left;
  background: #fff;
  border: 1px solid rgba(34, 36, 38, 0.15);
  border-radius: 0.28571429rem;
  outline: 0;
  box-shadow: none;
  transition: box-shadow 0.1s ease, border-color 0.1s ease;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
  &::selection {
    color: rgba(0, 0, 0, 0.87);
    background-color: rgba(100, 100, 100, 0.4);
  }
`;

export default inputStyle;
