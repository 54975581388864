import { VFC, ReactElement } from 'react';
import { css } from '@emotion/react';

type Props = {
  ownTask: ReactElement;
};

const outlineStyle = css`
  padding: 0.5rem;
`;

const MainContentsMainArea: VFC<Props> = ({ ownTask }) => (
  <div css={outlineStyle}>{ownTask}</div>
);

export default MainContentsMainArea;
