import axios, { AxiosError } from 'axios';
import camelcaseKeys from 'camelcase-keys';
import { useQuery, UseQueryResult } from 'react-query';
import { isStaffs, Staff } from '../models/staff';

const getNowDirectStaffs = async (): Promise<Staff[]> => {
  const response = await axios.get(
    `${process.env.REACT_APP_API_URL ?? ''}/api/staff/getNowDirectStaffs`,
  );

  const staffs = (await camelcaseKeys(response.data, {
    deep: true,
  })) as unknown[];

  if (!isStaffs(staffs)) {
    throw Error('API type error');
  }

  return staffs;
};

const useGetNowDirectStaffs = (
  isSuspense: boolean,
): UseQueryResult<Staff[], AxiosError> =>
  useQuery(['nowDirectStaffs'], () => getNowDirectStaffs(), {
    suspense: isSuspense,
  });

export default useGetNowDirectStaffs;
