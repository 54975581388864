import { VFC } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import SideMenu from 'components/03.organisms/WorkReport/Common/CommonSideMenu';

const EnhancedSideMenu: VFC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const handleItemClick = (to: string) => {
    navigate(to);
  };

  return (
    <SideMenu pathName={location.pathname} handleItemClick={handleItemClick} />
  );
};

export default EnhancedSideMenu;
