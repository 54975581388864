import { VFC } from 'react';
import { css } from '@emotion/react';
import FormLabel from '@material-ui/core/FormLabel';

type Props = {
  label: string;
};

const marginStyle = css`
  margin-bottom: 0;
`;

const labelStyle = css`
  font-size: 0.9rem;
  font-weight: bold;
`;

const ListLabel: VFC<Props> = ({ label }) => (
  <div css={marginStyle}>
    <FormLabel css={labelStyle}>{label}</FormLabel>
  </div>
);

export default ListLabel;
