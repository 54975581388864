import axios, { AxiosError } from 'axios';
import camelcaseKeys from 'camelcase-keys';
import { useQuery, UseQueryResult } from 'react-query';
import { UserDetail, isUserDetail } from '../models/userDetail';

export type FormProps = {
  userId: number;
};

const getUserDetail = async (formData: FormProps): Promise<UserDetail> => {
  const response = await axios.post(
    `${process.env.REACT_APP_API_URL ?? ''}/api/system/user/getUserDetail`,
    formData,
  );

  const detail = (await camelcaseKeys(response.data, {
    deep: true,
  })) as unknown;

  if (!isUserDetail(detail)) {
    throw Error('API type error');
  }

  return detail;
};

const useGetUserDetail = (
  userId: number,
  isSuspense: boolean,
): UseQueryResult<UserDetail, AxiosError> =>
  useQuery(['system', 'userDetail', userId], () => getUserDetail({ userId }), {
    suspense: isSuspense,
  });

export default useGetUserDetail;
