import axios from 'axios';
import camelcaseKeys from 'camelcase-keys';
import { isReportStatusList, ReportStatus } from '../models/reportStatus';

type FormData = {
  calendarMonth: string;
};

const getReportStatusList = async (
  formData: FormData,
): Promise<ReportStatus> => {
  const response = await axios.post(
    `${process.env.REACT_APP_API_URL ?? ''}/api/workReport/getReportStatusList`,
    formData,
  );

  const statusList = (await camelcaseKeys(response.data, {
    deep: true,
  })) as unknown;

  if (!isReportStatusList(statusList)) {
    throw Error('API type error');
  }

  return statusList;
};

export default getReportStatusList;
