import { Dispatch, SetStateAction, VFC, useState } from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import { css } from '@emotion/react';
import convertPeriodText from 'utils/function/convertPeriodText';
import {
  OrganizationPeriod,
  useGetOrganizationPeriods,
} from 'domains/organization';
import { TreeState } from 'domains/common';

const dateStyle = css`
  & span {
    padding: 0 0;
    font-family: Lato, 'Helvetica Neue', Arial, Helvetica, sans-serif;
    font-size: 1.5rem;
    font-weight: 700;
    line-height: 1.28571429em;
    color: rgba(0, 0, 0, 0.87);
    text-transform: none;
    border: none;
  }
`;

type Props = {
  viewPeriod: OrganizationPeriod;
  setViewPeriod: (arg: OrganizationPeriod) => void;
  setTreeState: Dispatch<SetStateAction<TreeState>>;
};

const PeriodMenu: VFC<Props> = ({
  viewPeriod,
  setViewPeriod,
  setTreeState,
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClickListItem = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuItemClick = (
    event: React.MouseEvent<HTMLElement>,
    index: OrganizationPeriod,
  ) => {
    setViewPeriod(index);
    setAnchorEl(null);
    setTreeState({
      displayableDepth: 1,
      activeNodeId: 0,
      openableNodeIds: [],
      disableNodeIds: [],
    });
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const { data: periods = [] as OrganizationPeriod[] } =
    useGetOrganizationPeriods(false);

  return (
    <>
      <List
        css={css`
          padding-top: 0;
          padding-bottom: 0;
        `}
        component="nav"
        aria-label="Device settings"
      >
        <ListItem
          css={dateStyle}
          button
          id="lock-button"
          aria-haspopup="listbox"
          aria-controls="lock-menu"
          aria-label="閲覧対象"
          aria-expanded={open ? 'true' : undefined}
          onClick={handleClickListItem}
        >
          <ListItemText
            primary={
              viewPeriod.id
                ? convertPeriodText({
                    start: viewPeriod.start,
                    end: viewPeriod.end,
                  })
                : '未選択'
            }
          />
        </ListItem>
      </List>
      <Menu
        id="lock-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'lock-button',
          role: 'listbox',
        }}
      >
        {periods?.map((period) => (
          <MenuItem
            key={period.id}
            selected={period.id === viewPeriod.id}
            onClick={(event) => handleMenuItemClick(event, period)}
          >
            {convertPeriodText({
              start: period.start,
              end: period.end,
            })}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default PeriodMenu;
