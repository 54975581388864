import { VFC } from 'react';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import { FaPencilAlt, FaStamp } from 'react-icons/fa';
import { css } from '@emotion/react';
import ListItemSelectType from 'components/01.atoms/Common/Basic/ListItemSelectType';

type Props = {
  pathName: string;
  handleItemClick: (url: string) => void;
};

const SideMenu: VFC<Props> = ({ pathName, handleItemClick }) => (
  <Drawer
    css={css`
      width: max-content;

      & > div {
        position: static;
        z-index: 0;
        width: max-content;
      }
    `}
    variant="permanent"
  >
    <div
      css={css`
        overflow: 'auto';
      `}
    >
      <List>
        <ListItemSelectType
          url="/workreport/input"
          pathName={pathName}
          keyName="日報作成"
          handleItemClick={handleItemClick}
          icon={<FaPencilAlt size="1.5rem" />}
        />
        <ListItemSelectType
          url="/workreport/approve"
          pathName={pathName}
          keyName="日報承認"
          handleItemClick={handleItemClick}
          icon={<FaStamp size="1.5rem" />}
        />
      </List>
    </div>
  </Drawer>
);

export default SideMenu;
