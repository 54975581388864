import { FC } from 'react';
import { css } from '@emotion/react';

const treeStyle = css`
  display: grid;
  grid-template-rows: max-content;
  grid-template-columns: max-content;
  grid-auto-flow: dense;
  grid-row-gap: 4px;
  grid-column-gap: 0;
  width: max-content;
  height: max-content;
  padding: 0 1rem 1rem 0;
  margin: 0;
  border-width: 0;
`;

const TreeStructure: FC = ({ children }) => (
  <div css={treeStyle}>{children}</div>
);

export default TreeStructure;
