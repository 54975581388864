import { VFC } from 'react';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import { css } from '@emotion/react';
import ListItemSelectType from 'components/01.atoms/Common/Basic/ListItemSelectType';
import AssignmentIcon from '@material-ui/icons/Assignment';
import AssignmentOutlined from '@material-ui/icons/AssignmentOutlined';
import PostAddIcon from '@material-ui/icons/PostAdd';

type Props = {
  pathName: string;
  handleItemClick: (url: string) => void;
};

const iconFontSize = css`
  font-size: 1.5rem;
`;

const TaskSideMenu: VFC<Props> = ({ pathName, handleItemClick }) => (
  <Drawer
    css={css`
      width: max-content;

      & > div {
        position: static;
        z-index: 0;
        width: max-content;
      }
    `}
    variant="permanent"
  >
    <div
      css={css`
        overflow: 'auto';
      `}
    >
      <List>
        <ListItemSelectType
          url="/task/ownTask"
          pathName={pathName}
          keyName="担当タスク"
          handleItemClick={handleItemClick}
          icon={<AssignmentIcon css={iconFontSize} />}
        />
        <ListItemSelectType
          url="/task/requestingTask"
          pathName={pathName}
          keyName="依頼中のタスク"
          handleItemClick={handleItemClick}
          icon={<AssignmentOutlined css={iconFontSize} />}
        />
        <ListItemSelectType
          url="/task/newSpotTask"
          pathName={pathName}
          keyName="スポットタスクの依頼"
          handleItemClick={handleItemClick}
          icon={<PostAddIcon css={iconFontSize} />}
        />
      </List>
    </div>
  </Drawer>
);

export default TaskSideMenu;
