import { VFC } from 'react';
import EffectTable from 'components/02.molecules/Position/EffectTable';
import { useGetNewPeriodEffects } from 'domains/position';

type Props = {
  label: string;
  newStart: Date;
};

const AddPeriodEffectTable: VFC<Props> = ({ newStart, label }) => {
  const { data: effects = [], isLoading } = useGetNewPeriodEffects(
    newStart,
    false,
  );

  return <EffectTable rows={effects} isLoading={isLoading} label={label} />;
};
export default AddPeriodEffectTable;
