import { VFC } from 'react';
import MainContentsHeader from 'components/03.organisms/Task/NewSpotTask/MainContentsHeader';

type Props = {
  handleOk: () => void;
};

const EnhancedMainContentsHeader: VFC<Props> = ({ handleOk }) => (
  <MainContentsHeader handleOk={handleOk} />
);

export default EnhancedMainContentsHeader;
