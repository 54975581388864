import { AxiosError } from 'axios';
import ErrorResponse from 'domains/common/models/error';
import { PositionState } from 'domains/user';

type ErrorType =
  | AxiosError<ErrorResponse>
  | {
      response: {
        data: ErrorResponse;
      };
    }
  | null;

const getPositionsError = (
  error: ErrorType,
  positionState: PositionState[],
): string => {
  if (error?.response?.data?.errors.positions) {
    return error?.response?.data?.errors.positions;
  }

  const positionIdIndex = positionState.findIndex((_, i) => {
    if (error?.response?.data?.errors[`positions.${i}.positionId`]) {
      return true;
    }

    return false;
  });
  if (positionIdIndex !== -1) {
    return (
      error?.response?.data?.errors[
        `positions.${positionIdIndex}.positionId`
      ] ?? ''
    );
  }

  return '';
};

export default getPositionsError;
