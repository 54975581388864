import { css } from '@emotion/react';
import { VFC } from 'react';
import OtherReportForm from 'components/01.atoms/WorkReport/Common/OtherReportForm';
import { DailyOtherReport } from 'domains/workReport';

type FormProps = {
  otherReport: DailyOtherReport;
};

const OtherReportViews: VFC<FormProps> = ({ otherReport }) => (
  <div
    css={css`
      & .Mui-disabled {
        color: grey;
      }
    `}
  >
    <OtherReportForm
      label="業務報告(必須)"
      placeholder=""
      isDisabled
      value={otherReport.otherReport1}
    />
    <div
      css={css`
        margin-top: 1rem;
      `}
    >
      <OtherReportForm
        label="業務外連絡(任意)"
        placeholder=""
        isDisabled
        value={otherReport.otherReport2}
      />
    </div>
  </div>
);
export default OtherReportViews;
