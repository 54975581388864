import { VFC, MouseEventHandler, Dispatch, SetStateAction } from 'react';
import { TreeState } from 'domains/common';
import useCustomTreeNode from 'hooks/use-customTreeNode';
import TreeNodeActive from 'components/01.atoms/Organization/TreeNodeActive';

export type Props = {
  orgId?: number;
  orgName?: string;
  canNextLoop?: boolean;
  hasSubOrg?: boolean;
  depthLevel?: number;
  orgType?: number;
  rowSpan: number;
  gridColumn: string;
  onClick?: MouseEventHandler<HTMLDivElement>;
  setTreeState: Dispatch<SetStateAction<TreeState>>;
};

const EnhancedTreeNodeActive: VFC<Props> = ({
  orgId,
  orgName,
  canNextLoop,
  hasSubOrg,
  depthLevel,
  orgType,
  rowSpan,
  gridColumn,
  onClick,
  setTreeState,
}) => {
  const {
    canExpand,
    canFold,
    handleExpand,
    handleFold,
    handleMouseEnter,
    handleMouseLeave,
  } = useCustomTreeNode({
    orgId,
    orgType,
    hasSubOrg,
    canNextLoop,
    setTreeState,
  });

  return (
    <TreeNodeActive
      orgId={orgId}
      orgName={orgName}
      canNextLoop={canNextLoop}
      hasSubOrg={hasSubOrg}
      depthLevel={depthLevel}
      orgType={orgType}
      rowSpan={rowSpan}
      gridColumn={gridColumn}
      canExpand={canExpand}
      canFold={canFold}
      onClick={onClick}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      handleExpand={handleExpand}
      handleFold={handleFold}
    />
  );
};

export default EnhancedTreeNodeActive;
