import { VFC, MouseEvent, useState } from 'react';
import { useController, UseControllerProps } from 'react-hook-form';
import { PropTypes } from '@material-ui/core';
import InputPassword from 'components/01.atoms/Common/Basic/InputPassword';
import { StrOnlyForm } from 'domains/common';

type Props = {
  label?: string;
  errorMsg?: string;
  placeholder?: string;
  isDisabled?: boolean;
  isFullWidth?: boolean;
  variant?: 'standard' | 'filled' | 'outlined';
  margin?: PropTypes.Margin;
};

const EnhanacedInputPassword: VFC<UseControllerProps<StrOnlyForm> & Props> = ({
  label,
  errorMsg,
  placeholder,
  isFullWidth,
  variant,
  margin,
  ...props
}) => {
  const { field } = useController(props);

  const [isRevealPassword, setIsRevealPassword] = useState(false);
  const toggleIsReveal = () => {
    setIsRevealPassword((prevState) => !prevState);
  };
  const handleMouseDown = (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  return (
    <InputPassword
      variant={variant}
      margin={margin}
      label={label}
      placeholder={placeholder}
      errorMsg={errorMsg}
      isFullWidth={isFullWidth}
      name={field.name}
      onBlur={field.onBlur}
      onChange={field.onChange}
      ref={field.ref}
      value={field.value}
      toggleIsReveal={toggleIsReveal}
      isRevealPassword={isRevealPassword}
      handleMouseDown={handleMouseDown}
    />
  );
};

export default EnhanacedInputPassword;
