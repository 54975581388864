import { css } from '@emotion/react';

const absoluteCenter = css`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: inline-block;
  width: max-content;
  height: max-content;
  margin: auto;
`;

export default absoluteCenter;
