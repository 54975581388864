import axios, { AxiosError } from 'axios';
import camelcaseKeys from 'camelcase-keys';
import { useQuery, UseQueryResult } from 'react-query';
import { OwnTask, isOwnTasks } from '../models/ownTask';

export type FormProps = {
  filterCategoryName?: string;
  filterDescription?: string;
  canViewWaitingTask?: boolean;
  canViewWorkingTask?: boolean;
  canViewDoneTask?: boolean;
  canViewCompletedTask?: boolean;
  canViewNotCompletedTask?: boolean;
  canViewTaskCanBeDone?: boolean;
  canViewTaskCanNotBeDone?: boolean;
  canViewOverDueTask?: boolean;
  canViewNotOverDueTask?: boolean;
  isOrderByApproaching?: boolean;
};

const getOwnTasks = async (formData: FormProps): Promise<OwnTask[]> => {
  const response = await axios.post(
    `${process.env.REACT_APP_API_URL ?? ''}/api/task/common/getOwnTasks`,
    formData,
  );

  const ownTasks = (await camelcaseKeys(response.data, {
    deep: true,
  })) as unknown[];

  if (!isOwnTasks(ownTasks)) {
    throw Error('API type error');
  }

  return ownTasks;
};

const useGetOwnTasks = (
  filterAndSortInfo: FormProps,
  isSuspense: boolean,
): UseQueryResult<OwnTask[], AxiosError> =>
  useQuery(
    ['authUser', 'ownTasks', filterAndSortInfo],
    () => getOwnTasks(filterAndSortInfo),
    {
      suspense: isSuspense,
    },
  );

export default useGetOwnTasks;
