import { VFC } from 'react';
import { css } from '@emotion/react';

const loginStyle = css`
  display: flex;
  justify-content: center;

  & div {
    display: inline-flex;
    justify-content: center;
    padding: 10px 20px 0 20px;
    font-size: 17px;
    color: #fff;
    border-top: solid 1px #fff;
  }
`;

export type TextProps = {
  text: string;
};

const FooterText: VFC<TextProps> = ({ text }) => (
  <div css={loginStyle}>
    <div>
      <span>{text}</span>
    </div>
  </div>
);

export default FooterText;
