import { VFC } from 'react';
import { css } from '@emotion/react';

type Props = {
  colorcode: string;
};

const ColorCircle: VFC<Props> = ({ colorcode }) => (
  <div
    css={css({
      backgroundColor: colorcode,
      width: '15px',
      height: '15px',
      display: 'inline-block',
      borderRadius: '50%',
    })}
  />
);

export default ColorCircle;
