import { VFC } from 'react';
import BasicFullSizeDialog from 'components/03.organisms/Common/BasicFullSizeDialog';
import UserDetailsArea from 'components/03.organisms/User/UserDetailsArea';
import BasicDetailList from 'containers/02.molecules/User/BasicDetailList';
import { useGetUserDetail } from 'domains/user';
import PositionDetailList from 'containers/02.molecules/User/PositionDetailList';
import MainOrgDetailList from 'containers/02.molecules/User/MainOrgDetailList';
import SubOrgDetailList from 'containers/02.molecules/User/SubOrgDetailList';

type Props = {
  isOpen: boolean;
  viewUserId: number;
  handleCancel: () => void;
};

const UserDetailDialog: VFC<Props> = ({ viewUserId, isOpen, handleCancel }) => {
  const {
    data: detail = {
      basic: {
        id: viewUserId,
        employeeNumber: '',
        name: '',
        nameKana: '',
        email: '',
        firstDate: '',
        lastDate: '',
        authorityLevel: 0,
      },
      positions: [],
      mainOrgs: [],
      subOrgs: [],
    },
    isLoading,
  } = useGetUserDetail(viewUserId, false);

  return (
    <>
      {isLoading || (
        <BasicFullSizeDialog
          title="ユーザーの詳細データ"
          contentsArea={
            <UserDetailsArea
              basic={
                <BasicDetailList basic={detail.basic} viewUserId={viewUserId} />
              }
              positions={
                <PositionDetailList
                  positions={detail.positions}
                  viewUserId={viewUserId}
                />
              }
              mainOrgs={
                <MainOrgDetailList
                  mainOrgs={detail.mainOrgs}
                  viewUserId={viewUserId}
                />
              }
              subOrgs={
                <SubOrgDetailList
                  subOrgs={detail.subOrgs}
                  viewUserId={viewUserId}
                />
              }
            />
          }
          isOpen={isOpen}
          handleCancel={handleCancel}
        />
      )}
    </>
  );
};

export default UserDetailDialog;
