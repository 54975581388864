import { VFC, ReactElement } from 'react';
import { css } from '@emotion/react';
import Grid from '@material-ui/core/Grid';
import AddItemButton from 'containers/01.atoms/Position/AddItemButton';
import { PositionPeriod } from 'domains/position';

const overall = css`
  padding: 0 1rem;
  margin-top: 0.5rem;
`;

type Props = {
  positionList: ReactElement | null;
  viewPeriod: PositionPeriod;
};

const MainContentsMainArea: VFC<Props> = ({ positionList, viewPeriod }) => (
  <div>
    <Grid css={overall} container spacing={2}>
      <Grid item xs={12}>
        <div
          css={css`
            display: flex;
            justify-content: flex-end;
            padding-right: 8px;
            padding-bottom: 6px;
          `}
        >
          <AddItemButton viewPeriod={viewPeriod} />
        </div>
        {positionList}
      </Grid>
    </Grid>
  </div>
);

export default MainContentsMainArea;
