import { VFC, Dispatch, SetStateAction } from 'react';
import {
  DataGrid,
  GridRowData,
  GridColDef,
  GridOverlay,
  GridCellParams,
} from '@material-ui/data-grid';
import { css } from '@emotion/react';
import { OrganizationState } from 'domains/user';
import MainOrgSelectInput from 'containers/02.molecules/User/MainOrgSelectInput';
import MainOrgPeriodInputGroup from 'containers/02.molecules/User/MainOrgPeriodInputGroup';

const customStyle = css`
  &.MuiDataGrid-root .MuiDataGrid-columnHeader:focus,
  &.MuiDataGrid-root .MuiDataGrid-columnHeader:focus-within,
  &.MuiDataGrid-root .MuiDataGrid-cell:focus,
  &.MuiDataGrid-root .MuiDataGrid-cell:focus-within {
    outline: none;
  }
  &.MuiDataGrid-root .MuiDataGrid-window {
    overflow-y: overlay;
  }
`;

type Props = {
  rows: OrganizationState[];
  setOrganizationState: Dispatch<SetStateAction<OrganizationState[]>>;
  scopeEnd?: Date | null;
  emptyMessage: string;
};

const columns = (
  rows: OrganizationState[],
  setOrganizationState: Dispatch<SetStateAction<OrganizationState[]>>,
  scopeEnd?: Date | null,
): GridColDef[] => [
  {
    field: 'period',
    headerName: '適用期間',
    width: 500,
    sortable: false,
    renderCell: (params: GridCellParams) => {
      const { getValue, id } = params;

      return (
        <MainOrgPeriodInputGroup
          rowNumber={Number(getValue(id, 'rowNumber'))}
          rows={rows}
          setOrganizationState={setOrganizationState}
          scopeEnd={scopeEnd}
        />
      );
    },
  },
  {
    field: 'positionSelect',
    headerName: '所属組織（主務）',
    flex: 1,
    sortable: false,
    renderCell: (params: GridCellParams) => {
      const { getValue, id } = params;

      return (
        <MainOrgSelectInput
          rowNumber={Number(getValue(id, 'rowNumber'))}
          rows={rows}
          setOrganizationState={setOrganizationState}
          scopeEnd={scopeEnd}
        />
      );
    },
  },
  { field: 'id', hide: true },
  { field: 'rowNumber', hide: true },
];

const convertOrganizationOptions = (
  original: OrganizationState[],
): GridRowData[] =>
  original.length === 0
    ? ([] as GridRowData[])
    : original.map((data, index) => ({
        id: `mainOrgSelect${index}`,
        start: data.start,
        end: data.end,
        isContinued: data.isContinued,
        rowNumber: data.rowNumber,
        orgName: data.orgName,
      }));

const MainOrgInputDataGrid: VFC<Props> = ({
  rows,
  setOrganizationState,
  scopeEnd,
  emptyMessage,
}) => {
  const CustomNoRowsOverlay: VFC = () => (
    <GridOverlay>
      <div>{emptyMessage}</div>
    </GridOverlay>
  );

  return (
    <div
      css={css`
        width: 100%;
      `}
    >
      <DataGrid
        css={customStyle}
        rows={!rows.length ? [] : convertOrganizationOptions(rows)}
        columns={columns(rows, setOrganizationState, scopeEnd)}
        autoHeight
        hideFooter
        disableColumnMenu
        disableColumnSelector
        disableSelectionOnClick
        components={{
          NoRowsOverlay: CustomNoRowsOverlay,
        }}
      />
      <div id="portal-root" />
    </div>
  );
};
export default MainOrgInputDataGrid;
