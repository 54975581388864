import { BasicDetail, isBasicDetail } from './basicDetail';
import { OrgMatch, isOrgMatches } from './orgMatch';
import { PositionMatch, isPositionMatches } from './positionMatch';

export type UserDetail = {
  basic: BasicDetail;
  positions: PositionMatch[];
  mainOrgs: OrgMatch[];
  subOrgs: OrgMatch[];
};

const isUserDetail = (arg: unknown): arg is UserDetail => {
  const u = arg as UserDetail;

  return (
    isBasicDetail(u?.basic) &&
    isPositionMatches(u?.positions) &&
    isOrgMatches(u?.mainOrgs) &&
    isOrgMatches(u?.subOrgs)
  );
};

const isUserDetails = (args: unknown[]): args is UserDetail[] =>
  !args.some((arg) => !isUserDetail(arg));

export { isUserDetail, isUserDetails };
