import axios, { AxiosError } from 'axios';
import camelcaseKeys from 'camelcase-keys';
import { isWithinInterval, parse, format } from 'date-fns';
import ErrorResponse from 'domains/common/models/error';
import { Dispatch, SetStateAction } from 'react';
import { useMutation, UseMutationResult } from 'react-query';
import {
  PositionOption,
  isPositionOptions,
  PositionState,
} from '../models/positionOption';

type FormProps = {
  isContinued: boolean;
  start: Date;
  end: Date | null;
};

const getEditPositionOptions = async (
  formData: FormProps,
): Promise<PositionOption[]> => {
  const response = await axios.post(
    `${
      process.env.REACT_APP_API_URL ?? ''
    }/api/system/user/getEditPositionOptions`,
    {
      ...formData,
      start: format(formData.start, 'yyyy-M-d'),
      end: formData.end ? format(formData.end, 'yyyy-M-d') : null,
    },
  );

  const options = (await camelcaseKeys(response.data, {
    deep: true,
  })) as unknown[];

  if (!isPositionOptions(options)) {
    throw Error('API type error');
  }

  return options;
};

const useGetEditPositionOptions = (
  period: FormProps,
  setPositionState: Dispatch<SetStateAction<PositionState[]>>,
): UseMutationResult<
  PositionOption[],
  AxiosError<ErrorResponse>,
  FormProps,
  undefined
> =>
  useMutation(getEditPositionOptions, {
    onSuccess: (options) => {
      setPositionState([]);

      if (period.start) {
        options.forEach((option, index) => {
          let periodStart = option.start;
          if (
            isWithinInterval(period.start, {
              start: parse(option.start, 'yyyy-MM-dd', new Date()),
              end: parse(option.end, 'yyyy-MM-dd', new Date()),
            })
          ) {
            periodStart = format(period.start, 'yyyy-MM-dd');
          }

          let periodEnd = option.end;
          if (
            period.end &&
            isWithinInterval(period.end, {
              start: parse(option.start, 'yyyy-MM-dd', new Date()),
              end: parse(option.end, 'yyyy-MM-dd', new Date()),
            })
          ) {
            periodEnd = format(period.end, 'yyyy-MM-dd');
          }

          setPositionState((prevState) => [
            ...prevState,
            {
              ...option,
              start: periodStart,
              end: periodEnd,
              rowNumber: index,
              positionId: 0,
              positionName: '',
            },
          ]);
        });
      }
    },
  });

export default useGetEditPositionOptions;
