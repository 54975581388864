import { useState, VFC } from 'react';
import { useForm } from 'react-hook-form';
import MainContentsMainArea from 'containers/03.organisms/WorkReport/Input/MainContentsMainArea';
import MainContentsHeader from 'components/03.organisms/WorkReport/Input/MainContentsHeader';
import MainContentsSideArea from 'components/03.organisms/WorkReport/Input/MainContentsSideArea';
import { OtherFormData } from 'domains/workReport';
import MainContentsArea from 'components/03.organisms/Common/MainContentsAreaPatternA';
import { useLocation } from 'react-router-dom';

const EnhancedMainContentsArea: VFC = () => {
  type CustomizedState = {
    viewDate: Date;
  };
  const initialPeriod = (useLocation().state ?? {
    viewDate: new Date(),
  }) as CustomizedState;
  const [viewDate, setViewDate] = useState(initialPeriod.viewDate);
  const [errorMsgWRDiv, setErrorMsgWRDiv] = useState('');
  const otherFormMethods = useForm<OtherFormData>({
    shouldUnregister: false,
  });

  return (
    <MainContentsArea
      headerArea={<MainContentsHeader viewDate={viewDate} />}
      sideArea={
        <MainContentsSideArea
          viewDate={viewDate}
          setViewDate={setViewDate}
          otherFormMethods={otherFormMethods}
          setErrorMsgWRDiv={setErrorMsgWRDiv}
        />
      }
      mainArea={
        <MainContentsMainArea
          viewDate={viewDate}
          otherFormMethods={otherFormMethods}
          errorMsgWRDiv={errorMsgWRDiv}
          setErrorMsgWRDiv={setErrorMsgWRDiv}
        />
      }
    />
  );
};

export default EnhancedMainContentsArea;
