import axios, { AxiosError } from 'axios';
import camelcaseKeys from 'camelcase-keys';
import { SuccessOnly, isSuccessOnly } from 'domains/common';
import ErrorResponse from 'domains/common/models/error';
import { UseMutationResult, useMutation, useQueryClient } from 'react-query';

type FormProps = {
  id: number;
};

const deleteSubOrganization = async (
  formData: FormProps,
): Promise<SuccessOnly> => {
  const response = await axios.post(
    `${
      process.env.REACT_APP_API_URL ?? ''
    }/api/system/user/deleteSubOrganization`,
    formData,
  );

  const users = (await camelcaseKeys(response.data, {
    deep: true,
  })) as unknown[];

  if (!isSuccessOnly(users)) {
    throw Error('API type error');
  }

  return users;
};

const useDeleteSubOrganization = (
  userId: number,
  setIsOpen: (arg: boolean) => void,
): UseMutationResult<
  SuccessOnly,
  AxiosError<ErrorResponse>,
  FormProps,
  undefined
> => {
  const queryClient = useQueryClient();

  return useMutation(deleteSubOrganization, {
    onSuccess: async () => {
      await queryClient.invalidateQueries(['system', 'userDetail', userId]);
      setIsOpen(false);
    },
  });
};

export default useDeleteSubOrganization;
