import { VFC } from 'react';
import MainContentsMainArea from 'components/03.organisms/Task/OwnTask/MainContentsMainArea';
import OwnTaskTable from 'components/02.molecules/Task/OwnTask/OwnTaskTable';
import { useGetOwnTasks } from 'domains/task/common';
import { FormProps } from 'domains/task/common/services/getOwnTasks';

type Props = {
  filterAndSortInfo: FormProps;
};

const EnhancedMainContentsMainArea: VFC<Props> = ({ filterAndSortInfo }) => {
  const { data: tasks = [], isFetching } = useGetOwnTasks(
    filterAndSortInfo,
    false,
  );

  const data = tasks.map((task) => ({
    ...task,
    id: task.taskId,
    button: {
      taskId: task.taskId,
      taskStatus: task.taskStatus,
    },
  }));

  return (
    <MainContentsMainArea
      ownTask={<OwnTaskTable data={data} isLoading={isFetching} />}
    />
  );
};

export default EnhancedMainContentsMainArea;
