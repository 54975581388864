import { VFC } from 'react';
import PresentationPage from 'components/05.pages/Common/SideAndMainPage';
import HeaderBar from 'containers/03.organisms/Common/CommonHeader';
import SideMenu from 'containers/03.organisms/Task/TaskSideMenu';
import MainContents from 'containers/03.organisms/Task/NewSpotTask/MainContentsArea';

const EnhancedNewSpotTaskPage: VFC = () => (
  <PresentationPage headerBar={<HeaderBar />} sideMenu={<SideMenu />}>
    <MainContents />
  </PresentationPage>
);
export default EnhancedNewSpotTaskPage;
