import { VFC, ReactElement } from 'react';
import EditPeriodDialog from 'components/03.organisms/Organization/Edit/EditPeriodDialog';
import PeriodSelectInput from 'components/02.molecules/WorkReport/Common/PeriodSelectInput';
import { OrganizationPeriod } from 'domains/organization';
import { SYSTEM_USAGE_PERIOD } from 'utils/const';
import parse from 'date-fns/parse';
import isSameDay from 'date-fns/isSameDay';
import DateSelectInput from 'components/02.molecules/WorkReport/Common/DateSelectInput';

type Props = {
  viewPeriod: OrganizationPeriod;
  isOpen: boolean;
  handleOk: () => void;
  handleCancel: () => void;
  start: Date | null;
  end: Date | null;
  setStart: (arg: Date | null) => void;
  setEnd: (arg: Date | null) => void;
  customStart: ReactElement;
  customEnd: ReactElement;
};

const EnhancedEditPeriodDialog: VFC<Props> = ({
  viewPeriod,
  isOpen,
  handleOk,
  handleCancel,
  start,
  end,
  setStart,
  setEnd,
  customStart,
  customEnd,
}) => {
  const isFirstPeriod = isSameDay(
    parse(viewPeriod.start, 'yyyy-MM-dd', new Date()),
    parse(SYSTEM_USAGE_PERIOD.START, 'yyyy/M/d', new Date()),
  );
  const isLastPeriod = isSameDay(
    parse(viewPeriod.end, 'yyyy-MM-dd', new Date()),
    parse(SYSTEM_USAGE_PERIOD.END, 'yyyy/M/d', new Date()),
  );
  let DateSelector = (
    <PeriodSelectInput
      start={start}
      end={end}
      setStart={setStart}
      setEnd={setEnd}
      customStart={customStart}
      customEnd={customEnd}
    />
  );
  if (isFirstPeriod) {
    DateSelector = (
      <DateSelectInput
        setViewDate={setEnd}
        viewDate={end}
        customInput={customEnd}
        minDate={parse(SYSTEM_USAGE_PERIOD.START, 'yyyy/M/d', new Date())}
      />
    );
  }
  if (isLastPeriod) {
    DateSelector = (
      <DateSelectInput
        setViewDate={setStart}
        viewDate={start}
        customInput={customStart}
        minDate={parse(SYSTEM_USAGE_PERIOD.START, 'yyyy/M/d', new Date())}
      />
    );
  }

  return (
    <EditPeriodDialog
      textField={DateSelector}
      isOpen={isOpen}
      handleOk={handleOk}
      handleCancel={handleCancel}
    />
  );
};

export default EnhancedEditPeriodDialog;
