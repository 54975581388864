import { VFC } from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import { Staff } from 'domains/staff';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import PersonIcon from '@material-ui/icons/Person';

type Props = {
  staffs: Staff[];
  handleListItemClick: (arg: number) => void;
};

const StaffSelectDialog: VFC<Props> = ({ staffs, handleListItemClick }) => (
  <Dialog aria-labelledby="staff-select-dialog" open>
    <DialogTitle id="staff-select-dialog">閲覧する部下の選択</DialogTitle>
    <List>
      {staffs.map((staff) => (
        <ListItem
          button
          onClick={() => handleListItemClick(staff.staffId)}
          key={staff.staffId}
        >
          <ListItemAvatar>
            <Avatar>
              <PersonIcon />
            </Avatar>
          </ListItemAvatar>
          <ListItemText primary={staff.staffName} />
        </ListItem>
      ))}
    </List>
  </Dialog>
);

export default StaffSelectDialog;
