import { VFC } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import TaskSideMenu from 'components/03.organisms/Task/TaskSideMenu';

const EnhancedTaskSideMenu: VFC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const handleItemClick = (to: string) => {
    navigate(to);
  };

  return (
    <TaskSideMenu
      pathName={location.pathname}
      handleItemClick={handleItemClick}
    />
  );
};

export default EnhancedTaskSideMenu;
