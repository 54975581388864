import axios, { AxiosError } from 'axios';
import camelcaseKeys from 'camelcase-keys';
import { useQuery, UseQueryResult } from 'react-query';
import { Effect, isEffects } from '../models/effect';

export type FormData = {
  periodId: number;
};

const getDeletePeriodEffects = async (
  formData: FormData,
): Promise<Effect[]> => {
  const response = await axios.post(
    `${
      process.env.REACT_APP_API_URL ?? ''
    }/api/system/position/getDeletePeriodEffects`,
    { periodId: formData.periodId },
  );

  const effects = (await camelcaseKeys(response.data, {
    deep: true,
  })) as unknown[];

  if (!isEffects(effects)) {
    throw Error('API type error');
  }

  return effects;
};

const useGetDeletePeriodEffects = (
  periodId: number,
  isSuspense: boolean,
): UseQueryResult<Effect[], AxiosError> =>
  useQuery(
    ['newPeriodEffect', periodId],
    () => getDeletePeriodEffects({ periodId }),
    {
      suspense: isSuspense,
      staleTime: 0,
    },
  );

export default useGetDeletePeriodEffects;
