import { ReactElement, VFC } from 'react';
import EffectCheckFormDialog from 'components/03.organisms/Common/EffectCheckFormDialog';

type Props = {
  isOpen: boolean;
  handleOk: () => void;
  handleCancel: () => void;
  effectTable: ReactElement;
  otherEffect: ReactElement;
};

const DeletePeriodDialog: VFC<Props> = ({
  effectTable,
  otherEffect,
  isOpen,
  handleOk,
  handleCancel,
}) => (
  <EffectCheckFormDialog
    title="期間データの削除"
    confirmMsg="閲覧中の期間データを削除します。"
    okButtonName="削除"
    cancelButtonName="キャンセル"
    maxWidth="md"
    effectTable={effectTable}
    otherEffect={otherEffect}
    isOpen={isOpen}
    handleOk={handleOk}
    handleCancel={handleCancel}
  />
);

export default DeletePeriodDialog;
