import {
  VFC,
  forwardRef,
  MouseEventHandler,
  Dispatch,
  SetStateAction,
  ChangeEvent,
} from 'react';
import MenuItem from '@material-ui/core/MenuItem';
import { isAfter, parse } from 'date-fns';
import CustomInputInDateSelect from 'components/01.atoms/Common/Basic/CustomInputInDateSelect';
import DateSelectInput from 'components/02.molecules/WorkReport/Common/DateSelectInput';
import { SYSTEM_USAGE_PERIOD } from 'utils/const';
import { css } from '@emotion/react';
import TextField from '@material-ui/core/TextField';
import { useQueryClient } from 'react-query';
import { UserDetail } from 'domains/user';

type PeriodStateProps = {
  isContinued: boolean;
  start: Date;
  end: Date | null;
};

type Props = {
  viewUserId: number;
  period: PeriodStateProps;
  setPeriod: Dispatch<SetStateAction<PeriodStateProps>>;
};

const PositionPeriodInputGroup: VFC<Props> = ({
  period,
  setPeriod,
  viewUserId,
}) => {
  const queryClient = useQueryClient();
  const details = queryClient.getQueryData<UserDetail>([
    'system',
    'userDetail',
    viewUserId,
  ]);

  type CustomProps = {
    onClick?: MouseEventHandler<HTMLDivElement>;
  };
  const CustomStart = forwardRef<HTMLDivElement, CustomProps>(
    ({ onClick }, ref) => (
      <CustomInputInDateSelect
        label="開始日"
        variant="standard"
        value={period.start}
        ref={ref}
        onClick={onClick}
      />
    ),
  );
  const CustomEnd = forwardRef<HTMLDivElement, CustomProps>(
    ({ onClick }, ref) => (
      <CustomInputInDateSelect
        label="終了日"
        variant="standard"
        value={period.end}
        ref={ref}
        onClick={onClick}
      />
    ),
  );

  const handleChangeIsContinued = (
    event: ChangeEvent<{
      name?: string | undefined;
      value: unknown;
    }>,
  ) => {
    const isContinuedNew = !!event.target.value;

    if (isContinuedNew) {
      setPeriod((prevState) => ({
        ...prevState,
        isContinued: isContinuedNew,
        end: parse(SYSTEM_USAGE_PERIOD.END, 'yyyy/M/d', new Date()),
      }));
    }

    if (!isContinuedNew) {
      setPeriod((prevState) => ({
        ...prevState,
        isContinued: isContinuedNew,
        end: null,
      }));
    }
  };

  const handleChangeStart = (date: Date) => {
    setPeriod((prevState) => ({
      ...prevState,
      start: date,
      end: prevState.end && isAfter(date, prevState.end) ? null : prevState.end,
    }));
  };

  const handleChangeEnd = (date: Date) => {
    setPeriod((prevState) => ({
      ...prevState,
      end: date,
    }));
  };

  return (
    <div
      css={css`
        display: flex;
        column-gap: 12px;
        align-items: flex-start;
      `}
    >
      <div>
        <DateSelectInput
          viewDate={period.start}
          customInput={<CustomStart />}
          minDate={
            details?.basic
              ? parse(details.basic.firstDate, 'yyyy-MM-dd', new Date())
              : parse(SYSTEM_USAGE_PERIOD.START, 'yyyy/M/d', new Date())
          }
          addChangeAction={handleChangeStart}
          openToDate={period.start}
        />
      </div>
      <TextField
        css={css`
          width: 100px;
          margin-top: 32px;
        `}
        value={Number(period.isContinued)}
        onChange={handleChangeIsContinued}
        select
      >
        <MenuItem key="isContinued1" value={1}>
          以降継続
        </MenuItem>
        <MenuItem key="isContinued2" value={0}>
          ～
        </MenuItem>
      </TextField>
      <div>
        {period.isContinued || (
          <DateSelectInput
            viewDate={period.end}
            customInput={<CustomEnd />}
            minDate={period.start}
            addChangeAction={handleChangeEnd}
            openToDate={period.end ?? period.start}
          />
        )}
      </div>
    </div>
  );
};

export default PositionPeriodInputGroup;
