import { VFC, ChangeEvent } from 'react';
import { css } from '@emotion/react';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import Box from '@material-ui/core/Box';
import FormLabel from '@material-ui/core/FormLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import { Member } from 'domains/task/projectTree';
import { Skeleton } from '@material-ui/lab';

type Props = {
  ownerId: number;
  ownerOptions: Member[];
  handleOwnerSelect: (event: ChangeEvent<HTMLInputElement>) => void;
  errorMessage?: string;
  isFetching: boolean;
};

const OwnerSelectRadios: VFC<Props> = ({
  ownerId,
  ownerOptions,
  handleOwnerSelect,
  errorMessage,
  isFetching,
}) => (
  <div>
    <div
      css={css`
        margin-bottom: 10px;
      `}
    >
      <FormLabel
        css={css`
          display: inline-block;
          font-size: 0.9rem;
        `}
      >
        担当者
      </FormLabel>
      {!!errorMessage && (
        <FormHelperText
          error
          css={css`
            display: inline-block;
            padding-left: 8px;
          `}
        >
          {errorMessage}
        </FormHelperText>
      )}
    </div>
    <Box ml={2}>
      {isFetching ? (
        [...Array(5).keys()].map((v) => <Skeleton height={40} key={v} />)
      ) : (
        <FormControl>
          <RadioGroup value={ownerId ?? 0} onChange={handleOwnerSelect}>
            {ownerOptions.map((option) => (
              <FormControlLabel
                key={option.memberId}
                value={option.memberId}
                control={<Radio color="primary" />}
                label={option.memberName}
              />
            ))}
          </RadioGroup>
        </FormControl>
      )}
    </Box>
  </div>
);

export default OwnerSelectRadios;
