import { ReactElement, VFC } from 'react';
import BasicConfirmFormDialog from 'components/03.organisms/Common/BasicConfirmFormDialog';

type Props = {
  isOpen: boolean;
  handleOk: () => void;
  handleCancel: () => void;
  textField: ReactElement;
};

const NewPeriodDialog: VFC<Props> = ({
  textField,
  isOpen,
  handleOk,
  handleCancel,
}) => (
  <BasicConfirmFormDialog
    title="新規期間データの作成開始"
    confirmMsg="開始日を指定してください。"
    okButtonName="作成開始"
    cancelButtonName="キャンセル"
    maxWidth="md"
    textField={textField}
    isOpen={isOpen}
    handleOk={handleOk}
    handleCancel={handleCancel}
  />
);

export default NewPeriodDialog;
