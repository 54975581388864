export const WORK_REPORT_STATUS = {
  UNDONE: 0,
  REMANDED: 1,
  SUBMITTED: 2,
  APPROVED: 3,
};

export const WORK_REPORT_STATUS_NAME = {
  [WORK_REPORT_STATUS.UNDONE]: '未提出',
  [WORK_REPORT_STATUS.REMANDED]: '差戻し',
  [WORK_REPORT_STATUS.SUBMITTED]: '提出中',
  [WORK_REPORT_STATUS.APPROVED]: '承認済み',
};

export const WORK_REPORT_STATUS_COLOR = {
  [WORK_REPORT_STATUS.UNDONE]: '#767676',
  [WORK_REPORT_STATUS.REMANDED]: '#fbbd08',
  [WORK_REPORT_STATUS.SUBMITTED]: '#00b5ad',
  [WORK_REPORT_STATUS.APPROVED]: '#2185d0',
};

export const TASK_TYPE = {
  PROJECT: 1,
  ROUTINE: 2,
  SPOT: 3,
};

export const TASK_TYPE_NAME = {
  [TASK_TYPE.PROJECT]: 'プロジェクト',
  [TASK_TYPE.ROUTINE]: 'ルーティン',
  [TASK_TYPE.SPOT]: 'スポット',
};

export const TASK_STATUS_FOR_WORKER = {
  WAITING: 1,
  WORKING: 2,
  DONE: 3,
};

export const TASK_STATUS_FOR_WORKER_NAME = {
  [TASK_STATUS_FOR_WORKER.WAITING]: '未着手',
  [TASK_STATUS_FOR_WORKER.WORKING]: '進行中',
  [TASK_STATUS_FOR_WORKER.DONE]: '完了',
};

export const TASK_STATUS_FOR_APPROVER = {
  NONE: 0,
  IS_OVERDUE: 1,
  ALL_DONE_BEFORE_APPROVE: 2,
  APPROVED: 3,
};

export const TASK_STATUS_FOR_APPROVER_COLOR = {
  [TASK_STATUS_FOR_APPROVER.NONE]: '#767676',
  [TASK_STATUS_FOR_APPROVER.IS_OVERDUE]: '#fbbd08',
  [TASK_STATUS_FOR_APPROVER.ALL_DONE_BEFORE_APPROVE]: '#00b5ad',
  [TASK_STATUS_FOR_APPROVER.APPROVED]: '#2185d0',
};

export const MOVE_TASK_DESCRIPTION = {
  MOVE_TARGET: 1,
  PARENT_TARGET: 2,
  OLD_STRUCTURE: 3,
  NEW_PARENT_OPTION: 4,
};

export const MOVE_TASK_DESCRIPTION_STYLE = {
  [MOVE_TASK_DESCRIPTION.MOVE_TARGET]: {
    COLOR: 'white',
    BORDER: 'thick solid #1976d2',
  },
  [MOVE_TASK_DESCRIPTION.PARENT_TARGET]: { COLOR: '#1976d2' },
  [MOVE_TASK_DESCRIPTION.OLD_STRUCTURE]: { COLOR: '#e7e7e7' },
  [MOVE_TASK_DESCRIPTION.NEW_PARENT_OPTION]: { COLOR: '#fff9c4' },
};

export const SYSTEM_USAGE_PERIOD = {
  START: process.env.REACT_APP_SYSTEM_START_DATE ?? '2021/1/1',
  END: '9999/12/31',
};

export const ORG_TYPE = {
  NORMAL: 1,
  BOTTOM_TEAM: 2,
  BOTTOM_MEMBER: 3,
};

export const ORG_TYPE_NAME = {
  [ORG_TYPE.NORMAL]: '通常レベル',
  [ORG_TYPE.BOTTOM_TEAM]: '最下層レベル',
  [ORG_TYPE.BOTTOM_MEMBER]: 'メンバー枠',
};

export const AUTHORITY_LEVEL = {
  SYSTEM: 1,
  MANAGER: 5,
  STAFF: 10,
};

export const AUTHORITY_LEVEL_NAME = {
  [AUTHORITY_LEVEL.SYSTEM]: 'システム管理者',
  [AUTHORITY_LEVEL.MANAGER]: 'マネージャー',
  [AUTHORITY_LEVEL.STAFF]: 'スタッフ',
};

export const NOTIFICATION_EFFECT = {
  WORK_REPORT: {
    SUBMIT: 1,
    REMAND: 2,
    APPROVE: 3,
  },
  TASK: {
    APPROVE_COMPLETED: {
      ENABLE: 4,
      EXECUTE: 5,
      CANCEL: 6,
    },
    ADD_SUB_TASK_REQUEST: {
      EXECUTE: 7,
      REMAND: 8,
      APPROVE: 9,
    },
    ADD_SUB_TASK: 10,
    EDIT: {
      BASIC: 11,
      IS_LEAF_NODE: {
        TRUE: 12,
        FALSE: 13,
      },
      OWNER: {
        TO_SELF: {
          ADD: 14,
          REMOVE: 15,
        },
        TO_CONCERNED: {
          ADD: 16,
          REMOVE: 17,
          CHANGE: 18,
        },
      },
      MEMBER: {
        TO_SELF: {
          ADD: 19,
          REMOVE: 20,
        },
        TO_CONCERNED: {
          ADD: 21,
          REMOVE: 22,
          CHANGE: 23,
        },
      },
    },
    DELETE: 24,
    MOVE: 25,
    DUE_DATE: {
      IS_OVER: 26,
      CHANGE_BY_RIPPLE_EFFECT_: 27,
    },
    PROGRESS_LEVEL: {
      CHANGE_IN_PROGRESS_BY_REMAND: 28,
    },
    SPOT_EDIT: 29,
  },
};

export const NOTIFICATION_TRIGGER = {
  WORK_REPORT: {
    SUBMIT: 1,
    REMAND: 2,
    APPROVE: 3,
  },
  TASK: {
    COMPLETED: {
      REMAND: 4,
      APPROVE: 5,
      CANCEL_APPROVE: 6,
    },
    ADD_SUBTASK_REQUEST: {
      NEW_REQUEST: 7,
      RE_REQUEST: 8,
      REMAND: 9,
      APPROVE: 10,
    },
    ADD_SUB_TASK: 11,
    CHANGE_STATUS: {
      DONE: 31,
    },
    EDIT: {
      BASIC: 12,
      LEAF_NODE: {
        VALID: 13,
        INVALID: 14,
      },
      OWNER: {
        ADD: 15,
        REMOVE: 16,
        CHANGE: 17,
      },
      MEMBER: {
        ADD: 18,
        REMOVE: 19,
        CHANGE: 20,
      },
    },
    DELETE: 21,
    MOVE: 22,
    SCHEDULER: {
      OVER_DUE_DATE: 23,
    },
    PROGRESS_LEVEL: {
      ALL_DONE: 24,
    },
  },
  SPOT_TASK: {
    NEW_TASK: 25,
    DELETE: 26,
    EDIT: 27,
    PROGRESS_LEVEL: {
      ALL_DONE: 28,
    },
  },
};
