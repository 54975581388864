import { VFC } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import Backdrop from '@material-ui/core/Backdrop';
import { css } from '@emotion/react';

const LoadingBackDrop: VFC = () => (
  <Backdrop
    css={css`
      z-index: 1301;
      color: white;
    `}
    open
  >
    <CircularProgress color="inherit" />
  </Backdrop>
);

export default LoadingBackDrop;
