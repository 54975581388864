import { QueryObserverResult, useQuery, useQueryClient } from 'react-query';
import axios, { AxiosError } from 'axios';
import { isUser, User } from '../models/user';

const getLoginUser = async (): Promise<User> => {
  const response = await axios.get(
    `${process.env.REACT_APP_API_URL ?? ''}/api/user`,
  );
  const user = (await response.data) as unknown;

  if (!isUser(user)) {
    throw Error('API type error');
  }

  return user;
};

const useGetUserQuery = <TData = User>(): QueryObserverResult<
  TData,
  AxiosError
> => {
  const queryClient = useQueryClient();

  return useQuery('user', getLoginUser, {
    retry: 0,
    initialData: undefined,
    onError: ({ response }) => {
      if (response?.status === 401) {
        queryClient.removeQueries();
      }

      queryClient.setQueryData('user', null);
    },
  });
};

export default useGetUserQuery;
