import axios, { AxiosError } from 'axios';
import camelcaseKeys from 'camelcase-keys';
import { format } from 'date-fns';
import { useQuery, UseQueryResult } from 'react-query';
import { Effect, isEffects } from '../models/effect';

export type FormData = {
  periodId: number;
  start: Date | null;
  end: Date | null;
};

const getEditPeriodEffects = async (formData: FormData): Promise<Effect[]> => {
  const response = await axios.post(
    `${
      process.env.REACT_APP_API_URL ?? ''
    }/api/system/position/getEditPeriodEffects`,
    {
      periodId: formData.periodId,
      start: formData.start ? format(formData.start, 'yyyy-M-d') : null,
      end: formData.end ? format(formData.end, 'yyyy-M-d') : null,
    },
  );

  const effects = (await camelcaseKeys(response.data, {
    deep: true,
  })) as unknown[];

  if (!isEffects(effects)) {
    throw Error('API type error');
  }

  return effects;
};

const useGetEditPeriodEffects = (
  periodId: number,
  start: Date | null,
  end: Date | null,
  isSuspense: boolean,
): UseQueryResult<Effect[], AxiosError> =>
  useQuery(
    ['editPeriodEffect', periodId, start, end],
    () => getEditPeriodEffects({ start, end, periodId }),
    {
      suspense: isSuspense,
      staleTime: 0,
    },
  );

export default useGetEditPeriodEffects;
