import { ReactElement, VFC } from 'react';
import BasicSelectDialog from 'components/03.organisms/Common/BasicSelectDialog';

type Props = {
  isOpen: boolean;
  handleCancel: () => void;
  userList: ReactElement;
};

const UserOrganizationSelectDialog: VFC<Props> = ({
  isOpen,
  handleCancel,
  userList,
}) => (
  <BasicSelectDialog
    title="所属者の選択"
    isOpen={isOpen}
    handleCancel={handleCancel}
    list={userList}
  />
);

export default UserOrganizationSelectDialog;
