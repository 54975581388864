import { FC } from 'react';
import { css } from '@emotion/react';
import BasicButton from 'components/01.atoms/Common/Basic/BasicButton';

const loginStyle = css`
  width: 130px;
  height: 40px;
  margin: 20px 50px;
  font-size: medium;
  color: white;
  background-color: transparent;
  border: double 1px white;
  border-radius: 10px;
  &:hover {
    color: #fff8b3;
    background-color: transparent;
    border: 1px solid #fff8b3;
    box-shadow: 0 0 7px #fff8b3;
    transition: 0.25s;
  }
`;

const LoginButton: FC = ({ children }) => (
  <BasicButton addCss={loginStyle} isPost>
    {children}
  </BasicButton>
);

export default LoginButton;
