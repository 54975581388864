import { VFC } from 'react';
import DetailListWithButton from 'components/02.molecules/Common/DetailListWithButton';
import { OrganizationPeriod, SubOrg } from 'domains/organization';
import AddSubOrgButton from 'containers/01.atoms/Organization/AddSubOrgButton';

export type Props = {
  subOrgs: SubOrg[];
  viewPeriod: OrganizationPeriod;
  activeOrgId: number;
};

const SubOrgDetailList: VFC<Props> = ({ subOrgs, viewPeriod, activeOrgId }) => {
  const dataLists = subOrgs?.map((subOrg, index) => ({
    key: `subOrgDetail${index}`,
    title: '',
    content: subOrg.orgName,
  }));

  return (
    <DetailListWithButton
      label="サブ組織"
      dataLists={dataLists}
      buttonElements={
        <AddSubOrgButton viewPeriod={viewPeriod} activeOrgId={activeOrgId} />
      }
    />
  );
};

export default SubOrgDetailList;
