export type BasicDetail = {
  id: number;
  employeeNumber: string;
  name: string;
  nameKana: string;
  email: string;
  firstDate: string;
  lastDate: string;
  authorityLevel: number;
};
const isBasicDetail = (arg: unknown): arg is BasicDetail => {
  const b = arg as BasicDetail;

  return (
    typeof b?.id === 'number' &&
    typeof b?.employeeNumber === 'string' &&
    typeof b?.name === 'string' &&
    typeof b?.nameKana === 'string' &&
    typeof b?.email === 'string' &&
    typeof b?.firstDate === 'string' &&
    typeof b?.lastDate === 'string' &&
    typeof b?.authorityLevel === 'number'
  );
};

const isBasicDetails = (args: unknown[]): args is BasicDetail[] =>
  !args.some((arg) => !isBasicDetail(arg));

export { isBasicDetail, isBasicDetails };
