import { VFC } from 'react';
import MainContentsHeader from 'containers/03.organisms/User/MainContentsHeader';
import MainContentsMainArea from 'containers/03.organisms/User/MainContentsMainArea';
import MainContentsAreaMainOnly from 'components/03.organisms/Common/MainContentsAreaMainOnly';

const EnhancedMainContentsArea: VFC = () => (
  <>
    <MainContentsAreaMainOnly
      headerArea={<MainContentsHeader />}
      mainArea={<MainContentsMainArea />}
    />
  </>
);

export default EnhancedMainContentsArea;
