import axios, { AxiosError } from 'axios';
import camelcaseKeys from 'camelcase-keys';
import format from 'date-fns/format';
import { isSuccessOnly, SuccessOnly } from 'domains/common';
import ErrorResponse from 'domains/common/models/error';
import { UseMutationResult, useMutation, useQueryClient } from 'react-query';
import { OrganizationState } from '../models/orgOption';

type FormProps = OrganizationState & { userId: number };

const resetEditMainOrganization = async (
  formData: FormProps,
): Promise<SuccessOnly> => {
  const response = await axios.post(
    `${
      process.env.REACT_APP_API_URL ?? ''
    }/api/system/user/resetEditMainOrganization`,
    {
      ...formData,
      start: formData.start ? format(formData.start, 'yyyy-M-d') : null,
      end: formData.end ? format(formData.end, 'yyyy-M-d') : null,
    },
  );

  const success = (await camelcaseKeys(response.data, {
    deep: true,
  })) as unknown[];

  if (!isSuccessOnly(success)) {
    throw Error('API type error');
  }

  return success;
};

const useResetEditMainOrganization = (
  userId: number,
  setIsOpen: (arg: boolean) => void,
  reset: () => void,
): UseMutationResult<
  SuccessOnly,
  AxiosError<ErrorResponse>,
  FormProps,
  undefined
> => {
  const queryClient = useQueryClient();

  return useMutation(resetEditMainOrganization, {
    onSuccess: async () => {
      const p1 = queryClient.invalidateQueries([
        'system',
        'userDetail',
        userId,
      ]);
      const p2 = queryClient.invalidateQueries(['system', 'users']);
      await Promise.all([p1, p2]);
      setIsOpen(false);
      reset();
    },
  });
};

export default useResetEditMainOrganization;
