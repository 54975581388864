import { ReactElement, VFC } from 'react';
import { css } from '@emotion/react';

type Props = {
  icon: ReactElement;
  text: string;
};

const iconStyle = css`
  box-sizing: border-box;
  display: inline-block;
  margin-right: 0.25rem;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 900;
  font-variant: normal;
  line-height: 1;
  color: #0070c0;
  text-align: left !important;
  word-wrap: break-word;
  transition: ease-in-out 300ms;
  -webkit-font-smoothing: antialiased;
  text-rendering: auto;
`;

const textStyle = css`
  box-sizing: border-box;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  word-wrap: break-word;
`;

const DescriptionWithIcon: VFC<Props> = ({ icon, text }) => (
  <>
    <span css={iconStyle}>{icon}</span>
    <span css={textStyle}>{text}</span>
  </>
);

export default DescriptionWithIcon;
