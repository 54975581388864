import { VFC, MouseEventHandler, useEffect, useState } from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import UserOrganizationSelectDialog from 'components/03.organisms/User/UserOrganizationSelectDialog';
import {
  OrganizationUserOption,
  OrganizationUserState,
  useGetOrganizationUserOptions,
} from 'domains/user';
import Skeleton from '@material-ui/lab/Skeleton';
import { css } from '@emotion/react';

type Props = {
  rows: OrganizationUserState[];
  rowNumber: number;
  isOpen: boolean;
  setIsOpen: (arg: boolean) => void;
  handleClick: MouseEventHandler<HTMLDivElement>;
  scopeEnd?: Date | null;
};

const EnhancedOrganizationUserSelectDialog: VFC<Props> = ({
  rows,
  rowNumber,
  isOpen,
  setIsOpen,
  handleClick,
  scopeEnd,
}) => {
  const handleCancel = () => {
    setIsOpen(false);
  };
  const { start, end, isContinued } = rows[rowNumber];
  const [userLists, setUserLists] = useState([] as OrganizationUserOption[]);

  const { mutate, isLoading } = useGetOrganizationUserOptions(setUserLists);

  useEffect(() => {
    if (start && (end || isContinued)) {
      mutate({ start, end, isContinued, maxEnd: scopeEnd });
    } else {
      setUserLists([]);
    }
  }, [start, end, isContinued, scopeEnd, mutate]);

  return (
    <UserOrganizationSelectDialog
      isOpen={isOpen}
      handleCancel={handleCancel}
      userList={
        isLoading ? (
          <>
            {[...Array(4).keys()].map((v) => (
              <Skeleton variant="rect" animation="wave" height={42} key={v} />
            ))}
          </>
        ) : (
          <List
            css={css`
              overflow: auto;
            `}
          >
            {userLists.map((user) => (
              <ListItem
                button
                key={user.userId}
                onClick={handleClick}
                data-id={user.userId}
                data-name={user.userName}
                data-row-number={rowNumber}
              >
                <ListItemText primary={user.userName} />
              </ListItem>
            ))}
          </List>
        )
      }
    />
  );
};

export default EnhancedOrganizationUserSelectDialog;
