import { forwardRef, ReactElement, VFC } from 'react';
import {
  Dialog,
  Button,
  AppBar,
  IconButton,
  Toolbar,
  Typography,
  PropTypes,
} from '@material-ui/core';
import Slide from '@material-ui/core/Slide';
import CloseIcon from '@material-ui/icons/Close';
import { css } from '@emotion/react';
import { TransitionProps } from '@material-ui/core/transitions';

type Props = {
  color?: PropTypes.Color | 'transparent';
  title: string;
  okButtonName?: string;
  isOpen: boolean;
  handleOk?: () => void;
  handleCancel: () => void;
  contentsArea: ReactElement;
  titleNextArea?: ReactElement;
};

const Transition = forwardRef(
  (
    props: TransitionProps,
    ref: React.Ref<unknown>,
    // eslint-disable-next-line react/jsx-props-no-spreading
  ) => <Slide direction="up" ref={ref} {...props} />,
);

const BasicFullSizeDialog: VFC<Props> = ({
  color = 'primary',
  title,
  okButtonName,
  contentsArea,
  titleNextArea,
  isOpen,
  handleOk,
  handleCancel,
}) => (
  <Dialog
    fullScreen
    open={isOpen}
    onClose={handleCancel}
    TransitionComponent={Transition}
  >
    <AppBar
      css={css`
        position: relative;
      `}
      color={color}
    >
      <Toolbar>
        <IconButton
          edge="start"
          color="inherit"
          onClick={handleCancel}
          aria-label="close"
        >
          <CloseIcon />
        </IconButton>
        <Typography
          variant="h6"
          css={css`
            margin-left: 8px;
          `}
        >
          {title}
        </Typography>
        {titleNextArea}
        {okButtonName && (
          <Button
            autoFocus
            color="inherit"
            onClick={handleOk}
            css={css`
              position: absolute;
              right: 5px;
            `}
          >
            {okButtonName}
          </Button>
        )}
      </Toolbar>
    </AppBar>
    <div>{contentsArea}</div>
  </Dialog>
);

export default BasicFullSizeDialog;
