import { Dispatch, SetStateAction, VFC } from 'react';
import { TreeState } from 'domains/common';
import useControllTreeDepth from 'hooks/use-controllTreeDepth';
import TreeController from 'components/02.molecules/Organization/TreeController';

export type Props = {
  maxDepthLevel: number;
  treeState: TreeState;
  setTreeState: Dispatch<SetStateAction<TreeState>>;
};

const EnhancedTreeController: VFC<Props> = ({
  treeState,
  setTreeState,
  maxDepthLevel,
}) => {
  const { increment, decrement, changeMin, changeMax } = useControllTreeDepth({
    maxDepthLevel,
    setTreeState,
  });

  return (
    <TreeController
      treeState={treeState}
      maxDepthLevel={maxDepthLevel}
      increment={increment}
      decrement={decrement}
      changeMin={changeMin}
      changeMax={changeMax}
    />
  );
};

export default EnhancedTreeController;
