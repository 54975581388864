import { VFC } from 'react';
import OtherReportViews from 'components/02.molecules/WorkReport/Approve/OtherReportViews';
import { useQuery } from 'react-query';
import { format } from 'date-fns';
import { getDailyOtherReportByUserId } from 'domains/workReport';

type FormProps = {
  viewDate: Date;
  viewStaffId: number;
};

const EnhancedOtherReportViews: VFC<FormProps> = ({
  viewDate,
  viewStaffId,
}) => {
  const { data: otherReport = { otherReport1: '', otherReport2: '' } } =
    useQuery(
      ['staffOtherReport', format(viewDate, 'yyyy-M-d'), viewStaffId],
      () =>
        getDailyOtherReportByUserId({
          viewDate: format(viewDate, 'yyyy-M-d'),
          userId: viewStaffId,
        }),
    );

  return <OtherReportViews otherReport={otherReport} />;
};
export default EnhancedOtherReportViews;
