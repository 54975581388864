import { VFC, useState } from 'react';
import SnackBars from 'components/02.molecules/Common/SnackBars';
import Button from '@material-ui/core/Button';
import { PositionState, useResetEditPositions } from 'domains/user';
import BasicConfirmFormDialog from 'components/03.organisms/Common/BasicConfirmFormDialog';
import ErrorResponse from 'domains/common/models/error';
import PositionPeriodInputGroup from 'containers/02.molecules/User/PositionPeriodInputGroup';
import PositionDataGridEditReset from 'containers/02.molecules/User/PositionDataGridEditReset';
import FormHelperText from '@material-ui/core/FormHelperText';
import { css } from '@emotion/react';
import Box from '@material-ui/core/Box';
import getPositionsError from 'utils/function/error/getPositionsError';

type Props = {
  viewUserId: number;
};

type PeriodStateProps = {
  isContinued: boolean;
  start: Date;
  end: Date | null;
};

const ResetEditPositionButton: VFC<Props> = ({ viewUserId }) => {
  const initialPeriod = {
    isContinued: true,
    start: new Date(),
    end: null,
  } as PeriodStateProps;

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [period, setPeriod] = useState(initialPeriod);
  const [positionState, setPositionState] = useState([] as PositionState[]);

  const handleReset = () => {
    setPeriod(initialPeriod);
    setPositionState([]);
  };

  const queryKeys = [
    ['system', 'userDetail', viewUserId],
    ['system', 'users'],
  ];
  const {
    mutate,
    status,
    reset: queryReset,
    error = {
      response: {
        data: {} as ErrorResponse,
      },
    },
  } = useResetEditPositions(setIsModalOpen, queryKeys, handleReset);

  const handleCancel = () => {
    setIsModalOpen(false);
    queryReset();
  };

  const handleOk = () => {
    mutate({ userId: viewUserId, positions: positionState });
  };

  const positionsError = getPositionsError(error, positionState);

  return (
    <>
      <Button
        variant="outlined"
        size="small"
        color="primary"
        onClick={() => setIsModalOpen(true)}
      >
        編集
      </Button>
      <SnackBars
        status={status}
        loadingMsg="更新中..."
        successMsg="更新が完了しました"
        errorMsg="更新に失敗しました"
      />
      <BasicConfirmFormDialog
        title="役職の編集"
        confirmMsg="指定期間のユーザーの役職を更新します。"
        maxWidth="md"
        okButtonName="更新"
        cancelButtonName="キャンセル"
        textField={
          <div
            css={css`
              width: 700px;
            `}
          >
            <PositionPeriodInputGroup
              period={period}
              setPeriod={setPeriod}
              viewUserId={viewUserId}
            />
            {!!positionsError && (
              <Box pb={1} pt={2}>
                <FormHelperText error>{positionsError}</FormHelperText>
              </Box>
            )}
            <PositionDataGridEditReset
              period={period}
              positionState={positionState}
              setPositionState={setPositionState}
              emptyMessage="役職選択のために期間入力が必要です"
            />
          </div>
        }
        isOpen={isModalOpen}
        handleOk={handleOk}
        handleCancel={handleCancel}
      />
    </>
  );
};

export default ResetEditPositionButton;
