import { ReactElement, VFC } from 'react';
import EffectCheckFormDialog from 'components/03.organisms/Common/EffectCheckFormDialog';

type Props = {
  isOpen: boolean;
  handleOk: () => void;
  handleCancel: () => void;
  textField: ReactElement;
  effectTable: ReactElement;
  otherEffect: ReactElement;
};

const NewPeriodDialog: VFC<Props> = ({
  textField,
  effectTable,
  otherEffect,
  isOpen,
  handleOk,
  handleCancel,
}) => (
  <EffectCheckFormDialog
    title="新しい期間データの作成"
    confirmMsg="新しく作成する期間の開始日を指定してください。"
    okButtonName="登録"
    cancelButtonName="キャンセル"
    maxWidth="md"
    textField={textField}
    effectTable={effectTable}
    otherEffect={otherEffect}
    isOpen={isOpen}
    handleOk={handleOk}
    handleCancel={handleCancel}
  />
);

export default NewPeriodDialog;
