import { VFC } from 'react';
import DetailList from 'components/02.molecules/Common/DetailList';
import { BasicDetail } from 'domains/organization';
import { ORG_TYPE_NAME } from 'utils/const';

export type Props = {
  basic: BasicDetail;
};

const BasicDetailList: VFC<Props> = ({ basic }) => {
  const dataLists = [
    {
      key: 'orgDetail1',
      title: '組織名(マスター名称)',
      content: basic.masterName,
    },
    {
      key: 'orgDetail2',
      title: '組織名(作成中の期間表示用)',
      content: basic.name,
    },
    {
      key: 'orgDetail3',
      title: '組織タイプ',
      content: ORG_TYPE_NAME[basic.orgType],
    },
  ];

  return <DetailList label="詳細" dataLists={dataLists} />;
};

export default BasicDetailList;
