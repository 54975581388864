import { VFC, ReactElement } from 'react';
import EditPeriodDialog from 'components/03.organisms/Organization/Edit/EditPeriodDialog';
import DateSelectInput from 'components/02.molecules/WorkReport/Common/DateSelectInput';
import { SYSTEM_USAGE_PERIOD } from 'utils/const';
import parse from 'date-fns/parse';

type Props = {
  isOpen: boolean;
  handleOk: () => void;
  handleCancel: () => void;
  start: Date | null;
  setStart: (arg: Date | null) => void;
  customStart: ReactElement;
};

const EnhancedEditPeriodDialog: VFC<Props> = ({
  isOpen,
  handleOk,
  handleCancel,
  start,
  setStart,
  customStart,
}) => (
  <EditPeriodDialog
    textField={
      <DateSelectInput
        setViewDate={setStart}
        viewDate={start}
        customInput={customStart}
        minDate={parse(SYSTEM_USAGE_PERIOD.START, 'yyyy/M/d', new Date())}
      />
    }
    isOpen={isOpen}
    handleOk={handleOk}
    handleCancel={handleCancel}
  />
);

export default EnhancedEditPeriodDialog;
