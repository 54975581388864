import { VFC } from 'react';
import MainContentsHeader from 'containers/03.organisms/Position/MainContentsHeader';
import MainContentsArea from 'components/03.organisms/Common/MainContentsAreaPatternA';
import MainContentsSideArea from 'components/03.organisms/Position/MainContentsSideArea';
import { PositionPeriod } from 'domains/position';
import PeriodSelectDialog from 'containers/02.molecules/Position/PeriodSelectDialog';
import MainContentsMainArea from 'containers/03.organisms/Position/MainContentsMainArea';

type Props = {
  viewPeriod: PositionPeriod;
  setViewPeriod: (arg: PositionPeriod) => void;
};

const EnhancedMainContentsArea: VFC<Props> = ({
  viewPeriod,
  setViewPeriod,
}) => (
  <>
    {viewPeriod.id ? (
      <MainContentsArea
        headerArea={<MainContentsHeader viewPeriod={viewPeriod} />}
        sideArea={
          <MainContentsSideArea
            viewPeriod={viewPeriod}
            setViewPeriod={setViewPeriod}
          />
        }
        mainArea={<MainContentsMainArea viewPeriod={viewPeriod} />}
      />
    ) : (
      <>
        <MainContentsArea
          headerArea={<div />}
          sideArea={<div />}
          mainArea={<div />}
        />
        <PeriodSelectDialog setViewPeriod={setViewPeriod} />
      </>
    )}
  </>
);

export default EnhancedMainContentsArea;
