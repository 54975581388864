import { VFC, MouseEventHandler, useEffect, useState } from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import MainOrgSelectDialog from 'components/03.organisms/User/MainOrgSelectDialog';
import {
  OrganizationOption,
  OrganizationState,
  useGetOrganizationOptions,
} from 'domains/user';
import Skeleton from '@material-ui/lab/Skeleton';
import { css } from '@emotion/react';

type Props = {
  rows: OrganizationState[];
  rowNumber: number;
  isOpen: boolean;
  setIsOpen: (arg: boolean) => void;
  handleClick: MouseEventHandler<HTMLDivElement>;
  scopeEnd?: Date | null;
};

const EnhancedMainOrgSelectDialog: VFC<Props> = ({
  rows,
  rowNumber,
  isOpen,
  setIsOpen,
  handleClick,
  scopeEnd,
}) => {
  const handleCancel = () => {
    setIsOpen(false);
  };
  const { start, end, isContinued } = rows[rowNumber];
  const [orgLists, setOrgLists] = useState([] as OrganizationOption[]);

  const { mutate, isLoading } = useGetOrganizationOptions(setOrgLists);

  useEffect(() => {
    if (start && (end || isContinued)) {
      mutate({ start, end, isContinued, maxEnd: scopeEnd });
    } else {
      setOrgLists([]);
    }
  }, [start, end, isContinued, scopeEnd, mutate]);

  return (
    <MainOrgSelectDialog
      isOpen={isOpen}
      handleCancel={handleCancel}
      orgList={
        isLoading ? (
          <>
            {[...Array(4).keys()].map((v) => (
              <Skeleton variant="rect" animation="wave" height={42} key={v} />
            ))}
          </>
        ) : (
          <List
            css={css`
              overflow: auto;
            `}
          >
            {orgLists.map((org) => (
              <ListItem
                button
                key={org.orgId}
                onClick={handleClick}
                data-id={org.orgId}
                data-name={org.orgName}
                data-row-number={rowNumber}
              >
                <ListItemText primary={org.orgName} />
              </ListItem>
            ))}
          </List>
        )
      }
    />
  );
};

export default EnhancedMainOrgSelectDialog;
