import { useEffect, VFC } from 'react';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { css } from '@emotion/react';
import SnackBars from 'components/02.molecules/Common/SnackBars';
import BasicConfirmFormDialog from 'components/03.organisms/Common/BasicConfirmFormDialog';
import FormTextField from 'containers/01.atoms/Common/FormTextFiled';
import { useForm } from 'react-hook-form';
import { StrOnlyForm } from 'domains/common';
import { useQueryClient } from 'react-query';
import { useEditUserName, UserDetail } from 'domains/user';

export type Props = {
  viewUserId: number;
  isOpen: boolean;
  setIsOpen: (arg: boolean) => void;
};

const EnhancedEditUserNameDialog: VFC<Props> = ({
  viewUserId,
  isOpen,
  setIsOpen,
}) => {
  const { getValues, setValue, control } = useForm<StrOnlyForm>();
  const {
    mutate,
    status,
    isLoading,
    error = {
      response: {
        data: { errors: { userName: '' } },
      },
    },
  } = useEditUserName(viewUserId, setIsOpen);
  const handleOk = () => {
    mutate({
      userId: viewUserId,
      userName: getValues('name') ?? '',
    });
  };
  const handleCancel = () => {
    setIsOpen(false);
  };

  const queryClient = useQueryClient();
  useEffect(() => {
    const data = queryClient.getQueryData<UserDetail>([
      'system',
      'userDetail',
      viewUserId,
    ]);
    setValue('name', data?.basic.name ?? '');
  }, [setValue, queryClient, viewUserId]);

  return (
    <>
      <BasicConfirmFormDialog
        title="ユーザーの氏名(漢字・英字)の編集"
        confirmMsg="変更後の氏名(漢字・英字)をご記入ください。"
        isOpen={isOpen}
        handleOk={handleOk}
        handleCancel={handleCancel}
        okButtonName="変更"
        cancelButtonName="キャンセル"
        textField={
          <FormTextField
            label="氏名(漢字・英字)"
            placeholder=""
            isMultiLine={false}
            isFullWidth
            variant="standard"
            margin="normal"
            name="name"
            control={control}
            errorMsg={error?.response?.data?.errors.userName}
          />
        }
      />
      <Backdrop
        css={css`
          z-index: 1301;
          color: white;
        `}
        open={isLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <SnackBars
        status={status}
        loadingMsg="変更中..."
        successMsg="変更が完了しました"
        errorMsg="変更に失敗しました"
      />
    </>
  );
};

export default EnhancedEditUserNameDialog;
