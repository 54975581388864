import { VFC } from 'react';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import { FaUsers } from 'react-icons/fa';
import { GiOrganigram } from 'react-icons/gi';
import { css } from '@emotion/react';
import ListItemSelectType from 'components/01.atoms/Common/Basic/ListItemSelectType';
import PersonPinIcon from '@material-ui/icons/PersonPin';
import { RiComputerLine } from 'react-icons/ri';

type Props = {
  pathName: string;
  handleItemClick: (url: string) => void;
};

const SystemSideMenu: VFC<Props> = ({ pathName, handleItemClick }) => (
  <Drawer
    css={css`
      width: max-content;

      & > div {
        position: static;
        z-index: 0;
        width: max-content;
      }
    `}
    variant="permanent"
  >
    <div
      css={css`
        overflow: 'auto';
      `}
    >
      <List>
        <ListItemSelectType
          url="/system"
          pathName={pathName}
          keyName="システム管理ホーム"
          handleItemClick={handleItemClick}
          icon={<RiComputerLine size="1.5rem" />}
        />
        <ListItemSelectType
          url="/system/user"
          pathName={pathName}
          keyName="ユーザー管理"
          handleItemClick={handleItemClick}
          icon={<FaUsers size="1.5rem" />}
        />
        <ListItemSelectType
          url="/system/position"
          pathName={pathName}
          keyName="役職管理"
          handleItemClick={handleItemClick}
          icon={
            <PersonPinIcon
              css={css`
                font-size: 1.5rem;
              `}
            />
          }
        />
        <ListItemSelectType
          url="/system/organization"
          pathName={pathName}
          keyName="組織管理"
          handleItemClick={handleItemClick}
          icon={<GiOrganigram size="1.5rem" />}
        />
      </List>
    </div>
  </Drawer>
);

export default SystemSideMenu;
