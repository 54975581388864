import { VFC, MouseEvent, useState } from 'react';
import TaskStatusToggleButtons from 'components/02.molecules/Task/OwnTask/TaskStatusToggleButtons';
import SnackBars from 'components/02.molecules/Common/SnackBars';
import {
  useCancelStartOwnTask,
  useFinishOwnTask,
  useStartOwnTask,
} from 'domains/task/common';
import { TASK_STATUS_FOR_WORKER } from 'utils/const';
import BasicConfirmDialog from 'components/03.organisms/Common/BasicConfirmDialog';
import BasicAlertDialog from 'components/03.organisms/Common/BasicAlertDialog';

type Props = {
  taskId: number;
  initialStatus: number;
};

const EnhancedTaskStatusToggleButtons: VFC<Props> = ({
  taskId,
  initialStatus,
}) => {
  const [status, setStatus] = useState(initialStatus);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isAlertOpen, setIsAlertOpen] = useState(false);

  const { mutate: mutateStart, status: statusStart } =
    useStartOwnTask(setStatus);
  const { mutate: mutateCancel, status: statusCancel } =
    useCancelStartOwnTask(setStatus);
  const { mutate: mutateFinish, status: statusFinish } = useFinishOwnTask(
    setIsModalOpen,
    setIsAlertOpen,
    setStatus,
  );

  const handleOk = () => {
    mutateFinish({ taskId });
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleChange = (_event: MouseEvent<HTMLElement>, newValue: number) => {
    if (newValue === TASK_STATUS_FOR_WORKER.WAITING) {
      setStatus(newValue);
      mutateCancel({ taskId });
    }

    if (newValue === TASK_STATUS_FOR_WORKER.WORKING) {
      setStatus(newValue);
      mutateStart({ taskId });
    }

    if (newValue === TASK_STATUS_FOR_WORKER.DONE) {
      setIsModalOpen(true);
    }
  };

  return (
    <>
      <TaskStatusToggleButtons status={status} handleChange={handleChange} />
      <SnackBars
        status={statusStart}
        loadingMsg="更新中..."
        successMsg="更新が完了しました"
        errorMsg="更新に失敗しました"
      />
      <SnackBars
        status={statusCancel}
        loadingMsg="更新中..."
        successMsg="更新が完了しました"
        errorMsg="更新に失敗しました"
      />
      <SnackBars
        status={statusFinish}
        loadingMsg="更新中..."
        successMsg="更新が完了しました"
        errorMsg="更新に失敗しました"
      />
      <BasicConfirmDialog
        title="タスクの完了"
        confirmMsg="選択中のタスクを完了にしますか？"
        isOpen={isModalOpen}
        handleOk={handleOk}
        handleCancel={handleCancel}
      />
      <BasicAlertDialog
        title="完了不可"
        confirmMsg="完了未承認のサブタスクがあるため、完了に更新できません。"
        isOpen={isAlertOpen}
        handleCancel={() => setIsAlertOpen(false)}
      />
    </>
  );
};

export default EnhancedTaskStatusToggleButtons;
