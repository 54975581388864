import { VFC } from 'react';
import { useGetApproachingOwnTasks } from 'domains/task/common';
import ApproachingOwnTaskTable from 'components/02.molecules/Home/ApproachingOwnTaskTable';

const EnhancedApproachingOwnTaskTable: VFC = () => {
  const { data: tasks = [], isFetching } = useGetApproachingOwnTasks(false);

  const data = tasks.map((task) => ({
    ...task,
    id: task.taskId,
  }));

  return <ApproachingOwnTaskTable data={data} isLoading={isFetching} />;
};

export default EnhancedApproachingOwnTaskTable;
