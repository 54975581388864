import { VFC, Dispatch, SetStateAction } from 'react';
import MainContentsMainArea from 'components/03.organisms/Organization/MainContentsMainArea';
import TreeStructure from 'containers/02.molecules/Organization/TreeStructure';
import {
  OrganizationPeriod,
  useGetTempOrganizations,
} from 'domains/organization';
import { TreeState } from 'domains/common';
import Skeleton from '@material-ui/lab/Skeleton';
import TreeController from 'containers/02.molecules/Organization/TreeController';

type Props = {
  viewPeriod: OrganizationPeriod;
  treeState: TreeState;
  setTreeState: Dispatch<SetStateAction<TreeState>>;
};

const EnhancedMainContentsMainArea: VFC<Props> = ({
  viewPeriod,
  treeState,
  setTreeState,
}) => {
  const { data: orgs = { maxDepthLevel: 0, organizationTree: [] }, isLoading } =
    useGetTempOrganizations(viewPeriod.id, setTreeState, false);

  return (
    <MainContentsMainArea
      treeController={
        <TreeController
          setTreeState={setTreeState}
          treeState={treeState}
          maxDepthLevel={orgs.maxDepthLevel}
        />
      }
      orgTree={
        <>
          {isLoading ? (
            <Skeleton variant="rect" width="60%" height={500} />
          ) : (
            <TreeStructure
              orgs={orgs.organizationTree}
              depth={1}
              displayableDepth={treeState.displayableDepth}
              activeNodeId={treeState.activeNodeId}
              openableNodeIds={treeState.openableNodeIds}
              disableNodeIds={treeState.disableNodeIds}
              setTreeState={setTreeState}
            />
          )}
        </>
      }
    />
  );
};

export default EnhancedMainContentsMainArea;
