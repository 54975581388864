import {
  VFC,
  useState,
  MouseEventHandler,
  Dispatch,
  SetStateAction,
} from 'react';
import TextField from '@material-ui/core/TextField';
import { OrganizationState } from 'domains/user';
import MainOrgSelectDialog from 'containers/03.organisms/User/MainOrgSelectDialog';

type Props = {
  rowNumber: number;
  rows: OrganizationState[];
  setOrganizationState: Dispatch<SetStateAction<OrganizationState[]>>;
  scopeEnd?: Date | null;
};

const MainOrgSelectInput: VFC<Props> = ({
  rowNumber,
  rows,
  setOrganizationState,
  scopeEnd,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const handleClick: MouseEventHandler<HTMLDivElement> = (event) => {
    const row = Number(
      event.currentTarget.getAttribute('data-row-number') ?? -1,
    );
    const orgId = Number(event.currentTarget.getAttribute('data-id') ?? -1);
    const orgName = event.currentTarget.getAttribute('data-name') ?? '名称なし';
    setOrganizationState((prevState) =>
      prevState.map((el) =>
        el.rowNumber === row ? { ...el, orgId, orgName } : el,
      ),
    );
    setIsOpen(false);
  };

  return (
    <>
      <TextField
        onClick={() => setIsOpen(true)}
        variant="standard"
        margin="normal"
        label=""
        placeholder="未選択"
        value={rows[rowNumber].orgName}
        InputLabelProps={{ shrink: true }}
        fullWidth
      />
      <MainOrgSelectDialog
        rows={rows}
        rowNumber={rowNumber}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        handleClick={handleClick}
        scopeEnd={scopeEnd}
      />
    </>
  );
};

export default MainOrgSelectInput;
