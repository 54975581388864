import { VFC } from 'react';
import { OrgMatch } from 'domains/user';
import DetailListWithButton from 'components/02.molecules/Common/DetailListWithButton';
import convertPeriodText from 'utils/function/convertPeriodText';
import AddSubOrganizationButton from 'containers/01.atoms/User/AddSubOrganizationButton';
import DeleteSubOrgButton from 'containers/01.atoms/User/DeleteSubOrgButton';

export type Props = {
  subOrgs: OrgMatch[];
  viewUserId: number;
};

const SubOrgDetailList: VFC<Props> = ({ subOrgs, viewUserId }) => {
  const dataLists = subOrgs?.map((org, index) => ({
    key: `subOrgDetail${index}`,
    title: convertPeriodText({
      start: org.start,
      end: org.end,
    }),
    content: org.orgName,
    button: (
      <DeleteSubOrgButton matchingId={org.matchId} viewUserId={viewUserId} />
    ),
  }));

  return (
    <DetailListWithButton
      label="所属組織（兼務）"
      dataLists={dataLists}
      buttonElements={<AddSubOrganizationButton viewUserId={viewUserId} />}
    />
  );
};

export default SubOrgDetailList;
