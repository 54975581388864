import { VFC, MouseEvent } from 'react';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreVertIcon from '@material-ui/icons/MoreVert';

type Props = {
  anchorEl: HTMLElement | null;
  canEdit: boolean;
  canApprove: boolean;
  canCancel: boolean;
  canDelete: boolean;
  handleOpen: (event: MouseEvent<HTMLButtonElement>) => void;
  handleClose: () => void;
  handleEdit: () => void;
  handleDelete: () => void;
  handleApprove: () => void;
  handleRemand: () => void;
  handleCancel: () => void;
};

const OperateMenuButton: VFC<Props> = ({
  anchorEl,
  canEdit,
  canApprove,
  canCancel,
  canDelete,
  handleOpen,
  handleClose,
  handleEdit,
  handleApprove,
  handleRemand,
  handleDelete,
  handleCancel,
}) => (
  <>
    <IconButton
      aria-label="operate"
      aria-controls="operate-menu"
      aria-haspopup="true"
      onClick={handleOpen}
    >
      <MoreVertIcon />
    </IconButton>
    <Menu
      anchorEl={anchorEl}
      getContentAnchorEl={null}
      keepMounted
      anchorOrigin={{
        vertical: 45,
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={Boolean(anchorEl)}
      onClose={handleClose}
      disableScrollLock
    >
      {canEdit && <MenuItem onClick={handleEdit}>基本項目の編集</MenuItem>}
      {canApprove && <MenuItem onClick={handleApprove}>完了承認</MenuItem>}
      {canApprove && <MenuItem onClick={handleRemand}>差戻</MenuItem>}
      {canCancel && <MenuItem onClick={handleCancel}>承認解除</MenuItem>}
      {canDelete && <MenuItem onClick={handleDelete}>削除</MenuItem>}
    </Menu>
  </>
);

export default OperateMenuButton;
