import { VFC } from 'react';
import DeletePeriodDialog from 'components/03.organisms/Position/DeletePeriodDialog';
import EffectTextList from 'components/02.molecules/Position/EffectTextList';
import DeletePeriodEffectTable from 'containers/02.molecules/Position/DeletePeriodEffectTable';
import { PositionPeriod } from 'domains/position';

type Props = {
  isOpen: boolean;
  viewPeriod: PositionPeriod;
  handleOk: () => void;
  handleCancel: () => void;
};

const textList = [
  {
    id: 1,
    value: '・無効になった役職名が使用されているユーザーの役職データの削除',
  },
];

const EnhancedDeletePeriodDialog: VFC<Props> = ({
  isOpen,
  handleOk,
  handleCancel,
  viewPeriod,
}) => (
  <DeletePeriodDialog
    effectTable={
      <DeletePeriodEffectTable
        viewPeriod={viewPeriod}
        label="他の期間データへの影響"
      />
    }
    otherEffect={<EffectTextList label="その他の影響" textList={textList} />}
    isOpen={isOpen}
    handleOk={handleOk}
    handleCancel={handleCancel}
  />
);

export default EnhancedDeletePeriodDialog;
