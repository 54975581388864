import { VFC } from 'react';
import { useGetTermsUsersHasNoPositions } from 'domains/system';
import TermsUsersHasNoPositionsTable from 'components/02.molecules/System/TermsUsersHasNoPositionsTable';
import useConvertInvalidListData from 'hooks/System/use-convertInvalidListData';
import InvalidDataLabel from 'components/01.atoms/System/InvalidDataLabel';

const EnhancedTermsUsersHasNoPositionsTable: VFC = () => {
  const { data = [], isLoading } = useGetTermsUsersHasNoPositions(false);
  const convertedData = useConvertInvalidListData({ original: data });

  return (
    <div>
      <InvalidDataLabel label="ユーザーの役職の未登録期間（各ユーザーの入社日から退職日の間に役職が未設定となっている期間）" />
      <TermsUsersHasNoPositionsTable
        data={convertedData}
        isLoading={isLoading}
      />
    </div>
  );
};

export default EnhancedTermsUsersHasNoPositionsTable;
