import { VFC, ReactElement } from 'react';

type Props = {
  headerArea: ReactElement;
  mainArea: ReactElement;
};

const MainContentsAreaMainOnly: VFC<Props> = ({ headerArea, mainArea }) => (
  <div>
    {headerArea}
    <div>{mainArea}</div>
  </div>
);

export default MainContentsAreaMainOnly;
