import axios from 'axios';
import camelcaseKeys from 'camelcase-keys';
import {
  isDailyOtherReport,
  DailyOtherReport,
} from '../models/dailyOtherReport';

type FormData = {
  viewDate: string;
  userId: number;
};

const getDailyOtherReportByUserId = async (
  formData: FormData,
): Promise<DailyOtherReport> => {
  const response = await axios.post(
    `${
      process.env.REACT_APP_API_URL ?? ''
    }/api/workReport/getDailyOtherReportByUserId`,
    formData,
  );

  const otherReport = (await camelcaseKeys(response.data, {
    deep: true,
  })) as unknown;

  if (!isDailyOtherReport(otherReport)) {
    throw Error('API type error');
  }

  return otherReport;
};

export default getDailyOtherReportByUserId;
