import axios, { AxiosError } from 'axios';
import camelcaseKeys from 'camelcase-keys';
import ErrorResponse from 'domains/common/models/error';
import { UseMutationResult, useMutation, useQueryClient } from 'react-query';
import { isPositions, Position } from '../models/position';

type FormData = {
  positionId: number;
  periodId: number;
};

const deletePosition = async (formData: FormData): Promise<Position[]> => {
  const response = await axios.post(
    `${process.env.REACT_APP_API_URL ?? ''}/api/system/position/deletePosition`,
    formData,
  );

  const positions = (await camelcaseKeys(response.data, {
    deep: true,
  })) as unknown[];

  if (!isPositions(positions)) {
    throw Error('API type error');
  }

  return positions;
};

const useDeletePosition = (
  periodId: number,
  setIsOpen: (arg: boolean) => void,
): UseMutationResult<
  Position[],
  AxiosError<ErrorResponse>,
  FormData,
  undefined
> => {
  const queryClient = useQueryClient();

  return useMutation(deletePosition, {
    onSuccess: (data) => {
      queryClient.setQueryData(['positions', periodId], data);
      setIsOpen(false);
    },
  });
};

export default useDeletePosition;
