import axios, { AxiosError } from 'axios';
import camelcaseKeys from 'camelcase-keys';
import { isWithinInterval, parse, format } from 'date-fns';
import ErrorResponse from 'domains/common/models/error';
import { Dispatch, SetStateAction } from 'react';
import { useMutation, UseMutationResult } from 'react-query';
import {
  PositionOption,
  isPositionOptions,
  PositionState,
} from '../models/positionOption';

type FormProps = {
  joiningDate: Date;
};

const getPositionOptions = async (
  formData: FormProps,
): Promise<PositionOption[]> => {
  const response = await axios.post(
    `${process.env.REACT_APP_API_URL ?? ''}/api/system/user/getPositionOptions`,
    {
      joiningDate: format(formData.joiningDate, 'yyyy-M-d'),
    },
  );

  const options = (await camelcaseKeys(response.data, {
    deep: true,
  })) as unknown[];

  if (!isPositionOptions(options)) {
    throw Error('API type error');
  }

  return options;
};

const useGetPositionOptions = (
  joiningDate: Date | null,
  setPositionState: Dispatch<SetStateAction<PositionState[]>>,
): UseMutationResult<
  PositionOption[],
  AxiosError<ErrorResponse>,
  FormProps,
  undefined
> =>
  useMutation(getPositionOptions, {
    onSuccess: (options) => {
      if (joiningDate) {
        setPositionState([]);
        options.forEach((option, index) => {
          const periodStart = isWithinInterval(joiningDate, {
            start: parse(option.start, 'yyyy-MM-dd', new Date()),
            end: parse(option.end, 'yyyy-MM-dd', new Date()),
          })
            ? format(joiningDate, 'yyyy-MM-dd')
            : option.start;

          setPositionState((prevState) => [
            ...prevState,
            {
              ...option,
              start: periodStart,
              rowNumber: index,
              positionId: 0,
              positionName: '',
            },
          ]);
        });
      }
    },
  });

export default useGetPositionOptions;
