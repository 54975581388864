import { VFC } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import SystemSideMenu from 'components/03.organisms/System/SystemSideMenu';

const EnhancedSystemSideMenu: VFC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const handleItemClick = (to: string) => {
    navigate(to);
  };

  return (
    <SystemSideMenu
      pathName={location.pathname}
      handleItemClick={handleItemClick}
    />
  );
};

export default EnhancedSystemSideMenu;
