import { useState, VFC } from 'react';
import PresentationPage from 'components/05.pages/Common/SideAndMainPage';
import HeaderBar from 'containers/03.organisms/Common/CommonHeader';
import SideMenu from 'containers/03.organisms/System/SystemSideMenu';
import MainContents from 'containers/03.organisms/Position/MainContentsArea';
import { PositionPeriod } from 'domains/position';

const EnhancedPositionPage: VFC = () => {
  const [viewPeriod, setViewPeriod] = useState({
    id: 0,
    start: '',
    end: '',
  } as PositionPeriod);

  return (
    <PresentationPage headerBar={<HeaderBar />} sideMenu={<SideMenu />}>
      <MainContents viewPeriod={viewPeriod} setViewPeriod={setViewPeriod} />
    </PresentationPage>
  );
};

export default EnhancedPositionPage;
