import { VFC, Dispatch, SetStateAction } from 'react';
import {
  DataGrid,
  GridRowData,
  GridColDef,
  GridOverlay,
  GridCellParams,
} from '@material-ui/data-grid';
import { css } from '@emotion/react';
import CircularProgress from '@material-ui/core/CircularProgress';
import { PositionState } from 'domains/user';
import convertPeriodText from 'utils/function/convertPeriodText';
import PositionSelectInput from 'containers/02.molecules/User/PositionSelectInput';

const customStyle = css`
  &.MuiDataGrid-root .MuiDataGrid-columnHeader:focus,
  &.MuiDataGrid-root .MuiDataGrid-columnHeader:focus-within,
  &.MuiDataGrid-root .MuiDataGrid-cell:focus,
  &.MuiDataGrid-root .MuiDataGrid-cell:focus-within {
    outline: none;
  }
  &.MuiDataGrid-root .MuiDataGrid-window {
    overflow-y: overlay;
  }
`;

const progressStyle = css`
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -12px;
  margin-left: -12px;
`;

type Props = {
  rows: PositionState[];
  setPositionState: Dispatch<SetStateAction<PositionState[]>>;
  isLoading: boolean;
  emptyMessage: string;
};

const columns = (
  rows: PositionState[],
  setPositionState: Dispatch<SetStateAction<PositionState[]>>,
): GridColDef[] => [
  {
    field: 'period',
    headerName: '適用期間',
    width: 250,
    sortable: false,
  },
  {
    field: 'positionSelect',
    headerName: '役職',
    flex: 1,
    sortable: false,
    renderCell: (params: GridCellParams) => {
      const { value: positionList, getValue, id } = params;

      return (
        <PositionSelectInput
          rowNumber={Number(getValue(id, 'rowNumber'))}
          positionListText={String(positionList)}
          rows={rows}
          setPositionState={setPositionState}
        />
      );
    },
  },
  { field: 'id', hide: true },
  { field: 'rowNumber', hide: true },
];

const convertPositionOptions = (original: PositionState[]): GridRowData[] =>
  original.length === 0
    ? ([] as GridRowData[])
    : original.map((data) => ({
        id: data.periodId,
        period: convertPeriodText({
          start: data.start,
          end: data.end,
        }),
        positionSelect: data.positionList,
        rowNumber: data.rowNumber,
      }));

const PositionInputDataGrid: VFC<Props> = ({
  rows,
  isLoading,
  setPositionState,
  emptyMessage,
}) => {
  const CustomNoRowsOverlay: VFC = () => (
    <GridOverlay>
      <div>{emptyMessage}</div>
    </GridOverlay>
  );

  return (
    <div
      css={css`
        position: relative;
        width: 100%;
      `}
    >
      <DataGrid
        css={customStyle}
        rows={!rows.length ? [] : convertPositionOptions(rows)}
        columns={columns(rows, setPositionState)}
        autoHeight
        hideFooter
        disableColumnMenu
        disableColumnSelector
        disableSelectionOnClick
        components={{
          NoRowsOverlay: CustomNoRowsOverlay,
        }}
      />
      {isLoading && <CircularProgress size={50} css={progressStyle} />}
    </div>
  );
};
export default PositionInputDataGrid;
