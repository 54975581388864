export type PositionMatch = {
  positionId: number;
  userId: number;
  start: string;
  end: string;
  positionName: string;
};
const isPositionMatch = (arg: unknown): arg is PositionMatch => {
  const o = arg as PositionMatch;

  return (
    typeof o?.positionId === 'number' &&
    typeof o?.userId === 'number' &&
    typeof o?.start === 'string' &&
    typeof o?.end === 'string' &&
    typeof o?.positionName === 'string'
  );
};
const isPositionMatches = (args: unknown[]): args is PositionMatch[] =>
  !args.some((arg) => !isPositionMatch(arg));

export { isPositionMatch, isPositionMatches };
