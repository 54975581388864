export type Leader = {
  id: number;
  orgId: number;
  userId: number;
  leaderName: string;
  startDate: string;
  endDate: string;
};
const isLeader = (arg: unknown): arg is Leader => {
  const o = arg as Leader;

  return (
    typeof o?.id === 'number' &&
    typeof o?.orgId === 'number' &&
    typeof o?.userId === 'number' &&
    typeof o?.leaderName === 'string' &&
    typeof o?.startDate === 'string' &&
    typeof o?.endDate === 'string'
  );
};
const isLeaders = (args: unknown[]): args is Leader[] =>
  !args.some((arg) => !isLeader(arg));

export { isLeader, isLeaders };
