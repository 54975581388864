import { VFC } from 'react';
import TermsUsersHasNoMainOrganizationsTable from 'components/02.molecules/System/TermsUsersHasNoMainOrganizationsTable';
import useConvertInvalidListData from 'hooks/System/use-convertInvalidListData';
import { useGetTermsUsersHasNoMainOrganizations } from 'domains/system';
import InvalidDataLabel from 'components/01.atoms/System/InvalidDataLabel';

const EnhancedTermsUsersHasNoMainOrganizationsTable: VFC = () => {
  const { data = [], isLoading } =
    useGetTermsUsersHasNoMainOrganizations(false);
  const convertedData = useConvertInvalidListData({ original: data });

  return (
    <div>
      <InvalidDataLabel label="ユーザーの主務の未登録期間（各ユーザーの入社日から退職日の間に主務が未設定となっている期間）" />
      <TermsUsersHasNoMainOrganizationsTable
        data={convertedData}
        isLoading={isLoading}
      />
    </div>
  );
};

export default EnhancedTermsUsersHasNoMainOrganizationsTable;
