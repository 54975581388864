import { Dispatch, SetStateAction, useEffect, VFC } from 'react';
import PositionInputDataGrid from 'components/02.molecules/User/PositionInputDataGrid';
import { PositionState, useGetEditPositionOptions } from 'domains/user';

type PeriodStateProps = {
  isContinued: boolean;
  start: Date;
  end: Date | null;
};

type Props = {
  period: PeriodStateProps;
  setPositionState: Dispatch<SetStateAction<PositionState[]>>;
  positionState: PositionState[];
  emptyMessage: string;
};

const PositionDataGridEditReset: VFC<Props> = ({
  period,
  positionState,
  setPositionState,
  emptyMessage,
}) => {
  const { mutate, isLoading } = useGetEditPositionOptions(
    period,
    setPositionState,
  );

  useEffect(() => {
    if (period.start && (period.end || period.isContinued)) {
      mutate(period);
    } else {
      setPositionState([]);
    }
  }, [period, mutate, setPositionState]);

  return (
    <PositionInputDataGrid
      rows={positionState}
      isLoading={isLoading}
      setPositionState={setPositionState}
      emptyMessage={emptyMessage}
    />
  );
};

export default PositionDataGridEditReset;
