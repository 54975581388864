export type DailyWorkReport = {
  id: number;
  userId: number;
  categoryId: number;
  referenceTableId: number;
  description: string;
  categoryName: string;
  startDatetime: string;
  endDatetime: string;
};

const isDailyWorkReport = (arg: unknown): arg is DailyWorkReport => {
  const d = arg as DailyWorkReport;

  return (
    typeof d?.id === 'number' &&
    typeof d?.userId === 'number' &&
    typeof d?.categoryId === 'number' &&
    typeof d?.referenceTableId === 'number' &&
    typeof d?.description === 'string' &&
    typeof d?.categoryName === 'string' &&
    typeof d?.startDatetime === 'string' &&
    typeof d?.endDatetime === 'string'
  );
};

const isDailyWorkReports = (args: unknown[]): args is DailyWorkReport[] =>
  !args.some((arg) => !isDailyWorkReport(arg));

export { isDailyWorkReport, isDailyWorkReports };
