import { Suspense, VFC } from 'react';
import { css } from '@emotion/react';
import StatusDescriptionGroup from 'components/02.molecules/WorkReport/Common/StatusDescriptionGroup';
import AttachedInfo from 'containers/02.molecules/WorkReport/Approve/AttachedInfo';
import DateSelector from 'containers/02.molecules/WorkReport/Approve/ReportDateSelector';
import Skeleton from '@material-ui/lab/Skeleton';
import StaffMenu from 'components/01.atoms/WorkReport/Approve/StaffMenu';
import ApproveButton from 'containers/01.atoms/WorkReport/ApproveOrRemandButton';

const adjustPadding = css`
  padding-top: 1rem;
`;

const width100 = css`
  width: 100%;
`;

const verticalMenu = css`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const flexCenter = css`
  display: flex;
  justify-content: center;
`;

type Props = {
  viewDate?: Date;
  viewStaffId: number;
  setViewDate: (arg: Date) => void;
  setViewStaffId: (arg: number) => void;
};

const MainContentsSideArea: VFC<Props> = ({
  viewDate = new Date(),
  viewStaffId,
  setViewDate,
  setViewStaffId,
}) => (
  <div>
    <div css={[adjustPadding, verticalMenu]}>
      <ApproveButton viewDate={viewDate} viewStaffId={viewStaffId} />
      <div css={[flexCenter, adjustPadding]}>
        <DateSelector
          viewDate={viewDate}
          setViewDate={setViewDate}
          viewStaffId={viewStaffId}
        />
      </div>
      <div>
        <StatusDescriptionGroup />
      </div>
      <div css={width100}>
        <StaffMenu viewStaffId={viewStaffId} setViewStaffId={setViewStaffId} />
      </div>
      <div css={[flexCenter]}>
        <Suspense
          fallback={
            <div>
              <Skeleton animation="wave" width={200} height={140} />
            </div>
          }
        >
          <AttachedInfo viewDate={viewDate} viewStaffId={viewStaffId} />
        </Suspense>
      </div>
    </div>
  </div>
);

export default MainContentsSideArea;
