import { VFC, ReactElement } from 'react';
import { css } from '@emotion/react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import FormLabel from '@material-ui/core/FormLabel';

const listStyle = css`
  padding-top: 0;
  padding-bottom: 0;
`;

const listItemStyle = css`
  min-height: 3rem;
`;

const primaryStyle = css`
  font-size: 0.8rem;
`;

const secondaryStyle = css`
  font-size: 0.85rem;
`;

const actionPositonStyle = css`
  position: absolute;
  top: 0;
  right: 16px;
  bottom: 0;
  width: max-content;
  height: max-content;
  margin: auto;
  transform: none;
`;

const marginStyle = css`
  margin-bottom: 0;
`;

const flexStyle = css`
  display: flex;
  justify-content: space-between;
`;

const labelStyle = css`
  padding-bottom: 0.5rem;
  font-size: 0.9rem;
  font-weight: bold;
`;

const paddingForButton = css`
  padding-right: 48px;
`;

type DataProps = {
  key: string | number;
  title?: string;
  content: string;
  button?: ReactElement;
};

export type Props = {
  label: string;
  dataLists: DataProps[];
  buttonElements: ReactElement;
};

const DetailListWithButton: VFC<Props> = ({
  label,
  dataLists,
  buttonElements,
}) => (
  <>
    <div css={[marginStyle, flexStyle]}>
      <FormLabel css={labelStyle}>{label}</FormLabel>
      <div
        css={css`
          display: flex;
          column-gap: 5px;
        `}
      >
        {buttonElements}
      </div>
    </div>
    <div>
      <List dense css={listStyle}>
        {dataLists?.map((data) => (
          <ListItem key={data.key} css={listItemStyle}>
            <ListItemText
              css={data.button && paddingForButton}
              primary={
                data.title ? (
                  <span css={primaryStyle}>{data.title}</span>
                ) : undefined
              }
              secondary={<span css={secondaryStyle}>{data.content}</span>}
            />
            {data.button && (
              <ListItemSecondaryAction css={actionPositonStyle}>
                {data.button}
              </ListItemSecondaryAction>
            )}
          </ListItem>
        ))}
      </List>
    </div>
  </>
);

export default DetailListWithButton;
