import { forwardRef, MouseEventHandler } from 'react';
import { SerializedStyles } from '@emotion/react';
import format from 'date-fns/format';
import FormTextField from 'components/01.atoms/Common/Basic/FormTextFiled';

type CustomInputProps = {
  label?: string;
  errorMsg?: string;
  value: Date | null;
  onClick?: MouseEventHandler<HTMLDivElement>;
  addCss?: SerializedStyles;
  variant?: 'standard' | 'filled' | 'outlined';
};

const CustomInputInDateSelect = forwardRef<HTMLDivElement, CustomInputProps>(
  ({ label, onClick, errorMsg, value, addCss, variant = 'standard' }, ref) => (
    <FormTextField
      addCss={addCss}
      label={label}
      placeholder="未選択"
      isMultiLine={false}
      isFullWidth
      variant={variant}
      margin="normal"
      errorMsg={errorMsg}
      onClick={onClick}
      value={value ? format(value, 'yyyy年M月d日') : ''}
      ref={ref}
    />
  ),
);

export default CustomInputInDateSelect;
