import { css } from '@emotion/react';

const iconWrapperStyle = css`
  & * {
    position: absolute;
    top: 0;
    right: 0;
    width: 2.67142857em;
    height: 100%;
    margin: 0;
    line-height: 1;
    text-align: center;
    cursor: default;
    border-radius: 0 0.28571429rem 0.28571429rem 0;
    opacity: 0.5;
    -webkit-transition: opacity 0.3s ease;
    transition: opacity 0.3s ease;
  }
`;

export default iconWrapperStyle;
