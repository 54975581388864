import { VFC } from 'react';
import { css } from '@emotion/react';
import PeriodMenu from 'components/01.atoms/Position/PeriodMenu';
import { PositionPeriod } from 'domains/position';
import AddPeriodButton from 'containers/01.atoms/Position/AddPeriodButton';
import DeletePeriodButton from 'containers/01.atoms/Position/DeletePeriodButton';
import EditPeriodButton from 'containers/01.atoms/Position/EditPeriodButton';
import { SYSTEM_USAGE_PERIOD } from 'utils/const';
import { parse, isSameDay } from 'date-fns';

const adjustPadding = css`
  padding-top: 1rem;
`;

const width100 = css`
  width: 100%;
`;

const verticalMenu = css`
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
`;

type Props = {
  viewPeriod: PositionPeriod;
  setViewPeriod: (arg: PositionPeriod) => void;
};

const isFirstPeriod = (viewPeriodStart: string): boolean =>
  isSameDay(
    parse(viewPeriodStart, 'yyyy-MM-dd', new Date()),
    parse(SYSTEM_USAGE_PERIOD.START, 'yyyy/M/d', new Date()),
  );

const MainContentsSideArea: VFC<Props> = ({ viewPeriod, setViewPeriod }) => (
  <div>
    <div css={[adjustPadding, verticalMenu]}>
      <AddPeriodButton setViewPeriod={setViewPeriod} />
      <div css={width100}>
        <PeriodMenu viewPeriod={viewPeriod} setViewPeriod={setViewPeriod} />
      </div>
      <EditPeriodButton viewPeriod={viewPeriod} setViewPeriod={setViewPeriod} />
      {isFirstPeriod(viewPeriod.start) || (
        <DeletePeriodButton
          viewPeriod={viewPeriod}
          setViewPeriod={setViewPeriod}
        />
      )}
    </div>
  </div>
);

export default MainContentsSideArea;
