import axios, { AxiosError } from 'axios';
import camelcaseKeys from 'camelcase-keys';
import { useQuery, UseQueryResult } from 'react-query';
import { RequestingTask, isRequestingTasks } from '../models/requestingTask';

export type FormProps = {
  filterCategoryName?: string;
  filterDescription?: string;
  filterTaskType?: number;
  canViewCompletedTask?: boolean;
  canViewNotCompletedTask?: boolean;
  canViewTaskCanBeApproved?: boolean;
  canViewTaskCanNotBeApproved?: boolean;
  canViewOverDueTask?: boolean;
  canViewNotOverDueTask?: boolean;
  isOrderByApproaching?: boolean;
};

const getRequestingTasks = async (
  formData: FormProps,
): Promise<RequestingTask[]> => {
  const response = await axios.post(
    `${process.env.REACT_APP_API_URL ?? ''}/api/task/common/getRequestingTasks`,
    formData,
  );

  const requestingTasks = (await camelcaseKeys(response.data, {
    deep: true,
  })) as unknown[];

  if (!isRequestingTasks(requestingTasks)) {
    throw Error('API type error');
  }

  return requestingTasks;
};

const useGetRequestingTasks = (
  filterAndSortInfo: FormProps,
  isSuspense: boolean,
): UseQueryResult<RequestingTask[], AxiosError> =>
  useQuery(
    ['authUser', 'requestingTasks', filterAndSortInfo],
    () => getRequestingTasks(filterAndSortInfo),
    {
      suspense: isSuspense,
    },
  );

export default useGetRequestingTasks;
