import { UseMutationResult, useMutation } from 'react-query';
import axios, { AxiosError } from 'axios';
import { SuccessOnly, isSuccessOnly } from 'domains/common';
import ErrorResponse from 'domains/common/models/error';

type FormProps = {
  oldPassword: string;
  newPassword: string;
};

const editPassword = async (formData: FormProps): Promise<SuccessOnly> => {
  const response = await axios.post(
    `${process.env.REACT_APP_API_URL ?? ''}/api/user/editPassword`,
    formData,
  );
  const success = (await response.data) as unknown;

  if (!isSuccessOnly(success)) {
    throw Error('API type error');
  }

  return success;
};

const useEditPassword = (
  setIsOpenModal: (arg: boolean) => void,
): UseMutationResult<
  SuccessOnly,
  AxiosError<ErrorResponse>,
  FormProps,
  undefined
> =>
  useMutation(editPassword, {
    onSuccess: () => {
      setIsOpenModal(false);
    },
  });

export default useEditPassword;
