import axios, { AxiosError } from 'axios';
import camelcaseKeys from 'camelcase-keys';
import ErrorResponse from 'domains/common/models/error';
import { UseMutationResult, useMutation, useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { PeriodSet, isPeriodSet } from '../models/periodSet';

type FormProps = {
  periodId: number;
};

const reflectTempData = async (formData: FormProps): Promise<PeriodSet> => {
  const response = await axios.post(
    `${
      process.env.REACT_APP_API_URL ?? ''
    }/api/system/organization/reflectTempData`,
    formData,
  );

  const periodSet = (await camelcaseKeys(response.data, {
    deep: true,
  })) as unknown;

  if (!isPeriodSet(periodSet)) {
    throw Error('API type error');
  }

  return periodSet;
};

const useReflectTempData = (): UseMutationResult<
  PeriodSet,
  AxiosError<ErrorResponse>,
  FormProps,
  undefined
> => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  return useMutation(reflectTempData, {
    onSuccess: (data) => {
      queryClient.setQueryData('organizationPeriods', data.organizationPeriods);
      navigate('/system/organization', {
        state: {
          viewPeriod: data.viewPeriod,
        },
      });
    },
  });
};

export default useReflectTempData;
