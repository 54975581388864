import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import axios from 'axios';
import './index.css';
import { Suspense } from 'react';
import { StylesProvider, ThemeProvider } from '@material-ui/core/styles';
import LoadingPage from 'components/05.pages/Common/LoadingPage';
import theme from 'materialui/theme';
import App from './App';
import reportWebVitals from './reportWebVitals';
import 'react-datepicker/dist/react-datepicker.css';

axios.defaults.withCredentials = true;

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 0,
      suspense: true,
      staleTime: 1000 * 5,
      // useErrorBoundary: true,
    },
    mutations: {
      retry: 0,
      // useErrorBoundary: true,
    },
  },
});

ReactDOM.render(
  <BrowserRouter>
    <QueryClientProvider client={queryClient}>
      <StylesProvider injectFirst>
        <ThemeProvider theme={theme}>
          <Suspense fallback={<LoadingPage />}>
            <App />
          </Suspense>
        </ThemeProvider>
      </StylesProvider>
      {process.env.NODE_ENV === 'development' && (
        <ReactQueryDevtools initialIsOpen={false} />
      )}
    </QueryClientProvider>
  </BrowserRouter>,
  document.getElementById('root') as HTMLElement,
);

reportWebVitals();
