import { Dispatch, SetStateAction, VFC } from 'react';
import { css } from '@emotion/react';
import IconHeader from 'components/01.atoms/Common/Basic/IconHeader';
import AssignmentOutlined from '@material-ui/icons/AssignmentOutlined';
import FilterButton from 'components/01.atoms/Task/RequestingTask/FilterButton';
import { FormProps } from 'domains/task/common/services/getRequestingTasks';

const flexBox = css`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const rightGroup = css`
  display: inline-block;
  align-self: flex-end;
  margin-right: 27px;
`;

type Props = {
  setFilterAndSortInfo: Dispatch<SetStateAction<FormProps>>;
};

const MainContentsHeader: VFC<Props> = ({ setFilterAndSortInfo }) => (
  <div css={flexBox}>
    <IconHeader
      icon={<AssignmentOutlined />}
      title="依頼中のタスク"
      description="依頼中のタスクの確認及び各種操作を実行します"
    />
    <div css={rightGroup}>
      <FilterButton setFilterAndSortInfo={setFilterAndSortInfo} />
    </div>
  </div>
);

export default MainContentsHeader;
