import { VFC, useMemo, useCallback, useState, useEffect } from 'react';
import {
  useGetDailyAttachedInfo,
  useUpdateWorkReport,
  WorkFormData,
} from 'domains/workReport';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import MenuItem from '@material-ui/core/MenuItem';
import { css } from '@emotion/react';
import Typography from '@material-ui/core/Typography';
import {
  UseFormReturn,
  useController,
  UseControllerProps,
  SubmitHandler,
} from 'react-hook-form';
import WorkCategorySelection from 'components/01.atoms/WorkReport/Input/WorkCategorySelection';
import SnackBars from 'components/02.molecules/Common/SnackBars';
import FormHelperText from '@material-ui/core/FormHelperText';
import { WORK_REPORT_STATUS } from 'utils/const';
import DeleteButton from 'containers/01.atoms/WorkReport/DeleteButton';
import useTimeSelectList from 'hooks/useTImeSelectList';
import { format } from 'date-fns';
import ja from 'date-fns/locale/ja';

type Props = {
  isOpen: boolean;
  setIsOpen: (arg: boolean) => void;
  workFormMethods: UseFormReturn<WorkFormData>;
  viewDate: Date;
};

const WorkReportDialog: VFC<Props> = ({
  isOpen,
  setIsOpen,
  workFormMethods,
  viewDate,
}) => {
  const {
    mutate: mutateWR,
    status: statusWR,
    error: errorWR = {
      response: {
        data: { errors: { description: '', categoryId: '', time: '' } },
      },
    },
  } = useUpdateWorkReport(setIsOpen, viewDate);

  const handleWorkReport: SubmitHandler<WorkFormData> = useCallback(
    (data) => {
      const postData = { ...data, ...{ viewDate } };
      mutateWR(postData);
    },
    [mutateWR, viewDate],
  );

  const { data: attachedInfo = { status: 0, feedback: '' } } =
    useGetDailyAttachedInfo(viewDate, false);

  const timeSelectGenerator = useMemo(useTimeSelectList, []);

  type AddProps = {
    errorMsg?: string;
    label?: string;
    isDisabled?: boolean;
  };

  const [errors, setErrors] = useState(errorWR?.response?.data?.errors);

  useEffect(() => {
    setErrors(errorWR?.response?.data?.errors);
  }, [errorWR]);

  const handleClose = () => {
    setErrors({ description: '', categoryId: '', time: '' });
    setIsOpen(false);
  };

  const TimeInput: VFC<UseControllerProps<WorkFormData> & AddProps> = ({
    label,
    errorMsg,
    isDisabled,
    ...props
  }) => {
    const { field } = useController(props);

    return (
      <TextField
        css={css`
          width: 8rem;
        `}
        name={field.name}
        onBlur={field.onBlur}
        onChange={field.onChange}
        ref={field.ref}
        value={field.value}
        select
        label={label}
        margin="normal"
        InputLabelProps={{ shrink: true }}
        error={!!errorMsg}
        disabled={isDisabled}
      >
        {timeSelectGenerator.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.viewText}
          </MenuItem>
        ))}
      </TextField>
    );
  };

  const Description: VFC<UseControllerProps<WorkFormData> & AddProps> = ({
    errorMsg,
    isDisabled,
    ...props
  }) => {
    const { field } = useController(props);

    return (
      <TextField
        multiline
        margin="normal"
        rows={6}
        label="業務内容"
        fullWidth
        error={!!errorMsg}
        helperText={errorMsg}
        name={field.name}
        onBlur={field.onBlur}
        onChange={field.onChange}
        ref={field.ref}
        value={field.value}
        InputLabelProps={{ shrink: true }}
        disabled={isDisabled}
      />
    );
  };

  const isSubmitted = attachedInfo.status >= WORK_REPORT_STATUS.SUBMITTED;
  const isNewMode = !workFormMethods.getValues('eventId');

  return (
    <>
      <Dialog
        open={isOpen}
        onClose={handleClose}
        aria-labelledby="workReportDialog"
        fullWidth
        maxWidth="md"
      >
        <form onSubmit={workFormMethods.handleSubmit(handleWorkReport)}>
          <DialogTitle id="workReportDialog">
            {format(viewDate, 'yyyy年M月d日(EEEE)の業務報告', { locale: ja })}
            <span
              css={css`
                position: absolute;
                top: 4px;
                right: 4px;
                display: inline-block;
              `}
            >
              {isSubmitted || isNewMode || (
                <DeleteButton
                  viewDate={viewDate}
                  workFormMethods={workFormMethods}
                  setIsOuterModalOpen={setIsOpen}
                />
              )}
            </span>
          </DialogTitle>
          <DialogContent>
            <div
              css={css`
                display: flex;
                align-items: center;
              `}
            >
              <TimeInput
                control={workFormMethods.control}
                label="開始時間"
                name="start"
                errorMsg={errors?.time}
                isDisabled={isSubmitted}
              />
              <Typography
                css={css`
                  display: inline-block;
                  padding: 16.5px 1.4rem 0;
                `}
                color="textSecondary"
              >
                ～
              </Typography>
              <TimeInput
                control={workFormMethods.control}
                label="終了時間"
                name="end"
                errorMsg={errors?.time}
                isDisabled={isSubmitted}
              />
            </div>
            {errors?.time && (
              <FormHelperText error>{errors?.time}</FormHelperText>
            )}
            <WorkCategorySelection
              workFormMethods={workFormMethods}
              errorMsg={errors?.categoryId}
              isDisabled={isSubmitted}
            />
            <Description
              control={workFormMethods.control}
              name="description"
              errorMsg={errors?.description}
              isDisabled={isSubmitted}
            />
          </DialogContent>
          <DialogActions>
            {isSubmitted || (
              <Button
                variant="contained"
                color="primary"
                type="submit"
                css={css`
                  margin-right: 16px;
                `}
              >
                登録
              </Button>
            )}
          </DialogActions>
        </form>
      </Dialog>
      <SnackBars
        status={statusWR}
        loadingMsg="保存中..."
        successMsg="保存が完了しました"
        errorMsg="保存に失敗しました"
      />
    </>
  );
};

export default WorkReportDialog;
