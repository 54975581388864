import { FC, ReactElement } from 'react';
import { css } from '@emotion/react';

const sideAndMain = css`
  display: flex;
  height: calc(100vh - 64px);
  & > div:nth-of-type(1) {
    width: max-content;
  }
  & > div:nth-of-type(2) {
    flex: 1;
    margin: 1rem;
  }
`;

type Props = {
  headerBar: ReactElement;
  sideMenu: ReactElement;
  children: ReactElement;
};

const SideAndMainPage: FC<Props> = ({ headerBar, sideMenu, children }) => (
  <>
    {headerBar}
    <div css={sideAndMain}>
      {sideMenu}
      {children}
    </div>
  </>
);

export default SideAndMainPage;
