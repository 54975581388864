export type WorkCategory = {
  tableId: number;
  categoryId: number;
  categoryName: string;
  description: string;
  isCompleted?: boolean;
  taskStatus?: number;
  dueDateTime?: string;
  taskType?: number;
};

const isWorkCategory = (arg: unknown): arg is WorkCategory => {
  const d = arg as WorkCategory;

  switch (d?.tableId) {
    case 1:
      return (
        typeof d?.tableId === 'number' &&
        typeof d?.categoryId === 'number' &&
        typeof d?.categoryName === 'string' &&
        typeof d?.description === 'string' &&
        typeof d?.isCompleted === 'boolean' &&
        typeof d?.taskStatus === 'number' &&
        typeof d?.dueDateTime === 'string' &&
        typeof d?.taskType === 'number'
      );
    case 2:
      return (
        typeof d?.tableId === 'number' &&
        typeof d?.categoryId === 'number' &&
        typeof d?.categoryName === 'string' &&
        typeof d?.description === 'string'
      );
    default:
      return false;
  }
};

const isWorkCategories = (args: unknown[]): args is WorkCategory[] =>
  !args.some((arg) => !isWorkCategory(arg));

export { isWorkCategory, isWorkCategories };
