import { useState, VFC } from 'react';
import { useQuery } from 'react-query';
import { getReportStatusList } from 'domains/workReport';
import { format } from 'date-fns';
import DateSelector from 'components/02.molecules/WorkReport/Common/DateSelector';

type Props = {
  viewDate?: Date;
  setViewDate: (arg: Date) => void;
};

const EnhancedReportDateSelector: VFC<Props> = ({
  viewDate = new Date(),
  setViewDate,
}) => {
  const [calendarMonth, setCalendarMonth] = useState(viewDate);

  const { data: statusList = {} } = useQuery(
    ['reportStatusList', format(calendarMonth, 'yyyy-M')],
    () =>
      getReportStatusList({ calendarMonth: format(calendarMonth, 'yyyy-M-d') }),
    {
      suspense: false,
    },
  );

  return (
    <DateSelector
      viewDate={viewDate}
      setViewDate={setViewDate}
      setCalendarMonth={setCalendarMonth}
      statusList={statusList}
    />
  );
};

export default EnhancedReportDateSelector;
