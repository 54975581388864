import { TreeState } from 'domains/common';
import { Dispatch, MouseEventHandler, SetStateAction, useState } from 'react';
import { ORG_TYPE } from 'utils/const';
import sleep from 'utils/function/sleep';

type ArgType = {
  orgId?: number;
  canNextLoop?: boolean;
  hasSubOrg?: boolean;
  depthLevel?: number;
  orgType?: number;
  setTreeState: Dispatch<SetStateAction<TreeState>>;
};

type ReturnType = {
  canExpand: boolean;
  canFold: boolean;
  handleMouseEnter: MouseEventHandler<HTMLDivElement>;
  handleMouseLeave: MouseEventHandler<HTMLDivElement>;
  handleExpand: MouseEventHandler<HTMLButtonElement>;
  handleFold: MouseEventHandler<HTMLButtonElement>;
};

const useCustomTreeNode = ({
  orgId,
  orgType,
  hasSubOrg,
  canNextLoop,
  setTreeState,
}: ArgType): ReturnType => {
  const [canExpand, setCanExpand] = useState(false);
  const [canFold, setCanFold] = useState(false);

  const handleMouseEnter: MouseEventHandler<HTMLDivElement> = () => {
    const isTarget = orgType === ORG_TYPE.NORMAL && hasSubOrg;

    if (isTarget && !canNextLoop) {
      setCanExpand(true);
    }

    if (isTarget && canNextLoop) {
      setCanFold(true);
    }
  };

  const handleMouseLeave: MouseEventHandler<HTMLDivElement> = () => {
    void sleep(1).then(() => {
      setCanExpand(false);
      setCanFold(false);
    });
  };

  const handleExpand: MouseEventHandler<HTMLButtonElement> = (event) => {
    event.stopPropagation();
    void sleep(1).then(() => {
      setCanFold(true);
      setCanExpand(false);
    });
    setTreeState((prevState) => ({
      ...prevState,
      openableNodeIds: [...prevState.openableNodeIds, orgId ?? -1],
      disableNodeIds: prevState.disableNodeIds.filter((el) => el !== orgId),
    }));
  };

  const handleFold: MouseEventHandler<HTMLButtonElement> = (event) => {
    event.stopPropagation();
    void sleep(1).then(() => {
      setCanFold(false);
      setCanExpand(true);
    });
    setTreeState((prevState) => ({
      ...prevState,
      disableNodeIds: [...prevState.disableNodeIds, orgId ?? -1],
      openableNodeIds: prevState.openableNodeIds.filter((el) => el !== orgId),
    }));
  };

  return {
    canExpand,
    canFold,
    handleExpand,
    handleFold,
    handleMouseEnter,
    handleMouseLeave,
  };
};

export default useCustomTreeNode;
