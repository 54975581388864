import { ChangeEvent, useState, VFC } from 'react';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import { OrganizationPeriod } from 'domains/organization';
import { useNavigate } from 'react-router-dom';

type Props = {
  viewPeriod: OrganizationPeriod;
};

const EnhancedViewModeSwitch: VFC<Props> = ({ viewPeriod }) => {
  const [isEditMode, setIsEditMode] = useState(true);
  const navigate = useNavigate();
  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setIsEditMode(event.target.checked);
    navigate('/system/organization', {
      state: {
        viewPeriod,
      },
    });
  };

  return (
    <>
      <FormControlLabel
        control={
          <Switch
            checked={isEditMode}
            onChange={handleChange}
            name="isEditMode"
            color="primary"
          />
        }
        label="編集モード"
      />
    </>
  );
};

export default EnhancedViewModeSwitch;
