import { VFC } from 'react';
import DetailList from 'components/02.molecules/Common/DetailList';
import { Leader } from 'domains/organization';
import convertPeriodText from 'utils/function/convertPeriodText';

export type Props = {
  leaders: Leader[];
};

const LeaderDetailList: VFC<Props> = ({ leaders }) => {
  const dataLists = leaders?.map((leader, index) => ({
    key: `leaderDetail${index}`,
    title: convertPeriodText({
      start: leader.startDate,
      end: leader.endDate,
    }),
    content: leader.leaderName,
  }));

  return <DetailList label="執行責任者" dataLists={dataLists} />;
};

export default LeaderDetailList;
