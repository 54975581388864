import { VFC } from 'react';
import MainContentsMainArea from 'components/03.organisms/Task/RequestingTask/MainContentsMainArea';
import RequestingTaskTable from 'components/02.molecules/Task/RequestingTask/RequestingTaskTable';
import { useGetRequestingTasks } from 'domains/task/common';
import { FormProps } from 'domains/task/common/services/getRequestingTasks';

type Props = {
  filterAndSortInfo: FormProps;
};

const EnhancedMainContentsMainArea: VFC<Props> = ({ filterAndSortInfo }) => {
  const { data: tasks = [], isFetching } = useGetRequestingTasks(
    filterAndSortInfo,
    false,
  );

  const data = tasks.map((task) => ({
    ...task,
    id: task.taskId,
    iconButton: {
      detail: task,
    },
  }));

  return (
    <MainContentsMainArea
      ownTask={
        <RequestingTaskTable
          data={data}
          isLoading={isFetching}
          filterAndSortInfo={filterAndSortInfo}
        />
      }
    />
  );
};

export default EnhancedMainContentsMainArea;
