import { VFC } from 'react';
import { css } from '@emotion/react';

const loginStyle = css`
  margin: 30px 0;
  font-family: 'Arial Black';
  font-size: 40px;
  color: #fff;
  text-align: center;
  letter-spacing: 0;
`;

export type TextProps = {
  text: string;
};

const HeaderText: VFC<TextProps> = ({ text }) => (
  <h1 css={loginStyle}>{text}</h1>
);

export default HeaderText;
