import { VFC, useState, MouseEvent, Dispatch, SetStateAction } from 'react';
import { useForm } from 'react-hook-form';
import Button from '@material-ui/core/Button';
import FilterListIcon from '@material-ui/icons/FilterList';
import Popper from '@material-ui/core/Popper';
import Fade from '@material-ui/core/Fade';
import Paper from '@material-ui/core/Paper';
import Divider from '@material-ui/core/Divider';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import ToggleButton from '@material-ui/lab/ToggleButton';
import Chip from '@material-ui/core/Chip';
import Box from '@material-ui/core/Box';
import { FormProps } from 'domains/task/common/services/getRequestingTasks';
import FormTextField from 'containers/01.atoms/Common/FormTextFiled';
import { css } from '@emotion/react';
import { StrOnlyForm } from 'domains/common';

const buttonStyle = css`
  padding: 2px 8px;
`;

const boxStyle = css`
  display: flex;
  flex-direction: column;
  row-gap: 15px;
`;

const buttonPosition = css`
  display: flex;
  justify-content: flex-end;
`;

type Props = {
  setFilterAndSortInfo: Dispatch<SetStateAction<FormProps>>;
};

const FilterButton: VFC<Props> = ({ setFilterAndSortInfo }) => {
  const [anchorEl, setAnchorEl] = useState(
    null as (EventTarget & HTMLButtonElement) | null,
  );

  const { getValues, control } = useForm<StrOnlyForm>({
    defaultValues: { taskName: '', description: '' },
  });

  const [isCompletedOnly, setIsCompletedOnly] = useState(0 as number);
  const handleChangeCompleted = (
    _event: MouseEvent<HTMLElement>,
    newValue: number,
  ) => {
    setIsCompletedOnly(newValue);
  };

  const [filterTaskType, _] = useState(0 as number);

  const [isCanApproveOnly, setIsCanApproveOnly] = useState(-1 as number);
  const handleChangeCanFinish = (
    _event: MouseEvent<HTMLElement>,
    newValue: number,
  ) => {
    setIsCanApproveOnly(newValue);
  };

  const [isOverDueOnly, setIsOverDueOnly] = useState(-1 as number);
  const handleChangeOverDue = (
    _event: MouseEvent<HTMLElement>,
    newValue: number,
  ) => {
    setIsOverDueOnly(newValue);
  };

  const [isOrderByNearDue, setIsOrderByNearDue] = useState(1);
  const handleChangeOrder = (
    _event: MouseEvent<HTMLElement>,
    newValue: number,
  ) => {
    setIsOrderByNearDue(newValue);
  };

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const handleUpdate = () => {
    setFilterAndSortInfo({
      filterCategoryName: getValues('taskName'),
      filterDescription: getValues('description'),
      filterTaskType,
      canViewCompletedTask: isCompletedOnly !== 0,
      canViewNotCompletedTask: isCompletedOnly !== 1,
      canViewTaskCanBeApproved: isCanApproveOnly !== 0,
      canViewTaskCanNotBeApproved: isCanApproveOnly !== 1,
      canViewOverDueTask: isOverDueOnly !== 0,
      canViewNotOverDueTask: isOverDueOnly !== 1,
      isOrderByApproaching: !!isOrderByNearDue,
    });
    setAnchorEl(null);
  };

  return (
    <>
      <Button
        aria-controls="filter-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        <FilterListIcon />
        フィルタリング・ソート
      </Button>
      <Popper
        open={!!anchorEl}
        anchorEl={anchorEl}
        placement="bottom-end"
        transition
      >
        {({ TransitionProps }) => (
          // eslint-disable-next-line react/jsx-props-no-spreading
          <Fade {...TransitionProps} timeout={350}>
            <Paper elevation={5}>
              <Box p={3} css={boxStyle}>
                <div>
                  <Chip
                    variant="outlined"
                    size="small"
                    label="フィルタリング(絞り込み)"
                  />
                </div>
                <FormTextField
                  label="タスク名称"
                  placeholder="タスク名称で絞り込み"
                  isMultiLine={false}
                  isFullWidth
                  variant="standard"
                  margin="none"
                  name="taskName"
                  control={control}
                />
                <FormTextField
                  label="内容説明"
                  placeholder="内容説明で絞り込み"
                  isMultiLine={false}
                  isFullWidth
                  variant="standard"
                  margin="none"
                  name="description"
                  control={control}
                />
                <div>
                  <ToggleButtonGroup
                    value={isCompletedOnly}
                    exclusive
                    onChange={handleChangeCompleted}
                    aria-label="filter by completed"
                  >
                    <ToggleButton
                      value={-1}
                      aria-label="no restriction"
                      css={buttonStyle}
                    >
                      制限なし
                    </ToggleButton>
                    <ToggleButton
                      value={0}
                      aria-label="only not completed"
                      css={buttonStyle}
                    >
                      完了承認前のみ
                    </ToggleButton>
                    <ToggleButton
                      value={1}
                      aria-label="only completed"
                      css={buttonStyle}
                    >
                      完了承認済みのみ
                    </ToggleButton>
                  </ToggleButtonGroup>
                </div>
                <div>
                  <ToggleButtonGroup
                    value={isCanApproveOnly}
                    exclusive
                    onChange={handleChangeCanFinish}
                    aria-label="filter by approvable"
                  >
                    <ToggleButton
                      value={-1}
                      aria-label="no restriction"
                      css={buttonStyle}
                    >
                      制限なし
                    </ToggleButton>
                    <ToggleButton
                      value={1}
                      aria-label="only approvable"
                      css={buttonStyle}
                    >
                      承認可のみ
                    </ToggleButton>
                    <ToggleButton
                      value={0}
                      aria-label="only unapprovable"
                      css={buttonStyle}
                    >
                      承認不可のみ
                    </ToggleButton>
                  </ToggleButtonGroup>
                </div>
                <div>
                  <ToggleButtonGroup
                    value={isOverDueOnly}
                    exclusive
                    onChange={handleChangeOverDue}
                    aria-label="filter by over due"
                  >
                    <ToggleButton
                      value={-1}
                      aria-label="no restriction"
                      css={buttonStyle}
                    >
                      制限なし
                    </ToggleButton>
                    <ToggleButton
                      value={1}
                      aria-label="only inner due"
                      css={buttonStyle}
                    >
                      期限内のみ
                    </ToggleButton>
                    <ToggleButton
                      value={0}
                      aria-label="only over due"
                      css={buttonStyle}
                    >
                      期限超過のみ
                    </ToggleButton>
                  </ToggleButtonGroup>
                </div>
                <Divider light />
                <div>
                  <Chip
                    variant="outlined"
                    size="small"
                    label="ソート(並び替え)"
                  />
                </div>
                <div>
                  <ToggleButtonGroup
                    value={Number(isOrderByNearDue)}
                    exclusive
                    onChange={handleChangeOrder}
                    aria-label="orderByDue"
                  >
                    <ToggleButton value={1} aria-label="near" css={buttonStyle}>
                      期限が近い順
                    </ToggleButton>
                    <ToggleButton
                      value={0}
                      aria-label="not near"
                      css={buttonStyle}
                    >
                      期限が遠い順
                    </ToggleButton>
                  </ToggleButtonGroup>
                </div>
                <div css={buttonPosition}>
                  <Button
                    variant="outlined"
                    color="default"
                    onClick={handleUpdate}
                  >
                    更新
                  </Button>
                </div>
              </Box>
            </Paper>
          </Fade>
        )}
      </Popper>
    </>
  );
};

export default FilterButton;
