import { VFC } from 'react';
import {
  DataGrid,
  GridRowData,
  GridColDef,
  GridOverlay,
  GridCellParams,
} from '@material-ui/data-grid';
import { css } from '@emotion/react';
import { Position } from 'domains/position';
import { Button } from '@material-ui/core';
import { UseFormSetValue } from 'react-hook-form';
import { NumOnlyForm, StrOrNumForm } from 'domains/common';
import CircularProgress from '@material-ui/core/CircularProgress';

const customStyle = css`
  &.MuiDataGrid-root .MuiDataGrid-columnHeader:focus,
  &.MuiDataGrid-root .MuiDataGrid-columnHeader:focus-within,
  &.MuiDataGrid-root .MuiDataGrid-cell:focus,
  &.MuiDataGrid-root .MuiDataGrid-cell:focus-within {
    outline: none;
  }
  &.MuiDataGrid-root .MuiDataGrid-window {
    overflow-y: overlay !important;
  }
`;

const progressStyle = css`
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -12px;
  margin-left: -12px;
`;

type EditProps = {
  positionId: number;
  positionName: string;
};
type Props = {
  rows: Position[];
  setValueDEL: UseFormSetValue<NumOnlyForm>;
  setValueUPD: UseFormSetValue<StrOrNumForm>;
  handleEdit: ({ positionId, positionName }: EditProps) => void;
  handleDelete: (positionId: number) => void;
  isLoading: boolean;
};

const columns = (
  handleEdit: ({ positionId, positionName }: EditProps) => void,
  handleDelete: (positionId: number) => void,
): GridColDef[] => [
  { field: 'positionName', headerName: '役職名称', flex: 1, sortable: false },
  {
    field: 'button',
    headerName: '操作',
    width: 150,
    sortable: false,
    renderCell: (params: GridCellParams) => (
      <>
        <Button
          variant="contained"
          color="primary"
          size="small"
          css={css`
            margin-right: 4px;
          `}
          onClick={() => {
            handleEdit({
              positionId: Number(params.getValue(params.id, 'id')),
              positionName: String(params.getValue(params.id, 'positionName')),
            });
          }}
        >
          編集
        </Button>
        <Button
          variant="contained"
          color="secondary"
          size="small"
          onClick={() => {
            handleDelete(Number(params.getValue(params.id, 'id')));
          }}
        >
          削除
        </Button>
      </>
    ),
  },
  { field: 'periodId', hide: true },
  { field: 'id', hide: true },
];

const convertPositions = (original: Position[]): GridRowData[] =>
  original.length === 0
    ? ([] as GridRowData[])
    : original.map((data) => ({
        id: data.id,
        periodId: data.periodId,
        positionName: data.name,
        button: '',
      }));

const CustomNoRowsOverlay: VFC = () => (
  <GridOverlay>
    <div>該当期間に登録済みの役職名称データはありません</div>
  </GridOverlay>
);

const PositionTable: VFC<Props> = ({
  rows,
  handleEdit,
  handleDelete,
  isLoading,
}) => (
  <div
    css={css`
      position: relative;
      width: 100%;
      height: 450px;
    `}
  >
    <DataGrid
      css={customStyle}
      rows={convertPositions(rows)}
      columns={columns(handleEdit, handleDelete)}
      hideFooter
      disableColumnMenu
      disableColumnSelector
      disableSelectionOnClick
      components={{
        NoRowsOverlay: CustomNoRowsOverlay,
      }}
    />
    {isLoading && <CircularProgress size={50} css={progressStyle} />}
  </div>
);
export default PositionTable;
