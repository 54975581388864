import { FC, VFC } from 'react';
import { ClassNames, css as emotionCss } from '@emotion/react';
import DatePicker, { CalendarContainer } from 'react-datepicker';
import { format, isSameDay } from 'date-fns';
import { ReportStatus } from 'domains/workReport';

const noneStyle = emotionCss`
  color: black;
  background-color: white;
  border-radius: 15px;
  &:hover{
    background-color:#f0f0f0;
    border-radius: 15px;
  }
`;

const remandStyle = emotionCss`
  color: white;
  background-color: #fbbd08;
  border-radius: 15px;
  &:hover{
    background-color:#f0f0f0;
    border-radius: 15px;
  }
`;

const successStyle = emotionCss`
  color: white;
  background-color: #00b5ad;
  border-radius: 15px;
  &:hover{
    background-color:#f0f0f0;
    border-radius: 15px;
  }
`;

const approveStyle = emotionCss`
  color: white;
  background-color: #2185d0;
  border-radius: 15px;
  &:hover{
    background-color:#f0f0f0;
    border-radius: 15px;
  }
`;

const selectedStyle = emotionCss`
  box-shadow: 0 0 0 2px #dc004e;
`;

const containerStyle = emotionCss`
  border-color: white;
    & > div > div > div:nth-of-type(1) {
    padding-bottom: 0.2rem;
    color: #3c4043;
    background-color: white;
    border-bottom: 0;
    & * {
      color: #3c4043;
      border-color: #3c4043;
    }
  }
`;

type Props = {
  viewDate?: Date;
  setViewDate: (arg: Date) => void;
  setCalendarMonth: (arg: Date) => void;
  statusList: ReportStatus;
};

const DateSelector: VFC<Props> = ({
  viewDate = new Date(),
  setViewDate,
  setCalendarMonth,
  statusList,
}) => (
  <ClassNames>
    {({ css, cx }) => {
      const containerClass = cx(css(containerStyle));
      const noneClass = cx(css(noneStyle));
      const remandClass = cx(css(remandStyle));
      const successClass = cx(css(successStyle));
      const approveClass = cx(css(approveStyle));
      const noneSelClass = cx(css([noneStyle, selectedStyle]));
      const remandSelClass = cx(css([remandStyle, selectedStyle]));
      const successSelClass = cx(css([successStyle, selectedStyle]));
      const approveSelClass = cx(css([approveStyle, selectedStyle]));

      const MyContainer: FC<{
        className?: string;
        children?: React.ReactNode;
      }> = ({ className, children }) => {
        const cls = className
          ? `${className} ${containerClass}`
          : containerClass;

        return (
          <CalendarContainer className={cls}>
            <div style={{ position: 'relative' }}>{children}</div>
          </CalendarContainer>
        );
      };

      const getColorClassName = (date: Date) => {
        const isSelected = isSameDay(date, viewDate);

        const dateStr = Number(format(date, 'yyyyMMdd'));
        const status = Number(statusList[dateStr]) ?? 0;
        switch (status) {
          case 1:
            return isSelected ? remandSelClass : remandClass;
          case 2:
            return isSelected ? successSelClass : successClass;
          case 3:
            return isSelected ? approveSelClass : approveClass;
          default:
            return isSelected ? noneSelClass : noneClass;
        }
      };

      return (
        <>
          <DatePicker
            selectsRange={false}
            selected={viewDate}
            onChange={(date: Date) => {
              setViewDate(date);
              setCalendarMonth(date);
            }}
            onMonthChange={(date) => {
              setCalendarMonth(date);
            }}
            inline
            dayClassName={getColorClassName}
            calendarContainer={MyContainer}
            renderCustomHeader={({ date, decreaseMonth, increaseMonth }) => (
              <div style={{ border: 0 }}>
                <button
                  type="button"
                  aria-label="Previous Month"
                  className="react-datepicker__navigation react-datepicker__navigation--previous"
                  onClick={decreaseMonth}
                >
                  <span className="react-datepicker__navigation-icon react-datepicker__navigation-icon--previous">
                    {'<'}
                  </span>
                </button>
                <span className="react-datepicker__current-month">
                  {date.toLocaleString('ja', {
                    month: 'long',
                    year: 'numeric',
                  })}
                </span>
                <button
                  type="button"
                  aria-label="Next Month"
                  className="react-datepicker__navigation react-datepicker__navigation--next"
                  onClick={increaseMonth}
                >
                  <span className="react-datepicker__navigation-icon react-datepicker__navigation-icon--next">
                    {'>'}
                  </span>
                </button>
              </div>
            )}
          />
        </>
      );
    }}
  </ClassNames>
);

export default DateSelector;
