import { VFC } from 'react';
import MainContentsHeader from 'components/03.organisms/Organization/New/MainContentsHeader';
import { OrganizationPeriod } from 'domains/organization';

type Props = {
  viewPeriod: OrganizationPeriod;
};

const EnhancedMainContentsHeader: VFC<Props> = ({ viewPeriod }) => (
  <MainContentsHeader viewPeriod={viewPeriod} />
);

export default EnhancedMainContentsHeader;
