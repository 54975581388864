import { useMemo } from 'react';
import { EmptyTerm } from 'domains/system';
import convertPeriodText from 'utils/function/convertPeriodText';

type ButtonProps = {
  targetId: number;
  start: string;
  end: string;
};

type DataProps = {
  id: number;
  name: string;
  emptyPeriod: string;
  button: ButtonProps;
};

type ArgType = {
  original: EmptyTerm[];
};

type ReturnType = DataProps[];

const convertData = (original: EmptyTerm[]): DataProps[] =>
  original.length === 0
    ? ([] as DataProps[])
    : original.map((data, index) => ({
        id: index,
        name: data.name,
        emptyPeriod: convertPeriodText({
          start: data.emptyStart,
          end: data.emptyEnd,
        }),
        button: {
          targetId: data.id,
          start: data.emptyStart,
          end: data.emptyEnd,
        },
      }));

const useConvertInvalidListData = ({ original }: ArgType): ReturnType =>
  useMemo(() => convertData(original), [original]);

export default useConvertInvalidListData;
