import { VFC } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from '@material-ui/core';
import { css } from '@emotion/react';

type Props = {
  title: string;
  confirmMsg: string;
  okButtonName?: string;
  cancelButtonName?: string;
  isOpen: boolean;
  handleOk: () => void;
  handleCancel: () => void;
  maxWidth?: false | 'xs' | 'sm' | 'md' | 'lg' | 'xl';
};

const BasicConfirmDialog: VFC<Props> = ({
  title,
  confirmMsg,
  okButtonName = 'はい',
  cancelButtonName = 'いいえ',
  isOpen,
  handleOk,
  handleCancel,
  maxWidth,
}) => (
  <Dialog
    aria-labelledby="confirmation-dialog"
    onClose={handleCancel}
    open={isOpen}
    maxWidth={maxWidth}
  >
    <DialogTitle id="confirmation-dialog">{title}</DialogTitle>
    <DialogContent
      css={css`
        white-space: pre-wrap;
      `}
      dividers
    >
      {confirmMsg}
    </DialogContent>
    <DialogActions>
      <Button onClick={handleOk} color="primary">
        {okButtonName}
      </Button>
      <Button autoFocus onClick={handleCancel} color="default">
        {cancelButtonName}
      </Button>
    </DialogActions>
  </Dialog>
);

export default BasicConfirmDialog;
