import axios from 'axios';
import camelcaseKeys from 'camelcase-keys';
import {
  DailyWorkReportSet,
  isDailyWorkReportSet,
} from '../models/dailyWorkReportSet';

type FormData = {
  viewDate: string;
};

const getDailyWorkReport = async (
  formData: FormData,
): Promise<DailyWorkReportSet> => {
  const response = await axios.post(
    `${process.env.REACT_APP_API_URL ?? ''}/api/workReport/getDailyWorkReport`,
    formData,
  );

  const workReportSet = (await camelcaseKeys(response.data, {
    deep: true,
  })) as unknown;

  if (!isDailyWorkReportSet(workReportSet)) {
    throw Error('API type error');
  }

  return workReportSet;
};

export default getDailyWorkReport;
