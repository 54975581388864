export type Member = {
  memberId: number;
  memberName: string;
};
const isMember = (arg: unknown): arg is Member => {
  const m = arg as Member;

  return typeof m?.memberId === 'number' && typeof m?.memberName === 'string';
};
const isMembers = (args: unknown[]): args is Member[] =>
  !args.some((arg) => !isMember(arg));

export { isMember, isMembers };
