type TimeProps = {
  viewText: string;
  value: string;
};

const useTimeSelectList = (): TimeProps[] => {
  const start = Number(
    (process.env.REACT_APP_BUSINESS_HOUR_START as unknown as string).split(
      ':',
    )[0],
  );
  const end = Number(
    (process.env.REACT_APP_BUSINESS_HOUR_END as unknown as string).split(
      ':',
    )[0],
  );
  const timeList = [];
  for (let i = start; i <= end; i += 1) {
    const viewText = i < 24 ? i : `（翌日）${i - 24}`;
    timeList.push({ viewText: `${viewText}:00`, value: `${i}:00` });
    if (i === end) {
      break;
    }
    timeList.push({ viewText: `${viewText}:15`, value: `${i}:15` });
    timeList.push({ viewText: `${viewText}:30`, value: `${i}:30` });
    timeList.push({ viewText: `${viewText}:45`, value: `${i}:45` });
  }

  return timeList;
};

export default useTimeSelectList;
