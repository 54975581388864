import {
  VFC,
  useState,
  MouseEventHandler,
  Dispatch,
  SetStateAction,
} from 'react';
import TextField from '@material-ui/core/TextField';
import { OrganizationUserState } from 'domains/user';
import OrganizationUserSelectDialog from 'containers/03.organisms/User/OrganizationUserSelectDialog';

type Props = {
  rowNumber: number;
  rows: OrganizationUserState[];
  setUsersState: Dispatch<SetStateAction<OrganizationUserState[]>>;
  scopeEnd?: Date | null;
};

const OrganizationUserSelectInput: VFC<Props> = ({
  rowNumber,
  rows,
  setUsersState,
  scopeEnd,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const handleClick: MouseEventHandler<HTMLDivElement> = (event) => {
    const row = Number(
      event.currentTarget.getAttribute('data-row-number') ?? -1,
    );
    const userId = Number(event.currentTarget.getAttribute('data-id') ?? -1);
    const userName =
      event.currentTarget.getAttribute('data-name') ?? '名称なし';
    setUsersState((prevState) =>
      prevState.map((el) =>
        el.rowNumber === row ? { ...el, userId, userName } : el,
      ),
    );
    setIsOpen(false);
  };

  return (
    <>
      <TextField
        onClick={() => setIsOpen(true)}
        variant="standard"
        margin="normal"
        label=""
        placeholder="未選択"
        value={rows[rowNumber].userName}
        InputLabelProps={{ shrink: true }}
        fullWidth
      />
      <OrganizationUserSelectDialog
        rows={rows}
        rowNumber={rowNumber}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        handleClick={handleClick}
        scopeEnd={scopeEnd}
      />
    </>
  );
};

export default OrganizationUserSelectInput;
