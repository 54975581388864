import { useCallback } from 'react';
import { useQueryClient, UseMutationResult, useMutation } from 'react-query';
import axios, { AxiosError } from 'axios';
import { useNavigate } from 'react-router-dom';
import { isUser, User } from '../models/user';

type FormData = {
  email: string;
  password: string;
};

const login = async (formData: FormData): Promise<User> => {
  await axios.get(`${process.env.REACT_APP_API_URL ?? ''}/sanctum/csrf-cookie`);
  const response = await axios.post(
    `${process.env.REACT_APP_API_URL ?? ''}/api/login`,
    formData,
  );
  const user = (await response.data) as unknown;

  if (!isUser(user)) {
    throw Error('API type error');
  }

  return user;
};

const useLogin = (): UseMutationResult<
  User,
  AxiosError,
  FormData,
  undefined
> => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const handleMove = useCallback(() => {
    navigate('/');
  }, [navigate]);

  return useMutation(login, {
    onSuccess: (data) => {
      queryClient.setQueryData('user', data);
      handleMove();
    },
  });
};

export default useLogin;
