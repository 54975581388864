import { VFC, useState, ChangeEvent } from 'react';
import MainContentsHeader from 'components/03.organisms/User/MainContentsHeader';

const EnhancedMainContentsHeader: VFC = () => {
  const [isEditMode, setIsEditMode] = useState(false);
  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setIsEditMode(event.target.checked);
  };

  return (
    <MainContentsHeader isEditMode={isEditMode} clickSwitch={handleChange} />
  );
};

export default EnhancedMainContentsHeader;
