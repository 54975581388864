import axios, { AxiosError } from 'axios';
import camelcaseKeys from 'camelcase-keys';
import { Bool, isBool } from 'domains/common';
import { useQuery, UseQueryResult } from 'react-query';

const getExistsNewModeData = async (): Promise<Bool> => {
  const response = await axios.get(
    `${
      process.env.REACT_APP_API_URL ?? ''
    }/api/system/organization/getExistsNewModeData`,
  );

  const data = (await camelcaseKeys(response.data, {
    deep: true,
  })) as unknown;

  if (!isBool(data)) {
    throw Error('API type error');
  }

  return data;
};

const useGetExistsNewModeData = (
  isSuspense: boolean,
): UseQueryResult<Bool, AxiosError> =>
  useQuery(
    ['system', 'organizations', 'existsNewModeData'],
    () => getExistsNewModeData(),
    {
      suspense: isSuspense,
    },
  );

export default useGetExistsNewModeData;
