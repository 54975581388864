import { VFC } from 'react';
import { Navigate, Route, Routes } from 'react-router';
import { useGetUserQuery } from 'domains/auth';
import { AuthRoute, UnAuthRoute } from 'hooks/useAuth';
import { registerLocale, setDefaultLocale } from 'react-datepicker';
import ja from 'date-fns/locale/ja';
import './App.css';
import LoginPage from 'components/05.pages/Auth/LoginPage';
import DashBoardPage from 'containers/05.pages/Home/DashBoardPage';
import WorkReportPage from 'containers/05.pages/WorkReport/WorkReportPage';
import PositionPage from 'containers/05.pages/Position/PositionPage';
import UserPage from 'containers/05.pages/User/UserPage';
import OrganizationPage from 'containers/05.pages/Organization/OrganizationPage';
import OrganizationEditPage from 'containers/05.pages/Organization/OrganizationEditPage';
import OrganizationNewPage from 'containers/05.pages/Organization/OrganizationNewPage';
import SystemHomePage from 'containers/05.pages/System/SystemHomePage';
import SystemInvalidPage from 'containers/05.pages/System/SystemInvalidPage';
import OwnTaskPage from 'containers/05.pages/Task/OwnTaskPage';
import RequestingTaskPage from 'containers/05.pages/Task/RequestingTaskPage';
import NewSpotTaskPage from 'containers/05.pages/Task/NewSpotTaskPage';

registerLocale('ja', ja);
setDefaultLocale('ja');

const App: VFC = () => {
  useGetUserQuery();

  return (
    <Routes>
      <UnAuthRoute path="/login" element={<LoginPage />} />
      <AuthRoute>
        <Route path="/" element={<DashBoardPage />} />
        <Route path="workreport/*" element={<WorkReportPage />} />
        <Route path="/task">
          <Route path="/ownTask" element={<OwnTaskPage />} />
          <Route path="/requestingTask" element={<RequestingTaskPage />} />
          <Route path="/newSpotTask" element={<NewSpotTaskPage />} />
        </Route>
        <Route path="system">
          <Route path="/" element={<SystemHomePage />} />
          <Route path="/invalid" element={<SystemInvalidPage />} />
          <Route path="/position" element={<PositionPage />} />
          <Route path="/user" element={<UserPage />} />
          <Route path="/organization">
            <Route path="/" element={<OrganizationPage />} />
            <Route path="/edit" element={<OrganizationEditPage />} />
            <Route path="/new" element={<OrganizationNewPage />} />
          </Route>
        </Route>
      </AuthRoute>
      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  );
};

export default App;
