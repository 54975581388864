import { useMemo, VFC } from 'react';
import ReactTableBasic from 'components/02.molecules/Common/ReactTableBasic';
import { Column } from 'react-table';
import { OwnTask } from 'domains/task/common';
import convertDateHourMinute from 'utils/function/convertDateHourMinute';
import { css } from '@emotion/react';
import TaskStatusToggleButtons from 'containers/02.molecules/Task/OwnTask/TaskStatusToggleButtons';

type ButtonProps = {
  taskId: number;
  taskStatus: number;
};

type DataProps = OwnTask & {
  id: number;
  button: ButtonProps;
};

type Props = {
  data: DataProps[];
  isLoading: boolean;
};

const OwnTaskTable: VFC<Props> = ({ data, isLoading }) => {
  const columns = useMemo(
    (): Column<DataProps>[] => [
      {
        accessor: 'id',
      },
      {
        Header: 'タスク名称',
        accessor: 'taskName',
        maxWidth: 20,
      },
      {
        Header: '内容説明',
        accessor: 'description',
        width: 30,
      },
      {
        Header: '期限',
        accessor: 'dueDateTime',
        width: 200,
        minWidth: 200,
        maxWidth: 10,
        Cell: ({ value }) => convertDateHourMinute(value),
      },
      {
        Header: '進捗状態',
        accessor: 'button',
        width: '215',
        minWidth: 215,
        maxWidth: 5,
        Cell: ({ value }) => (
          <TaskStatusToggleButtons
            taskId={value.taskId}
            initialStatus={value.taskStatus}
          />
        ),
      },
    ],
    [],
  );

  return (
    <div>
      <ReactTableBasic
        columns={columns}
        data={data}
        isLoading={isLoading}
        addBodyCss={css`
          height: calc(100vh - 250px);
        `}
      />
    </div>
  );
};

export default OwnTaskTable;
