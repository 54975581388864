import { isOrganizations, Organization } from './organization';

export type OrganizationSet = {
  maxDepthLevel: number;
  organizationTree: Organization[];
};

const isOrganizationSet = (arg: unknown): arg is OrganizationSet => {
  const o = arg as OrganizationSet;

  return (
    typeof o?.maxDepthLevel === 'number' && isOrganizations(o?.organizationTree)
  );
};

export { isOrganizationSet };
