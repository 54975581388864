import { VFC, ReactElement } from 'react';
import { css } from '@emotion/react';
import { ORG_TYPE } from 'utils/const';
import hoverScrollStyle from 'baseCss/emotion/hoverScroll';

const overall = css`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  row-gap: 12px;
  height: calc(100vh - 170px);
  padding: 1.5rem 0.5rem 0;
  overflow-y: overlay;
`;

type Props = {
  orgType: number;
  basic: ReactElement;
  leaders: ReactElement;
  members: ReactElement;
  subOrgs: ReactElement;
};

const MainContentsDetails: VFC<Props> = ({
  orgType,
  basic,
  leaders,
  members,
  subOrgs,
}) => (
  <div css={[overall, hoverScrollStyle]}>
    <div>{basic}</div>
    <div>{leaders}</div>
    {orgType === ORG_TYPE.BOTTOM_TEAM && <div>{members}</div>}
    {orgType === ORG_TYPE.NORMAL && <div>{subOrgs}</div>}
  </div>
);

export default MainContentsDetails;
