import axios, { AxiosError } from 'axios';
import camelcaseKeys from 'camelcase-keys';
import { useQuery, UseQueryResult } from 'react-query';
import { Member, isMembers } from '../models/member';

const getMemberOptions = async (): Promise<Member[]> => {
  const response = await axios.get(
    `${process.env.REACT_APP_API_URL ?? ''}/api/task/project/getMemberOptions`,
  );

  const members = (await camelcaseKeys(response.data, {
    deep: true,
  })) as unknown[];

  if (!isMembers(members)) {
    throw Error('API type error');
  }

  return members;
};

const useGetMemberOptions = (
  isSuspense: boolean,
): UseQueryResult<Member[], AxiosError> =>
  useQuery(
    ['task', 'spotTree', 'new', 'memberOptions'],
    () => getMemberOptions(),
    {
      suspense: isSuspense,
    },
  );

export default useGetMemberOptions;
