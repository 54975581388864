import { VFC } from 'react';
import { useQuery } from 'react-query';
import Radio from '@material-ui/core/Radio';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { getWorkCategories } from 'domains/workReport';

const CategoryRadioOptions: VFC = () => {
  const { data: categories = [] } = useQuery('workCategories', () =>
    getWorkCategories({ filterName: undefined }),
  );

  return (
    <>
      {categories.map((category) => (
        <FormControlLabel
          value={`${category.tableId}:${category.categoryId}`}
          key={`${category.tableId}:${category.categoryId}`}
          control={<Radio color="primary" />}
          label={category.categoryName}
          data-table-id={category.tableId}
          data-category-id={category.categoryId}
          data-category-name={category.categoryName}
        />
      ))}
    </>
  );
};

export default CategoryRadioOptions;
