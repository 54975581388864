import { Dispatch, VFC, SetStateAction } from 'react';
import { BasicDetail, OrganizationPeriod } from 'domains/organization';
import { TreeState } from 'domains/common';
import { ORG_TYPE_NAME } from 'utils/const';
import DetailListWithButton from 'components/02.molecules/Common/DetailListWithButton';
import DeleteButton from 'containers/01.atoms/Organization/DeleteButton';
import EditNameButton from 'containers/01.atoms/Organization/EditNameButton';

export type Props = {
  basic: BasicDetail;
  viewPeriod: OrganizationPeriod;
  activeOrgId: number;
  setTreeState: Dispatch<SetStateAction<TreeState>>;
};

const BasicDetailList: VFC<Props> = ({
  basic,
  viewPeriod,
  activeOrgId,
  setTreeState,
}) => {
  const dataLists = [
    {
      key: 'orgDetail1',
      title: '組織名(マスター名称)',
      content: basic.masterName,
    },
    {
      key: 'orgDetail2',
      title: '組織名(作成中の期間表示用)',
      content: basic.name,
    },
    {
      key: 'orgDetail3',
      title: '組織タイプ',
      content: ORG_TYPE_NAME[basic.orgType],
    },
  ];

  return (
    <DetailListWithButton
      label="詳細"
      dataLists={dataLists}
      buttonElements={
        <>
          <EditNameButton viewPeriod={viewPeriod} activeOrgId={activeOrgId} />
          <DeleteButton
            viewPeriod={viewPeriod}
            activeOrgId={activeOrgId}
            setTreeState={setTreeState}
          />
        </>
      }
    />
  );
};

export default BasicDetailList;
