import { VFC, MouseEvent } from 'react';
import { css } from '@emotion/react';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import {
  TASK_STATUS_FOR_WORKER,
  TASK_STATUS_FOR_WORKER_NAME,
} from 'utils/const';

const buttonStyle = css`
  padding: 2px 8px;
`;

type Props = {
  status: number;
  handleChange: (event: MouseEvent<HTMLElement>, newValue: number) => void;
};

const TaskStatusToggleButtons: VFC<Props> = ({ status, handleChange }) => (
  <ToggleButtonGroup
    value={status}
    exclusive
    onChange={handleChange}
    aria-label="taskStatusSwitch"
  >
    <ToggleButton
      value={TASK_STATUS_FOR_WORKER.WAITING}
      aria-label="waiting"
      css={buttonStyle}
      disabled={status === TASK_STATUS_FOR_WORKER.DONE}
    >
      {TASK_STATUS_FOR_WORKER_NAME[TASK_STATUS_FOR_WORKER.WAITING]}
    </ToggleButton>
    <ToggleButton
      value={TASK_STATUS_FOR_WORKER.WORKING}
      aria-label="working"
      css={buttonStyle}
      disabled={status === TASK_STATUS_FOR_WORKER.DONE}
    >
      {TASK_STATUS_FOR_WORKER_NAME[TASK_STATUS_FOR_WORKER.WORKING]}
    </ToggleButton>
    <ToggleButton
      value={TASK_STATUS_FOR_WORKER.DONE}
      aria-label="done"
      css={buttonStyle}
      disabled={status === TASK_STATUS_FOR_WORKER.DONE}
    >
      {TASK_STATUS_FOR_WORKER_NAME[TASK_STATUS_FOR_WORKER.DONE]}
    </ToggleButton>
  </ToggleButtonGroup>
);

export default TaskStatusToggleButtons;
