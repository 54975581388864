import { VFC, ReactElement } from 'react';
import NewPeriodDialog from 'components/03.organisms/Position/NewPeriodDialog';
import EffectTextList from 'components/02.molecules/Position/EffectTextList';
import AddPeriodEffectTable from 'containers/02.molecules/Position/AddPeriodEffectTable';
import EffectTable from 'components/02.molecules/Position/EffectTable';
import DateSelectInput from 'components/02.molecules/WorkReport/Common/DateSelectInput';

type Props = {
  isOpen: boolean;
  handleOk: () => void;
  handleCancel: () => void;
  customInput: ReactElement;
  newStart: Date | null;
  setNewStart: (arg: Date | null) => void;
};

const textList = [
  {
    id: 1,
    value: '・無効になった役職名が使用されているユーザーの役職データの削除',
  },
];

const EnhancedNewPeriodDialog: VFC<Props> = ({
  isOpen,
  handleOk,
  handleCancel,
  customInput,
  newStart,
  setNewStart,
}) => (
  <NewPeriodDialog
    textField={
      <DateSelectInput
        setViewDate={setNewStart}
        viewDate={newStart}
        customInput={customInput}
      />
    }
    effectTable={
      newStart ? (
        <AddPeriodEffectTable
          newStart={newStart}
          label="他の期間データへの影響"
        />
      ) : (
        <EffectTable
          rows={[]}
          isLoading={false}
          label="他の期間データへの影響"
        />
      )
    }
    otherEffect={<EffectTextList label="その他の影響" textList={textList} />}
    isOpen={isOpen}
    handleOk={handleOk}
    handleCancel={handleCancel}
  />
);

export default EnhancedNewPeriodDialog;
