import { VFC, useState } from 'react';
import Button from '@material-ui/core/Button';
import { OrganizationPeriod } from 'domains/organization';
import AddSubOrgDialog from 'containers/03.organisms/Organization/Edit/AddSubOrgDialog';

type Props = {
  activeOrgId: number;
  viewPeriod: OrganizationPeriod;
};

const AddSubOrgButton: VFC<Props> = ({ viewPeriod, activeOrgId }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <>
      <Button
        variant="outlined"
        size="small"
        color="primary"
        onClick={() => setIsModalOpen(true)}
      >
        追加
      </Button>
      <AddSubOrgDialog
        activeOrgId={activeOrgId}
        viewPeriod={viewPeriod}
        isOpen={isModalOpen}
        setIsOpen={setIsModalOpen}
      />
    </>
  );
};

export default AddSubOrgButton;
