import axios, { AxiosError } from 'axios';
import camelcaseKeys from 'camelcase-keys';
import { useQuery, UseQueryResult } from 'react-query';
import { isOrganizationPeriods, OrganizationPeriod } from '../models/period';

const getOrganizationPeriods = async (): Promise<OrganizationPeriod[]> => {
  const response = await axios.get(
    `${process.env.REACT_APP_API_URL ?? ''}/api/system/organization/getPeriods`,
  );

  const periods = (await camelcaseKeys(response.data, {
    deep: true,
  })) as unknown[];

  if (!isOrganizationPeriods(periods)) {
    throw Error('API type error');
  }

  return periods;
};

const useGetOrganizationPeriods = (
  isSuspense: boolean,
): UseQueryResult<OrganizationPeriod[], AxiosError> =>
  useQuery('organizationPeriods', () => getOrganizationPeriods(), {
    suspense: isSuspense,
  });

export default useGetOrganizationPeriods;
