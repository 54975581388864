import { useMemo, VFC } from 'react';
import { css } from '@emotion/react';
import ReactTableBasic from 'components/02.molecules/Common/ReactTableBasic';
import { Column } from 'react-table';
import AddUsersForEmptyOrganizationButton from 'containers/01.atoms/System/AddUsersForEmptyOrganizationButton';

type ButtonProps = {
  targetId: number;
  start: string;
  end: string;
};

type DataProps = {
  id: number;
  name: string;
  emptyPeriod: string;
  button: ButtonProps;
};

type Props = {
  data: DataProps[];
  isLoading: boolean;
};

const TermsOrganizationsHasNoUsersTable: VFC<Props> = ({ data, isLoading }) => {
  const columns = useMemo(
    (): Column<DataProps>[] => [
      {
        accessor: 'id',
      },
      {
        Header: '組織名称',
        accessor: 'name',
        width: '100',
      },
      {
        Header: '未登録期間',
        accessor: 'emptyPeriod',
        width: '70',
      },
      {
        Header: '操作',
        accessor: 'button',
        width: '30',
        minWidth: 30,
        Cell: ({ value }) => (
          <AddUsersForEmptyOrganizationButton
            viewOrgId={value.targetId}
            start={value.start}
            end={value.end}
          />
        ),
      },
    ],
    [],
  );

  return (
    <div>
      <ReactTableBasic
        columns={columns}
        data={data}
        addBodyCss={css`
          height: 64vh;
        `}
        isLoading={isLoading}
      />
    </div>
  );
};

export default TermsOrganizationsHasNoUsersTable;
