import { VFC, useState } from 'react';
import SnackBars from 'components/02.molecules/Common/SnackBars';
import Button from '@material-ui/core/Button';
import { OrganizationState, useAddSubOrganization } from 'domains/user';
import BasicConfirmFormDialog from 'components/03.organisms/Common/BasicConfirmFormDialog';
import SubOrgSelectInput from 'containers/02.molecules/User/CommonOrgSelector';
import SubOrgPeriodInputGroup from 'containers/02.molecules/User/CommonOrgPeriodInputSet';
import ErrorResponse from 'domains/common/models/error';

type Props = {
  viewUserId: number;
};

const AddSubOrganizationButton: VFC<Props> = ({ viewUserId }) => {
  const initialState = {
    isContinued: true,
    start: new Date(),
    end: null,
    orgId: 0,
    orgName: '',
  } as OrganizationState;

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [organizationState, setOrganizationState] = useState(initialState);

  const handleReset = () => {
    setOrganizationState(initialState);
  };

  const {
    mutate,
    status,
    reset: queryReset,
    error = {
      response: {
        data: {} as ErrorResponse,
      },
    },
  } = useAddSubOrganization(viewUserId, setIsModalOpen, handleReset);

  const handleCancel = () => {
    setIsModalOpen(false);
    queryReset();
  };

  const handleOk = () => {
    mutate({ ...organizationState, userId: viewUserId });
  };

  return (
    <>
      <Button
        variant="outlined"
        size="small"
        color="primary"
        onClick={() => setIsModalOpen(true)}
      >
        追加
      </Button>
      <SnackBars
        status={status}
        loadingMsg="登録中..."
        successMsg="登録が完了しました"
        errorMsg="登録に失敗しました"
      />
      <BasicConfirmFormDialog
        title="所属組織（兼務）の登録"
        confirmMsg="登録する兼務先の情報をご入力ください。"
        okButtonName="登録"
        cancelButtonName="キャンセル"
        textField={
          <>
            <SubOrgPeriodInputGroup
              organizationState={organizationState}
              setOrganizationState={setOrganizationState}
              errorData={error?.response?.data}
              viewUserId={viewUserId}
            />
            <SubOrgSelectInput
              organizationState={organizationState}
              setOrganizationState={setOrganizationState}
              errorData={error?.response?.data}
              isMain={false}
            />
          </>
        }
        isOpen={isModalOpen}
        handleOk={handleOk}
        handleCancel={handleCancel}
      />
    </>
  );
};

export default AddSubOrganizationButton;
