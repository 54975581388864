import axios, { AxiosError } from 'axios';
import camelcaseKeys from 'camelcase-keys';
import { useQuery, UseQueryResult } from 'react-query';
import { PositionPeriod } from '../models/period';
import { isPositions, Position } from '../models/position';

export type FormData = {
  periodId: number;
};

const getPositions = async (formData: FormData): Promise<Position[]> => {
  const response = await axios.post(
    `${process.env.REACT_APP_API_URL ?? ''}/api/system/position/getPositions`,
    formData,
  );

  const positions = (await camelcaseKeys(response.data, {
    deep: true,
  })) as unknown[];

  if (!isPositions(positions)) {
    throw Error('API type error');
  }

  return positions;
};

const useGetPositions = (
  period: PositionPeriod,
  isSuspense: boolean,
): UseQueryResult<Position[], AxiosError> =>
  useQuery(
    ['positions', period.id],
    () => getPositions({ periodId: period.id }),
    {
      suspense: isSuspense,
    },
  );

export default useGetPositions;
