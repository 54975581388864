import {
  forwardRef,
  ChangeEventHandler,
  FocusEventHandler,
  MouseEventHandler,
} from 'react';
import {
  FormControl,
  InputLabel,
  Input,
  InputAdornment,
  IconButton,
  FormHelperText,
  PropTypes,
} from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';

type Props = {
  label?: string;
  placeholder?: string;
  errorMsg?: string;
  isRevealPassword: boolean;
  isFullWidth?: boolean;
  variant?: 'standard' | 'filled' | 'outlined';
  margin?: PropTypes.Margin;
  toggleIsReveal: () => void;
  handleMouseDown: MouseEventHandler<HTMLButtonElement>;
  name?: string;
  onBlur?: FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  onChange?: ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  onClick?: MouseEventHandler<HTMLDivElement>;
  value?: unknown;
};

const InputPassword = forwardRef<HTMLDivElement, Props>(
  (
    {
      label,
      value,
      placeholder,
      errorMsg,
      isRevealPassword,
      isFullWidth,
      variant,
      margin,
      toggleIsReveal,
      handleMouseDown,
      name,
      onBlur,
      onChange,
      onClick,
    },
    ref,
  ) => (
    <FormControl
      error={!!errorMsg}
      variant={variant}
      margin={margin}
      fullWidth={isFullWidth}
    >
      <InputLabel shrink>{label}</InputLabel>
      <Input
        type={isRevealPassword ? 'text' : 'password'}
        placeholder={placeholder}
        fullWidth={isFullWidth}
        name={name}
        onBlur={onBlur}
        onChange={onChange}
        onClick={onClick}
        value={value}
        ref={ref}
        endAdornment={
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={toggleIsReveal}
              onMouseDown={handleMouseDown}
            >
              {isRevealPassword ? <Visibility /> : <VisibilityOff />}
            </IconButton>
          </InputAdornment>
        }
      />
      <FormHelperText>{errorMsg}</FormHelperText>
    </FormControl>
  ),
);

export default InputPassword;
