import {
  ChangeEventHandler,
  FocusEventHandler,
  forwardRef,
  MouseEventHandler,
  ReactNode,
} from 'react';
import { SerializedStyles } from '@emotion/react';
import { PropTypes } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';

type AddProps = {
  label?: string;
  errorMsg?: string;
  placeholder?: string;
  variant?: 'standard' | 'filled' | 'outlined';
  margin?: PropTypes.Margin;
  isDisabled?: boolean;
  isMultiLine: boolean;
  rows?: number;
  isFullWidth: boolean;
  isSelect?: boolean;
  name?: string;
  onBlur?: FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  onChange?: ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  onClick?: MouseEventHandler<HTMLDivElement>;
  value?: unknown;
  addCss?: SerializedStyles;
  children?: ReactNode;
};

const FormTextField = forwardRef<HTMLDivElement, AddProps>(
  (
    {
      label,
      isDisabled = false,
      errorMsg,
      placeholder = '内容をご記入ください。',
      rows = 9,
      name,
      onBlur,
      onChange,
      value,
      isMultiLine,
      isFullWidth,
      isSelect,
      variant = 'outlined',
      margin = 'normal',
      onClick,
      addCss,
      children,
    },
    ref,
  ) => (
    <TextField
      css={addCss}
      variant={variant}
      rows={rows}
      label={label}
      placeholder={placeholder}
      disabled={isDisabled}
      error={!!errorMsg}
      helperText={errorMsg}
      name={name}
      onBlur={onBlur}
      onChange={onChange}
      onClick={onClick}
      value={value}
      ref={ref}
      InputLabelProps={{ shrink: true }}
      multiline={isMultiLine}
      margin={margin}
      fullWidth={isFullWidth}
      select={isSelect}
    >
      {children}
    </TextField>
  ),
);

export default FormTextField;
