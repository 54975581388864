import { UseMutationResult, useMutation, useQueryClient } from 'react-query';
import axios, { AxiosError } from 'axios';
import { format } from 'date-fns';
import { UseFormReturn } from 'react-hook-form';
import ErrorResponse from 'domains/common/models/error';
import {
  isDailyOtherReport,
  DailyOtherReport,
} from '../models/dailyOtherReport';
import {
  isDailyAttachedInfo,
  DailyAttachedInfo,
} from '../models/dailyAttachedInfo';
import { isReportStatusList, ReportStatus } from '../models/reportStatus';
import { OtherFormData } from '../models/formData';

type ResponseProps = {
  otherReports: DailyOtherReport;
  attachedInfo: DailyAttachedInfo;
  reportStatusList: ReportStatus[];
};

const isResponseProps = (arg: unknown): arg is ResponseProps => {
  const r = arg as ResponseProps;

  return (
    isDailyOtherReport(r?.otherReports) &&
    isDailyAttachedInfo(r?.attachedInfo) &&
    isReportStatusList(r?.reportStatusList)
  );
};

const submitReport = async (
  formData: OtherFormData,
): Promise<ResponseProps> => {
  const response = await axios.post(
    `${process.env.REACT_APP_API_URL ?? ''}/api/workReport/submitReport`,
    formData,
  );
  const responseData = (await response.data) as unknown;

  if (!isResponseProps(responseData)) {
    throw Error('API type error');
  }

  return responseData;
};

const useSubmitReport = (
  setIsModalOpen: (arg: boolean) => void,
  viewDate: Date,
  otherFormMethods: UseFormReturn<OtherFormData>,
  setErrorMsgWRDiv: (arg: string) => void,
): UseMutationResult<
  ResponseProps,
  AxiosError<ErrorResponse>,
  OtherFormData,
  undefined
> => {
  const queryClient = useQueryClient();

  return useMutation(submitReport, {
    onSuccess: (data) => {
      queryClient.setQueryData(
        ['otherReport', format(viewDate, 'yyyy-M-d')],
        data.otherReports,
      );
      queryClient.setQueryData(
        ['reportAttachedInfo', format(viewDate, 'yyyy-M-d')],
        data.attachedInfo,
      );
      queryClient.setQueryData(
        ['reportStatusList', format(viewDate, 'yyyy-M')],
        data.reportStatusList,
      );
      otherFormMethods.clearErrors('otherReport1');
      setErrorMsgWRDiv('');
      setIsModalOpen(false);
    },
    onError: (error) => {
      const errors = error?.response?.data?.errors;
      if (errors?.otherReport1) {
        otherFormMethods.setError('otherReport1', {
          type: 'manual',
          message: errors.otherReport1,
        });
      } else {
        otherFormMethods.clearErrors('otherReport1');
      }
      if (errors?.loginId) {
        setErrorMsgWRDiv(errors?.loginId);
      } else {
        setErrorMsgWRDiv('');
      }
      setIsModalOpen(false);
    },
  });
};

export default useSubmitReport;
