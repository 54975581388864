import { VFC } from 'react';
import { FaStamp } from 'react-icons/fa';
import { css } from '@emotion/react';
import absoluteCenter from 'baseCss/emotion/absoluteCenter';
import IconHeader from 'components/01.atoms/Common/Basic/IconHeader';
import { format } from 'date-fns';
import { Typography } from '@material-ui/core';
import ja from 'date-fns/locale/ja';

const dateStyle = css`
  padding: 0 0;
  font-family: Lato, 'Helvetica Neue', Arial, Helvetica, sans-serif;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1.28571429em;
  color: rgba(0, 0, 0, 0.87);
  text-transform: none;
  border: none;
`;

const flexBox = css`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const labelGroup = css`
  align-self: flex-end;
  margin-right: 27px;
`;

const inlineBlock = css`
  display: inline-block;
`;

type Props = {
  viewDate: Date;
};

const MainContentsHeader: VFC<Props> = ({ viewDate }) => (
  <div css={flexBox}>
    <div css={absoluteCenter}>
      <Typography css={dateStyle} variant="h5" color="textPrimary">
        {format(viewDate, 'yyyy年M月d日（EEEE）', { locale: ja })}
      </Typography>
    </div>
    <IconHeader
      icon={<FaStamp />}
      title="日報承認"
      description="部下の業務報告を承認します"
    />
    <div css={[inlineBlock, labelGroup]} />
  </div>
);

export default MainContentsHeader;
