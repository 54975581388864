import { VFC } from 'react';
import { SerializedStyles } from '@emotion/react';
import { MutationStatus } from 'react-query';
import SnackBars from 'components/02.molecules/Common/SnackBars';

type Props = {
  statuses: MutationStatus[];
  loadingMsg: string;
  successMsg: string;
  errorMsg: string;
  addCss?: SerializedStyles;
};

const SnackBarsArray: VFC<Props> = ({
  statuses,
  loadingMsg,
  successMsg,
  errorMsg,
  addCss,
}) => (
  <>
    {statuses.map((status) => (
      <SnackBars
        status={status}
        loadingMsg={loadingMsg}
        successMsg={successMsg}
        errorMsg={errorMsg}
        addCss={addCss}
      />
    ))}
  </>
);
export default SnackBarsArray;
