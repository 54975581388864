export type EmptyTerm = {
  id: number;
  name: string;
  emptyStart: string;
  emptyEnd: string;
};

const isEmptyTerm = (arg: unknown): arg is EmptyTerm => {
  const e = arg as EmptyTerm;

  return (
    typeof e.id === 'number' &&
    typeof e.name === 'string' &&
    typeof e.emptyStart === 'string' &&
    typeof e.emptyEnd === 'string'
  );
};

const isEmptyTerms = (args: unknown[]): args is EmptyTerm[] =>
  !args.some((arg) => !isEmptyTerm(arg));

export { isEmptyTerm, isEmptyTerms };
