import axios, { AxiosError } from 'axios';
import camelcaseKeys from 'camelcase-keys';
import format from 'date-fns/format';
import { isSuccessOnly, SuccessOnly } from 'domains/common';
import ErrorResponse from 'domains/common/models/error';
import { UseMutationResult, useMutation, useQueryClient } from 'react-query';
import { OrganizationUserState } from '../models/organizationUserOption';

type FormProps = {
  orgId: number;
  users: OrganizationUserState[];
  emptyPeriodsEnd: string;
};

const addUsersForEmptyOrganization = async (
  formData: FormProps,
): Promise<SuccessOnly> => {
  const users = formData.users.map((data) => ({
    ...data,
    start: data.start ? format(data.start, 'yyyy-M-d') : null,
    end: data.end ? format(data.end, 'yyyy-M-d') : null,
  }));

  const response = await axios.post(
    `${
      process.env.REACT_APP_API_URL ?? ''
    }/api/system/user/addUsersForEmptyOrganization`,
    {
      ...formData,
      users,
    },
  );

  const success = (await camelcaseKeys(response.data, {
    deep: true,
  })) as unknown[];

  if (!isSuccessOnly(success)) {
    throw Error('API type error');
  }

  return success;
};

const useAddUsersForEmptyOrganization = (
  setIsOpen: (arg: boolean) => void,
  reset: () => void,
): UseMutationResult<
  SuccessOnly,
  AxiosError<ErrorResponse>,
  FormProps,
  undefined
> => {
  const queryClient = useQueryClient();

  return useMutation(addUsersForEmptyOrganization, {
    onSuccess: async () => {
      reset();
      setIsOpen(false);
      const p1 = queryClient.invalidateQueries([
        'system',
        'emptyTerms',
        'organizationsHasNoUsers',
      ]);
      const p2 = queryClient.invalidateQueries([
        'system',
        'emptyTerms',
        'usersHasNoMainOrganizations',
      ]);
      await Promise.all([p1, p2]);
    },
  });
};

export default useAddUsersForEmptyOrganization;
