import { VFC, useState } from 'react';
import { useDeleteWorkReport, WorkFormData } from 'domains/workReport';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import SnackBars from 'components/02.molecules/Common/SnackBars';
import { UseFormReturn } from 'react-hook-form';
import BasicConfirmDialog from 'components/03.organisms/Common/BasicConfirmDialog';
import Tooltip from '@material-ui/core/Tooltip';
import { Typography } from '@material-ui/core';

type Props = {
  viewDate?: Date;
  workFormMethods: UseFormReturn<WorkFormData>;
  setIsOuterModalOpen: (arg: boolean) => void;
};

const DeleteButton: VFC<Props> = ({
  viewDate = new Date(),
  workFormMethods,
  setIsOuterModalOpen,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { mutate, status } = useDeleteWorkReport(
    setIsModalOpen,
    setIsOuterModalOpen,
    viewDate,
  );
  const eventId = workFormMethods.getValues('eventId');

  const handleOk = () => {
    mutate({ eventId, viewDate });
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <Tooltip title={<Typography variant="caption">削除</Typography>}>
        <IconButton onClick={() => setIsModalOpen(true)} aria-label="delete">
          <DeleteIcon />
        </IconButton>
      </Tooltip>
      <SnackBars
        status={status}
        loadingMsg="削除中..."
        successMsg="削除が完了しました"
        errorMsg="削除に失敗しました"
      />
      <BasicConfirmDialog
        title="業務報告の削除"
        confirmMsg="選択中の業務報告を削除しますか？"
        isOpen={isModalOpen}
        handleOk={handleOk}
        handleCancel={handleCancel}
      />
    </>
  );
};

export default DeleteButton;
