export type OwnerTaskStatus = {
  ownerId: number;
  ownerName: string;
  taskStatus: number;
};

const isOwnerTaskStatus = (arg: unknown): arg is OwnerTaskStatus => {
  const o = arg as OwnerTaskStatus;

  return (
    typeof o?.ownerId === 'number' &&
    typeof o?.ownerName === 'string' &&
    typeof o?.taskStatus === 'number'
  );
};

const isOwnerTaskStatuses = (args: unknown[]): args is OwnerTaskStatus[] =>
  !args.some((arg) => !isOwnerTaskStatus(arg));

export { isOwnerTaskStatus, isOwnerTaskStatuses };
