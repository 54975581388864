import { OwnerTaskStatus, isOwnerTaskStatuses } from './ownerTaskStatus';
import { OwnTask } from './ownTask';

export type RequestingTask = Omit<OwnTask, 'taskStatus'> & {
  creatorId: number;
  isParentTaskOwner: boolean;
  ownerTaskStatus: OwnerTaskStatus[];
};
const isRequestingTask = (arg: unknown): arg is RequestingTask => {
  const o = arg as RequestingTask;

  return (
    typeof o?.taskId === 'number' &&
    typeof o?.creatorId === 'number' &&
    typeof o?.taskName === 'string' &&
    (o?.parentTaskName === null || typeof o?.parentTaskName === 'string') &&
    typeof o?.description === 'string' &&
    typeof o?.dueDateTime === 'string' &&
    typeof o?.isLeafNode === 'boolean' &&
    typeof o?.isCompleted === 'boolean' &&
    typeof o?.isDisabled === 'boolean' &&
    typeof o?.taskType === 'number' &&
    typeof o?.isParentTaskOwner === 'boolean' &&
    isOwnerTaskStatuses(o?.ownerTaskStatus)
  );
};

const isRequestingTasks = (args: unknown[]): args is RequestingTask[] =>
  !args.some((arg) => !isRequestingTask(arg));

export { isRequestingTask, isRequestingTasks };
