import {
  VFC,
  Dispatch,
  SetStateAction,
  MouseEventHandler,
  useMemo,
} from 'react';
import TreeStructure from 'components/02.molecules/Common/TreeStructure';
import RecursionOrganizationBlock from 'containers/02.molecules/Organization/RecursionOrganizationBlock';
import { Organization } from 'domains/organization';
import { TreeState } from 'domains/common';
import useDrawOrganizationTree from 'hooks/use-drawOrganizationTree';

type TreeProps = TreeState & {
  orgs: Organization[];
  depth: number;
  setTreeState: Dispatch<SetStateAction<TreeState>>;
};

const EnhancedTreeStructure: VFC<TreeProps> = ({
  orgs,
  displayableDepth,
  activeNodeId,
  openableNodeIds,
  disableNodeIds,
  setTreeState,
}) => {
  const handleClick: MouseEventHandler<HTMLDivElement> = (event) => {
    const active = event.currentTarget.getAttribute('data-org-id') ?? 0;
    setTreeState((prevState) => ({
      ...prevState,
      activeNodeId: Number(active),
    }));
  };
  const { getCanNextLoop, getDisplayedDepth, getRowSpan } =
    useDrawOrganizationTree({
      displayableDepth,
      openableNodeIds,
      disableNodeIds,
    });
  const displayedDepth = useMemo(
    () => getDisplayedDepth(orgs),
    [getDisplayedDepth, orgs],
  );

  return (
    <TreeStructure>
      <RecursionOrganizationBlock
        orgs={orgs}
        depth={1}
        displayedDepth={displayedDepth}
        activeNodeId={activeNodeId}
        handleClick={handleClick}
        setTreeState={setTreeState}
        getCanNextLoop={getCanNextLoop}
        getRowSpan={getRowSpan}
      />
    </TreeStructure>
  );
};

export default EnhancedTreeStructure;
