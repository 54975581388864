import { AxiosError } from 'axios';
import ErrorResponse from 'domains/common/models/error';
import { OrganizationState } from 'domains/user';

type ErrorType =
  | AxiosError<ErrorResponse>
  | {
      response: {
        data: ErrorResponse;
      };
    }
  | null;

const getMainOrgsError = (
  error: ErrorType,
  organizationState: OrganizationState[],
): string => {
  if (error?.response?.data?.errors.mainOrgs) {
    return error?.response?.data?.errors.mainOrgs;
  }

  const orgIdIndex = organizationState.findIndex((_, i) => {
    if (error?.response?.data?.errors[`mainOrgs.${i}.orgId`]) {
      return true;
    }

    return false;
  });
  if (orgIdIndex !== -1) {
    return error?.response?.data?.errors[`mainOrgs.${orgIdIndex}.orgId`] ?? '';
  }

  const endIndex = organizationState.findIndex((_, i) => {
    if (error?.response?.data?.errors[`mainOrgs.${i}.end`]) {
      return true;
    }

    return false;
  });
  if (endIndex !== -1) {
    return error?.response?.data?.errors[`mainOrgs.${endIndex}.end`] ?? '';
  }

  return '';
};

export default getMainOrgsError;
