import { isBasicDetail, BasicDetail } from './basicDetail';
import { isSubOrgs, SubOrg } from './subOrg';

export type OrganizationDetailTemp = {
  basic: BasicDetail;
  subOrgs: SubOrg[];
};

const isOrganizationDetailTemp = (
  arg: unknown,
): arg is OrganizationDetailTemp => {
  const o = arg as OrganizationDetailTemp;

  return isBasicDetail(o?.basic) && isSubOrgs(o?.subOrgs);
};

const isOrganizationDetailTemps = (
  args: unknown[],
): args is OrganizationDetailTemp[] =>
  !args.some((arg) => !isOrganizationDetailTemp(arg));

export { isOrganizationDetailTemp, isOrganizationDetailTemps };
