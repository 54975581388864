import { VFC } from 'react';
import { useController, UseControllerProps } from 'react-hook-form';
import { OtherFormData } from 'domains/workReport';
import OtherReportForm from 'components/01.atoms/WorkReport/Common/OtherReportForm';

type FormProps = {
  label?: string;
  isDisabled?: boolean;
  errorMsg?: string;
  placeholder?: string;
  rows?: number;
};

const EnhancedOtherReportForm: VFC<
  UseControllerProps<OtherFormData> & FormProps
> = ({
  label = 'ラベル',
  isDisabled = false,
  errorMsg,
  placeholder = '内容をご記入ください。',
  rows = 9,
  ...props
}) => {
  const { field } = useController(props);

  return (
    <OtherReportForm
      rows={rows}
      label={label}
      placeholder={placeholder}
      isDisabled={isDisabled}
      errorMsg={errorMsg}
      name={field.name}
      onBlur={field.onBlur}
      onChange={field.onChange}
      ref={field.ref}
      value={field.value}
    />
  );
};

export default EnhancedOtherReportForm;
