import { FC, MouseEventHandler } from 'react';
import { css, SerializedStyles } from '@emotion/react';
import base from 'baseCss/emotion/button';

const original = css`
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  &:hover {
    color: white;
    background-color: #0070c0;
  }
`;

export type ButtonProps = {
  isPost?: boolean;
  addCss?: SerializedStyles;
  onClick?: MouseEventHandler<HTMLButtonElement>;
};

const BasicButton: FC<ButtonProps> = ({
  isPost = false,
  addCss,
  onClick,
  children,
}) => (
  <button
    css={[base, original, addCss]}
    type={isPost ? 'submit' : 'button'}
    onClick={onClick}
  >
    {children}
  </button>
);

export default BasicButton;
