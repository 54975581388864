import { VFC } from 'react';
import {
  DataGrid,
  GridRowData,
  GridColDef,
  GridOverlay,
  GridCellParams,
} from '@material-ui/data-grid';
import { css } from '@emotion/react';
import { Button } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import { User } from 'domains/user';

const customStyle = css`
  &.MuiDataGrid-root .MuiDataGrid-columnHeader:focus,
  &.MuiDataGrid-root .MuiDataGrid-columnHeader:focus-within,
  &.MuiDataGrid-root .MuiDataGrid-cell:focus,
  &.MuiDataGrid-root .MuiDataGrid-cell:focus-within {
    outline: none;
  }
  &.MuiDataGrid-root .MuiDataGrid-window {
    overflow-y: overlay;
  }
  &.MuiDataGrid-root {
    height: 450px;
  }
`;

const progressStyle = css`
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -12px;
  margin-left: -12px;
`;

type Props = {
  rows: User[];
  handleDelete: (userId: number) => void;
  handleCheckDetail: (userId: number) => void;
  isLoading: boolean;
};

const columns = (
  handleDelete: (userId: number) => void,
  handleCheckDetail: (userId: number) => void,
): GridColDef[] => [
  {
    field: 'employeeNumber',
    headerName: '社員番号',
    width: 100,
    sortable: false,
  },
  {
    field: 'userName',
    headerName: '氏名(漢字・英字)',
    flex: 1,
    sortable: false,
  },
  {
    field: 'userNameKana',
    headerName: '氏名(ふりがな)',
    flex: 1,
    sortable: false,
  },
  { field: 'email', headerName: 'メールアドレス', flex: 1, sortable: false },
  {
    field: 'organization',
    headerName: '主務(現時点)',
    flex: 1,
    sortable: false,
  },
  { field: 'position', headerName: '役職(現時点)', flex: 1, sortable: false },
  {
    field: 'button',
    headerName: '操作',
    width: 150,
    sortable: false,
    renderCell: (params: GridCellParams) => (
      <>
        <Button
          css={css`
            margin-right: 4px;
          `}
          variant="contained"
          color="default"
          size="small"
          onClick={() => {
            handleCheckDetail(Number(params.getValue(params.id, 'id')));
          }}
        >
          詳細
        </Button>
        <Button
          variant="contained"
          color="secondary"
          size="small"
          onClick={() => {
            handleDelete(Number(params.getValue(params.id, 'id')));
          }}
        >
          削除
        </Button>
      </>
    ),
  },
  { field: 'id', hide: true },
];

const convertUsers = (original: User[]): GridRowData[] =>
  original.length === 0
    ? ([] as GridRowData[])
    : original.map((data) => ({
        id: data.userId,
        employeeNumber: data.employeeNumber,
        userName: data.name,
        userNameKana: data.nameKana,
        email: data.email,
        position: data.positionName,
        organization: data.orgName,
        button: '',
      }));

const CustomNoRowsOverlay: VFC = () => (
  <GridOverlay>
    <div>ユーザーデータは見つかりませんでした。</div>
  </GridOverlay>
);

const UserTable: VFC<Props> = ({
  rows,
  handleDelete,
  handleCheckDetail,
  isLoading,
}) => (
  <div
    css={css`
      position: relative;
      width: 100%;
      height: 450px;
    `}
  >
    <DataGrid
      css={customStyle}
      rows={convertUsers(rows)}
      columns={columns(handleDelete, handleCheckDetail)}
      hideFooter
      disableColumnMenu
      disableColumnSelector
      disableSelectionOnClick
      components={{
        NoRowsOverlay: CustomNoRowsOverlay,
      }}
    />
    {isLoading && <CircularProgress size={50} css={progressStyle} />}
  </div>
);
export default UserTable;
