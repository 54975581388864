export type PositionOption = {
  periodId: number;
  start: string;
  end: string;
  positionList: string;
};

export type PositionState = PositionOption & {
  rowNumber: number;
  positionId: number;
  positionName: string;
};

const isPositionOption = (arg: unknown): arg is PositionOption => {
  const o = arg as PositionOption;

  return (
    typeof o?.periodId === 'number' &&
    typeof o?.start === 'string' &&
    typeof o?.end === 'string' &&
    typeof o?.positionList === 'string'
  );
};

const isPositionOptions = (args: unknown[]): args is PositionOption[] =>
  !args.some((arg) => !isPositionOption(arg));

export { isPositionOption, isPositionOptions };
