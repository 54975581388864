import { VFC, ReactElement } from 'react';
import { css } from '@emotion/react';
import FormLabel from '@material-ui/core/FormLabel';

type Props = {
  label: string;
  element: ReactElement;
};

const marginStyle = css`
  margin-top: 20px;
  margin-bottom: 15px;
`;

const labelStyle = css`
  font-size: 0.9rem;
`;

const LabelAndElement: VFC<Props> = ({ label, element }) => (
  <div css={marginStyle}>
    <div>
      <FormLabel css={labelStyle}>{label}</FormLabel>
    </div>
    <div>{element}</div>
  </div>
);

export default LabelAndElement;
