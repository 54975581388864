import { Organization } from 'domains/organization';
import { useCallback, useMemo } from 'react';

type ArgType = {
  displayableDepth: number;
  openableNodeIds: number[];
  disableNodeIds: number[];
};

type ReturnType = {
  getCanNextLoop: (org: Organization, depthLevel: number) => boolean;
  getDisplayedDepth: (orgTree: Organization[]) => number;
  getRowSpan: (
    org: Organization[],
    depth: number,
    canNextLoop: boolean,
  ) => number;
};

const useDrawOrganizationTree = ({
  displayableDepth,
  openableNodeIds,
  disableNodeIds,
}: ArgType): ReturnType => {
  const getCanNextLoop = useCallback(
    (org: Organization, depthLevel: number): boolean => {
      const { orgId, orgType, childOrgs } = org;
      const isCustomizeDisplayable = openableNodeIds.includes(orgId);
      const isCustomizeDisable = disableNodeIds.includes(orgId);
      const hasSubOrg = childOrgs.length > 0;
      const isNotLeafNode = Number(orgType) === 1;

      return (
        isNotLeafNode &&
        !isCustomizeDisable &&
        hasSubOrg &&
        (depthLevel < displayableDepth || isCustomizeDisplayable)
      );
    },
    [disableNodeIds, displayableDepth, openableNodeIds],
  );

  const getDisplayedDepth = useMemo(
    () =>
      (orgTree: Organization[]): number => {
        let displayedDepth = 1;

        const updateDisplayedDepthLevel = (
          childOrgs: Organization[],
          thisDepth: number,
        ) => {
          if (displayedDepth < thisDepth) displayedDepth = thisDepth;
          childOrgs.forEach((org) => {
            if (getCanNextLoop(org, thisDepth)) {
              updateDisplayedDepthLevel(org.childOrgs, thisDepth + 1);
            }
          });
        };

        updateDisplayedDepthLevel(orgTree, 1);

        return displayedDepth;
      },
    [getCanNextLoop],
  );

  const getRowSpan = useCallback(
    (org: Organization[], depth: number, canNextLoop: boolean): number => {
      let rowSpanCounter = 0;

      const countRowSpan = (
        childOrgs: Organization[],
        thisDepth: number,
        thisCanNextLoop: boolean,
      ): void => {
        if (thisCanNextLoop) {
          childOrgs.forEach((childOrg) => {
            const canMoreNextLoop = getCanNextLoop(childOrg, depth + 1);
            countRowSpan(childOrg.childOrgs, thisDepth + 1, canMoreNextLoop);
          });
        } else {
          rowSpanCounter += 1;
        }
      };

      countRowSpan(org, depth, canNextLoop);

      return rowSpanCounter;
    },
    [getCanNextLoop],
  );

  return {
    getCanNextLoop,
    getDisplayedDepth,
    getRowSpan,
  };
};

export default useDrawOrganizationTree;
