import { VFC } from 'react';
import MainContentsHeader from 'containers/03.organisms/System//Invalid/MainContentsHeader';
import MainContentsArea from 'components/03.organisms/System/Invalid/MainContentsArea';
import TermsUsersHasNoPositionsTable from 'containers/02.molecules/System/TermsUsersHasNoPositionsTable';
import TermsUsersHasNoMainOrganizationsTable from 'containers/02.molecules/System/TermsUsersHasNoMainOrganizationsTable';
import TermsOrganizationsHasNoUsersTable from 'containers/02.molecules/System/TermsOrganizationsHasNoUsersTable';

const EnhancedMainContentsArea: VFC = () => (
  <MainContentsArea
    headerArea={<MainContentsHeader />}
    termsUsersHasNoPositions={<TermsUsersHasNoPositionsTable />}
    termsUsersHasNoMainOrganizations={<TermsUsersHasNoMainOrganizationsTable />}
    termsOrganizationsHasNoUsers={<TermsOrganizationsHasNoUsersTable />}
  />
);

export default EnhancedMainContentsArea;
