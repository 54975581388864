import { TreeState } from 'domains/common';
import { Dispatch, SetStateAction } from 'react';

type ArgType = {
  maxDepthLevel: number;
  setTreeState: Dispatch<SetStateAction<TreeState>>;
};

type ReturnType = {
  increment: () => void;
  decrement: () => void;
  changeMin: () => void;
  changeMax: () => void;
};

const useControllTreeDepth = ({
  maxDepthLevel,
  setTreeState,
}: ArgType): ReturnType => {
  const increment = () => {
    setTreeState((prevState) => ({
      ...prevState,
      displayableDepth:
        maxDepthLevel > prevState.displayableDepth
          ? prevState.displayableDepth + 1
          : prevState.displayableDepth,
      openableNodeIds: [],
      disableNodeIds: [],
    }));
  };
  const decrement = () => {
    setTreeState((prevState) => ({
      ...prevState,
      displayableDepth:
        prevState.displayableDepth > 1
          ? prevState.displayableDepth - 1
          : prevState.displayableDepth,
      openableNodeIds: [],
      disableNodeIds: [],
    }));
  };
  const changeMin = () => {
    setTreeState((prevState) => ({
      ...prevState,
      displayableDepth: 1,
      openableNodeIds: [],
      disableNodeIds: [],
    }));
  };
  const changeMax = () => {
    setTreeState((prevState) => ({
      ...prevState,
      displayableDepth: maxDepthLevel,
      openableNodeIds: [],
      disableNodeIds: [],
    }));
  };

  return {
    increment,
    decrement,
    changeMin,
    changeMax,
  };
};

export default useControllTreeDepth;
