import { VFC, useState } from 'react';
import MainContentsMainArea from 'containers/03.organisms/Organization/MainContentsMainArea';
import MainContentsHeader from 'containers/03.organisms/Organization/MainContentsHeader';
import MainContentsArea from 'components/03.organisms/Common/MainContentsAreaPatternB';
import { OrganizationPeriod } from 'domains/organization';
import { TreeState } from 'domains/common';
import PeriodSelectDialog from 'containers/02.molecules/Organization/PeriodSelectDialog';
import MainContentsDetails from 'containers/03.organisms/Organization/MainContentsDetails';
import EmptyDiv from 'components/01.atoms/Common/Basic/EmptyDiv';
import { css } from '@emotion/react';

type Props = {
  viewPeriod: OrganizationPeriod;
  setViewPeriod: (arg: OrganizationPeriod) => void;
};

const EnhancedMainContentsArea: VFC<Props> = ({
  viewPeriod,
  setViewPeriod,
}) => {
  const [treeState, setTreeState] = useState({
    displayableDepth: 1,
    activeNodeId: 0,
    openableNodeIds: [] as number[],
    disableNodeIds: [] as number[],
  } as TreeState);

  return (
    <>
      {viewPeriod.id ? (
        <MainContentsArea
          headerArea={
            <MainContentsHeader
              viewPeriod={viewPeriod}
              setViewPeriod={setViewPeriod}
              setTreeState={setTreeState}
            />
          }
          mainArea={
            <MainContentsMainArea
              viewPeriod={viewPeriod}
              treeState={treeState}
              setTreeState={setTreeState}
            />
          }
          sideArea={
            <div
              css={css`
                width: 250px;
                height: calc(100vh - 175px);
                padding-left: 10px;
                margin-left: 1rem;
                border-left: 1px solid #dee2e6;
              `}
            >
              {!treeState.activeNodeId ? (
                <EmptyDiv emptyMsg="未選択" width="100%" height="100%" />
              ) : (
                <MainContentsDetails
                  viewPeriod={viewPeriod}
                  activeOrgId={treeState.activeNodeId}
                />
              )}
            </div>
          }
        />
      ) : (
        <>
          <MainContentsArea
            headerArea={<div />}
            sideArea={<div />}
            mainArea={<div />}
          />
          <PeriodSelectDialog setViewPeriod={setViewPeriod} />
        </>
      )}
    </>
  );
};

export default EnhancedMainContentsArea;
