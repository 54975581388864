export type PositionPeriod = {
  id: number;
  start: string;
  end: string;
};

const isPositionPeriod = (arg: unknown): arg is PositionPeriod => {
  const p = arg as PositionPeriod;

  return (
    typeof p?.id === 'number' &&
    typeof p?.start === 'string' &&
    typeof p?.end === 'string'
  );
};

const isPositionPeriods = (args: unknown[]): args is PositionPeriod[] =>
  !args.some((arg) => !isPositionPeriod(arg));

export { isPositionPeriod, isPositionPeriods };
