import { VFC, ReactElement } from 'react';
import { css } from '@emotion/react';

const sideAndMain = css`
  display: grid;
  grid-template-columns: 1fr max-content;
  margin-top: 1.2rem;
`;

type Props = {
  headerArea: ReactElement;
  sideArea: ReactElement;
  mainArea: ReactElement;
};

const MainContentsArea: VFC<Props> = ({ headerArea, sideArea, mainArea }) => (
  <div>
    {headerArea}
    <div css={sideAndMain}>
      {mainArea}
      {sideArea}
    </div>
  </div>
);

export default MainContentsArea;
