import axios, { AxiosError } from 'axios';
import camelcaseKeys from 'camelcase-keys';
import { isSuccessOnly, SuccessOnly } from 'domains/common';
import ErrorResponse from 'domains/common/models/error';
import { UseMutationResult, useMutation, useQueryClient } from 'react-query';

type FormProps = {
  periodId: number;
  orgId: number;
  masterName: string;
  periodName: string;
  isBottomTeam: boolean;
};

const addTempSubOrganization = async (
  formData: FormProps,
): Promise<SuccessOnly> => {
  const response = await axios.post(
    `${
      process.env.REACT_APP_API_URL ?? ''
    }/api/system/organization/addTempSubOrganization`,
    formData,
  );

  const success = (await camelcaseKeys(response.data, {
    deep: true,
  })) as unknown;

  if (!isSuccessOnly(success)) {
    throw Error('API type error');
  }

  return success;
};

const useAddTempSubOrganization = (
  periodId: number,
  orgId: number,
  setIsOpen: (arg: boolean) => void,
  formReset: () => void,
): UseMutationResult<
  SuccessOnly,
  AxiosError<ErrorResponse>,
  FormProps,
  undefined
> => {
  const queryClient = useQueryClient();

  return useMutation(addTempSubOrganization, {
    onSuccess: async () => {
      const invalid1 = queryClient.invalidateQueries([
        'system',
        'organizationDetail',
        'temp',
        orgId,
        periodId,
      ]);
      const invalid2 = queryClient.invalidateQueries([
        'system',
        'organizations',
        'edit',
        periodId,
      ]);
      await Promise.all([invalid1, invalid2]);
      formReset();
      setIsOpen(false);
    },
  });
};

export default useAddTempSubOrganization;
