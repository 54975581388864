import { VFC } from 'react';
import { css } from '@emotion/react';
import IconHeader from 'components/01.atoms/Common/Basic/IconHeader';
import PostAddIcon from '@material-ui/icons/PostAdd';
import Button from '@material-ui/core/Button';

const flexBox = css`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const rightGroup = css`
  display: inline-block;
  align-self: flex-end;
  margin-right: 27px;
`;

type Props = {
  handleOk: () => void;
};

const MainContentsHeader: VFC<Props> = ({ handleOk }) => (
  <div css={flexBox}>
    <IconHeader
      icon={<PostAddIcon />}
      title="スポットタスクの依頼"
      description="新しく1回限りのタスク依頼を行います"
    />
    <div css={rightGroup}>
      <Button onClick={handleOk} color="primary" variant="contained">
        依頼開始
      </Button>
    </div>
  </div>
);

export default MainContentsHeader;
