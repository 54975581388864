import { ReactElement, VFC } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
  Button,
} from '@material-ui/core';

type Props = {
  title: string;
  confirmMsg?: string;
  cancelButtonName: string;
  isOpen: boolean;
  handleCancel: () => void;
  anyElement: ReactElement;
  addButtonElement?: ReactElement | null;
};

const ConfirmAnyActionDialog: VFC<Props> = ({
  title,
  anyElement,
  addButtonElement,
  cancelButtonName,
  confirmMsg,
  isOpen,
  handleCancel,
}) => (
  <Dialog
    aria-labelledby="confirmation-dialog"
    onClose={handleCancel}
    open={isOpen}
  >
    <DialogTitle id="confirmation-dialog">{title}</DialogTitle>
    <DialogContent dividers>
      {!!confirmMsg && <DialogContentText>{confirmMsg}</DialogContentText>}
      {anyElement}
    </DialogContent>
    <DialogActions>
      {addButtonElement}
      <Button onClick={handleCancel} color="default">
        {cancelButtonName}
      </Button>
    </DialogActions>
  </Dialog>
);

export default ConfirmAnyActionDialog;
