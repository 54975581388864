import axios, { AxiosError } from 'axios';
import camelcaseKeys from 'camelcase-keys';
import { useQuery, UseQueryResult } from 'react-query';
import {
  OrganizationDetail,
  isOrganizationDetail,
} from '../models/organizationDetail';

export type FormProps = {
  orgId: number;
  periodId: number;
};

const getOrganizationDetail = async (
  formData: FormProps,
): Promise<OrganizationDetail> => {
  const response = await axios.post(
    `${
      process.env.REACT_APP_API_URL ?? ''
    }/api/system/organization/getOrganizationDetail`,
    formData,
  );

  const detail = (await camelcaseKeys(response.data, {
    deep: true,
  })) as unknown;

  if (!isOrganizationDetail(detail)) {
    throw Error('API type error');
  }

  return detail;
};

const useGetOrganizationDetail = (
  orgId: number,
  periodId: number,
  isSuspense: boolean,
): UseQueryResult<OrganizationDetail, AxiosError> =>
  useQuery(
    ['system', 'organizationDetail', orgId, periodId],
    () => getOrganizationDetail({ orgId, periodId }),
    {
      suspense: isSuspense,
    },
  );

export default useGetOrganizationDetail;
