export type User = {
  userId: number;
  employeeNumber: string;
  name: string;
  nameKana: string;
  email: string;
  positionName: string | null;
  orgType: number | null;
  orgName: string | null;
};

const isUser = (arg: unknown): arg is User => {
  const u = arg as User;

  return (
    typeof u?.userId === 'number' &&
    typeof u?.employeeNumber === 'string' &&
    typeof u?.name === 'string' &&
    typeof u?.nameKana === 'string' &&
    typeof u?.email === 'string' &&
    (typeof u?.positionName === 'string' || u?.positionName === null) &&
    (typeof u?.orgType === 'number' || u?.orgType === null) &&
    (typeof u?.orgName === 'string' || u?.orgName === null)
  );
};

const isUsers = (args: unknown[]): args is User[] =>
  !args.some((arg) => !isUser(arg));

export { isUser, isUsers };
