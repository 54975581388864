import { VFC, useState } from 'react';
import { css } from '@emotion/react';
import Button from '@material-ui/core/Button';
import { useGetDailyAttachedInfoByUserId } from 'domains/workReport';
import { WORK_REPORT_STATUS } from 'utils/const';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ApproveConfirmDialog from 'containers/03.organisms/WorkReport/Approve/ApproveConfirmDialog';
import RemandConfirmDialog from 'containers/03.organisms/WorkReport/Approve/RemandConfirmDialog';

type Props = {
  viewDate?: Date;
  viewStaffId: number;
};

const ApproveOrRemandButton: VFC<Props> = ({
  viewDate = new Date(),
  viewStaffId,
}) => {
  const [isApproveOpen, setIsApproveOpen] = useState(false);
  const [isRemandOpen, setIsRemandOpen] = useState(false);
  const { data: attachedInfo = { status: 0, feedback: '' }, isLoading } =
    useGetDailyAttachedInfoByUserId(viewDate, viewStaffId, false);

  const canAction = attachedInfo.status === WORK_REPORT_STATUS.SUBMITTED;

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleStartApprove = () => {
    setAnchorEl(null);
    setIsApproveOpen(true);
  };
  const handleStartRemand = () => {
    setAnchorEl(null);
    setIsRemandOpen(true);
  };

  let buttonName;
  switch (attachedInfo.status) {
    case WORK_REPORT_STATUS.UNDONE:
      buttonName = '未提出';
      break;
    case WORK_REPORT_STATUS.REMANDED:
      buttonName = '差戻中';
      break;
    case WORK_REPORT_STATUS.SUBMITTED:
      buttonName = '承認・差戻';
      break;
    case WORK_REPORT_STATUS.APPROVED:
      buttonName = '承認済み';
      break;
    default:
      buttonName = 'ステータス異常値';
      break;
  }

  return (
    <>
      <Button
        variant="contained"
        css={css`
          width: 100%;
          max-width: 200px;
          font-weight: bold;
        `}
        color="primary"
        disableElevation
        onClick={handleClick}
        disabled={!canAction || isLoading}
        aria-controls="header-menu"
        aria-haspopup="true"
      >
        {buttonName}
      </Button>
      <Menu
        id="header-menu"
        anchorEl={anchorEl}
        getContentAnchorEl={null}
        keepMounted
        anchorOrigin={{
          vertical: 45,
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        disableScrollLock
      >
        <MenuItem onClick={() => handleStartApprove()}>日報の承認</MenuItem>
        <MenuItem onClick={() => handleStartRemand()}>日報の差戻</MenuItem>
      </Menu>
      <ApproveConfirmDialog
        viewDate={viewDate}
        viewStaffId={viewStaffId}
        isOpen={isApproveOpen}
        setIsOpen={setIsApproveOpen}
      />
      <RemandConfirmDialog
        viewDate={viewDate}
        viewStaffId={viewStaffId}
        isOpen={isRemandOpen}
        setIsOpen={setIsRemandOpen}
      />
    </>
  );
};

export default ApproveOrRemandButton;
