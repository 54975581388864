import { VFC, useState, ChangeEvent } from 'react';
import MainContentsHeader from 'components/03.organisms/Position/MainContentsHeader';
import { PositionPeriod } from 'domains/position';

type Props = {
  viewPeriod: PositionPeriod;
};

const EnhancedMainContentsHeader: VFC<Props> = ({ viewPeriod }) => {
  const [isEditMode, setIsEditMode] = useState(false);
  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setIsEditMode(event.target.checked);
  };

  return (
    <MainContentsHeader
      viewPeriod={viewPeriod}
      isEditMode={isEditMode}
      clickSwitch={handleChange}
    />
  );
};

export default EnhancedMainContentsHeader;
