import axios, { AxiosError } from 'axios';
import camelcaseKeys from 'camelcase-keys';
import { SuccessOnly, isSuccessOnly } from 'domains/common';
import ErrorResponse from 'domains/common/models/error';
import { FormProps as FilterProps } from 'domains/task/common/services/getRequestingTasks';
import { UseMutationResult, useMutation, useQueryClient } from 'react-query';

type FormProps = {
  taskId: number;
};

const cancelCompleted = async (formData: FormProps): Promise<SuccessOnly> => {
  const response = await axios.post(
    `${process.env.REACT_APP_API_URL ?? ''}/api/task/spot/cancelCompleted`,
    formData,
  );

  const success = (await camelcaseKeys(response.data, {
    deep: true,
  })) as unknown;

  if (!isSuccessOnly(success)) {
    throw Error('API type error');
  }

  return success;
};

const useApproveCompleted = (
  filterAndSortInfo: FilterProps,
  closeAfterSuccess: () => void,
): UseMutationResult<
  SuccessOnly,
  AxiosError<ErrorResponse>,
  FormProps,
  undefined
> => {
  const queryClient = useQueryClient();

  return useMutation(cancelCompleted, {
    onSuccess: async () => {
      await queryClient.invalidateQueries([
        'authUser',
        'requestingTasks',
        filterAndSortInfo,
      ]);
      closeAfterSuccess();
    },
  });
};

export default useApproveCompleted;
