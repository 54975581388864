import { VFC, MouseEventHandler } from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import PositionSelectDialog from 'components/03.organisms/User/PositionSelectDialog';

type Props = {
  rowNumber: number;
  isOpen: boolean;
  setIsOpen: (arg: boolean) => void;
  positionListText: string;
  handleClick: MouseEventHandler<HTMLDivElement>;
};

const EnhancedPositionSelectDialog: VFC<Props> = ({
  rowNumber,
  isOpen,
  setIsOpen,
  positionListText,
  handleClick,
}) => {
  const handleCancel = () => {
    setIsOpen(false);
  };
  const positionLists = positionListText ? positionListText.split(',,') : [];

  return (
    <PositionSelectDialog
      isOpen={isOpen}
      handleCancel={handleCancel}
      positionList={
        <List>
          {positionLists.map((position) => {
            const positionInfo = position.split('::');
            const positionId = positionInfo[0];
            const positionName = positionInfo[1];

            return (
              <ListItem
                button
                key={positionId}
                onClick={handleClick}
                data-id={positionId}
                data-name={positionName}
                data-row-number={rowNumber}
              >
                <ListItemText primary={positionName} />
              </ListItem>
            );
          })}
        </List>
      }
    />
  );
};

export default EnhancedPositionSelectDialog;
