import { VFC, useState } from 'react';
import MainContentsArea from 'components/03.organisms/Common/MainContentsAreaMainOnly';
import MainContentsMainArea from 'containers/03.organisms/Task/OwnTask/MainContentsMainArea';
import MainContentsHeader from 'containers/03.organisms/Task/OwnTask/MainContentsHeader';
import { FormProps } from 'domains/task/common/services/getOwnTasks';

const EnhancedMainContentsArea: VFC = () => {
  const [filterAndSortInfo, setFilterAndSortInfo] = useState({} as FormProps);

  return (
    <MainContentsArea
      headerArea={
        <MainContentsHeader setFilterAndSortInfo={setFilterAndSortInfo} />
      }
      mainArea={<MainContentsMainArea filterAndSortInfo={filterAndSortInfo} />}
    />
  );
};

export default EnhancedMainContentsArea;
