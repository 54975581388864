import { VFC, Dispatch, SetStateAction, MouseEvent } from 'react';
import { OrganizationUserState } from 'domains/user';
import IsMainToggleButtons from 'components/02.molecules/User/IsMainToggleButtons';

type Props = {
  rowNumber: number;
  isMain: boolean;
  setUsersState: Dispatch<SetStateAction<OrganizationUserState[]>>;
};

const EnhancedIsMainToggleButtons: VFC<Props> = ({
  rowNumber,
  isMain,
  setUsersState,
}) => {
  const handleChange = (_event: MouseEvent<HTMLElement>, newValue: number) => {
    setUsersState((prevState) =>
      prevState.map((el) =>
        el.rowNumber === rowNumber ? { ...el, isMain: !!newValue } : el,
      ),
    );
  };

  return <IsMainToggleButtons isMain={isMain} handleChange={handleChange} />;
};

export default EnhancedIsMainToggleButtons;
