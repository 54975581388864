import React, { ReactElement, VFC } from 'react';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import { css } from '@emotion/react';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';

const listItemStyle = css`
  padding-top: 12px;
  padding-bottom: 12px;
`;

const listIconStyle = css`
  min-width: 0;
`;

type ListItemProps = {
  pathName: string;
  keyName: string;
  url: string;
  handleItemClick: (url: string) => void;
  icon: ReactElement;
};

const ListItemSelectType: VFC<ListItemProps> = ({
  pathName,
  keyName,
  url,
  handleItemClick,
  icon,
}) => (
  <Tooltip
    title={<Typography variant="caption">{keyName}</Typography>}
    placement="right"
    arrow
  >
    <ListItem
      css={listItemStyle}
      button
      key={keyName}
      selected={pathName === url}
      onClick={() => {
        handleItemClick(url);
      }}
    >
      <ListItemIcon css={listIconStyle}>{icon}</ListItemIcon>
    </ListItem>
  </Tooltip>
);

export default ListItemSelectType;
