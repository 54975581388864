import { VFC } from 'react';
import { TreeState } from 'domains/common';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import LastPageIcon from '@material-ui/icons/LastPage';
import { css } from '@emotion/react';

export type Props = {
  maxDepthLevel: number;
  treeState: TreeState;
  increment: () => void;
  decrement: () => void;
  changeMin: () => void;
  changeMax: () => void;
};

const TreeController: VFC<Props> = ({
  treeState,
  maxDepthLevel,
  increment,
  decrement,
  changeMin,
  changeMax,
}) => (
  <div
    css={css`
      display: flex;
      align-items: center;
    `}
  >
    <Tooltip
      title={<Typography variant="caption">表示階層を最小にする</Typography>}
    >
      <IconButton
        onClick={changeMin}
        aria-label="change min depth"
        color="primary"
        size="small"
      >
        <FirstPageIcon />
      </IconButton>
    </Tooltip>
    <Tooltip
      title={<Typography variant="caption">表示階層を減らす</Typography>}
    >
      <IconButton
        onClick={decrement}
        aria-label="decrement depth"
        color="primary"
        size="small"
      >
        <NavigateBeforeIcon />
      </IconButton>
    </Tooltip>
    <Typography
      variant="h6"
      color="textPrimary"
      display="inline"
      css={css`
        margin-right: 10px;
        margin-left: 10px;
        font-size: 0.9rem;
        font-weight: bold;
      `}
    >
      ツリー全体の共通表示レベル {treeState.displayableDepth} / {maxDepthLevel}
    </Typography>
    <Tooltip
      title={<Typography variant="caption">表示階層を増やす</Typography>}
    >
      <IconButton
        onClick={increment}
        aria-label="increment depth"
        color="primary"
        size="small"
      >
        <NavigateNextIcon />
      </IconButton>
    </Tooltip>
    <Tooltip
      title={<Typography variant="caption">表示階層を最大にする</Typography>}
    >
      <IconButton
        onClick={changeMax}
        aria-label="change max depth"
        color="primary"
        size="small"
      >
        <LastPageIcon />
      </IconButton>
    </Tooltip>
  </div>
);

export default TreeController;
