import { VFC } from 'react';
import { Global, css } from '@emotion/react';
import { format } from 'date-fns';
import { getDailyWorkReportByUserId, WorkFormData } from 'domains/workReport';
import { useQuery } from 'react-query';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import { UseFormReturn } from 'react-hook-form';
import WorkReportTable from 'components/02.molecules/WorkReport/Common/WorkReportTable';

const basicLabel = css`
  display: inline-block;
  flex-grow: inherit !important;
  margin: 0 0 1rem 0;
  font-size: 1.1em;
  font-weight: 700;
  color: rgba(0, 0, 0, 0.87);
  text-transform: none;
`;

const progressStyle = css`
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 1;
  margin-top: -45px;
  margin-left: -15px;
  color: rgb(158, 158, 158);
`;

type WorkReportInputProps = {
  viewDate: Date;
  viewStaffId: number;
  setIsModalOpen: (arg: boolean) => void;
  workFormMethods: UseFormReturn<WorkFormData>;
};

const WorkReportLists: VFC<WorkReportInputProps> = ({
  viewDate,
  viewStaffId,
  setIsModalOpen,
  workFormMethods,
}) => {
  const {
    data: workReportSet = { workReports: [], workingHours: '' },
    status,
  } = useQuery(
    ['staffWorkReports', format(viewDate, 'yyyy-M-d'), viewStaffId],
    () =>
      getDailyWorkReportByUserId({
        viewDate: format(viewDate, 'yyyy-M-d'),
        userId: viewStaffId,
      }),
    {
      suspense: false,
    },
  );
  const isLoading = status === 'loading';

  return (
    <>
      <Global
        styles={css`
          ::-webkit-scrollbar {
            -webkit-appearance: none;
            width: 10px;
            height: 10px;
          }
          ::-webkit-scrollbar-thumb {
            cursor: pointer;
            background: rgba(0, 0, 0, 0.25);
            border-radius: 5px;
            transition: color 0.2s ease;
          }
          ::-webkit-scrollbar-track {
            background: rgba(0, 0, 0, 0.1);
            border-radius: 0;
          }
        `}
      />
      <div
        css={css`
          display: flex;
          justify-content: space-between;
        `}
      >
        <div
          css={css`
            display: inline-block;
          `}
        >
          <span css={basicLabel}>業務内容</span>
        </div>
      </div>
      <div
        css={css`
          position: relative;
          height: 90%;
        `}
      >
        <WorkReportTable
          rows={workReportSet.workReports}
          viewDate={viewDate}
          setIsModalOpen={setIsModalOpen}
          workFormMethods={workFormMethods}
        />
        <div
          css={css`
            display: flex;
            justify-content: flex-end;
            padding-top: 10px;
            padding-right: 10px;
            padding-bottom: 10px;
          `}
        >
          <Typography
            gutterBottom
            variant="h6"
            css={css`
              color: #515151;
            `}
          >
            業務時間 {workReportSet?.workingHours || 'なし'}
          </Typography>
        </div>
        {isLoading && <CircularProgress size={50} css={progressStyle} />}
      </div>
    </>
  );
};

export default WorkReportLists;
