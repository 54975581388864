import { VFC, useState } from 'react';
import Button from '@material-ui/core/Button';
import EditUserNameKanaDialog from 'containers/03.organisms/User/EditUserNameKanaDialog';

type Props = {
  viewUserId: number;
};

const EditUserNameKanaButton: VFC<Props> = ({ viewUserId }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <>
      <Button
        variant="outlined"
        size="small"
        color="primary"
        onClick={() => setIsModalOpen(true)}
      >
        編集
      </Button>
      <EditUserNameKanaDialog
        viewUserId={viewUserId}
        isOpen={isModalOpen}
        setIsOpen={setIsModalOpen}
      />
    </>
  );
};

export default EditUserNameKanaButton;
