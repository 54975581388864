import { ReactElement, VFC } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
  Button,
} from '@material-ui/core';
import { css } from '@emotion/react';

type Props = {
  title: string;
  confirmMsg: string;
  okButtonName: string;
  cancelButtonName: string;
  isOpen: boolean;
  handleOk: () => void;
  handleCancel: () => void;
  textField: ReactElement;
  maxWidth?: false | 'xs' | 'sm' | 'md' | 'lg' | 'xl';
};

const BasicConfirmFormDialog: VFC<Props> = ({
  title,
  textField,
  okButtonName,
  cancelButtonName,
  confirmMsg,
  isOpen,
  handleOk,
  handleCancel,
  maxWidth,
}) => (
  <Dialog
    aria-labelledby="confirmation-dialog"
    onClose={handleCancel}
    open={isOpen}
    maxWidth={maxWidth}
    css={css`
      & .MuiDialog-paper {
        overflow-y: initial;
      }
    `}
  >
    <DialogTitle id="confirmation-dialog">{title}</DialogTitle>
    <DialogContent dividers>
      <DialogContentText>{confirmMsg}</DialogContentText>
      {textField}
    </DialogContent>
    <DialogActions>
      <Button onClick={handleOk} color="primary">
        {okButtonName}
      </Button>
      <Button onClick={handleCancel} color="default">
        {cancelButtonName}
      </Button>
    </DialogActions>
  </Dialog>
);

export default BasicConfirmFormDialog;
