export type DailyOtherReport = {
  otherReport1: string;
  otherReport2: string;
};

const isDailyOtherReport = (arg: unknown): arg is DailyOtherReport => {
  const d = arg as DailyOtherReport;

  return (
    typeof d?.otherReport1 === 'string' && typeof d?.otherReport2 === 'string'
  );
};

const isDailyOtherReports = (args: unknown[]): args is DailyOtherReport[] =>
  !args.some((arg) => !isDailyOtherReport(arg));

export { isDailyOtherReport, isDailyOtherReports };
