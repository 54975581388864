import { VFC, useState } from 'react';
import { css } from '@emotion/react';
import Button from '@material-ui/core/Button';
import { useForm } from 'react-hook-form';
import BasicConfirmFormDialog from 'components/03.organisms/Common/BasicConfirmFormDialog';
import SnackBars from 'components/02.molecules/Common/SnackBars';
import { UpdatePositionForm } from 'domains/common';
import { PositionPeriod, useUpdatePosition } from 'domains/position';
import FormTextField from 'containers/01.atoms/Common/FormTextFiled';

type Props = {
  viewPeriod: PositionPeriod;
};

const AddItemButton: VFC<Props> = ({ viewPeriod }) => {
  const [isOpen, setIsOpen] = useState(false);
  const { getValues, control, reset } = useForm<UpdatePositionForm>({
    defaultValues: { positionName: '' },
  });
  const {
    mutate,
    status,
    reset: resetError,
    error = {
      response: {
        data: { errors: { positionName: '' } },
      },
    },
  } = useUpdatePosition(viewPeriod.id, setIsOpen, reset);
  const handleUpdateOk = () => {
    mutate({
      periodId: viewPeriod.id,
      positionId: 0,
      positionName: getValues('positionName'),
    });
  };
  const handleUpdateCancel = () => {
    setIsOpen(false);
    resetError();
  };

  return (
    <>
      <Button
        css={css`
          font-weight: bold;
        `}
        variant="outlined"
        color="primary"
        size="small"
        disableElevation
        onClick={() => setIsOpen(true)}
      >
        追加
      </Button>
      <BasicConfirmFormDialog
        title="役職アイテムの追加登録"
        confirmMsg="新しく役職アイテムを登録します。"
        isOpen={isOpen}
        handleOk={handleUpdateOk}
        handleCancel={handleUpdateCancel}
        okButtonName="登録"
        cancelButtonName="キャンセル"
        textField={
          <FormTextField
            label="役職名称"
            placeholder="役職名称をご記入ください"
            isMultiLine={false}
            isFullWidth
            variant="standard"
            margin="normal"
            name="positionName"
            control={control}
            errorMsg={error?.response?.data?.errors.positionName}
          />
        }
      />
      <SnackBars
        status={status}
        loadingMsg="登録中..."
        successMsg="登録が完了しました"
        errorMsg="登録に失敗しました"
      />
    </>
  );
};

export default AddItemButton;
