import { VFC } from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import DialogLabel from 'components/01.atoms/Common/Basic/DialogLabel';
import { css } from '@emotion/react';

type Props = {
  label: string;
  textList: { id: number; value: string }[];
};

const EffectTextList: VFC<Props> = ({ label, textList }) => (
  <>
    <DialogLabel label={label} />
    <List
      dense
      css={css`
        padding-top: 0;
      `}
    >
      {textList?.map((text) => (
        <ListItem
          key={text.id}
          css={css`
            padding-top: 0;
          `}
        >
          <ListItemText primary={text.value} />
        </ListItem>
      ))}
    </List>
  </>
);

export default EffectTextList;
