import { useState, VFC } from 'react';
import { useQuery } from 'react-query';
import { getReportStatusListByUserId } from 'domains/workReport';
import { format } from 'date-fns';
import DateSelector from 'components/02.molecules/WorkReport/Common/DateSelector';

type Props = {
  viewDate?: Date;
  setViewDate: (arg: Date) => void;
  viewStaffId: number;
};

const ReportDateSelector: VFC<Props> = ({
  viewDate = new Date(),
  setViewDate,
  viewStaffId,
}) => {
  const [calendarMonth, setCalendarMonth] = useState(viewDate);

  const { data: statusList = {} } = useQuery(
    ['staffReportStatusList', format(calendarMonth, 'yyyy-M'), viewStaffId],
    () =>
      getReportStatusListByUserId({
        calendarMonth: format(calendarMonth, 'yyyy-M-d'),
        userId: viewStaffId,
      }),
    {
      suspense: false,
    },
  );

  return (
    <DateSelector
      viewDate={viewDate}
      setViewDate={setViewDate}
      setCalendarMonth={setCalendarMonth}
      statusList={statusList}
    />
  );
};

export default ReportDateSelector;
