import { VFC, useState } from 'react';
import { css } from '@emotion/react';
import WorkCategorySelectionDialog from 'components/01.atoms/WorkReport/Input/WorkCategorySelectionDialog';
import WorkCategoryInput from 'components/01.atoms/WorkReport/Input/WorkCategoryInput';
import { UseFormReturn } from 'react-hook-form';
import { WorkFormData } from 'domains/workReport';

type CategoryProps = {
  value?: string;
  tableId?: string;
  categoryId?: string;
  categoryName?: string;
};

type ConfirmationDialogProps = {
  workFormMethods: UseFormReturn<WorkFormData>;
  errorMsg?: string;
  isDisabled: boolean;
};

const WorkCategorySelection: VFC<ConfirmationDialogProps> = ({
  workFormMethods,
  errorMsg = '',
  isDisabled,
}) => {
  const [open, setOpen] = useState(false);
  const tableId = workFormMethods.getValues('tableId');
  const categoryId = workFormMethods.getValues('categoryId');
  const [value, setValue] = useState(`${tableId}:${categoryId}`);

  const handleClickInput = () => {
    if (!isDisabled) {
      setOpen(true);
    }
  };

  const handleClose = (props?: CategoryProps) => {
    setOpen(false);
    if (props?.value) {
      setValue(props?.value);
      workFormMethods.setValue('categoryName', props?.categoryName ?? '');
      workFormMethods.setValue('tableId', Number(props?.tableId ?? 0));
      workFormMethods.setValue('categoryId', Number(props?.categoryId ?? 0));
    }
  };

  return (
    <div
      css={css`
        width: 100%;
        min-width: 300px;
        max-width: 500px;
        background-color: white;
      `}
    >
      <WorkCategoryInput
        control={workFormMethods.control}
        name="categoryName"
        defaultValue=""
        onClick={handleClickInput}
        errorMsg={errorMsg}
        isDisabled={isDisabled}
      />
      <WorkCategorySelectionDialog
        id="workCategorySelection"
        keepMounted
        open={open}
        onClose={handleClose}
        value={value}
      />
    </div>
  );
};

export default WorkCategorySelection;
