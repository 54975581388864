import { VFC } from 'react';
import { css } from '@emotion/react';
import { CircularProgress, Typography } from '@material-ui/core';

const overall = css`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  background-color: white;
`;

const center = css`
  display: flex;
  flex-direction: column;
  gap: 2em;
  align-items: center;
  justify-content: center;
`;

const LoadingPage: VFC = () => (
  <div css={overall}>
    <div css={center}>
      <CircularProgress size={120} />
      <Typography variant="h4" display="block">
        認証確認中
      </Typography>
    </div>
  </div>
);
export default LoadingPage;
