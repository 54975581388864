export type Position = {
  id: number;
  periodId: number;
  name: string;
};

const isPosition = (arg: unknown): arg is Position => {
  const p = arg as Position;

  return typeof p?.id === 'number' && typeof p?.name === 'string';
};

const isPositions = (args: unknown[]): args is Position[] =>
  !args.some((arg) => !isPosition(arg));

export { isPosition, isPositions };
