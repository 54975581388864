import { VFC, ReactElement } from 'react';
import { css } from '@emotion/react';
import Typography from '@material-ui/core/Typography';
import parse from 'date-fns/parse';
import { SYSTEM_USAGE_PERIOD } from 'utils/const';
import DateSelectInput from './DateSelectInput';

type Props = {
  start: Date | null;
  end: Date | null;
  setStart: (arg: Date | null) => void;
  setEnd: (arg: Date | null) => void;
  customStart: ReactElement;
  customEnd: ReactElement;
};

const PeriodSelectInput: VFC<Props> = ({
  start,
  end,
  setStart,
  setEnd,
  customStart,
  customEnd,
}) => (
  <div
    css={css`
      display: flex;
      align-items: flex-start;
    `}
  >
    <DateSelectInput
      selectsStart
      periodStart={start}
      periodEnd={end}
      viewDate={start}
      setViewDate={setStart}
      setEndDate={setEnd}
      customInput={customStart}
      minDate={parse(SYSTEM_USAGE_PERIOD.START, 'yyyy/M/d', new Date())}
    />
    <Typography
      css={css`
        display: inline-block;
        padding: 36.5px 1.4rem 0;
      `}
      color="textSecondary"
    >
      ～
    </Typography>
    <DateSelectInput
      selectsEnd
      periodStart={start}
      periodEnd={end}
      minDate={
        start ?? parse(SYSTEM_USAGE_PERIOD.START, 'yyyy/M/d', new Date())
      }
      viewDate={end}
      setViewDate={setEnd}
      customInput={customEnd}
    />
  </div>
);

export default PeriodSelectInput;
