import { VFC } from 'react';
import EffectTable from 'components/02.molecules/Position/EffectTable';
import { PositionPeriod, useGetDeletePeriodEffects } from 'domains/position';

type Props = {
  label: string;
  viewPeriod: PositionPeriod;
};

const DeletePeriodEffectTable: VFC<Props> = ({ viewPeriod, label }) => {
  const { data: effects = [], isLoading } = useGetDeletePeriodEffects(
    viewPeriod.id,
    false,
  );

  return <EffectTable rows={effects} isLoading={isLoading} label={label} />;
};
export default DeletePeriodEffectTable;
