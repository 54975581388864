import { VFC, MouseEventHandler } from 'react';
import { css, SerializedStyles } from '@emotion/react';
import { ButtonBase } from '@material-ui/core';
import FoldTreeButton from 'components/01.atoms/Common/Tree/FoldTreeButton';
import ExpandTreeButton from 'components/01.atoms/Common/Tree/ExpandTreeButton';

const nodeArea = css`
  position: relative;
  &[data-org-type='2'] > :first-of-type {
    border-right: 8px ridge #17a2b8;
  }
`;

const nodeBlock = css`
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 200px;
  height: 100%;
  padding: 10px;
  margin: 2px;
  cursor: pointer;
  border: 0.01rem solid #cdcdcd;
  border-radius: 5px;
  :hover {
    opacity: 0.7;
  }
`;

const nodeText = css`
  width: 150px;
  min-height: 16.8px;
  font-size: 0.8rem;
  text-align: center;
  word-wrap: break-word;
  overflow-wrap: break-word;
`;

export type Props = {
  orgId?: number;
  orgName?: string;
  canNextLoop?: boolean;
  hasSubOrg?: boolean;
  depthLevel?: number;
  orgType?: number;
  rowSpan: number;
  gridColumn: string;
  canExpand?: boolean;
  canFold?: boolean;
  onClick?: MouseEventHandler<HTMLDivElement>;
  onMouseEnter?: MouseEventHandler<HTMLDivElement>;
  onMouseLeave?: MouseEventHandler<HTMLDivElement>;
  handleExpand?: MouseEventHandler<HTMLButtonElement>;
  handleFold?: MouseEventHandler<HTMLButtonElement>;
  addAreaCss?: SerializedStyles;
  addBlockCss?: SerializedStyles;
  addTextCss?: SerializedStyles;
};

const TreeNode: VFC<Props> = ({
  orgId,
  orgName,
  canNextLoop,
  hasSubOrg,
  depthLevel,
  orgType,
  rowSpan,
  gridColumn,
  canExpand,
  canFold,
  onClick,
  onMouseEnter,
  onMouseLeave,
  handleExpand,
  handleFold,
  addAreaCss,
  addBlockCss,
  addTextCss,
}) => (
  <div
    css={[
      nodeArea,
      addAreaCss,
      css`
        grid-row: span ${rowSpan};
        grid-column: ${gridColumn};
      `,
    ]}
    data-org-id={orgId}
    data-next-displayed={canNextLoop}
    data-has-next={hasSubOrg}
    data-depth={depthLevel}
    data-org-type={orgType}
    onClick={onClick}
    onMouseEnter={onMouseEnter}
    onMouseLeave={onMouseLeave}
    aria-hidden
  >
    <ButtonBase css={[nodeBlock, addBlockCss]}>
      <span css={[nodeText, addTextCss]}>{orgName}</span>
    </ButtonBase>
    {canExpand && <ExpandTreeButton handleExpand={handleExpand} />}
    {canFold && <FoldTreeButton handleFold={handleFold} />}
  </div>
);

export default TreeNode;
