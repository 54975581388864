import axios, { AxiosError } from 'axios';
import camelcaseKeys from 'camelcase-keys';
import { format } from 'date-fns';
import ErrorResponse from 'domains/common/models/error';
import { useMutation, UseMutationResult } from 'react-query';
import {
  OrganizationUserOption,
  isOrganizationUserOptions,
} from '../models/organizationUserOption';

type FormProps = {
  start: Date;
  end: Date | null;
  isContinued: boolean;
  maxEnd?: Date | null;
};

const getOrganizationUserOptions = async (
  formData: FormProps,
): Promise<OrganizationUserOption[]> => {
  const response = await axios.post(
    `${
      process.env.REACT_APP_API_URL ?? ''
    }/api/system/user/getOrganizationUserOptions`,
    {
      ...formData,
      start: format(formData.start, 'yyyy-M-d'),
      end: formData.end ? format(formData.end, 'yyyy-M-d') : null,
      maxEnd: formData.maxEnd ? format(formData.maxEnd, 'yyyy-M-d') : null,
    },
  );

  const options = (await camelcaseKeys(response.data, {
    deep: true,
  })) as unknown[];

  if (!isOrganizationUserOptions(options)) {
    throw Error('API type error');
  }

  return options;
};

const useGetOrganizationUserOptions = (
  setOrganizationUserLists: (data: OrganizationUserOption[]) => void,
): UseMutationResult<
  OrganizationUserOption[],
  AxiosError<ErrorResponse>,
  FormProps,
  undefined
> =>
  useMutation(getOrganizationUserOptions, {
    onSuccess: (data) => {
      setOrganizationUserLists(data);
    },
  });

export default useGetOrganizationUserOptions;
