import axios, { AxiosError } from 'axios';
import camelcaseKeys from 'camelcase-keys';
import { SuccessOnly, isSuccessOnly, TreeState } from 'domains/common';
import ErrorResponse from 'domains/common/models/error';
import { Dispatch, SetStateAction } from 'react';
import { UseMutationResult, useMutation, useQueryClient } from 'react-query';

type FormProps = {
  periodId: number;
  orgId: number;
};

const deleteTempOrganization = async (
  formData: FormProps,
): Promise<SuccessOnly> => {
  const response = await axios.post(
    `${
      process.env.REACT_APP_API_URL ?? ''
    }/api/system/organization/deleteTempOrganization`,
    formData,
  );

  const success = (await camelcaseKeys(response.data, {
    deep: true,
  })) as unknown;

  if (!isSuccessOnly(success)) {
    throw Error('API type error');
  }

  return success;
};

const useDeleteTempOrganization = (
  periodId: number,
  setIsOpen: (arg: boolean) => void,
  setTreeState: Dispatch<SetStateAction<TreeState>>,
): UseMutationResult<
  SuccessOnly,
  AxiosError<ErrorResponse>,
  FormProps,
  undefined
> => {
  const queryClient = useQueryClient();

  return useMutation(deleteTempOrganization, {
    onSuccess: async () => {
      await queryClient.invalidateQueries([
        'system',
        'organizations',
        'edit',
        periodId,
      ]);
      setTreeState((prevState) => ({ ...prevState, activeNodeId: 0 }));
      setIsOpen(false);
    },
  });
};

export default useDeleteTempOrganization;
