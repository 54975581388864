import {
  VFC,
  useState,
  MouseEventHandler,
  Dispatch,
  SetStateAction,
} from 'react';
import TextField from '@material-ui/core/TextField';
import { PositionState } from 'domains/user';
import PositionSelectDialog from 'containers/03.organisms/User/PositionSelectDialog';

type Props = {
  rowNumber: number;
  positionListText: string;
  rows: PositionState[];
  setPositionState: Dispatch<SetStateAction<PositionState[]>>;
};

const PositionSelectInput: VFC<Props> = ({
  rowNumber,
  rows,
  positionListText,
  setPositionState,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const handleClick: MouseEventHandler<HTMLDivElement> = (event) => {
    const row = Number(
      event.currentTarget.getAttribute('data-row-number') ?? -1,
    );
    const positionId = Number(
      event.currentTarget.getAttribute('data-id') ?? -1,
    );
    const name = event.currentTarget.getAttribute('data-name') ?? '名称なし';
    setPositionState((prevState) =>
      prevState.map((el) =>
        el.rowNumber === row ? { ...el, positionId, positionName: name } : el,
      ),
    );
    setIsOpen(false);
  };

  return (
    <>
      <TextField
        onClick={() => setIsOpen(true)}
        variant="standard"
        margin="normal"
        label=""
        placeholder="未選択"
        value={rows[rowNumber].positionName}
        InputLabelProps={{ shrink: true }}
        fullWidth
      />
      <PositionSelectDialog
        rowNumber={rowNumber}
        positionListText={positionListText}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        handleClick={handleClick}
      />
    </>
  );
};

export default PositionSelectInput;
