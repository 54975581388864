import { FC, VFC, ReactElement } from 'react';
import {
  ClassNames,
  css as emotionCss,
  SerializedStyles,
} from '@emotion/react';
import DatePicker, { CalendarContainer } from 'react-datepicker';
import { isAfter } from 'date-fns';

const containerStyle = emotionCss`
  border-color: rgba(224, 224, 224, 1);
    & > div > div > div:nth-of-type(1) {
    padding-bottom: 0.2rem;
    color: #3c4043;
    background-color: white;
    border-bottom: 0;
    & * {
      color: #3c4043;
      border-color: #3c4043;
    }
  }

  & .react-datepicker__day--outside-month {
    color: transparent;
    pointer-events: none;
    visibility:hidden;
  }

  & .react-datepicker__time-container {
    border-left: 1px solid rgba(224, 224, 224, 1);
  }

  & .react-datepicker__time-list-item  {
    line-height: 30px;
  }

`;

const wrapperStyle = emotionCss`
  width: max-content;
`;
const popperStyle = emotionCss`
  z-index: 10;
`;

type Props = {
  viewDate: Date | null;
  setViewDate?: (arg: Date) => void;
  setEndDate?: (arg: Date | null) => void;
  addChangeAction?: (arg: Date) => void;
  customInput: ReactElement;
  selectsStart?: boolean;
  selectsEnd?: boolean;
  periodStart?: Date | null;
  periodEnd?: Date | null;
  minDate?: Date | null;
  maxDate?: Date | null;
  portalId?: string;
  isWithPortal?: boolean;
  openToDate?: Date | null;
  addWrapperCss?: SerializedStyles;
};

const DateTimeSelectInput: VFC<Props> = ({
  viewDate = new Date(),
  setViewDate,
  setEndDate,
  addChangeAction,
  customInput,
  selectsStart,
  selectsEnd,
  periodStart,
  periodEnd,
  minDate,
  maxDate,
  portalId,
  isWithPortal,
  openToDate,
  addWrapperCss,
}) => (
  <ClassNames>
    {({ css, cx }) => {
      const containerClass = cx(css(containerStyle));
      const wrapperClass = cx(css([wrapperStyle, addWrapperCss]));
      const popperClass = cx(css(popperStyle));

      const MyContainer: FC<{
        className?: string;
        children?: React.ReactNode;
      }> = ({ className, children }) => {
        const cls = className
          ? `${className} ${containerClass}`
          : containerClass;

        return (
          <CalendarContainer className={cls}>
            <div style={{ position: 'relative' }}>{children}</div>
          </CalendarContainer>
        );
      };

      return (
        <>
          <DatePicker
            showTimeSelect
            timeFormat="HH:mm"
            timeIntervals={5}
            timeCaption="時間"
            portalId={portalId}
            withPortal={isWithPortal}
            wrapperClassName={wrapperClass}
            popperClassName={popperClass}
            customInput={customInput}
            selectsRange={false}
            openToDate={openToDate ?? viewDate ?? new Date()}
            selected={typeof viewDate === 'object' ? null : viewDate}
            onChange={(date: Date) => {
              if (setViewDate) {
                setViewDate(date);
              }

              if (
                selectsStart &&
                periodEnd &&
                isAfter(date, periodEnd) &&
                setEndDate
              ) {
                setEndDate(null);
              }

              if (addChangeAction) {
                addChangeAction(date);
              }
            }}
            selectsStart={selectsStart}
            selectsEnd={selectsEnd}
            startDate={periodStart}
            endDate={periodEnd}
            minDate={minDate}
            maxDate={maxDate}
            calendarContainer={MyContainer}
            renderCustomHeader={({ date, decreaseMonth, increaseMonth }) => (
              <div style={{ border: 0 }}>
                <button
                  type="button"
                  aria-label="Previous Month"
                  className="react-datepicker__navigation react-datepicker__navigation--previous"
                  onClick={decreaseMonth}
                >
                  <span className="react-datepicker__navigation-icon react-datepicker__navigation-icon--previous">
                    {'<'}
                  </span>
                </button>
                <span className="react-datepicker__current-month">
                  {date.toLocaleString('ja', {
                    month: 'long',
                    year: 'numeric',
                  })}
                </span>
                <button
                  type="button"
                  aria-label="Next Month"
                  className="react-datepicker__navigation react-datepicker__navigation--next"
                  onClick={increaseMonth}
                >
                  <span className="react-datepicker__navigation-icon react-datepicker__navigation-icon--next">
                    {'>'}
                  </span>
                </button>
              </div>
            )}
          />
        </>
      );
    }}
  </ClassNames>
);

export default DateTimeSelectInput;
