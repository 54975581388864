import { css } from '@emotion/react';

const buttonStyle = css`
  position: relative;
  display: flex;
  justify-content: center;
  padding: 0.5rem 0;
  overflow: visible;
  font-size: 1rem;
  font-weight: bolder;
  line-height: 1.5;
  color: #0070c0;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  border-color: #0070c0;
  border-width: 2px;
  border-radius: 0.25rem;
`;

export default buttonStyle;
