import { UseFormRegisterReturn } from 'react-hook-form';
import { forwardRef } from 'react';
import { css } from '@emotion/react';
import baseInput from 'baseCss/emotion/baseInput';
import blockStyle from 'baseCss/emotion/relativeInlineFlex';
import iconWrapperStyle from 'baseCss/emotion/baseIconWrapper';

const transparentInput = css`
  width: 230px;
  height: 40px;
  padding-right: 1em !important;
  padding-left: 2.67142857em !important;
  margin: 10px 0;
  font-size: medium;
  color: white;
  background-color: transparent;
  border: double 1px white;
  border-radius: 5px;
  &::placeholder {
    color: white;
  }

  &:hover,
  :focus {
    color: white;
    border: 1px solid #fff8b3;
    box-shadow: 0 0 7px #fff8b3;
    opacity: 0.8;
    transition: 0.25s;
    &::placeholder {
      color: #fff8b3;
      transition: 0.25s;
    }
  }
`;

const iconBlock = css`
  & * {
    left: 10px;
    font-size: 0.6em;
    color: white;
    opacity: 0.85;
  }
`;

export type InputProps = {
  type?: string;
  placeholder?: string;
  children?: React.ReactElement;
};

const LoginInput = forwardRef<
  HTMLInputElement,
  InputProps & UseFormRegisterReturn
>(
  (
    {
      onChange,
      onBlur,
      name,
      type = 'text',
      placeholder = 'placeholder...',
      children,
    },
    ref,
  ) => (
    <div css={blockStyle}>
      <input
        css={[baseInput, transparentInput]}
        type={type}
        placeholder={placeholder}
        name={name}
        ref={ref}
        onChange={onChange}
        onBlur={onBlur}
      />
      <span css={[iconWrapperStyle, iconBlock]}>{children}</span>
    </div>
  ),
);

export default LoginInput;
