import { VFC } from 'react';
import LoadingBackDrop from 'components/01.atoms/Common/Basic/LoadingBackDrop';
import {
  OrganizationPeriod,
  useGetOrganizationPeriods,
} from 'domains/organization';
import PeriodSelectDialog from 'components/02.molecules/Organization/PeriodSelectDialog';

type Props = {
  setViewPeriod: (arg: OrganizationPeriod) => void;
};

const EnhancedPeriodSelectDialog: VFC<Props> = ({ setViewPeriod }) => {
  const { data: periods = [] as OrganizationPeriod[], isLoading } =
    useGetOrganizationPeriods(false);
  const handleListItemClick = (value: OrganizationPeriod) => {
    setViewPeriod(value);
  };

  return isLoading ? (
    <LoadingBackDrop />
  ) : (
    <PeriodSelectDialog
      periods={periods}
      handleListItemClick={handleListItemClick}
    />
  );
};

export default EnhancedPeriodSelectDialog;
