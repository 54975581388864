import { VFC } from 'react';
import { Staff, useGetNowDirectStaffs } from 'domains/staff';
import StaffSelectDialog from 'components/02.molecules/WorkReport/Approve/StaffSelectDialog';
import LoadingBackDrop from 'components/01.atoms/Common/Basic/LoadingBackDrop';

type Props = {
  setViewStaffId: (arg: number) => void;
};

const EnhancedStaffSelectDialog: VFC<Props> = ({ setViewStaffId }) => {
  const { data: staffs = [] as Staff[], isLoading } =
    useGetNowDirectStaffs(false);
  const handleListItemClick = (value: number) => {
    setViewStaffId(value);
  };

  return isLoading ? (
    <LoadingBackDrop />
  ) : (
    <StaffSelectDialog
      staffs={staffs}
      handleListItemClick={handleListItemClick}
    />
  );
};

export default EnhancedStaffSelectDialog;
