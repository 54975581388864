import { useEffect, VFC } from 'react';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { css } from '@emotion/react';
import SnackBars from 'components/02.molecules/Common/SnackBars';
import BasicConfirmFormDialog from 'components/03.organisms/Common/BasicConfirmFormDialog';
import { useForm } from 'react-hook-form';
import { NumOnlyForm } from 'domains/common';
import { useQueryClient } from 'react-query';
import { useEditAuthorityLevel, UserDetail } from 'domains/user';
import { AUTHORITY_LEVEL, AUTHORITY_LEVEL_NAME } from 'utils/const';
import FormSelect from 'containers/01.atoms/Common/FormSelect';
import MenuItem from '@material-ui/core/MenuItem';

export type Props = {
  viewUserId: number;
  isOpen: boolean;
  setIsOpen: (arg: boolean) => void;
};

const EnhancedEditAuthorityLevelDialog: VFC<Props> = ({
  viewUserId,
  isOpen,
  setIsOpen,
}) => {
  const { getValues, setValue, control } = useForm<NumOnlyForm>();
  const {
    mutate,
    status,
    isLoading,
    error = {
      response: {
        data: { errors: { authorityLevel: '' } },
      },
    },
  } = useEditAuthorityLevel(viewUserId, setIsOpen);
  const handleOk = () => {
    mutate({
      userId: viewUserId,
      authorityLevel: Number(getValues('authorityLevel')) ?? 0,
    });
  };
  const handleCancel = () => {
    setIsOpen(false);
  };

  const queryClient = useQueryClient();
  useEffect(() => {
    const data = queryClient.getQueryData<UserDetail>([
      'system',
      'userDetail',
      viewUserId,
    ]);
    setValue('authorityLevel', data?.basic.authorityLevel ?? 0);
  }, [setValue, queryClient, viewUserId]);

  return (
    <>
      <BasicConfirmFormDialog
        title="権限レベルの変更"
        confirmMsg="変更後の権限レベルを選択してください。"
        isOpen={isOpen}
        handleOk={handleOk}
        handleCancel={handleCancel}
        okButtonName="変更"
        cancelButtonName="キャンセル"
        textField={
          <FormSelect
            label="権限レベル"
            placeholder=""
            isMultiLine={false}
            isFullWidth
            variant="standard"
            margin="normal"
            name="authorityLevel"
            control={control}
            errorMsg={error?.response?.data?.errors.authorityLevel}
          >
            <MenuItem key="authorityLevel1" value={AUTHORITY_LEVEL.STAFF}>
              {AUTHORITY_LEVEL_NAME[AUTHORITY_LEVEL.STAFF]}
            </MenuItem>
            <MenuItem key="authorityLevel2" value={AUTHORITY_LEVEL.MANAGER}>
              {AUTHORITY_LEVEL_NAME[AUTHORITY_LEVEL.MANAGER]}
            </MenuItem>
            <MenuItem key="authorityLevel3" value={AUTHORITY_LEVEL.SYSTEM}>
              {AUTHORITY_LEVEL_NAME[AUTHORITY_LEVEL.SYSTEM]}
            </MenuItem>
          </FormSelect>
        }
      />
      <Backdrop
        css={css`
          z-index: 1301;
          color: white;
        `}
        open={isLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <SnackBars
        status={status}
        loadingMsg="変更中..."
        successMsg="変更が完了しました"
        errorMsg="変更に失敗しました"
      />
    </>
  );
};

export default EnhancedEditAuthorityLevelDialog;
