import axios, { AxiosError } from 'axios';
import camelcaseKeys from 'camelcase-keys';
import { SuccessOnly, isSuccessOnly } from 'domains/common';
import ErrorResponse from 'domains/common/models/error';
import { UseMutationResult, useMutation } from 'react-query';
import { TASK_STATUS_FOR_WORKER } from 'utils/const';

type FormProps = {
  taskId: number;
};

const startOwnTask = async (formData: FormProps): Promise<SuccessOnly> => {
  const response = await axios.post(
    `${process.env.REACT_APP_API_URL ?? ''}/api/task/common/startOwnTask`,
    formData,
  );

  const success = (await camelcaseKeys(response.data, {
    deep: true,
  })) as unknown;

  if (!isSuccessOnly(success)) {
    throw Error('API type error');
  }

  return success;
};

const useStartOwnTask = (
  setStatus: (arg: number) => void,
): UseMutationResult<
  SuccessOnly,
  AxiosError<ErrorResponse>,
  FormProps,
  undefined
> =>
  useMutation(startOwnTask, {
    onError: () => {
      setStatus(TASK_STATUS_FOR_WORKER.WAITING);
    },
  });

export default useStartOwnTask;
