import { ChangeEvent, useState, VFC } from 'react';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import { OrganizationPeriod, useResetEditTempData } from 'domains/organization';
import ConfirmAnyActionDialog from 'components/03.organisms/Common/ConfirmAnyActionDialog';
import SnackBars from 'components/02.molecules/Common/SnackBars';
import Button from '@material-ui/core/Button';
import { useNavigate } from 'react-router-dom';

type Props = {
  viewPeriod: OrganizationPeriod;
};

const EnhancedEditModeSwitch: VFC<Props> = ({ viewPeriod }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setIsOpen(true);
    setIsEditMode(event.target.checked);
  };
  const handleClose = () => {
    setIsOpen(false);
    setIsEditMode(false);
  };
  const navigate = useNavigate();
  const handleStartOk = () => {
    navigate('/system/organization/edit', {
      state: {
        viewPeriod: {
          ...viewPeriod,
          tempStart: viewPeriod.start,
          tempEnd: viewPeriod.end,
        },
      },
    });
  };
  const handleRestartOk = () => {
    navigate('/system/organization/edit', {
      state: {
        viewPeriod,
      },
    });
  };

  const { mutate, status } = useResetEditTempData(handleStartOk);
  const handleResetOk = () => {
    mutate({ periodId: viewPeriod.id });
  };

  return (
    <>
      <FormControlLabel
        control={
          <Switch
            checked={isEditMode}
            onChange={handleChange}
            name="isEditMode"
            color="primary"
          />
        }
        label="編集モード"
      />
      <ConfirmAnyActionDialog
        isOpen={isOpen}
        anyElement={
          <>
            <Button
              fullWidth
              variant="text"
              color="default"
              disableElevation
              onClick={handleResetOk}
            >
              最初から編集を始める
            </Button>
            {viewPeriod.tempStart && (
              <Button
                fullWidth
                variant="text"
                color="default"
                disableElevation
                onClick={handleRestartOk}
              >
                編集を再開する（編集中データを引継ぐ）
              </Button>
            )}
          </>
        }
        cancelButtonName="キャンセル"
        title="編集モードへの移行"
        confirmMsg="選択中の組織管理期間の編集モードに移行します。"
        handleCancel={handleClose}
      />
      <SnackBars
        status={status}
        loadingMsg="準備中..."
        successMsg="準備が完了しました"
        errorMsg="準備に失敗しました"
      />
    </>
  );
};

export default EnhancedEditModeSwitch;
