import axios, { AxiosError } from 'axios';
import camelcaseKeys from 'camelcase-keys';
import { useQuery, UseQueryResult } from 'react-query';
import { EmptyTerm, isEmptyTerms } from '../models/emptyTerm';

const getTermsUsersHasNoMainOrganizations = async (): Promise<EmptyTerm[]> => {
  const response = await axios.get(
    `${
      process.env.REACT_APP_API_URL ?? ''
    }/api/system/getTermsUsersHasNoMainOrganizations`,
  );

  const emptyTerms = (await camelcaseKeys(response.data, {
    deep: true,
  })) as unknown[];

  if (!isEmptyTerms(emptyTerms)) {
    throw Error('API type error');
  }

  return emptyTerms;
};

const useGetTermsUsersHasNoMainOrganizations = (
  isSuspense: boolean,
): UseQueryResult<EmptyTerm[], AxiosError> =>
  useQuery(
    ['system', 'emptyTerms', 'usersHasNoMainOrganizations'],
    () => getTermsUsersHasNoMainOrganizations(),
    {
      suspense: isSuspense,
    },
  );

export default useGetTermsUsersHasNoMainOrganizations;
