import { UseMutationResult, useMutation, useQueryClient } from 'react-query';
import axios, { AxiosError } from 'axios';
import { format } from 'date-fns';
import ErrorResponse from 'domains/common/models/error';
import {
  isDailyOtherReport,
  DailyOtherReport,
} from '../models/dailyOtherReport';
import { OtherFormData } from '../models/formData';

const updateOtherReport = async (
  formData: OtherFormData,
): Promise<DailyOtherReport> => {
  const response = await axios.post(
    `${process.env.REACT_APP_API_URL ?? ''}/api/workReport/updateOtherReport`,
    formData,
  );
  const workReport = (await response.data) as unknown;

  if (!isDailyOtherReport(workReport)) {
    throw Error('API type error');
  }

  return workReport;
};

const useUpdateOtherReport = (
  viewDate: Date,
): UseMutationResult<
  DailyOtherReport,
  AxiosError<ErrorResponse>,
  OtherFormData,
  undefined
> => {
  const queryClient = useQueryClient();

  return useMutation(updateOtherReport, {
    onSuccess: (data) => {
      queryClient.setQueryData(
        ['otherReport', format(viewDate, 'yyyy-M-d')],
        data,
      );
    },
  });
};

export default useUpdateOtherReport;
