import { VFC } from 'react';
import { css } from '@emotion/react';
import TreeNode from './TreeNode';

const nodeBlock = css`
  pointer-events: none;
  cursor: default;
  border-color: transparent;
  :hover {
    opacity: 1;
  }
`;

export type Props = {
  gridColumn: string;
};

const TreeNodeEmpty: VFC<Props> = ({ gridColumn }) => (
  <TreeNode
    orgName=""
    rowSpan={1}
    gridColumn={gridColumn}
    addBlockCss={nodeBlock}
  />
);

export default TreeNodeEmpty;
