import { VFC, useState } from 'react';
import PresentationPage from 'components/05.pages/Common/SideAndMainPage';
import HeaderBar from 'containers/03.organisms/Common/CommonHeader';
import SideMenu from 'containers/03.organisms/System/SystemSideMenu';
import MainContents from 'containers/03.organisms/Organization/MainContentsArea';
import { OrganizationPeriod } from 'domains/organization';
import { useLocation } from 'react-router-dom';

const EnhancedOrganizationPage: VFC = () => {
  type CustomizedState = {
    viewPeriod: OrganizationPeriod;
  };
  const location = useLocation();
  const state = location.state as CustomizedState;
  const initialPeriod =
    state?.viewPeriod ??
    ({
      id: 0,
      start: '',
      end: '',
      tempStart: '',
      tempEnd: '',
    } as OrganizationPeriod);

  const [viewPeriod, setViewPeriod] = useState(initialPeriod);

  return (
    <PresentationPage headerBar={<HeaderBar />} sideMenu={<SideMenu />}>
      <MainContents viewPeriod={viewPeriod} setViewPeriod={setViewPeriod} />
    </PresentationPage>
  );
};
export default EnhancedOrganizationPage;
