export type OrganizationPeriod = {
  id: number;
  start: string;
  end: string;
  tempStart: string | null;
  tempEnd: string | null;
};

const isOrganizationPeriod = (arg: unknown): arg is OrganizationPeriod => {
  const p = arg as OrganizationPeriod;

  return (
    typeof p?.id === 'number' &&
    typeof p?.start === 'string' &&
    typeof p?.end === 'string' &&
    (typeof p?.tempStart === 'string' || p?.tempStart === null) &&
    (typeof p?.tempEnd === 'string' || p?.tempEnd === null)
  );
};

const isOrganizationPeriods = (args: unknown[]): args is OrganizationPeriod[] =>
  !args.some((arg) => !isOrganizationPeriod(arg));

export { isOrganizationPeriod, isOrganizationPeriods };
