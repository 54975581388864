import { ReactElement, VFC } from 'react';
import BasicSelectDialog from 'components/03.organisms/Common/BasicSelectDialog';

type Props = {
  isOpen: boolean;
  handleCancel: () => void;
  positionList: ReactElement;
};

const PositionSelectDialog: VFC<Props> = ({
  isOpen,
  handleCancel,
  positionList,
}) => (
  <BasicSelectDialog
    title="役職の選択"
    isOpen={isOpen}
    handleCancel={handleCancel}
    list={positionList}
  />
);

export default PositionSelectDialog;
