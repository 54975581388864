import { css } from '@emotion/react';
import Button from '@material-ui/core/Button';
import { VFC } from 'react';
import TextField from '@material-ui/core/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';

const progressStyle = css`
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 1;
  margin-top: -45px;
  margin-left: -15px;
  color: rgb(158, 158, 158);
`;

type Props = {
  hasButton: boolean;
};

const FallBackOtherReportForms: VFC<Props> = ({ hasButton }) => (
  <>
    <div
      css={css`
        position: relative;
      `}
    >
      <TextField
        fullWidth
        multiline
        variant="outlined"
        rows={9}
        label="業務報告(必須)"
        disabled
        InputLabelProps={{ shrink: true }}
      />
      <div
        css={css`
          margin-top: 1rem;
        `}
      >
        <TextField
          fullWidth
          multiline
          variant="outlined"
          rows={9}
          label="業務外連絡(任意)"
          disabled
          InputLabelProps={{ shrink: true }}
        />
      </div>
      <div
        css={css`
          display: flex;
          justify-content: flex-end;
          padding-top: 1rem;
        `}
      >
        {hasButton && (
          <Button
            variant="contained"
            css={css`
              width: 150px;
              font-weight: bold;
            `}
            color="primary"
            disableElevation
            disabled
          >
            一時保存
          </Button>
        )}
      </div>
      <CircularProgress size={50} css={progressStyle} />
    </div>
  </>
);
export default FallBackOtherReportForms;
