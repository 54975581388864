import { VFC } from 'react';
import { css } from '@emotion/react';

type Props = {
  emptyMsg: string;
  width: string;
  height: string;
};

const EmptyDiv: VFC<Props> = ({ emptyMsg, width, height }) => (
  <div
    css={css`
      display: grid;
      place-items: center;
      width: ${width};
      height: ${height};
    `}
  >
    {emptyMsg}
  </div>
);

export default EmptyDiv;
