import { UseMutationResult, useMutation, useQueryClient } from 'react-query';
import axios, { AxiosError } from 'axios';
import { format } from 'date-fns';
import camelcaseKeys from 'camelcase-keys';
import {
  isDailyWorkReportSet,
  DailyWorkReportSet,
} from '../models/dailyWorkReportSet';

export type FormData = {
  viewDate: Date;
  eventId: string;
  start: Date | null;
  end: Date | null;
};

const updateWorkReportTime = async (
  formData: FormData,
): Promise<DailyWorkReportSet> => {
  const start = formData.start
    ? format(formData.start, 'yyyy-M-d H:m:s')
    : null;
  const end = formData.end ? format(formData.end, 'yyyy-M-d H:m:s') : null;

  const processingFormData = {
    ...formData,
    viewDate: format(formData.viewDate, 'yyyy-M-d'),
    start,
    end,
  };

  const response = await axios.post(
    `${
      process.env.REACT_APP_API_URL ?? ''
    }/api/workReport/updateWorkReportTime`,
    processingFormData,
  );

  const workReports = (await camelcaseKeys(response.data, {
    deep: true,
  })) as unknown[];

  if (!isDailyWorkReportSet(workReports)) {
    throw Error('API type error');
  }

  return workReports;
};

const useUpdateWorkReportTime = (
  viewDate: Date,
): UseMutationResult<DailyWorkReportSet, AxiosError, FormData, undefined> => {
  const queryClient = useQueryClient();

  return useMutation(updateWorkReportTime, {
    onSuccess: (data) => {
      queryClient.setQueryData(
        ['workReports', format(viewDate, 'yyyy-M-d')],
        data,
      );
    },
  });
};

export default useUpdateWorkReportTime;
