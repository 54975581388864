import { VFC, MouseEventHandler } from 'react';
import { css } from '@emotion/react';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import UnfoldMoreIcon from '@material-ui/icons/UnfoldMore';

export type Props = {
  handleExpand?: MouseEventHandler<HTMLButtonElement>;
};

const ExpandTreeButton: VFC<Props> = ({ handleExpand }) => (
  <Tooltip title={<Typography variant="caption">サブツリーを開く</Typography>}>
    <IconButton
      css={css`
        position: absolute;
        right: 5px;
        bottom: 3px;
        background-color: white;
        border: 1px solid #e0e0e0;

        &:hover {
          background-color: #f3f3f3;
          border: 1px solid #e0e0e0;
        }
      `}
      aria-label="expand sub tree"
      size="small"
      onClick={handleExpand}
    >
      <UnfoldMoreIcon />
    </IconButton>
  </Tooltip>
);

export default ExpandTreeButton;
