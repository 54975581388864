import { VFC } from 'react';
import TextField from '@material-ui/core/TextField';
import { UseControllerProps, useController } from 'react-hook-form';
import { WorkFormData } from 'domains/workReport';

type SelectProps = UseControllerProps<WorkFormData> & {
  onClick: () => void;
  errorMsg: string;
  isDisabled: boolean;
};

const WorkCategoryInput: VFC<SelectProps> = ({
  onClick,
  errorMsg,
  isDisabled,
  ...props
}) => {
  const { field } = useController(props);

  return (
    <TextField
      aria-controls="workCategorySelection"
      aria-label="workCategory"
      margin="normal"
      label="業務カテゴリー"
      fullWidth
      onClick={onClick}
      name={field.name}
      onBlur={field.onBlur}
      onChange={field.onChange}
      ref={field.ref}
      value={field.value}
      InputLabelProps={{ shrink: true }}
      error={!!errorMsg}
      helperText={errorMsg}
      disabled={isDisabled}
    />
  );
};

export default WorkCategoryInput;
