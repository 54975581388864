import { VFC, ReactElement } from 'react';
import { css } from '@emotion/react';

const mainStyle = css`
  display: flex;
  flex-direction: row;
  gap: 20px;
  margin-top: 20px;

  & > div {
    flex: 1;
  }
`;

const listGroup = css`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

type Props = {
  headerArea: ReactElement;
  termsUsersHasNoPositions: ReactElement;
  termsUsersHasNoMainOrganizations: ReactElement;
  termsOrganizationsHasNoUsers: ReactElement;
};

const MainContentsArea: VFC<Props> = ({
  headerArea,
  termsUsersHasNoPositions,
  termsUsersHasNoMainOrganizations,
  termsOrganizationsHasNoUsers,
}) => (
  <div>
    {headerArea}
    <div css={mainStyle}>
      <div css={listGroup}>
        <div>{termsUsersHasNoPositions}</div>
        <div>{termsUsersHasNoMainOrganizations}</div>
      </div>
      <div>{termsOrganizationsHasNoUsers}</div>
    </div>
  </div>
);

export default MainContentsArea;
