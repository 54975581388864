import { VFC, useState } from 'react';
import { TreeState } from 'domains/common';
import MainContentsMainArea from 'containers/03.organisms/Organization/Edit/MainContentsMainArea';
import MainContentsHeader from 'containers/03.organisms/Organization/New/MainContentsHeader';
import MainContentsArea from 'components/03.organisms/Common/MainContentsAreaPatternB';
import { OrganizationPeriod, useGetNewTempPeriod } from 'domains/organization';
import MainContentsDetails from 'containers/03.organisms/Organization/Edit/MainContentsDetails';
import EmptyDiv from 'components/01.atoms/Common/Basic/EmptyDiv';
import LoadingBackDrop from 'components/01.atoms/Common/Basic/LoadingBackDrop';
import { css } from '@emotion/react';

const EnhancedMainContentsArea: VFC = () => {
  const [treeState, setTreeState] = useState({
    displayableDepth: 1,
    activeNodeId: 0,
    openableNodeIds: [] as number[],
    disableNodeIds: [] as number[],
  } as TreeState);
  const initialPeriod = {
    id: 0,
    start: '',
    end: '',
    tempStart: '',
    tempEnd: '',
  } as OrganizationPeriod;
  const { data: viewPeriod = initialPeriod, isLoading = false } =
    useGetNewTempPeriod(false);

  return (
    <>
      {!isLoading ? (
        <MainContentsArea
          headerArea={<MainContentsHeader viewPeriod={viewPeriod} />}
          mainArea={
            <MainContentsMainArea
              viewPeriod={viewPeriod}
              treeState={treeState}
              setTreeState={setTreeState}
            />
          }
          sideArea={
            <div
              css={css`
                width: 250px;
                height: calc(100vh - 175px);
                padding-left: 10px;
                margin-left: 1rem;
                border-left: 1px solid #dee2e6;
              `}
            >
              {!treeState.activeNodeId ? (
                <EmptyDiv emptyMsg="未選択" width="100%" height="100%" />
              ) : (
                <MainContentsDetails
                  viewPeriod={viewPeriod}
                  activeOrgId={treeState.activeNodeId}
                  setTreeState={setTreeState}
                />
              )}
            </div>
          }
        />
      ) : (
        <>
          <MainContentsArea
            headerArea={<div />}
            sideArea={<div />}
            mainArea={<div />}
          />
          <LoadingBackDrop />
        </>
      )}
    </>
  );
};

export default EnhancedMainContentsArea;
