import { VFC } from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import { OrganizationPeriod } from 'domains/organization';
import convertPeriodText from 'utils/function/convertPeriodText';

type Props = {
  periods: OrganizationPeriod[];
  handleListItemClick: (arg: OrganizationPeriod) => void;
};

const PeriodSelectDialog: VFC<Props> = ({ periods, handleListItemClick }) => (
  <Dialog aria-labelledby="org-period-select-dialog" open>
    <DialogTitle id="org-period-select-dialog">
      閲覧する組織管理期間の選択
    </DialogTitle>
    <List>
      {periods.map((period) => {
        const periodText = convertPeriodText({
          start: period.start,
          end: period.end,
        });

        return (
          <ListItem
            button
            onClick={() => handleListItemClick(period)}
            key={period.id}
          >
            <ListItemAvatar>
              <Avatar>
                <AccessTimeIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary={periodText} />
          </ListItem>
        );
      })}
    </List>
  </Dialog>
);

export default PeriodSelectDialog;
