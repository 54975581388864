import { VFC } from 'react';
import ColorCircle from 'components/01.atoms/WorkReport/Common/ColorCircle';
import { Typography } from '@material-ui/core';
import { css } from '@emotion/react';

type Props = {
  colorcode: string;
  description: string;
};

const StatusDescription: VFC<Props> = ({ colorcode, description }) => (
  <div
    css={css`
      display: inline-flex;
      gap: 4px;
      align-items: center;
    `}
  >
    <ColorCircle colorcode={colorcode} />
    <Typography
      css={css`
        display: inline-block;
        font-size: 13px;
        line-height: 1;
      `}
      variant="subtitle1"
    >
      {description}
    </Typography>
  </div>
);

export default StatusDescription;
