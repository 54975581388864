import { VFC } from 'react';
import PositionTable from 'containers/02.molecules/Position/PositionTable';
import { PositionPeriod } from 'domains/position';
import MainContentsMainArea from 'components/03.organisms/Position/MainContentsMainArea';

type Props = {
  viewPeriod: PositionPeriod;
};

const EnhancedMainContentsMainArea: VFC<Props> = ({ viewPeriod }) => (
  <MainContentsMainArea
    viewPeriod={viewPeriod}
    positionList={<PositionTable viewPeriod={viewPeriod} />}
  />
);

export default EnhancedMainContentsMainArea;
