import axios, { AxiosError } from 'axios';
import camelcaseKeys from 'camelcase-keys';
import { useQuery, UseQueryResult } from 'react-query';
import { OwnTask, isOwnTasks } from '../models/ownTask';

const getApproachingOwnTasks = async (): Promise<OwnTask[]> => {
  const response = await axios.get(
    `${
      process.env.REACT_APP_API_URL ?? ''
    }/api/task/common/getApproachingOwnTasks`,
  );

  const ownTasks = (await camelcaseKeys(response.data, {
    deep: true,
  })) as unknown[];

  if (!isOwnTasks(ownTasks)) {
    throw Error('API type error');
  }

  return ownTasks;
};

const useGetApproachingOwnTasks = (
  isSuspense: boolean,
): UseQueryResult<OwnTask[], AxiosError> =>
  useQuery(
    ['authUser', 'ownTasks', 'approaching'],
    () => getApproachingOwnTasks(),
    {
      suspense: isSuspense,
    },
  );

export default useGetApproachingOwnTasks;
