import { VFC } from 'react';
import Button from '@material-ui/core/Button';
import { useNavigate } from 'react-router-dom';

const ViewJumpButton: VFC = () => {
  const navigate = useNavigate();

  return (
    <Button
      variant="outlined"
      size="small"
      color="default"
      onClick={() => {
        navigate('/system/organization');
      }}
    >
      通常画面に戻る
    </Button>
  );
};

export default ViewJumpButton;
