import { VFC, useState } from 'react';
import { css } from '@emotion/react';
import Button from '@material-ui/core/Button';
import { OtherFormData, useGetDailyAttachedInfo } from 'domains/workReport';
import { WORK_REPORT_STATUS } from 'utils/const';
import SubmitConfirmDialog from 'containers/03.organisms/WorkReport/Input/SubmitConfirmDialog';
import { UseFormReturn } from 'react-hook-form';

type Props = {
  viewDate?: Date;
  setErrorMsgWRDiv: (arg: string) => void;
  otherFormMethods: UseFormReturn<OtherFormData>;
};

const SubmitButton: VFC<Props> = ({
  viewDate = new Date(),
  otherFormMethods,
  setErrorMsgWRDiv,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const { data: attachedInfo = { status: 0, feedback: '' }, isLoading } =
    useGetDailyAttachedInfo(viewDate, false);
  const isSubmitted = attachedInfo.status >= WORK_REPORT_STATUS.SUBMITTED;

  return (
    <>
      <Button
        variant="contained"
        css={css`
          width: 100%;
          max-width: 200px;
          font-weight: bold;
        `}
        color="primary"
        disableElevation
        onClick={() => setIsOpen(true)}
        disabled={isSubmitted || isLoading}
      >
        {isSubmitted ? '提出済み' : '日報提出'}
      </Button>
      <SubmitConfirmDialog
        viewDate={viewDate}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        setErrorMsgWRDiv={setErrorMsgWRDiv}
        otherFormMethods={otherFormMethods}
      />
    </>
  );
};

export default SubmitButton;
