import { ReactElement, VFC } from 'react';
import EffectCheckFormDialog from 'components/03.organisms/Common/EffectCheckFormDialog';

type Props = {
  isOpen: boolean;
  handleOk: () => void;
  handleCancel: () => void;
  textField: ReactElement;
  effectTable: ReactElement;
  otherEffect: ReactElement;
};

const EditPeriodDialog: VFC<Props> = ({
  textField,
  effectTable,
  otherEffect,
  isOpen,
  handleOk,
  handleCancel,
}) => (
  <EffectCheckFormDialog
    title="期間データの編集"
    confirmMsg="変更後の期間を指定してください。"
    okButtonName="登録"
    cancelButtonName="キャンセル"
    maxWidth="md"
    textField={textField}
    effectTable={effectTable}
    otherEffect={otherEffect}
    isOpen={isOpen}
    handleOk={handleOk}
    handleCancel={handleCancel}
  />
);

export default EditPeriodDialog;
