import { VFC } from 'react';
import MainContentsHeader from 'containers/03.organisms/System/MainContentsHeader';
import MainContentsArea from 'components/03.organisms/System/MainContentsArea';
import LinkButton from 'components/01.atoms/System/LinkButton';
import { useNavigate } from 'react-router-dom';
import { Alert, AlertTitle } from '@material-ui/lab';
import { useGetHasValidData } from 'domains/system';
import { css } from '@emotion/react';

const EnhancedMainContentsArea: VFC = () => {
  const navigate = useNavigate();

  const { data: hasInvalid, isFetching } = useGetHasValidData(false);
  const getAlertBar = () => {
    if (isFetching) {
      return (
        <Alert severity="warning">
          <AlertTitle>システム設定データの確認中...</AlertTitle>
          システムデータに不整合がないかを確認しています
        </Alert>
      );
    }

    if (hasInvalid) {
      return (
        <Alert
          severity="error"
          onClick={() => navigate('/system/invalid')}
          css={css`
            cursor: pointer;
          `}
        >
          <AlertTitle>不整合データの検出</AlertTitle>
          不整合なデータが検出されました — <strong>更新作業が必要です</strong>
        </Alert>
      );
    }

    return (
      <Alert severity="success">
        <AlertTitle>データ正常</AlertTitle>
        不整合なデータは見つかりませんでした
      </Alert>
    );
  };

  return (
    <MainContentsArea
      headerArea={<MainContentsHeader />}
      alertBar={getAlertBar()}
      linkGroup={
        <>
          <LinkButton
            title="ユーザー管理"
            subTitle="システムのユーザー情報を管理します"
            description="ユーザー一覧の閲覧、新規登録、役職や所属組織のデータの登録・編集ができます。"
            onClick={() => navigate('/system/user')}
          />
          <LinkButton
            title="組織管理"
            subTitle="ユーザーが所属する組織データを管理します"
            description="設定期間ごとに組織ツリーを設定することができます。

            また、設定期間の開始日、終了日を変更することもできます。"
            onClick={() => navigate('/system/organization')}
          />
          <LinkButton
            title="役職管理"
            subTitle="ユーザーに登録する役職名称を管理します"
            description="設定期間ごとに使用可能な役職名称を設定することができます。

            また、設定期間の開始日、終了日を変更することもできます。"
            onClick={() => navigate('/system/position')}
          />
        </>
      }
    />
  );
};

export default EnhancedMainContentsArea;
