export type Member = {
  id: number;
  orgId: number;
  userId: number;
  memberName: string;
  startDate: string;
  endDate: string;
};
const isMember = (arg: unknown): arg is Member => {
  const o = arg as Member;

  return (
    typeof o?.id === 'number' &&
    typeof o?.orgId === 'number' &&
    typeof o?.userId === 'number' &&
    typeof o?.memberName === 'string' &&
    typeof o?.startDate === 'string' &&
    typeof o?.endDate === 'string'
  );
};
const isMembers = (args: unknown[]): args is Member[] =>
  !args.some((arg) => !isMember(arg));

export { isMember, isMembers };
