import { VFC } from 'react';
import { useGetDailyAttachedInfo } from 'domains/workReport';
import {
  List,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
} from '@material-ui/core';
import NoteIcon from '@material-ui/icons/Note';
import FeedbackIcon from '@material-ui/icons/Feedback';
import { WORK_REPORT_STATUS_NAME } from 'utils/const';
import { css } from '@emotion/react';

type Props = {
  viewDate: Date;
};

const AttachedInfo: VFC<Props> = ({ viewDate }) => {
  const { data: attachedInfo = { status: 0, feedback: '' } } =
    useGetDailyAttachedInfo(viewDate, true);

  return (
    <List
      css={css`
        width: 242px;
        word-break: break-all;
      `}
    >
      <ListItem>
        <ListItemAvatar>
          <Avatar>
            <NoteIcon />
          </Avatar>
        </ListItemAvatar>
        <ListItemText
          primary={
            <span
              css={css`
                font-size: 0.8rem;
              `}
            >
              ステータス
            </span>
          }
          secondary={WORK_REPORT_STATUS_NAME[attachedInfo.status]}
        />
      </ListItem>
      <ListItem
        css={css`
          align-items: start;
        `}
      >
        <ListItemAvatar
          css={css`
            padding-top: 8px;
          `}
        >
          <Avatar>
            <FeedbackIcon />
          </Avatar>
        </ListItemAvatar>
        <ListItemText
          primary={
            <span
              css={css`
                font-size: 0.8rem;
              `}
            >
              承認者のコメント
            </span>
          }
          secondary={
            <span
              css={css`
                display: inline-block;
                max-height: 150px;
                overflow-y: auto;
              `}
            >
              {attachedInfo.feedback || 'コメントはありません'}
            </span>
          }
        />
      </ListItem>
    </List>
  );
};

export default AttachedInfo;
