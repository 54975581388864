import { css } from '@emotion/react';
import Button from '@material-ui/core/Button';
import { VFC, useEffect } from 'react';
import OtherReportForm from 'containers/02.molecules/WorkReport/Input/OtherReportForm';
import { useFormContext } from 'react-hook-form';
import CircularProgress from '@material-ui/core/CircularProgress';
import SnackBars from 'components/02.molecules/Common/SnackBars';
import { MutationStatus, useQuery } from 'react-query';
import { format } from 'date-fns';
import {
  getDailyOtherReport,
  OtherFormData,
  useGetDailyAttachedInfo,
} from 'domains/workReport';
import { WORK_REPORT_STATUS } from 'utils/const';

const progressStyle = css`
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -12px;
  margin-left: -12px;
`;

type FormProps = {
  viewDate: Date;
  status: MutationStatus;
  errorMsg1?: string;
  errorMsg2?: string;
};

const OtherReportArea: VFC<FormProps> = ({
  status,
  errorMsg1,
  errorMsg2,
  viewDate,
}) => {
  const { setValue, control, clearErrors } = useFormContext<OtherFormData>();
  const { data: otherReport = { otherReport1: '', otherReport2: '' } } =
    useQuery(
      ['otherReport', format(viewDate, 'yyyy-M-d')],
      () => getDailyOtherReport({ viewDate: format(viewDate, 'yyyy-M-d') }),
      {
        onSuccess: () => {
          clearErrors('otherReport1');
        },
      },
    );
  const {
    data: attachedInfo = { status: 0, feedback: '' },
    status: statusDAI,
  } = useGetDailyAttachedInfo(viewDate, false);
  const isLoading = status === 'loading' || statusDAI === 'loading';
  const isSubmitted = attachedInfo.status >= WORK_REPORT_STATUS.SUBMITTED;

  useEffect(() => {
    setValue('otherReport1', otherReport.otherReport1);
    setValue('otherReport2', otherReport.otherReport2);
  }, [setValue, otherReport, viewDate]);

  return (
    <>
      <OtherReportForm
        label="業務報告(必須)"
        placeholder={isSubmitted ? '' : '業務報告をご記入ください。'}
        isDisabled={isLoading || isSubmitted}
        errorMsg={errorMsg1}
        name="otherReport1"
        control={control}
      />
      <div
        css={css`
          margin-top: 1rem;
        `}
      >
        <OtherReportForm
          label="業務外連絡(任意)"
          placeholder={isSubmitted ? '' : '業務外連絡をご記入ください。'}
          isDisabled={isLoading || isSubmitted}
          errorMsg={errorMsg2}
          name="otherReport2"
          control={control}
        />
      </div>
      <div
        css={css`
          display: flex;
          justify-content: flex-end;
          padding-top: 1rem;
        `}
      >
        <div
          css={css`
            position: relative;
          `}
        >
          <Button
            type="submit"
            variant="contained"
            css={css`
              width: 150px;
              font-weight: bold;
            `}
            color="primary"
            disableElevation
            disabled={isLoading || isSubmitted}
          >
            一時保存
          </Button>
          {isLoading && <CircularProgress size={24} css={progressStyle} />}
          <SnackBars
            status={status}
            loadingMsg="一時保存中..."
            successMsg="保存が完了しました"
            errorMsg="保存に失敗しました"
          />
        </div>
      </div>
    </>
  );
};
export default OtherReportArea;
