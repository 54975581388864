export type Effect = {
  beforePeriodStart: string | null;
  beforePeriodEnd: string | null;
  afterPeriodStart: string | null;
  afterPeriodEnd: string | null;
  methodType: string;
};

const isEffect = (arg: unknown): arg is Effect => {
  const e = arg as Effect;

  return (
    (typeof e?.beforePeriodStart === 'string' ||
      e?.beforePeriodStart === null) &&
    (typeof e?.beforePeriodEnd === 'string' || e?.beforePeriodEnd === null) &&
    (typeof e?.afterPeriodStart === 'string' || e?.afterPeriodStart === null) &&
    (typeof e?.afterPeriodEnd === 'string' || e?.afterPeriodEnd === null) &&
    typeof e?.methodType === 'string'
  );
};

const isEffects = (args: unknown[]): args is Effect[] =>
  !args.some((arg) => !isEffect(arg));

export { isEffect, isEffects };
