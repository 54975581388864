import axios, { AxiosError } from 'axios';
import camelcaseKeys from 'camelcase-keys';
import { format } from 'date-fns';
import { useQuery, UseQueryResult } from 'react-query';
import {
  isDailyAttachedInfo,
  DailyAttachedInfo,
} from '../models/dailyAttachedInfo';

type FormData = {
  viewDate: string;
};

const getDailyAttachedInfo = async (
  formData: FormData,
): Promise<DailyAttachedInfo> => {
  const response = await axios.post(
    `${
      process.env.REACT_APP_API_URL ?? ''
    }/api/workReport/getDailyAttachedInfo`,
    formData,
  );

  const attachedInfo = (await camelcaseKeys(response.data, {
    deep: true,
  })) as unknown;

  if (!isDailyAttachedInfo(attachedInfo)) {
    throw Error('API type error');
  }

  return attachedInfo;
};

const useGetDailyAttachedInfo = (
  viewDate: Date,
  isSuspense: boolean,
): UseQueryResult<DailyAttachedInfo, AxiosError> =>
  useQuery(
    ['reportAttachedInfo', format(viewDate, 'yyyy-M-d')],
    () => getDailyAttachedInfo({ viewDate: format(viewDate, 'yyyy-M-d') }),
    {
      suspense: isSuspense,
    },
  );

export default useGetDailyAttachedInfo;
