import { VFC, useState } from 'react';
import SnackBars from 'components/02.molecules/Common/SnackBars';
import Button from '@material-ui/core/Button';
import BasicFullSizeDialog from 'components/03.organisms/Common/BasicFullSizeDialog';
import useAddUser from 'domains/user/services/addUser';
import AddUserInputArea from 'components/03.organisms/User/AddUserInputArea';
import { useForm } from 'react-hook-form';
import { StrOrNumForm } from 'domains/common';
import FormTextField from 'containers/01.atoms/Common/FormTextFiled';
import FormSelect from 'containers/01.atoms/Common/FormSelect';
import MenuItem from '@material-ui/core/MenuItem';
import { parse } from 'date-fns';
import {
  SYSTEM_USAGE_PERIOD,
  AUTHORITY_LEVEL,
  AUTHORITY_LEVEL_NAME,
} from 'utils/const';
import DateSelectInput from 'components/02.molecules/WorkReport/Common/DateSelectInput';
import { OrganizationState, PositionState } from 'domains/user';
import PositionInputDataGrid from 'containers/02.molecules/User/PositionInputDataGrid';
import MainOrgInputDataGrid from 'containers/02.molecules/User/MainOrgInputDataGrid';
import FormLabel from '@material-ui/core/FormLabel';
import { css } from '@emotion/react';
import FormHelperText from '@material-ui/core/FormHelperText';
import ErrorResponse from 'domains/common/models/error';
import getPositionsError from 'utils/function/error/getPositionsError';
import getMainOrgsError from 'utils/function/error/getMainOrgsError';
import CustomDate from 'components/01.atoms/Common/Basic/CustomDate';

const AddNewUserButton: VFC = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [joiningDate, setJoiningDate] = useState(null as Date | null);
  const [positionState, setPositionState] = useState([] as PositionState[]);
  const [organizationState, setOrganizationState] = useState(
    [] as OrganizationState[],
  );

  const {
    control,
    getValues,
    reset: formReset,
  } = useForm<StrOrNumForm>({
    defaultValues: {
      employeeNumber: '',
      name: '',
      nameKana: '',
      email: '',
      authorityLevel: '',
    },
    shouldUnregister: false,
  });

  const handleReset = () => {
    formReset();
    setJoiningDate(null);
    setPositionState([]);
    setOrganizationState([]);
  };

  const {
    mutate,
    status,
    error = {
      response: {
        data: {} as ErrorResponse,
      },
    },
    reset: queryReset,
  } = useAddUser(setIsModalOpen, handleReset);

  const handleCancel = () => {
    setIsModalOpen(false);
    queryReset();
  };

  const handleOk = () => {
    mutate({
      employeeNumber: String(getValues('employeeNumber')),
      name: String(getValues('name')),
      nameKana: String(getValues('nameKana')),
      email: String(getValues('email')),
      authorityLevel: Number(getValues('authorityLevel')),
      joiningDate,
      positions: positionState,
      mainOrgs: organizationState,
    });
  };

  const mainOrgsError = getMainOrgsError(error, organizationState);

  const positionsError = getPositionsError(error, positionState);

  return (
    <>
      <Button
        variant="outlined"
        size="small"
        color="primary"
        onClick={() => setIsModalOpen(true)}
      >
        新規ユーザーの登録
      </Button>
      <SnackBars
        status={status}
        loadingMsg="登録中..."
        successMsg="登録が完了しました"
        errorMsg="登録に失敗しました"
      />
      <BasicFullSizeDialog
        title="新規のユーザー登録"
        okButtonName="登録"
        contentsArea={
          <AddUserInputArea
            leftSide={
              <>
                <FormTextField
                  label="社員番号"
                  placeholder=""
                  isMultiLine={false}
                  isFullWidth
                  variant="outlined"
                  margin="normal"
                  name="employeeNumber"
                  control={control}
                  errorMsg={error?.response?.data?.errors.employeeNumber}
                />
                <FormTextField
                  label="氏名(漢字・英字)"
                  placeholder=""
                  isMultiLine={false}
                  isFullWidth
                  variant="outlined"
                  margin="normal"
                  name="name"
                  control={control}
                  errorMsg={error?.response?.data?.errors.name}
                />
                <FormTextField
                  label="氏名（ふりがな）"
                  placeholder=""
                  isMultiLine={false}
                  isFullWidth
                  variant="outlined"
                  margin="normal"
                  name="nameKana"
                  control={control}
                  errorMsg={error?.response?.data?.errors.nameKana}
                />
                <FormTextField
                  label="メールアドレス"
                  placeholder=""
                  isMultiLine={false}
                  isFullWidth
                  variant="outlined"
                  margin="normal"
                  name="email"
                  control={control}
                  errorMsg={error?.response?.data?.errors.email}
                />
                <FormSelect
                  label="権限レベル"
                  placeholder=""
                  isMultiLine={false}
                  isFullWidth
                  variant="outlined"
                  margin="normal"
                  name="authorityLevel"
                  control={control}
                  errorMsg={error?.response?.data?.errors.authorityLevel}
                >
                  <MenuItem key="authorityLevel1" value={AUTHORITY_LEVEL.STAFF}>
                    {AUTHORITY_LEVEL_NAME[AUTHORITY_LEVEL.STAFF]}
                  </MenuItem>
                  <MenuItem
                    key="authorityLevel2"
                    value={AUTHORITY_LEVEL.MANAGER}
                  >
                    {AUTHORITY_LEVEL_NAME[AUTHORITY_LEVEL.MANAGER]}
                  </MenuItem>
                  <MenuItem
                    key="authorityLevel3"
                    value={AUTHORITY_LEVEL.SYSTEM}
                  >
                    {AUTHORITY_LEVEL_NAME[AUTHORITY_LEVEL.SYSTEM]}
                  </MenuItem>
                </FormSelect>
                <DateSelectInput
                  setViewDate={setJoiningDate}
                  viewDate={joiningDate}
                  customInput={
                    <CustomDate
                      label="入社日"
                      variant="standard"
                      dateValue={joiningDate}
                      errorMsg={error?.response?.data?.errors.joiningDate}
                    />
                  }
                  minDate={parse(
                    SYSTEM_USAGE_PERIOD.START,
                    'yyyy/M/d',
                    new Date(),
                  )}
                  addWrapperCss={css`
                    width: 100%;
                  `}
                />
              </>
            }
            rightSide={
              <>
                <div>
                  <>
                    <div
                      css={css`
                        margin-bottom: 10px;
                      `}
                    >
                      <FormLabel
                        css={css`
                          display: inline-block;
                          font-size: 0.9rem;
                        `}
                      >
                        役職
                      </FormLabel>
                      {!!positionsError && (
                        <FormHelperText
                          error
                          css={css`
                            display: inline-block;
                            padding-left: 8px;
                          `}
                        >
                          {positionsError}
                        </FormHelperText>
                      )}
                    </div>
                    <PositionInputDataGrid
                      joiningDate={joiningDate}
                      positionState={positionState}
                      setPositionState={setPositionState}
                    />
                  </>
                </div>
                <div>
                  <>
                    <div
                      css={css`
                        margin-bottom: 10px;
                      `}
                    >
                      <FormLabel
                        css={css`
                          display: inline-block;
                          font-size: 0.9rem;
                        `}
                      >
                        所属組織（主務）
                      </FormLabel>
                      {!!mainOrgsError && (
                        <FormHelperText
                          error
                          css={css`
                            display: inline-block;
                            padding-left: 8px;
                          `}
                        >
                          {mainOrgsError}
                        </FormHelperText>
                      )}
                    </div>
                    <MainOrgInputDataGrid
                      scopeStart={joiningDate}
                      organizationState={organizationState}
                      setOrganizationState={setOrganizationState}
                      emptyMessage="組織選択のために入社日の選択が必要です。"
                    />
                  </>
                </div>
              </>
            }
          />
        }
        isOpen={isModalOpen}
        handleOk={handleOk}
        handleCancel={handleCancel}
      />
    </>
  );
};

export default AddNewUserButton;
