import { parse, format } from 'date-fns';

type PeriodProps = {
  start?: string;
  end?: string;
  period?: {
    start: string;
    end: string;
  };
};

const convertPeriodText = (props: PeriodProps): string => {
  const { start, end, period } = props;
  let startRsc;
  let endRsc;
  if (typeof start === 'string' && typeof end === 'string') {
    startRsc = start;
    endRsc = end;
  } else if (
    typeof period?.start === 'string' &&
    typeof period?.end === 'string'
  ) {
    startRsc = period?.start;
    endRsc = period?.end;
  } else {
    return '期間データが不正な値です';
  }

  const startText = format(
    parse(startRsc, 'yyyy-MM-dd', new Date()),
    'yyyy年M月d日',
  );
  const endText =
    endRsc === '9999-12-31'
      ? ' 以降継続'
      : format(parse(endRsc, 'yyyy-MM-dd', new Date()), '～yyyy年M月d日');

  return `${startText}${endText}`;
};

export default convertPeriodText;
