import { useEffect, VFC, useState } from 'react';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { css } from '@emotion/react';
import SnackBars from 'components/02.molecules/Common/SnackBars';
import BasicConfirmFormDialog from 'components/03.organisms/Common/BasicConfirmFormDialog';
import { useQueryClient } from 'react-query';
import { useEditJoiningDate, UserDetail } from 'domains/user';
import parse from 'date-fns/parse';
import DateSelectInput from 'components/02.molecules/WorkReport/Common/DateSelectInput';
import { SYSTEM_USAGE_PERIOD } from 'utils/const';
import CustomDate from 'components/01.atoms/Common/Basic/CustomDate';

export type Props = {
  viewUserId: number;
  isOpen: boolean;
  setIsOpen: (arg: boolean) => void;
};

const EnhancedEditJoiningDateDialog: VFC<Props> = ({
  viewUserId,
  isOpen,
  setIsOpen,
}) => {
  const [joiningDate, setJoiningDate] = useState(null as Date | null);
  const {
    mutate,
    status,
    isLoading,
    error = {
      response: {
        data: { errors: { joiningDate: '' } },
      },
    },
  } = useEditJoiningDate(viewUserId, setIsOpen);
  const handleOk = () => {
    mutate({
      userId: viewUserId,
      joiningDate,
    });
  };
  const handleCancel = () => {
    setIsOpen(false);
  };

  const queryClient = useQueryClient();
  useEffect(() => {
    const data = queryClient.getQueryData<UserDetail>([
      'system',
      'userDetail',
      viewUserId,
    ]);
    setJoiningDate(
      data?.basic.firstDate
        ? parse(data?.basic.firstDate, 'yyyy-MM-dd', new Date())
        : null,
    );
  }, [queryClient, viewUserId]);

  return (
    <>
      <BasicConfirmFormDialog
        title="入社日の変更"
        confirmMsg="変更後の入社日を選択してください。"
        isOpen={isOpen}
        handleOk={handleOk}
        handleCancel={handleCancel}
        okButtonName="変更"
        cancelButtonName="キャンセル"
        textField={
          <DateSelectInput
            setViewDate={setJoiningDate}
            viewDate={joiningDate}
            customInput={
              <CustomDate
                label="入社日"
                variant="standard"
                dateValue={joiningDate}
                errorMsg={error?.response?.data?.errors.joiningDate}
              />
            }
            minDate={parse(SYSTEM_USAGE_PERIOD.START, 'yyyy/M/d', new Date())}
          />
        }
      />
      <Backdrop
        css={css`
          z-index: 1301;
          color: white;
        `}
        open={isLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <SnackBars
        status={status}
        loadingMsg="変更中..."
        successMsg="変更が完了しました"
        errorMsg="変更に失敗しました"
      />
    </>
  );
};

export default EnhancedEditJoiningDateDialog;
