import { css } from '@emotion/react';
import { VFC } from 'react';
import { WORK_REPORT_STATUS, WORK_REPORT_STATUS_COLOR } from 'utils/const';
import StatusDescription from 'components/02.molecules/WorkReport/Common/StatusDescription';

const StatusDescriptionGroup: VFC = () => (
  <div
    css={css`
      display: flex;
      gap: 10px;
    `}
  >
    <StatusDescription
      colorcode={WORK_REPORT_STATUS_COLOR[WORK_REPORT_STATUS.REMANDED]}
      description="差戻中"
    />
    <StatusDescription
      colorcode={WORK_REPORT_STATUS_COLOR[WORK_REPORT_STATUS.SUBMITTED]}
      description="提出中"
    />
    <StatusDescription
      colorcode={WORK_REPORT_STATUS_COLOR[WORK_REPORT_STATUS.APPROVED]}
      description="承認済"
    />
  </div>
);

export default StatusDescriptionGroup;
