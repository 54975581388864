import { VFC, useState, useEffect, forwardRef, MouseEventHandler } from 'react';
import SnackBars from 'components/02.molecules/Common/SnackBars';
import EditPeriodDialog from 'containers/02.molecules/Organization/New/EditPeriodDialog';
import {
  OrganizationPeriod,
  useEditNewTempOrganizationPeriod,
} from 'domains/organization';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import CustomInput from 'components/01.atoms/Common/Basic/CustomInputInDateSelect';
import dateSelectWidth from 'baseCss/emotion/dateSelectWidth';

type Props = {
  viewPeriod: OrganizationPeriod;
};

const EditNewPeriodButton: VFC<Props> = ({ viewPeriod }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [start, setStart] = useState(null as Date | null);

  const resetForm = () => {
    setStart(null);
  };
  useEffect(() => {
    resetForm();
  }, [viewPeriod]);
  const {
    mutate,
    status,
    reset: resetError,
    error = {
      response: {
        data: { errors: { start: '' } },
      },
    },
  } = useEditNewTempOrganizationPeriod(setIsOpen, resetForm);
  const handleOk = () => {
    mutate({
      periodId: viewPeriod.id,
      start,
    });
  };
  const handleCancel = () => {
    setIsOpen(false);
    resetError();
  };

  type CustomProps = {
    onClick?: MouseEventHandler<HTMLDivElement>;
  };

  const CustomStart = forwardRef<HTMLDivElement, CustomProps>(
    ({ onClick }, ref) => (
      <CustomInput
        addCss={dateSelectWidth}
        label="変更後の期間の開始日"
        value={start}
        errorMsg={error?.response?.data?.errors.start}
        ref={ref}
        onClick={onClick}
      />
    ),
  );

  return (
    <>
      <Tooltip title={<Typography variant="caption">期間の編集</Typography>}>
        <IconButton
          onClick={() => setIsOpen(true)}
          aria-label="edit"
          color="primary"
        >
          <EditIcon />
        </IconButton>
      </Tooltip>
      <EditPeriodDialog
        isOpen={isOpen}
        handleOk={handleOk}
        handleCancel={handleCancel}
        start={start}
        setStart={setStart}
        customStart={<CustomStart />}
      />
      <SnackBars
        status={status}
        loadingMsg="変更中..."
        successMsg="変更が完了しました"
        errorMsg="変更に失敗しました"
      />
    </>
  );
};

export default EditNewPeriodButton;
