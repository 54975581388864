export type Organization = {
  orgId: number;
  orgName: string;
  orgType: number;
  childOrgs: Organization[];
};

const isOrganization = (arg: unknown): arg is Organization => {
  const o = arg as Organization;

  return (
    typeof o?.orgId === 'number' &&
    typeof o?.orgName === 'string' &&
    typeof o?.orgType === 'number' &&
    typeof o?.orgName === 'string' &&
    (!o?.childOrgs.some((child) => !isOrganization(child)) ||
      o?.childOrgs === [])
  );
};

const isOrganizations = (args: unknown[]): args is Organization[] =>
  !args.some((arg) => !isOrganization(arg));

export { isOrganization, isOrganizations };
