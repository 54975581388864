import { VFC, useState, forwardRef, MouseEventHandler } from 'react';
import Button from '@material-ui/core/Button';
import SnackBars from 'components/02.molecules/Common/SnackBars';
import { PositionPeriod, useAddPositionPeriod } from 'domains/position';
import NewPeriodDialog from 'containers/02.molecules/Position/NewPeriodDialog';
import FormTextField from 'components/01.atoms/Common/Basic/FormTextFiled';
import AddIcon from '@material-ui/icons/Add';
import format from 'date-fns/format';
import { css } from '@emotion/react';

type Props = {
  setViewPeriod: (arg: PositionPeriod) => void;
};

const AddPeriodButton: VFC<Props> = ({ setViewPeriod }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [newStart, setNewStart] = useState(null as Date | null);
  const resetForm = () => {
    setNewStart(null);
  };
  const {
    mutate,
    status,
    reset: resetError,
    error = {
      response: {
        data: { errors: { start: '' } },
      },
    },
  } = useAddPositionPeriod(setIsOpen, setViewPeriod, resetForm);
  const handleOk = () => {
    mutate({
      start: newStart,
    });
  };
  const handleCancel = () => {
    setIsOpen(false);
    resetError();
  };

  type FormProps = {
    onClick?: MouseEventHandler<HTMLDivElement>;
  };

  const CustomInput = forwardRef<HTMLDivElement, FormProps>(
    ({ onClick }, ref) => (
      <FormTextField
        addCss={css`
          width: 200px;
        `}
        label="変更後の期間の開始日"
        placeholder="未選択"
        isMultiLine={false}
        isFullWidth
        variant="standard"
        margin="normal"
        errorMsg={error?.response?.data?.errors.start}
        onClick={onClick}
        value={newStart ? format(newStart, 'yyyy年M月d日 以降継続') : ''}
        ref={ref}
      />
    ),
  );

  return (
    <>
      <Button
        fullWidth
        startIcon={<AddIcon />}
        variant="outlined"
        color="default"
        disableElevation
        onClick={() => setIsOpen(true)}
      >
        新しい期間の追加
      </Button>
      <NewPeriodDialog
        isOpen={isOpen}
        handleOk={handleOk}
        handleCancel={handleCancel}
        newStart={newStart}
        setNewStart={setNewStart}
        customInput={<CustomInput />}
      />
      <SnackBars
        status={status}
        loadingMsg="登録中..."
        successMsg="登録が完了しました"
        errorMsg="登録に失敗しました"
      />
    </>
  );
};

export default AddPeriodButton;
