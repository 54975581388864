import axios, { AxiosError } from 'axios';
import camelcaseKeys from 'camelcase-keys';
import { useQuery, UseQueryResult } from 'react-query';
import {
  OrganizationDetailTemp,
  isOrganizationDetailTemp,
} from '../models/organizationDetailTemp';

export type FormProps = {
  orgId: number;
  periodId: number;
};

const getTempOrganizationDetail = async (
  formData: FormProps,
): Promise<OrganizationDetailTemp> => {
  const response = await axios.post(
    `${
      process.env.REACT_APP_API_URL ?? ''
    }/api/system/organization/getTempOrganizationDetail`,
    formData,
  );

  const detail = (await camelcaseKeys(response.data, {
    deep: true,
  })) as unknown;

  if (!isOrganizationDetailTemp(detail)) {
    throw Error('API type error');
  }

  return detail;
};

const useGetTempOrganizationDetail = (
  orgId: number,
  periodId: number,
  isSuspense: boolean,
): UseQueryResult<OrganizationDetailTemp, AxiosError> =>
  useQuery(
    ['system', 'organizationDetail', 'temp', orgId, periodId],
    () => getTempOrganizationDetail({ orgId, periodId }),
    {
      suspense: isSuspense,
    },
  );

export default useGetTempOrganizationDetail;
