import { FC } from 'react';
import { css } from '@emotion/react';
import hoverScrollStyle from 'baseCss/emotion/hoverScroll';

type Props = {
  height: string;
};

const OverlayScrollWrapper: FC<Props> = ({ height, children }) => (
  <div
    css={[
      css`
        height: ${height};
        overflow: overlay;
      `,
      hoverScrollStyle,
    ]}
  >
    {children}
  </div>
);

export default OverlayScrollWrapper;
