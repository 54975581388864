import { ReactElement, VFC } from 'react';
import { css } from '@emotion/react';

type Props = {
  headerTitle: ReactElement;
  headerButton: ReactElement;
  dataList: ReactElement;
};

const overallStyle = css`
  position: relative;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  min-width: 0;
  margin-right: 1rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
`;

const headerOuterStyle = css`
  box-sizing: border-box;
  padding: 0 1.25rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  word-wrap: break-word;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0;
`;

const headerInnerStyle = css`
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 1rem;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  word-wrap: break-word;
`;

const headerLeftAreaStyle = css`
  box-sizing: border-box;
  display: flex;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  word-wrap: break-word;
`;

const headerRightAreaStyle = css`
  box-sizing: border-box;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: right;
  word-wrap: break-word;
`;

const contentsStyle = css`
  box-sizing: border-box;
  height: calc(100% - 44px);
  font-size: 1rem;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  word-wrap: break-word;
`;

const OriginalContentsArea: VFC<Props> = ({
  headerTitle,
  headerButton,
  dataList,
}) => (
  <div css={overallStyle}>
    <div css={headerOuterStyle}>
      <div css={headerInnerStyle}>
        <div css={headerLeftAreaStyle}>{headerTitle}</div>
        <div css={headerRightAreaStyle}>{headerButton}</div>
      </div>
    </div>
    <div css={contentsStyle}>{dataList}</div>
  </div>
);

export default OriginalContentsArea;
