import { VFC, useState } from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import { css } from '@emotion/react';
import { PositionPeriod, useGetPositionPeriods } from 'domains/position';
import convertPeriodText from 'utils/function/convertPeriodText';

type Props = {
  viewPeriod: PositionPeriod;
  setViewPeriod: (arg: PositionPeriod) => void;
};

const PeriodMenu: VFC<Props> = ({ viewPeriod, setViewPeriod }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClickListItem = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuItemClick = (
    event: React.MouseEvent<HTMLElement>,
    index: PositionPeriod,
  ) => {
    setViewPeriod(index);
    setAnchorEl(null);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const { data: periods = [] as PositionPeriod[] } =
    useGetPositionPeriods(false);

  return (
    <>
      <List
        css={css`
          padding-bottom: 0;
        `}
        component="nav"
        aria-label="Device settings"
      >
        <ListItem
          button
          id="lock-button"
          aria-haspopup="listbox"
          aria-controls="lock-menu"
          aria-label="閲覧対象"
          aria-expanded={open ? 'true' : undefined}
          onClick={handleClickListItem}
        >
          <ListItemText
            primary="閲覧対象の役職期間"
            secondary={
              viewPeriod.id
                ? convertPeriodText({
                    period: periods.find(
                      (period) => period.id === viewPeriod.id,
                    ),
                  })
                : '未選択'
            }
          />
        </ListItem>
      </List>
      <Menu
        id="lock-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'lock-button',
          role: 'listbox',
        }}
      >
        {periods?.map((period) => (
          <MenuItem
            key={period.id}
            selected={period.id === viewPeriod.id}
            onClick={(event) => handleMenuItemClick(event, period)}
          >
            {convertPeriodText({
              start: period.start,
              end: period.end,
            })}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default PeriodMenu;
