import { VFC } from 'react';
import { useNavigate } from 'react-router-dom';
import { css } from '@emotion/react';
import HomeButton from 'components/01.atoms/Home/HomeButton';
import {
  FaClipboardList,
  FaRegFileAlt,
  FaTasks,
  FaUserClock,
} from 'react-icons/fa';

const wrapperStyle = css`
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  padding: 0 0 0 1rem;
`;

const iconStyle = css`
  position: absolute;
  left: 0;
  align-self: center;
  width: 2.5rem;
  margin-left: 0.5rem;
  font-size: 1.5rem;
`;

const HomeButtonGroup: VFC = () => {
  const navigate = useNavigate();

  return (
    <div css={wrapperStyle}>
      <HomeButton onClick={() => navigate('/workreport/input')}>
        <FaClipboardList css={iconStyle} />
        日次報告書作成
      </HomeButton>
      <HomeButton onClick={() => navigate('/workreport/approve')}>
        <FaRegFileAlt css={iconStyle} />
        日次報告書承認
      </HomeButton>
      <HomeButton onClick={() => navigate('/task/newSpotTask')}>
        <FaTasks css={iconStyle} />
        単発タスクの依頼
      </HomeButton>
      <HomeButton onClick={() => navigate('/task/ownTask')}>
        <FaTasks css={iconStyle} />
        担当タスク一覧
      </HomeButton>
      <HomeButton onClick={() => navigate('/task/requestingTask')}>
        <FaTasks css={iconStyle} />
        依頼タスク一覧
      </HomeButton>
      <HomeButton onClick={() => navigate('/workreport/input')}>
        <FaUserClock css={iconStyle} />
        部下の勤怠一覧
      </HomeButton>
    </div>
  );
};

export default HomeButtonGroup;
