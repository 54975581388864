import { Dispatch, SetStateAction, useEffect, VFC } from 'react';
import { OrganizationState } from 'domains/user';
import { parse } from 'date-fns';
import MainOrgInputDataGrid from 'components/02.molecules/User/MainOrgInputDataGrid';
import { SYSTEM_USAGE_PERIOD } from 'utils/const';

type Props = {
  scopeStart: Date | null;
  scopeEnd?: Date | null;
  setOrganizationState: Dispatch<SetStateAction<OrganizationState[]>>;
  organizationState: OrganizationState[];
  emptyMessage: string;
};

const EnhancedMainOrgInputDataGrid: VFC<Props> = ({
  scopeStart,
  scopeEnd,
  organizationState,
  setOrganizationState,
  emptyMessage,
}) => {
  useEffect(() => {
    setOrganizationState([]);
    if (scopeStart) {
      setOrganizationState([
        {
          start: scopeStart,
          end:
            scopeEnd ?? parse(SYSTEM_USAGE_PERIOD.END, 'yyyy/M/d', new Date()),
          isContinued: true,
          rowNumber: 0,
          orgId: 0,
          orgName: '',
        },
      ]);
    }
  }, [scopeStart, scopeEnd, setOrganizationState]);

  return (
    <MainOrgInputDataGrid
      rows={organizationState}
      setOrganizationState={setOrganizationState}
      scopeEnd={scopeEnd}
      emptyMessage={emptyMessage}
    />
  );
};

export default EnhancedMainOrgInputDataGrid;
