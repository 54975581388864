import { VFC } from 'react';
import PresentationPage from 'components/05.pages/Common/SideAndMainPage';
import HeaderBar from 'containers/03.organisms/Common/CommonHeader';
import SideMenu from 'containers/03.organisms/System/SystemSideMenu';
import MainContents from 'containers/03.organisms/System/MainContentsArea';

const EnhancedSystemHomePage: VFC = () => (
  <PresentationPage headerBar={<HeaderBar />} sideMenu={<SideMenu />}>
    <MainContents />
  </PresentationPage>
);

export default EnhancedSystemHomePage;
