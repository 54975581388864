import { useMemo, VFC } from 'react';
import { css } from '@emotion/react';
import ReactTableBasic from 'components/02.molecules/Common/ReactTableBasic';
import { Column } from 'react-table';
import { OwnTask } from 'domains/task/common';
import convertDateHourMinute from 'utils/function/convertDateHourMinute';

const heightAdjustStyle = css`
  height: 100%;
`;

type DataProps = OwnTask & {
  id: number;
};

type Props = {
  data: DataProps[];
  isLoading: boolean;
};

const ApproachingOwnTaskTable: VFC<Props> = ({ data, isLoading }) => {
  const columns = useMemo(
    (): Column<DataProps>[] => [
      {
        accessor: 'id',
      },
      {
        // Header: 'タスク名称',
        accessor: 'taskName',
        width: 200,
      },
      {
        // Header: '期限',
        accessor: 'dueDateTime',
        width: 200,
        minWidth: 210,
        maxWidth: 10,
        Cell: ({ value }) => convertDateHourMinute(value),
      },
    ],
    [],
  );

  return (
    <ReactTableBasic
      columns={columns}
      data={data}
      isLoading={isLoading}
      addTableCss={heightAdjustStyle}
      addBodyCss={heightAdjustStyle}
      isHeaderLess
    />
  );
};

export default ApproachingOwnTaskTable;
