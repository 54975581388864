import axios, { AxiosError } from 'axios';
import camelcaseKeys from 'camelcase-keys';
import { format } from 'date-fns';
import { useQuery, UseQueryResult } from 'react-query';
import { Effect, isEffects } from '../models/effect';

export type FormData = {
  start: Date;
};

const getNewPeriodEffects = async (formData: FormData): Promise<Effect[]> => {
  const response = await axios.post(
    `${
      process.env.REACT_APP_API_URL ?? ''
    }/api/system/position/getNewPeriodEffects`,
    { start: format(formData.start, 'yyyy-M-d') },
  );

  const effects = (await camelcaseKeys(response.data, {
    deep: true,
  })) as unknown[];

  if (!isEffects(effects)) {
    throw Error('API type error');
  }

  return effects;
};

const useGetNewPeriodEffects = (
  start: Date,
  isSuspense: boolean,
): UseQueryResult<Effect[], AxiosError> =>
  useQuery(['newPeriodEffect', start], () => getNewPeriodEffects({ start }), {
    suspense: isSuspense,
    staleTime: 0,
  });

export default useGetNewPeriodEffects;
