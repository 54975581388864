import { useEffect, VFC } from 'react';
import { format } from 'date-fns';
import { useRemandReport } from 'domains/workReport';
import ja from 'date-fns/locale/ja';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { css } from '@emotion/react';
import SnackBars from 'components/02.molecules/Common/SnackBars';
import BasicConfirmFormDialog from 'components/03.organisms/Common/BasicConfirmFormDialog';
import FormTextField from 'containers/01.atoms/Common/FormTextFiled';
import { useForm } from 'react-hook-form';
import { StrOrNumForm } from 'domains/common';

export type Props = {
  viewDate: Date;
  viewStaffId: number;
  isOpen: boolean;
  setIsOpen: (arg: boolean) => void;
};

const EnhancedRemandConfirmDialog: VFC<Props> = ({
  viewDate,
  viewStaffId,
  isOpen,
  setIsOpen,
}) => {
  const { getValues, control, reset } = useForm<StrOrNumForm>();
  const { mutate, status, isLoading } = useRemandReport(
    setIsOpen,
    viewDate,
    viewStaffId,
    reset,
  );
  const formData = {
    viewDate: format(viewDate, 'yyyy-M-d'),
    userId: viewStaffId,
  };
  const handleOk = () => {
    mutate({ ...formData, ...{ comment: String(getValues('comment')) } });
  };
  const handleCancel = () => {
    setIsOpen(false);
  };

  useEffect(() => reset(), [reset, viewDate, viewStaffId]);

  return (
    <>
      <BasicConfirmFormDialog
        title="日報の差戻"
        confirmMsg={format(
          viewDate,
          'yyyy年M月d日（EEEE）の日報を差戻しますか？',
          { locale: ja },
        )}
        isOpen={isOpen}
        handleOk={handleOk}
        handleCancel={handleCancel}
        okButtonName="はい"
        cancelButtonName="いいえ"
        textField={
          <FormTextField
            label="コメント(任意)"
            placeholder=""
            isMultiLine
            rows={4}
            isFullWidth
            variant="standard"
            margin="normal"
            name="comment"
            control={control}
          />
        }
      />
      <Backdrop
        css={css`
          z-index: 1301;
          color: white;
        `}
        open={isLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <SnackBars
        status={status}
        loadingMsg="日報の差戻中..."
        successMsg="日報の差戻が完了しました"
        errorMsg="日報の差戻に失敗しました"
      />
    </>
  );
};

export default EnhancedRemandConfirmDialog;
