import { VFC, MouseEventHandler } from 'react';
import { css } from '@emotion/react';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import UnfoldLessIcon from '@material-ui/icons/UnfoldLess';

export type Props = {
  handleFold?: MouseEventHandler<HTMLButtonElement>;
};

const FoldTreeButton: VFC<Props> = ({ handleFold }) => (
  <Tooltip
    title={<Typography variant="caption">サブツリーを閉じる</Typography>}
  >
    <IconButton
      css={css`
        position: absolute;
        right: 5px;
        bottom: 3px;
        background-color: white;
        border: 1px solid #e0e0e0;

        &:hover {
          background-color: #f3f3f3;
          border: 1px solid #e0e0e0;
        }
      `}
      aria-label="fold sub tree"
      size="small"
      onClick={handleFold}
    >
      <UnfoldLessIcon />
    </IconButton>
  </Tooltip>
);

export default FoldTreeButton;
