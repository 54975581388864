import { VFC, ReactElement } from 'react';
import EffectTextList from 'components/02.molecules/Position/EffectTextList';
import EffectTable from 'components/02.molecules/Position/EffectTable';
import EditPeriodDialog from 'components/03.organisms/Position/EditPeriodDialog';
import PeriodSelectInput from 'components/02.molecules/WorkReport/Common/PeriodSelectInput';
import EditPeriodEffectTable from 'containers/02.molecules/Position/EditPeriodEffectTable';
import { PositionPeriod } from 'domains/position';
import { SYSTEM_USAGE_PERIOD } from 'utils/const';
import parse from 'date-fns/parse';
import isSameDay from 'date-fns/isSameDay';
import DateSelectInput from 'components/02.molecules/WorkReport/Common/DateSelectInput';

type Props = {
  viewPeriod: PositionPeriod;
  isOpen: boolean;
  handleOk: () => void;
  handleCancel: () => void;
  start: Date | null;
  end: Date | null;
  setStart: (arg: Date | null) => void;
  setEnd: (arg: Date | null) => void;
  customStart: ReactElement;
  customEnd: ReactElement;
};

const textList = [
  {
    id: 1,
    value: '・無効になった役職名が使用されているユーザーの役職データの削除',
  },
];

const EnhancedEditPeriodDialog: VFC<Props> = ({
  viewPeriod,
  isOpen,
  handleOk,
  handleCancel,
  start,
  end,
  setStart,
  setEnd,
  customStart,
  customEnd,
}) => {
  const isFirstPeriod = isSameDay(
    parse(viewPeriod.start, 'yyyy-MM-dd', new Date()),
    parse(SYSTEM_USAGE_PERIOD.START, 'yyyy/M/d', new Date()),
  );
  const isLastPeriod = isSameDay(
    parse(viewPeriod.end, 'yyyy-MM-dd', new Date()),
    parse(SYSTEM_USAGE_PERIOD.END, 'yyyy/M/d', new Date()),
  );
  let DateSelector = (
    <PeriodSelectInput
      start={start}
      end={end}
      setStart={setStart}
      setEnd={setEnd}
      customStart={customStart}
      customEnd={customEnd}
    />
  );
  if (isFirstPeriod) {
    DateSelector = (
      <DateSelectInput
        setViewDate={setEnd}
        viewDate={end}
        customInput={customEnd}
      />
    );
  }
  if (isLastPeriod) {
    DateSelector = (
      <DateSelectInput
        setViewDate={setStart}
        viewDate={start}
        customInput={customStart}
      />
    );
  }

  return (
    <EditPeriodDialog
      textField={DateSelector}
      effectTable={
        start || end ? (
          <EditPeriodEffectTable
            viewPeriod={viewPeriod}
            start={start}
            end={end}
            label="他の期間データへの影響"
          />
        ) : (
          <EffectTable
            rows={[]}
            isLoading={false}
            label="他の期間データへの影響"
          />
        )
      }
      otherEffect={<EffectTextList label="その他の影響" textList={textList} />}
      isOpen={isOpen}
      handleOk={handleOk}
      handleCancel={handleCancel}
    />
  );
};

export default EnhancedEditPeriodDialog;
