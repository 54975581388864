import { VFC, Dispatch, SetStateAction, ChangeEvent } from 'react';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import {
  format,
  parse,
  addDays,
  isAfter,
  isWithinInterval,
  isSameDay,
} from 'date-fns';
import DateSelectInput from 'components/02.molecules/WorkReport/Common/DateSelectInput';
import { OrganizationState } from 'domains/user';
import { SYSTEM_USAGE_PERIOD } from 'utils/const';
import { css } from '@emotion/react';
import CustomDate from 'components/01.atoms/Common/Basic/CustomDate';

export type Props = {
  rowNumber: number;
  rows: OrganizationState[];
  setOrganizationState: Dispatch<SetStateAction<OrganizationState[]>>;
  scopeEnd?: Date | null;
};

const MainOrgPeriodInputGroup: VFC<Props> = ({
  rowNumber,
  rows,
  setOrganizationState,
  scopeEnd,
}) => {
  const systemUsagePeriodEnd = parse(
    SYSTEM_USAGE_PERIOD.END,
    'yyyy/M/d',
    new Date(),
  );

  const handleChangeIsContinued = (
    event: ChangeEvent<{
      name?: string | undefined;
      value: unknown;
    }>,
  ) => {
    const isContinuedNew = !!event.target.value;

    if (isContinuedNew) {
      setOrganizationState((prevState) =>
        prevState
          .filter((el) => el.rowNumber <= rowNumber)
          .map((el) =>
            el.rowNumber === rowNumber
              ? {
                  ...el,
                  isContinued: isContinuedNew,
                  end: scopeEnd ?? systemUsagePeriodEnd,
                  orgId: 0,
                  orgName: '',
                }
              : el,
          ),
      );
    }

    if (!isContinuedNew) {
      setOrganizationState((prevState) =>
        prevState.map((el) =>
          el.rowNumber === rowNumber
            ? {
                ...el,
                isContinued: isContinuedNew,
                end: null,
                orgId: 0,
                orgName: '',
              }
            : el,
        ),
      );
    }
  };

  const handleChangeEnd = (date: Date) => {
    setOrganizationState((prevState) =>
      prevState
        .filter((el) => {
          const canDelete =
            el.end && (isAfter(date, el.end) || isSameDay(date, el.end));
          if (el.rowNumber > rowNumber && canDelete) {
            return false;
          }

          return true;
        })
        .map((el, index) => {
          if (el.rowNumber === rowNumber) {
            return {
              ...el,
              end: date,
              orgId: 0,
              orgName: '',
            };
          }

          if (
            el.end
              ? isWithinInterval(date, {
                  start: el.start,
                  end: el.end,
                })
              : isAfter(date, el.start) || isSameDay(date, el.start)
          ) {
            return {
              ...el,
              start: addDays(date, 1),
              orgId: 0,
              orgName: '',
              rowNumber: index,
            };
          }

          if (el.rowNumber === rowNumber + 1 && isAfter(el.start, date)) {
            return {
              ...el,
              start: addDays(date, 1),
              orgId: 0,
              orgName: '',
              rowNumber: index,
            };
          }

          return { ...el, rowNumber: index };
        }),
    );
    if (rowNumber === rows.length - 1) {
      setOrganizationState((prevState) => [
        ...prevState,
        {
          start: addDays(date, 1),
          end: scopeEnd ?? systemUsagePeriodEnd,
          isContinued: true,
          rowNumber: rowNumber + 1,
          orgId: 0,
          orgName: '',
        },
      ]);
    }
  };

  return (
    <div
      css={css`
        display: flex;
        column-gap: 12px;
        align-items: center;
      `}
    >
      <span
        css={css`
          display: inline-block;
          width: 105px;
        `}
      >
        {format(rows[rowNumber].start, 'yyyy年M月d日')}
      </span>
      <FormControl>
        <Select
          css={css`
            width: max-content;
            min-width: 100px;
          `}
          value={Number(rows[rowNumber].isContinued)}
          onChange={handleChangeIsContinued}
        >
          <MenuItem key="isContinued1" value={1}>
            {scopeEnd && !isSameDay(scopeEnd, systemUsagePeriodEnd)
              ? `～ ${format(scopeEnd, 'yyyy年M月d日')}`
              : '以降継続'}
          </MenuItem>
          <MenuItem key="isContinued2" value={0}>
            ～
          </MenuItem>
        </Select>
      </FormControl>
      <div>
        {rows[rowNumber].isContinued || (
          <DateSelectInput
            viewDate={rows[rowNumber].end}
            customInput={
              <CustomDate
                addCss={css`
                  & > div {
                    height: 25px;
                  }
                `}
                variant="standard"
                dateValue={rows[rowNumber].end}
              />
            }
            minDate={rows[rowNumber].start}
            maxDate={scopeEnd}
            portalId="portal-root"
            addChangeAction={handleChangeEnd}
            openToDate={rows[rowNumber].end ?? rows[rowNumber].start}
          />
        )}
      </div>
    </div>
  );
};

export default MainOrgPeriodInputGroup;
