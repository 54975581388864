import axios from 'axios';
import camelcaseKeys from 'camelcase-keys';
import {
  DailyWorkReportSet,
  isDailyWorkReportSet,
} from '../models/dailyWorkReportSet';

type FormData = {
  viewDate: string;
  userId: number;
};

const getDailyWorkReportByUserId = async (
  formData: FormData,
): Promise<DailyWorkReportSet> => {
  const response = await axios.post(
    `${
      process.env.REACT_APP_API_URL ?? ''
    }/api/workReport/getDailyWorkReportByUserId`,
    formData,
  );

  const workReportSet = (await camelcaseKeys(response.data, {
    deep: true,
  })) as unknown;

  if (!isDailyWorkReportSet(workReportSet)) {
    throw Error('API type error');
  }

  return workReportSet;
};

export default getDailyWorkReportByUserId;
