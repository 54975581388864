import { VFC, ReactElement } from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListLabel from 'components/01.atoms/Common/Basic/ListLabel';
import { css } from '@emotion/react';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';

const listStyle = css`
  padding-top: 0;
  padding-bottom: 0;
`;

const primaryStyle = css`
  font-size: 0.8rem;
`;

const secondaryStyle = css`
  font-size: 0.85rem;
`;

const actionPositonStyle = css`
  position: absolute;
  top: 0;
  right: 16px;
  bottom: 0;
  width: max-content;
  height: max-content;
  margin: auto;
  transform: none;
`;

type DataProps = {
  key: string | number;
  title?: string;
  content: string;
  button?: ReactElement;
};

export type Props = {
  label?: string | null;
  dataLists: DataProps[];
};

const DetailList: VFC<Props> = ({ label, dataLists }) => (
  <>
    {label && <ListLabel label={label} />}
    <div>
      <List dense css={listStyle}>
        {dataLists?.map((data) => (
          <ListItem key={data.key} css={listStyle}>
            <ListItemText
              primary={
                data.title ? (
                  <span css={primaryStyle}>{data.title}</span>
                ) : undefined
              }
              secondary={<span css={secondaryStyle}>{data.content}</span>}
            />
            {data.button && (
              <ListItemSecondaryAction css={actionPositonStyle}>
                {data.button}
              </ListItemSecondaryAction>
            )}
          </ListItem>
        ))}
      </List>
    </div>
  </>
);

export default DetailList;
