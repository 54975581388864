import { UseMutationResult, useMutation, useQueryClient } from 'react-query';
import axios, { AxiosError } from 'axios';
import { format } from 'date-fns';
import ErrorResponse from 'domains/common/models/error';
import {
  isDailyAttachedInfo,
  DailyAttachedInfo,
} from '../models/dailyAttachedInfo';
import { isReportStatusList, ReportStatus } from '../models/reportStatus';

type ResponseProps = {
  attachedInfo: DailyAttachedInfo;
  reportStatusList: ReportStatus[];
};

const isResponseProps = (arg: unknown): arg is ResponseProps => {
  const r = arg as ResponseProps;

  return (
    isDailyAttachedInfo(r?.attachedInfo) &&
    isReportStatusList(r?.reportStatusList)
  );
};

type FormData = {
  viewDate: string;
  userId: number;
  comment: string;
};

const remandReport = async (formData: FormData): Promise<ResponseProps> => {
  const response = await axios.post(
    `${process.env.REACT_APP_API_URL ?? ''}/api/workReport/remandReport`,
    formData,
  );
  const responseData = (await response.data) as unknown;

  if (!isResponseProps(responseData)) {
    throw Error('API type error');
  }

  return responseData;
};

const useRemandReport = (
  setIsModalOpen: (arg: boolean) => void,
  viewDate: Date,
  userId: number,
  formReset: () => void,
): UseMutationResult<
  ResponseProps,
  AxiosError<ErrorResponse>,
  FormData,
  undefined
> => {
  const queryClient = useQueryClient();

  return useMutation(remandReport, {
    onSuccess: (data) => {
      queryClient.setQueryData(
        ['reportAttachedInfo', format(viewDate, 'yyyy-M-d'), userId],
        data.attachedInfo,
      );
      queryClient.setQueryData(
        ['staffReportStatusList', format(viewDate, 'yyyy-M'), userId],
        data.reportStatusList,
      );
      formReset();
      setIsModalOpen(false);
    },
  });
};

export default useRemandReport;
