import { VFC, useState } from 'react';
import Button from '@material-ui/core/Button';
import SnackBars from 'components/02.molecules/Common/SnackBars';
import { useDeleteSubOrganization } from 'domains/user';
import BasicConfirmDialog from 'components/03.organisms/Common/BasicConfirmDialog';

type Props = {
  matchingId: number;
  viewUserId: number;
};

const DeleteSubOrgButton: VFC<Props> = ({ matchingId, viewUserId }) => {
  const [isOpen, setIsOpen] = useState(false);
  const {
    mutate,
    status,
    reset: resetError,
  } = useDeleteSubOrganization(viewUserId, setIsOpen);
  const handleOk = () => {
    mutate({
      id: matchingId,
    });
  };
  const handleCancel = () => {
    setIsOpen(false);
    resetError();
  };

  return (
    <>
      <Button
        fullWidth
        variant="outlined"
        size="small"
        color="secondary"
        disableElevation
        onClick={() => setIsOpen(true)}
      >
        削除
      </Button>
      <BasicConfirmDialog
        title="兼務の削除"
        confirmMsg="選択した所属組織（兼務）データを削除しますか？"
        isOpen={isOpen}
        handleOk={handleOk}
        handleCancel={handleCancel}
      />
      <SnackBars
        status={status}
        loadingMsg="削除中..."
        successMsg="削除が完了しました"
        errorMsg="削除に失敗しました"
      />
    </>
  );
};

export default DeleteSubOrgButton;
