import {
  VFC,
  useState,
  MouseEventHandler,
  Dispatch,
  SetStateAction,
} from 'react';
import TextField from '@material-ui/core/TextField';
import { OrganizationState } from 'domains/user';
import OrganizationSelectDialog from 'containers/03.organisms/User/CommonOrgSelectDialog';
import ErrorResponse from 'domains/common/models/error';

type Props = {
  organizationState: OrganizationState;
  setOrganizationState: Dispatch<SetStateAction<OrganizationState>>;
  errorData?: ErrorResponse;
  isMain: boolean;
};

const CommonOrgSelector: VFC<Props> = ({
  organizationState,
  setOrganizationState,
  errorData,
  isMain,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const handleClick: MouseEventHandler<HTMLDivElement> = (event) => {
    const orgId = Number(event.currentTarget.getAttribute('data-id') ?? -1);
    const orgName = event.currentTarget.getAttribute('data-name') ?? '名称なし';
    setOrganizationState((prevState) => ({ ...prevState, orgId, orgName }));
    setIsOpen(false);
  };
  const errorMsg = errorData?.errors.orgId;

  return (
    <>
      <TextField
        onClick={() => setIsOpen(true)}
        variant="standard"
        margin="normal"
        label={isMain ? '所属組織（主務）' : '所属組織（兼務）'}
        placeholder="未選択"
        value={organizationState.orgName}
        InputLabelProps={{ shrink: true }}
        fullWidth
        error={!!errorMsg}
        helperText={errorMsg}
      />
      <OrganizationSelectDialog
        organizationState={organizationState}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        handleClick={handleClick}
        isMain={isMain}
      />
    </>
  );
};

export default CommonOrgSelector;
