import { VFC, ReactElement } from 'react';
import { css } from '@emotion/react';
import AddNewUserButton from 'containers/01.atoms/User/AddNewUserButton';

const overall = css`
  padding: 0 1rem;
  margin-top: 0.5rem;
`;

type Props = {
  userList: ReactElement | null;
};

const MainContentsMainArea: VFC<Props> = ({ userList }) => (
  <div css={overall}>
    <div
      css={css`
        display: flex;
        justify-content: flex-end;
        padding-right: 8px;
        padding-bottom: 6px;
      `}
    >
      <AddNewUserButton />
    </div>
    {userList}
  </div>
);

export default MainContentsMainArea;
