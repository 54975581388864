import { VFC, ReactElement } from 'react';
import { css } from '@emotion/react';
import Grid from '@material-ui/core/Grid';

const overall = css`
  padding: 0 1rem;
  margin-top: 0.5rem;
`;

type Props = {
  leftSide: ReactElement;
  rightSide: ReactElement;
};

const AddUserInputArea: VFC<Props> = ({ leftSide, rightSide }) => (
  <div>
    <Grid css={overall} container>
      <Grid item xs={4}>
        {leftSide}
      </Grid>
      <Grid
        item
        xs={8}
        css={css`
          padding: 15px;
        `}
      >
        <div
          css={css`
            display: flex;
            flex-direction: column;
            row-gap: 30px;
          `}
        >
          {rightSide}
        </div>
      </Grid>
    </Grid>
  </div>
);

export default AddUserInputArea;
