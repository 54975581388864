import { VFC, useState, useMemo } from 'react';
import SnackBars from 'components/02.molecules/Common/SnackBars';
import Button from '@material-ui/core/Button';
import {
  OrganizationState,
  useAddMainOrganizationForEmptyPeriods,
} from 'domains/user';
import BasicConfirmFormDialog from 'components/03.organisms/Common/BasicConfirmFormDialog';
import ErrorResponse from 'domains/common/models/error';
import FormHelperText from '@material-ui/core/FormHelperText';
import { css } from '@emotion/react';
import Box from '@material-ui/core/Box';
import convertPeriodText from 'utils/function/convertPeriodText';
import MainOrgInputDataGrid from 'containers/02.molecules/User/MainOrgInputDataGrid';
import getMainOrgsError from 'utils/function/error/getMainOrgsError';

type Props = {
  viewUserId: number;
  start: string;
  end: string;
};

type PeriodStateProps = {
  isContinued: boolean;
  start: Date;
  end: Date;
};

const AddUserMainOrganizationForEmptyPeriodsButton: VFC<Props> = ({
  viewUserId,
  start,
  end,
}) => {
  const entirePeriod = useMemo(
    () =>
      ({
        isContinued: false,
        start: new Date(start),
        end: new Date(end),
      } as PeriodStateProps),
    [end, start],
  );

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [organizationState, setOrganizationState] = useState(
    [] as OrganizationState[],
  );

  const handleReset = () => {
    setOrganizationState([]);
  };

  const {
    mutate,
    status,
    reset: queryReset,
    error = {
      response: {
        data: {} as ErrorResponse,
      },
    },
  } = useAddMainOrganizationForEmptyPeriods(setIsModalOpen, handleReset);

  const handleCancel = () => {
    setIsModalOpen(false);
    queryReset();
  };

  const handleOk = () => {
    mutate({
      userId: viewUserId,
      mainOrgs: organizationState,
      emptyPeriodsEnd: end,
    });
  };
  const mainOrgsError = getMainOrgsError(error, organizationState);

  return (
    <>
      <Button
        variant="outlined"
        size="small"
        color="primary"
        onClick={() => setIsModalOpen(true)}
      >
        登録
      </Button>
      <SnackBars
        status={status}
        loadingMsg="登録中..."
        successMsg="登録が完了しました"
        errorMsg="登録に失敗しました"
      />
      <BasicConfirmFormDialog
        title="ユーザーの所属組織（主務）の登録（未登録期間）"
        confirmMsg="下記の未登録期間について、主務データを登録します。"
        maxWidth="md"
        okButtonName="登録"
        cancelButtonName="キャンセル"
        textField={
          <div
            css={css`
              width: 700px;
            `}
          >
            <Box pb={2} pt={1} pl={2}>
              <span>{convertPeriodText({ start, end })}</span>
            </Box>
            {!!mainOrgsError && (
              <Box pb={1} pt={2}>
                <FormHelperText error>{mainOrgsError}</FormHelperText>
              </Box>
            )}
            <MainOrgInputDataGrid
              scopeStart={entirePeriod.start}
              scopeEnd={entirePeriod.end}
              organizationState={organizationState}
              setOrganizationState={setOrganizationState}
              emptyMessage="対象期間が取得できません"
            />
          </div>
        }
        isOpen={isModalOpen}
        handleOk={handleOk}
        handleCancel={handleCancel}
      />
    </>
  );
};

export default AddUserMainOrganizationForEmptyPeriodsButton;
