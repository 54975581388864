import { VFC, useState, useMemo } from 'react';
import SnackBars from 'components/02.molecules/Common/SnackBars';
import Button from '@material-ui/core/Button';
import { PositionState, useResetEditPositions } from 'domains/user';
import BasicConfirmFormDialog from 'components/03.organisms/Common/BasicConfirmFormDialog';
import ErrorResponse from 'domains/common/models/error';
import PositionDataGridEditReset from 'containers/02.molecules/User/PositionDataGridEditReset';
import FormHelperText from '@material-ui/core/FormHelperText';
import { css } from '@emotion/react';
import Box from '@material-ui/core/Box';
import convertPeriodText from 'utils/function/convertPeriodText';
import getPositionsError from 'utils/function/error/getPositionsError';

type Props = {
  viewUserId: number;
  start: string;
  end: string;
};

type PeriodStateProps = {
  isContinued: boolean;
  start: Date;
  end: Date;
};

const AddUserEmptyTermPositionButton: VFC<Props> = ({
  viewUserId,
  start,
  end,
}) => {
  const entirePeriod = useMemo(
    () =>
      ({
        isContinued: false,
        start: new Date(start),
        end: new Date(end),
      } as PeriodStateProps),
    [end, start],
  );

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [positionState, setPositionState] = useState([] as PositionState[]);

  const handleReset = () => {
    setPositionState([]);
  };

  const queryKeys = [['system', 'emptyTerms', 'usersHasNoPositions']];
  const {
    mutate,
    status,
    reset: queryReset,
    error = {
      response: {
        data: {} as ErrorResponse,
      },
    },
  } = useResetEditPositions(setIsModalOpen, queryKeys, handleReset);

  const handleCancel = () => {
    setIsModalOpen(false);
    queryReset();
  };

  const handleOk = () => {
    mutate({ userId: viewUserId, positions: positionState });
  };

  const positionsError = getPositionsError(error, positionState);

  return (
    <>
      <Button
        variant="outlined"
        size="small"
        color="primary"
        onClick={() => setIsModalOpen(true)}
      >
        登録
      </Button>
      <SnackBars
        status={status}
        loadingMsg="登録中..."
        successMsg="登録が完了しました"
        errorMsg="登録に失敗しました"
      />
      <BasicConfirmFormDialog
        title="ユーザーの役職の登録（未登録期間）"
        confirmMsg="下記の未登録期間について、役職データを登録します。"
        maxWidth="md"
        okButtonName="登録"
        cancelButtonName="キャンセル"
        textField={
          <div
            css={css`
              width: 700px;
            `}
          >
            <Box pb={2} pt={1} pl={2}>
              <span>{convertPeriodText({ start, end })}</span>
            </Box>
            {!!positionsError && (
              <Box pb={1} pt={2}>
                <FormHelperText error>{positionsError}</FormHelperText>
              </Box>
            )}
            <PositionDataGridEditReset
              period={entirePeriod}
              positionState={positionState}
              setPositionState={setPositionState}
              emptyMessage=""
            />
          </div>
        }
        isOpen={isModalOpen}
        handleOk={handleOk}
        handleCancel={handleCancel}
      />
    </>
  );
};

export default AddUserEmptyTermPositionButton;
