import { UseMutationResult, useMutation, useQueryClient } from 'react-query';
import axios, { AxiosError } from 'axios';
import { format } from 'date-fns';
import camelcaseKeys from 'camelcase-keys';
import ErrorResponse from 'domains/common/models/error';
import {
  isDailyWorkReportSet,
  DailyWorkReportSet,
} from '../models/dailyWorkReportSet';

export type DeleteWRFormData = {
  eventId: number;
  viewDate: Date;
};

const updateWorkReport = async (
  formData: DeleteWRFormData,
): Promise<DailyWorkReportSet> => {
  const processingFormData = {
    ...formData,
    viewDate: format(formData.viewDate, 'yyyy-M-d'),
  };

  const response = await axios.post(
    `${process.env.REACT_APP_API_URL ?? ''}/api/workReport/deleteWorkReport`,
    processingFormData,
  );

  const workReportSet = (await camelcaseKeys(response.data, {
    deep: true,
  })) as unknown[];

  if (!isDailyWorkReportSet(workReportSet)) {
    throw Error('API type error');
  }

  return workReportSet;
};

const useDeleteWorkReport = (
  setIsModalOpen: (arg: boolean) => void,
  setIsOuterModalOpen: (arg: boolean) => void,
  viewDate: Date,
): UseMutationResult<
  DailyWorkReportSet,
  AxiosError<ErrorResponse>,
  DeleteWRFormData,
  undefined
> => {
  const queryClient = useQueryClient();

  return useMutation(updateWorkReport, {
    onSuccess: (data) => {
      queryClient.setQueryData(
        ['workReports', format(viewDate, 'yyyy-M-d')],
        data,
      );
      setIsModalOpen(false);
      setIsOuterModalOpen(false);
    },
  });
};

export default useDeleteWorkReport;
