import { VFC } from 'react';
import { PositionMatch } from 'domains/user';
import DetailListWithButton from 'components/02.molecules/Common/DetailListWithButton';
import convertPeriodText from 'utils/function/convertPeriodText';
import ResetEditPositionButton from 'containers/01.atoms/User/ResetEditPositionButton';

export type Props = {
  positions: PositionMatch[];
  viewUserId: number;
};

const PositionDetailList: VFC<Props> = ({ positions, viewUserId }) => {
  const dataLists = positions?.map((position, index) => ({
    key: `positionDetail${index}`,
    title: convertPeriodText({
      start: position.start,
      end: position.end,
    }),
    content: position.positionName,
  }));

  return (
    <DetailListWithButton
      label="役職"
      dataLists={dataLists}
      buttonElements={<ResetEditPositionButton viewUserId={viewUserId} />}
    />
  );
};

export default PositionDetailList;
