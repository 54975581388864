import axios, { AxiosError } from 'axios';
import camelcaseKeys from 'camelcase-keys';
import { Dispatch, SetStateAction } from 'react';
import { useQuery, UseQueryResult } from 'react-query';
import { TreeState } from 'domains/common';
import { OrganizationSet, isOrganizationSet } from '../models/organizationSet';

export type FormProps = {
  periodId: number;
};

const getOrganizations = async (
  formData: FormProps,
): Promise<OrganizationSet> => {
  const response = await axios.post(
    `${
      process.env.REACT_APP_API_URL ?? ''
    }/api/system/organization/getOrganizations`,
    formData,
  );

  const organizations = (await camelcaseKeys(response.data, {
    deep: true,
  })) as unknown;

  if (!isOrganizationSet(organizations)) {
    throw Error('API type error');
  }

  return organizations;
};

const useGetOrganizations = (
  periodId: number,
  setTreeState: Dispatch<SetStateAction<TreeState>>,
  isSuspense: boolean,
): UseQueryResult<OrganizationSet, AxiosError> =>
  useQuery(
    ['system', 'organizations', periodId],
    () => getOrganizations({ periodId }),
    {
      suspense: isSuspense,
      onSuccess: (data) => {
        setTreeState((prevState) => ({
          ...prevState,
          displayableDepth:
            data.maxDepthLevel < prevState.displayableDepth
              ? data.maxDepthLevel
              : prevState.displayableDepth,
        }));
      },
    },
  );

export default useGetOrganizations;
