import {
  DateSelectArg,
  EventClickArg,
  EventDropArg,
} from '@fullcalendar/react';
import { EventResizeDoneArg } from '@fullcalendar/interaction';
import { UseFormReturn } from 'react-hook-form';
import { format, isSameDay, getHours } from 'date-fns';
import { UseMutateFunction } from 'react-query';
import {
  DailyWorkReportSet,
  updateWRTProps,
  WorkFormData,
} from 'domains/workReport';
import { AxiosError } from 'axios';

type ReturnType = {
  select: (arg: DateSelectArg) => void;
  eventClick: (arg: EventClickArg) => void;
  eventDrop: (arg: EventDropArg) => void;
  eventResize: (arg: EventResizeDoneArg) => void;
};

const useDailyWorkReport = (
  viewDate: Date,
  setIsModalOpen: (arg: boolean) => void,
  workFormMethods: UseFormReturn<WorkFormData>,
  mutateWRT: UseMutateFunction<
    DailyWorkReportSet,
    AxiosError<unknown>,
    updateWRTProps,
    undefined
  >,
): ReturnType => {
  const select = (info: DateSelectArg) => {
    workFormMethods.reset();
    const startHour = getHours(info.start);
    const start = isSameDay(info.start, viewDate)
      ? format(info.start, `H:mm`)
      : format(info.start, `${startHour + 24}:mm`);
    const endHour = getHours(info.end);
    const end = isSameDay(info.end, viewDate)
      ? format(info.end, `H:mm`)
      : format(info.end, `${endHour + 24}:mm`);
    workFormMethods.setValue('start', start);
    workFormMethods.setValue('end', end);
    setIsModalOpen(true);
  };

  const eventClick = (info: EventClickArg) => {
    workFormMethods.reset();
    const startRsc = info.event.start as Date;
    const endRsc = info.event.end as Date;
    const startHour = getHours(startRsc);
    const start = isSameDay(startRsc, viewDate)
      ? format(startRsc, `H:mm`)
      : format(startRsc, `${startHour + 24}:mm`);
    const endHour = getHours(endRsc);
    const end = isSameDay(endRsc, viewDate)
      ? format(endRsc, `H:mm`)
      : format(endRsc, `${endHour + 24}:mm`);
    workFormMethods.setValue('start', start);
    workFormMethods.setValue('end', end);
    workFormMethods.setValue('eventId', Number(info.event.id));
    workFormMethods.setValue(
      'tableId',
      info.event.extendedProps.referenceTableId,
    );
    workFormMethods.setValue('categoryId', info.event.extendedProps.categoryId);
    workFormMethods.setValue(
      'categoryName',
      info.event.extendedProps.categoryName,
    );
    workFormMethods.setValue(
      'description',
      info.event.extendedProps.description,
    );
    setIsModalOpen(true);
  };
  const eventDrop = (info: EventDropArg) => {
    const postData = {
      viewDate,
      eventId: info.event.id,
      start: info.event.start,
      end: info.event.end,
    };
    mutateWRT(postData);
  };
  const eventResize = (info: EventResizeDoneArg) => {
    const postData = {
      viewDate,
      eventId: info.event.id,
      start: info.event.start,
      end: info.event.end,
    };
    mutateWRT(postData);
  };

  return { select, eventClick, eventDrop, eventResize };
};

export default useDailyWorkReport;
