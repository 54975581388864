import { useEffect, VFC } from 'react';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { css } from '@emotion/react';
import SnackBars from 'components/02.molecules/Common/SnackBars';
import BasicConfirmFormDialog from 'components/03.organisms/Common/BasicConfirmFormDialog';
import FormTextField from 'containers/01.atoms/Common/FormTextFiled';
import { useForm } from 'react-hook-form';
import { StrOrNumForm } from 'domains/common';
import {
  OrganizationDetailTemp,
  OrganizationPeriod,
} from 'domains/organization';
import useEditTempOrganizationName from 'domains/organization/services/editTempOrganizationName';
import { useQueryClient } from 'react-query';
import ErrorResponse from 'domains/common/models/error';

export type Props = {
  activeOrgId: number;
  viewPeriod: OrganizationPeriod;
  isOpen: boolean;
  setIsOpen: (arg: boolean) => void;
};

const EnhancedEditOrgNameDialog: VFC<Props> = ({
  activeOrgId,
  viewPeriod,
  isOpen,
  setIsOpen,
}) => {
  const { getValues, setValue, control } = useForm<StrOrNumForm>();
  const {
    mutate,
    status,
    isLoading,
    error = {
      response: {
        data: {} as ErrorResponse,
      },
    },
  } = useEditTempOrganizationName(viewPeriod.id, activeOrgId, setIsOpen);
  const formData = {
    periodId: viewPeriod.id,
    orgId: activeOrgId,
  };
  const handleOk = () => {
    mutate({
      ...formData,
      masterName: String(getValues('masterName')),
      periodName: String(getValues('periodName')),
    });
  };
  const handleCancel = () => {
    setIsOpen(false);
  };

  const queryClient = useQueryClient();
  useEffect(() => {
    const data = queryClient.getQueryData<OrganizationDetailTemp>([
      'system',
      'organizationDetail',
      'temp',
      activeOrgId,
      viewPeriod.id,
    ]);
    setValue('masterName', data?.basic.masterName ?? '');
    setValue('periodName', data?.basic.name ?? '');
  }, [setValue, queryClient, activeOrgId, viewPeriod.id]);

  return (
    <>
      <BasicConfirmFormDialog
        title="組織名称の編集"
        confirmMsg="変更後の組織名称をご記入ください。"
        isOpen={isOpen}
        handleOk={handleOk}
        handleCancel={handleCancel}
        okButtonName="変更"
        cancelButtonName="キャンセル"
        textField={
          <>
            <FormTextField
              label="マスター名称(全期間共通の管理用)"
              placeholder=""
              isMultiLine={false}
              isFullWidth
              variant="standard"
              margin="normal"
              name="masterName"
              control={control}
              errorMsg={error?.response?.data?.errors?.masterName}
            />
            <FormTextField
              label="期間名称(選択中の期間表示用)"
              placeholder=""
              isMultiLine={false}
              isFullWidth
              variant="standard"
              margin="normal"
              name="periodName"
              control={control}
              errorMsg={error?.response?.data?.errors?.periodName}
            />
          </>
        }
      />
      <Backdrop
        css={css`
          z-index: 1301;
          color: white;
        `}
        open={isLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <SnackBars
        status={status}
        loadingMsg="変更中..."
        successMsg="変更が完了しました"
        errorMsg="変更に失敗しました"
      />
    </>
  );
};

export default EnhancedEditOrgNameDialog;
