import { FC } from 'react';
import { css } from '@emotion/react';

const loginStyle = css`
  font-size: medium;
  color: white;
  cursor: pointer;
  &:hover {
    color: #fff8b3;
    transition: 0.25s;
  }
`;

export type LinkProps = {
  link: string;
};

const LoginLink: FC<LinkProps> = ({ link, children }) => (
  <a css={loginStyle} href={link}>
    {children}
  </a>
);

export default LoginLink;
