import { VFC } from 'react';
import EffectTable from 'components/02.molecules/Position/EffectTable';
import { PositionPeriod, useGetEditPeriodEffects } from 'domains/position';

type Props = {
  viewPeriod: PositionPeriod;
  label: string;
  start: Date | null;
  end: Date | null;
};

const EditPeriodEffectTable: VFC<Props> = ({
  label,
  viewPeriod,
  start,
  end,
}) => {
  const { data: effects = [], isLoading } = useGetEditPeriodEffects(
    viewPeriod.id,
    start,
    end,
    false,
  );

  return <EffectTable rows={effects} isLoading={isLoading} label={label} />;
};
export default EditPeriodEffectTable;
