import axios, { AxiosError } from 'axios';
import camelcaseKeys from 'camelcase-keys';
import format from 'date-fns/format';
import { SuccessOnly, isSuccessOnly } from 'domains/common';
import ErrorResponse from 'domains/common/models/error';
import { UseMutationResult, useMutation } from 'react-query';

type FormProps = {
  start: Date | null;
};

const resetNewTempData = async (formData: FormProps): Promise<SuccessOnly> => {
  const response = await axios.post(
    `${
      process.env.REACT_APP_API_URL ?? ''
    }/api/system/organization/resetNewTempData`,
    {
      start: formData.start ? format(formData.start, 'yyyy-M-d') : null,
    },
  );

  const success = (await camelcaseKeys(response.data, {
    deep: true,
  })) as unknown;

  if (!isSuccessOnly(success)) {
    throw Error('API type error');
  }

  return success;
};

const useResetNewTempData = (
  jumpToPage: () => void,
): UseMutationResult<
  SuccessOnly,
  AxiosError<ErrorResponse>,
  FormProps,
  undefined
> =>
  useMutation(resetNewTempData, {
    onSuccess: () => {
      jumpToPage();
    },
  });

export default useResetNewTempData;
