import { VFC, ReactElement } from 'react';
import { css } from '@emotion/react';
import Grid from '@material-ui/core/Grid';
import OverlayScrollWrapper from 'components/02.molecules/Common/OverlayScrollWrapper';

type Props = {
  orgTree: ReactElement | null;
  treeController: ReactElement;
};

const MainContentsMainArea: VFC<Props> = ({ orgTree, treeController }) => (
  <OverlayScrollWrapper height="calc(100vh - 175px)">
    <Grid
      container
      css={css`
        row-gap: 1rem;
      `}
    >
      <Grid item xs={12}>
        {treeController}
      </Grid>
      <Grid item xs={12}>
        {orgTree}
      </Grid>
    </Grid>
  </OverlayScrollWrapper>
);

export default MainContentsMainArea;
