import { ReactElement, VFC } from 'react';
import BasicSelectDialog from 'components/03.organisms/Common/BasicSelectDialog';

type Props = {
  isOpen: boolean;
  handleCancel: () => void;
  orgList: ReactElement;
  isMain: boolean;
};

const CommonOrgSelectDialog: VFC<Props> = ({
  isOpen,
  handleCancel,
  orgList,
  isMain,
}) => (
  <BasicSelectDialog
    title={isMain ? '所属組織（主務）の選択' : '所属組織（兼務）の選択'}
    isOpen={isOpen}
    handleCancel={handleCancel}
    list={orgList}
  />
);

export default CommonOrgSelectDialog;
