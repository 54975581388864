/* eslint-disable react/jsx-props-no-spreading */
import { ReactElement } from 'react';
import {
  useTable,
  Column,
  useFlexLayout,
  TableState,
  IdType,
} from 'react-table';
import MaUTable from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import CircularProgress from '@material-ui/core/CircularProgress';
import { css, SerializedStyles } from '@emotion/react';
import absoluteCenter from 'baseCss/emotion/absoluteCenter';
import originalScrollStyle from 'baseCss/emotion/originalScroll';
import { SafeAnyObject } from 'domains/common';

const hoverStyle = css`
  &:hover {
    cursor: pointer;
    background-color: #f8f8f8;
  }
`;

const selectedStyle = css`
  background: #c2dbff;
`;

type TableProps<TData extends SafeAnyObject> = {
  columns: Column<TData>[];
  data: TData[];
  initialState?: Partial<TableState<TData>>;
  addTableCss?: SerializedStyles;
  addHeaderCss?: SerializedStyles;
  addBodyCss?: SerializedStyles;
  isLoading?: boolean;
  emptyMessage?: string;
  isSelectMode?: boolean;
  isHeaderLess?: boolean;
  handleRowClick?: (rowData: Record<IdType<TData>, unknown>) => void;
  selectedIds?: number[];
};

const ReactTableBasic = <T extends SafeAnyObject>({
  columns,
  data,
  initialState,
  addTableCss,
  addHeaderCss,
  addBodyCss,
  isLoading,
  emptyMessage,
  isSelectMode,
  isHeaderLess,
  selectedIds,
  handleRowClick,
}: TableProps<T>): ReactElement => {
  const { getTableProps, headerGroups, rows, prepareRow } = useTable<T>(
    {
      columns,
      data,
      initialState: initialState ?? { hiddenColumns: ['id'] },
    },
    useFlexLayout,
  );

  return (
    <MaUTable
      component="div"
      {...getTableProps()}
      css={[
        css`
          position: relative;
          display: block;
          border: 0.01rem solid rgba(224, 224, 224, 1);
          border-radius: 4px;
        `,
        addTableCss,
      ]}
    >
      {isHeaderLess || (
        <TableHead
          component="div"
          css={[
            css`
              display: block;
            `,
            addHeaderCss,
          ]}
        >
          {headerGroups.map((headerGroup) => (
            <TableRow component="div" {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <TableCell
                  component="div"
                  {...column.getHeaderProps()}
                  css={css`
                    border-bottom: 0;
                  `}
                >
                  {column.render('Header')}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableHead>
      )}
      <TableBody
        component="div"
        css={[
          css`
            position: relative;
            display: block;
            height: 300px;
            overflow: hidden overlay;
            border-top: 0.01rem solid rgba(224, 224, 224, 1);
          `,
          addBodyCss,
          originalScrollStyle,
        ]}
      >
        {rows.map((row) => {
          prepareRow(row);

          const rowClick = handleRowClick
            ? () => {
                handleRowClick(row.values);
              }
            : undefined;
          const fontWeightStyle = (() => {
            if (row.values.isRead === false) {
              return css`
                font-weight: bold;
              `;
            }

            return undefined;
          })();

          return (
            <TableRow
              onClick={rowClick}
              css={[
                css`
                  border-bottom: 0.01rem solid rgba(224, 224, 224, 1);
                `,
                !!selectedIds &&
                  selectedIds.includes(row.values.id) &&
                  selectedStyle,
                isSelectMode && hoverStyle,
              ]}
              component="div"
              {...row.getRowProps()}
            >
              {row.cells.map((cell) => {
                const paddingStyle = (() => {
                  if (cell.column.id === 'button') {
                    return css`
                      padding-top: 10px;
                      padding-bottom: 0;
                    `;
                  }

                  if (cell.column.id === 'iconButton') {
                    return css`
                      padding-top: 2px;
                      padding-bottom: 0;
                    `;
                  }

                  if (cell.column.id === 'checkBox') {
                    return css`
                      padding-top: 4px;
                      padding-bottom: 0;
                    `;
                  }

                  return css`
                    padding: 16px;
                  `;
                })();

                return (
                  <TableCell
                    component="div"
                    {...cell.getCellProps()}
                    css={[
                      css`
                        word-break: break-all;
                        word-wrap: break-word;
                        border-bottom: 0;
                      `,
                      paddingStyle,
                      fontWeightStyle,
                    ]}
                  >
                    {cell.render('Cell')}
                  </TableCell>
                );
              })}
            </TableRow>
          );
        })}
        {!rows.length && <div css={absoluteCenter}>{emptyMessage}</div>}
      </TableBody>
      {isLoading && <CircularProgress size={50} css={absoluteCenter} />}
    </MaUTable>
  );
};

ReactTableBasic.defaultProps = {
  initialState: undefined,
  addTableCss: undefined,
  addHeaderCss: undefined,
  addBodyCss: undefined,
  handleRowClick: undefined,
  isLoading: false,
  isSelectMode: false,
  isHeaderLess: false,
  emptyMessage: '該当データはありません',
  selectedIds: [],
};

export default ReactTableBasic;
