export type SuccessOnly = {
  success: boolean;
};

const isSuccessOnly = (arg: unknown): arg is SuccessOnly => {
  const w = arg as SuccessOnly;

  return typeof w?.success === 'boolean';
};

export { isSuccessOnly };
