import { VFC } from 'react';
import {
  DataGrid,
  GridRowData,
  GridColDef,
  GridOverlay,
  GridRowParams,
} from '@material-ui/data-grid';
import { css } from '@emotion/react';
import { DailyWorkReport, WorkFormData } from 'domains/workReport';
import { format, parse, isSameDay, getHours } from 'date-fns';
import { UseFormReturn } from 'react-hook-form';

const customStyle = css`
  &.MuiDataGrid-root .MuiDataGrid-columnHeader:focus,
  &.MuiDataGrid-root .MuiDataGrid-cell:focus {
    outline: none;
  }
  &.MuiDataGrid-root .MuiDataGrid-window {
    overflow-y: overlay !important;
  }
  &.MuiDataGrid-root .MuiDataGrid-row {
    cursor: pointer;
  }
`;

type Props = {
  rows: DailyWorkReport[];
  viewDate: Date;
  setIsModalOpen: (arg: boolean) => void;
  workFormMethods: UseFormReturn<WorkFormData>;
};

const columns: GridColDef[] = [
  {
    field: 'time',
    headerName: '作業時間',
    width: 115,
    sortable: false,
    align: 'center',
  },
  {
    field: 'categoryName',
    headerName: 'カテゴリー名',
    width: 150,
    sortable: false,
  },
  { field: 'description', headerName: '業務内容', flex: 1, sortable: false },
  { field: 'id', hide: true },
  { field: 'categoryId', hide: true },
  { field: 'referenceTableId', hide: true },
  { field: 'start', hide: true },
  { field: 'end', hide: true },
];

const convertDateTime = (datetime: string, viewDate: Date): string => {
  const date = parse(datetime, 'yyyy-MM-dd H:mm:ss', new Date());

  return isSameDay(date, viewDate)
    ? format(date, `H:mm`)
    : format(date, `${getHours(date) + 24}:mm`);
};

const convertWorkReports = (
  original: DailyWorkReport[],
  viewDate: Date,
): GridRowData[] =>
  original.length === 0
    ? ([] as GridRowData[])
    : original.map((data) => ({
        id: data.id,
        time: `${convertDateTime(
          data.startDatetime,
          viewDate,
        )}～${convertDateTime(data.endDatetime, viewDate)}`,
        start: data.startDatetime,
        end: data.endDatetime,
        description: data.description,
        categoryId: data.categoryId,
        categoryName: data.categoryName,
        referenceTableId: data.referenceTableId,
      }));

const CustomNoRowsOverlay: VFC = () => (
  <GridOverlay>
    <div>登録済みの業務内容データはありません</div>
  </GridOverlay>
);

const WorkReportTable: VFC<Props> = ({
  rows,
  viewDate,
  setIsModalOpen,
  workFormMethods,
}) => {
  const eventClick = (param: GridRowParams): void => {
    workFormMethods.reset();

    const eventId = param.getValue(param.id, 'id');
    const categoryId = param.getValue(param.id, 'categoryId');
    const categoryName = param.getValue(param.id, 'categoryName') as string;
    const tableId = param.getValue(param.id, 'referenceTableId');
    const description = param.getValue(param.id, 'description') as string;

    const startRsc = parse(
      param.getValue(param.id, 'start') as string,
      'yyyy-MM-dd H:mm:ss',
      new Date(),
    );
    const endRsc = parse(
      param.getValue(param.id, 'end') as string,
      'yyyy-MM-dd H:mm:ss',
      new Date(),
    );
    const startHour = getHours(startRsc);
    const start = isSameDay(startRsc, viewDate)
      ? format(startRsc, `H:mm`)
      : format(startRsc, `${startHour + 24}:mm`);
    const endHour = getHours(endRsc);
    const end = isSameDay(endRsc, viewDate)
      ? format(endRsc, `H:mm`)
      : format(endRsc, `${endHour + 24}:mm`);

    workFormMethods.setValue('start', start);
    workFormMethods.setValue('end', end);
    workFormMethods.setValue('eventId', Number(eventId));
    workFormMethods.setValue('tableId', Number(tableId));
    workFormMethods.setValue('categoryId', Number(categoryId));
    workFormMethods.setValue('categoryName', categoryName);
    workFormMethods.setValue('description', description);
    setIsModalOpen(true);
  };

  return (
    <div
      css={css`
        width: 100%;
        height: 450px;
      `}
    >
      <DataGrid
        css={customStyle}
        rows={convertWorkReports(rows, viewDate)}
        columns={columns}
        hideFooter
        disableColumnMenu
        disableColumnSelector
        components={{
          NoRowsOverlay: CustomNoRowsOverlay,
        }}
        onRowClick={eventClick}
      />
    </div>
  );
};

export default WorkReportTable;
