import { css } from '@emotion/react';

const originalScrollStyle = css`
  &::-webkit-scrollbar {
    width: 18px;
    height: 18px;
  }

  &::-webkit-scrollbar-button {
    display: none;
  }

  &::-webkit-scrollbar-corner,
  ::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #c8c8c8;
    background-clip: content-box;
    border: solid 6px transparent;
    border-radius: 9px;
  }
`;

export default originalScrollStyle;
