import { Dispatch, SetStateAction, VFC } from 'react';
import MainContentsHeader from 'components/03.organisms/Task/RequestingTask/MainContentsHeader';
import { FormProps } from 'domains/task/common/services/getRequestingTasks';

type Props = {
  setFilterAndSortInfo: Dispatch<SetStateAction<FormProps>>;
};

const EnhancedMainContentsHeader: VFC<Props> = ({ setFilterAndSortInfo }) => (
  <MainContentsHeader setFilterAndSortInfo={setFilterAndSortInfo} />
);

export default EnhancedMainContentsHeader;
