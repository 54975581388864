import { ReactElement, VFC } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
  Button,
} from '@material-ui/core';
import { css } from '@emotion/react';

type Props = {
  title: string;
  confirmMsg: string;
  okButtonName: string;
  cancelButtonName: string;
  isOpen: boolean;
  handleOk: () => void;
  handleCancel: () => void;
  textField?: ReactElement;
  effectTable: ReactElement;
  otherEffect: ReactElement;
  maxWidth?: false | 'xs' | 'sm' | 'md' | 'lg' | 'xl';
};

const EffectCheckFormDialog: VFC<Props> = ({
  title,
  textField,
  effectTable,
  otherEffect,
  okButtonName,
  cancelButtonName,
  confirmMsg,
  isOpen,
  handleOk,
  handleCancel,
  maxWidth,
}) => (
  <Dialog
    aria-labelledby="manage-position-period-dialog"
    onClose={handleCancel}
    open={isOpen}
    fullWidth
    maxWidth={maxWidth}
  >
    <DialogTitle id="manage-position-period-dialog">{title}</DialogTitle>
    <DialogContent dividers>
      <DialogContentText>{confirmMsg}</DialogContentText>
      {textField && (
        <div
          css={css`
            margin-bottom: 1rem;
          `}
        >
          {textField}
        </div>
      )}
      <div
        css={css`
          margin-bottom: 1rem;
        `}
      >
        {effectTable}
      </div>
      <div>{otherEffect}</div>
    </DialogContent>
    <DialogActions>
      <Button onClick={handleOk} color="primary">
        {okButtonName}
      </Button>
      <Button onClick={handleCancel} color="default">
        {cancelButtonName}
      </Button>
    </DialogActions>
  </Dialog>
);

export default EffectCheckFormDialog;
