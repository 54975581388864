import { ChangeEvent, VFC } from 'react';
import PersonPinIcon from '@material-ui/icons/PersonPin';
import { css } from '@emotion/react';
import absoluteCenter from 'baseCss/emotion/absoluteCenter';
import IconHeader from 'components/01.atoms/Common/Basic/IconHeader';
import { Typography } from '@material-ui/core';
import { PositionPeriod } from 'domains/position';
import convertPeriodText from 'utils/function/convertPeriodText';

const dateStyle = css`
  padding: 0 0;
  font-family: Lato, 'Helvetica Neue', Arial, Helvetica, sans-serif;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1.28571429em;
  color: rgba(0, 0, 0, 0.87);
  text-transform: none;
  border: none;
`;

const flexBox = css`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const labelGroup = css`
  align-self: flex-end;
  margin-right: 27px;
`;

const inlineBlock = css`
  display: inline-block;
`;

type Props = {
  viewPeriod: PositionPeriod;
  isEditMode: boolean;
  clickSwitch: (event: ChangeEvent<HTMLInputElement>) => void;
};

const MainContentsHeader: VFC<Props> = ({ viewPeriod }) => {
  const periodText = convertPeriodText({
    start: viewPeriod.start,
    end: viewPeriod.end,
  });

  return (
    <div css={flexBox}>
      <div css={absoluteCenter}>
        <Typography css={dateStyle} variant="h5" color="textPrimary">
          {periodText}
        </Typography>
      </div>
      <IconHeader
        icon={<PersonPinIcon />}
        title="役職管理"
        description="ユーザーに登録する役職名称を管理します"
      />
      <div css={[inlineBlock, labelGroup]} />
    </div>
  );
};

export default MainContentsHeader;
