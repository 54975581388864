import { ChangeEventHandler, FocusEventHandler, forwardRef } from 'react';
import TextField from '@material-ui/core/TextField';

type FormProps = {
  label?: string;
  isDisabled?: boolean;
  errorMsg?: string;
  placeholder?: string;
  name?: string;
  rows?: number;
  onBlur?: FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  onChange?: ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  value?: unknown;
};

const OtherReportForm = forwardRef<HTMLDivElement, FormProps>(
  (
    {
      label = 'ラベル',
      isDisabled = false,
      errorMsg,
      placeholder = '内容をご記入ください。',
      rows = 9,
      name,
      onBlur,
      onChange,
      value,
    },
    ref,
  ) => (
    <TextField
      fullWidth
      multiline
      variant="outlined"
      rows={rows}
      label={label}
      placeholder={placeholder}
      disabled={isDisabled}
      error={!!errorMsg}
      helperText={errorMsg}
      name={name}
      onBlur={onBlur}
      onChange={onChange}
      value={value}
      ref={ref}
      InputLabelProps={{ shrink: true }}
    />
  ),
);

export default OtherReportForm;
