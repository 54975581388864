import { VFC, useState, MouseEvent } from 'react';
import OperateMenuButton from 'components/01.atoms/Task/RequestingTask/OperateMenuButton';
import {
  useDeleteTask,
  useApproveCompleted,
  useRemandCompleted,
  useCancelCompleted,
  useEditBasicInfo,
} from 'domains/task/spot';
import {
  useDeleteTask as useDeleteSpotTreeTask,
  useApproveCompleted as useApproveSpotTreeCompleted,
  useRemandCompleted as useRemandSpotTreeCompleted,
  useCancelCompleted as useCancelCompletedSpotTree,
  useEditBasicInfo as useEditBasicInfoSpotTree,
} from 'domains/task/projectTree';
import { FormProps as FilterProps } from 'domains/task/common/services/getRequestingTasks';
import BasicConfirmDialog from 'components/03.organisms/Common/BasicConfirmDialog';
import { useQueryClient } from 'react-query';
import { TASK_TYPE } from 'utils/const';
import useRequestTaskPolicy from 'hooks/use-requestTaskPolicy';
import { RequestingTask } from 'domains/task/common';
import SnackBarsArray from 'components/02.molecules/Common/SnackBarsArray';
import BasicConfirmFormDialog from 'components/03.organisms/Common/BasicConfirmFormDialog';
import Box from '@material-ui/core/Box';
import FormTextField from 'containers/01.atoms/Common/FormTextFiled';
import CustomDate from 'components/01.atoms/Common/Basic/CustomDate';
import { useForm } from 'react-hook-form';
import { FormProps } from 'domains/task/projectTree/services/createTaskTree';
import ErrorResponse from 'domains/common/models/error';
import { css } from '@emotion/react';
import DateTimeSelectInput from 'components/02.molecules/WorkReport/Common/DateTimeSelectInput';

type Props = {
  detail: RequestingTask;
  filterAndSortInfo: FilterProps;
};

const EnhancedOperateMenuButton: VFC<Props> = ({
  detail,
  filterAndSortInfo,
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const handleMenuOpen = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [dueDateTime, setDueDateTime] = useState(null as Date | null);
  const [isDelSpotModalOpen, setIsDelSpotModalOpen] = useState(false);
  const [isDelSpotTreeModalOpen, setIsDelSpotTreeModalOpen] = useState(false);
  const [isApproveModalOpen, setIsApproveModalOpen] = useState(false);
  const [isRemandModalOpen, setIsRemandModalOpen] = useState(false);
  const [isCancelModalOpen, setIsCancelModalOpen] = useState(false);

  const handleModalClose = () => {
    setIsEditModalOpen(false);
    setIsDelSpotModalOpen(false);
    setIsDelSpotTreeModalOpen(false);
    setIsApproveModalOpen(false);
    setIsRemandModalOpen(false);
    setIsCancelModalOpen(false);
  };

  const closeAfterSuccess = () => {
    setAnchorEl(null);
    handleModalClose();
  };

  const { mutate: mutateDelSpot, status: statusDelSpot } = useDeleteTask(
    filterAndSortInfo,
    closeAfterSuccess,
  );

  const queryClient = useQueryClient();
  const handleSuccess = async () => {
    await queryClient.invalidateQueries([
      'authUser',
      'requestingTasks',
      filterAndSortInfo,
    ]);
    closeAfterSuccess();
  };

  const {
    control,
    getValues,
    setValue,
    reset: formResetEditSpotTree,
  } = useForm<FormProps>({
    defaultValues: {
      taskName: '',
      description: '',
    },
    shouldUnregister: false,
  });

  const handleSuccessEdit = async () => {
    await handleSuccess();
    formResetEditSpotTree();
  };

  const {
    mutate: mutateEditSpot,
    status: statusEditSpot,
    error: errorEditSpot = {
      response: {
        data: {} as ErrorResponse,
      },
    },
    reset: queryResetEditSpot,
  } = useEditBasicInfo(handleSuccessEdit);
  const {
    mutate: mutateEditSpotTree,
    status: statusEditSpotTree,
    error: errorEditSpotTree = {
      response: {
        data: {} as ErrorResponse,
      },
    },
    reset: queryResetEditSpotTree,
  } = useEditBasicInfoSpotTree(handleSuccessEdit);

  const handleStartEdit = () => {
    queryResetEditSpot();
    queryResetEditSpotTree();
    setValue('taskName', detail.taskName ?? '');
    setValue('description', detail.description ?? '');
    setDueDateTime(detail.dueDateTime ? new Date(detail.dueDateTime) : null);
    setIsEditModalOpen(true);
  };
  const handleEdit = () => {
    if (detail.taskType === TASK_TYPE.SPOT) {
      mutateEditSpot({
        taskId: detail.taskId,
        taskName: String(getValues('taskName')),
        description: String(getValues('description')),
        dueDateTime,
      });
    }

    if (detail.taskType === TASK_TYPE.PROJECT) {
      mutateEditSpotTree({
        taskId: detail.taskId,
        taskName: String(getValues('taskName')),
        description: String(getValues('description')),
        dueDateTime,
      });
    }
  };

  const { mutate: mutateDelSpotTree, status: statusDelSpotTree } =
    useDeleteSpotTreeTask(handleSuccess);

  const handleStartDeleteSpot = () => {
    if (detail.taskType === TASK_TYPE.SPOT) {
      setIsDelSpotModalOpen(true);
    }

    if (detail.taskType === TASK_TYPE.PROJECT) {
      setIsDelSpotTreeModalOpen(true);
    }
  };
  const handleDeleteSpot = () => {
    mutateDelSpot({ taskId: detail.taskId });
  };
  const handleDeleteSpotTree = () => {
    mutateDelSpotTree({ taskId: detail.taskId });
  };

  const { mutate: mutateApproveSpot, status: statusApproveSpot } =
    useApproveCompleted(filterAndSortInfo, closeAfterSuccess);
  const { mutate: mutateApproveSpotTree, status: statusApproveSpotTree } =
    useApproveSpotTreeCompleted(handleSuccess);
  const handleStartApprove = () => {
    setIsApproveModalOpen(true);
  };
  const handleApprove = () => {
    if (detail.taskType === TASK_TYPE.SPOT) {
      mutateApproveSpot({ taskId: detail.taskId });
    }

    if (detail.taskType === TASK_TYPE.PROJECT) {
      mutateApproveSpotTree({ taskId: detail.taskId });
    }
  };

  const { mutate: mutateRemandSpot, status: statusRemandSpot } =
    useRemandCompleted(filterAndSortInfo, closeAfterSuccess);
  const { mutate: mutateRemandSpotTree, status: statusRemandSpotTree } =
    useRemandSpotTreeCompleted(handleSuccess);
  const handleStartRemand = () => {
    setIsRemandModalOpen(true);
  };
  const handleRemand = () => {
    if (detail.taskType === TASK_TYPE.SPOT) {
      mutateRemandSpot({ taskId: detail.taskId });
    }

    if (detail.taskType === TASK_TYPE.PROJECT) {
      mutateRemandSpotTree({ taskId: detail.taskId });
    }
  };

  const { mutate: mutateCancelSpot, status: statusCancelSpot } =
    useCancelCompleted(filterAndSortInfo, closeAfterSuccess);
  const { mutate: mutateCancelSpotTree, status: statusCancelSpotTree } =
    useCancelCompletedSpotTree(handleSuccess);
  const handleStartCancelCompleted = () => {
    setIsCancelModalOpen(true);
  };
  const handleCancelCompleted = () => {
    if (detail.taskType === TASK_TYPE.SPOT) {
      mutateCancelSpot({ taskId: detail.taskId });
    }

    if (detail.taskType === TASK_TYPE.PROJECT) {
      mutateCancelSpotTree({ taskId: detail.taskId });
    }
  };

  const { canEdit, canApproveCompleted, canCancelCompleted, canDeleteTask } =
    useRequestTaskPolicy({
      detail,
    });
  const editError =
    errorEditSpot?.response?.data?.errors ??
    errorEditSpotTree?.response?.data?.errors;

  return (
    <>
      <OperateMenuButton
        anchorEl={anchorEl}
        canEdit={canEdit}
        canApprove={canApproveCompleted}
        canCancel={canCancelCompleted}
        canDelete={canDeleteTask}
        handleClose={handleMenuClose}
        handleOpen={handleMenuOpen}
        handleEdit={handleStartEdit}
        handleDelete={handleStartDeleteSpot}
        handleApprove={handleStartApprove}
        handleRemand={handleStartRemand}
        handleCancel={handleStartCancelCompleted}
      />
      <BasicConfirmFormDialog
        title="タスクの基本項目の編集"
        okButtonName="変更"
        cancelButtonName="キャンセル"
        confirmMsg="変更後の内容を入力してください"
        isOpen={isEditModalOpen}
        handleOk={handleEdit}
        handleCancel={handleModalClose}
        maxWidth="xl"
        textField={
          <Box width={600}>
            <FormTextField
              label="タスク名"
              placeholder=""
              isMultiLine={false}
              isFullWidth
              variant="outlined"
              margin="normal"
              name="taskName"
              control={control}
              errorMsg={editError?.taskName}
            />
            <DateTimeSelectInput
              setViewDate={setDueDateTime}
              viewDate={dueDateTime}
              customInput={
                <CustomDate
                  label="期限"
                  variant="outlined"
                  dateValue={dueDateTime}
                  errorMsg={editError?.dueDateTime}
                />
              }
              addWrapperCss={css`
                width: 100%;
              `}
            />
            <FormTextField
              label="内容説明"
              placeholder=""
              isMultiLine
              isFullWidth
              variant="outlined"
              margin="normal"
              name="description"
              control={control}
              errorMsg={editError?.description}
            />
          </Box>
        }
      />
      <BasicConfirmDialog
        title="タスクの削除"
        confirmMsg="選択中のタスクを削除します"
        isOpen={isDelSpotModalOpen}
        handleOk={handleDeleteSpot}
        handleCancel={handleModalClose}
      />
      <BasicConfirmDialog
        title="タスクの削除"
        confirmMsg="選択中のタスク及びその下位タスクを削除します。"
        isOpen={isDelSpotTreeModalOpen}
        handleOk={handleDeleteSpotTree}
        handleCancel={handleModalClose}
      />
      <BasicConfirmDialog
        title="タスクの完了承認"
        confirmMsg="選択中のタスクについて、完了承認しますか。"
        isOpen={isApproveModalOpen}
        handleOk={handleApprove}
        handleCancel={handleModalClose}
      />
      <BasicConfirmDialog
        title="タスクの差戻し"
        confirmMsg="選択中のタスクの担当者に進捗状況の再確認依頼をしますか。"
        isOpen={isRemandModalOpen}
        handleOk={handleRemand}
        handleCancel={handleModalClose}
      />
      <BasicConfirmDialog
        title="タスクの完了承認の解除"
        confirmMsg="選択中のタスクについて、完了承認を解除しますか。"
        isOpen={isCancelModalOpen}
        handleOk={handleCancelCompleted}
        handleCancel={handleModalClose}
      />
      <SnackBarsArray
        statuses={[statusDelSpot, statusDelSpotTree]}
        loadingMsg="削除中..."
        successMsg="削除が完了しました"
        errorMsg="削除に失敗しました"
      />
      <SnackBarsArray
        statuses={[
          statusApproveSpot,
          statusApproveSpotTree,
          statusRemandSpot,
          statusRemandSpotTree,
          statusCancelSpot,
          statusCancelSpotTree,
        ]}
        loadingMsg="更新中..."
        successMsg="更新が完了しました"
        errorMsg="更新に失敗しました"
      />
      <SnackBarsArray
        statuses={[statusEditSpot, statusEditSpotTree]}
        loadingMsg="変更中..."
        successMsg="変更が完了しました"
        errorMsg="変更に失敗しました"
      />
    </>
  );
};

export default EnhancedOperateMenuButton;
