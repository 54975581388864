import axios, { AxiosError } from 'axios';
import camelcaseKeys from 'camelcase-keys';
import ErrorResponse from 'domains/common/models/error';
import { UseMutationResult, useMutation, useQueryClient } from 'react-query';
import { PositionPeriod } from '../models/period';
import { PeriodSet, isPeriodSet } from '../models/periodSet';

type FormProps = {
  periodId: number;
};

const deletePositionPeriod = async (
  formData: FormProps,
): Promise<PeriodSet> => {
  const response = await axios.post(
    `${
      process.env.REACT_APP_API_URL ?? ''
    }/api/system/position/deletePositionPeriod`,
    { periodId: formData.periodId },
  );

  const periodSet = (await camelcaseKeys(response.data, {
    deep: true,
  })) as unknown;

  if (!isPeriodSet(periodSet)) {
    throw Error('API type error');
  }

  return periodSet;
};

const useDeletePositionPeriod = (
  setIsOpen: (arg: boolean) => void,
  setViewPeriod: (arg: PositionPeriod) => void,
): UseMutationResult<
  PeriodSet,
  AxiosError<ErrorResponse>,
  FormProps,
  undefined
> => {
  const queryClient = useQueryClient();

  return useMutation(deletePositionPeriod, {
    onSuccess: (data) => {
      queryClient.setQueryData('positionPeriods', data.positionPeriods);
      setViewPeriod(data.viewPeriod);
      setIsOpen(false);
    },
  });
};

export default useDeletePositionPeriod;
