import axios, { AxiosError } from 'axios';
import camelcaseKeys from 'camelcase-keys';
import format from 'date-fns/format';
import { SuccessOnly, isSuccessOnly } from 'domains/common';
import ErrorResponse from 'domains/common/models/error';
import { UseMutationResult, useMutation } from 'react-query';

export type FormProps = {
  taskName: string;
  description: string;
  dueDateTime: Date | null;
  ownerId: number;
};

const createTask = async (formData: FormProps): Promise<SuccessOnly> => {
  const response = await axios.post(
    `${process.env.REACT_APP_API_URL ?? ''}/api/task/spot/createTask`,
    {
      ...formData,
      dueDateTime: formData.dueDateTime
        ? format(formData.dueDateTime, 'yyyy-M-d H:mm')
        : null,
    },
  );

  const success = (await camelcaseKeys(response.data, {
    deep: true,
  })) as unknown;

  if (!isSuccessOnly(success)) {
    throw Error('API type error');
  }

  return success;
};

const useCreateTask = (
  reset: () => void,
): UseMutationResult<
  SuccessOnly,
  AxiosError<ErrorResponse>,
  FormProps,
  undefined
> =>
  useMutation(createTask, {
    onSuccess: () => {
      reset();
    },
  });

export default useCreateTask;
