import { VFC, useState, Dispatch, SetStateAction } from 'react';
import SnackBars from 'components/02.molecules/Common/SnackBars';
import BasicConfirmDialog from 'components/03.organisms/Common/BasicConfirmDialog';
import Button from '@material-ui/core/Button';
import {
  OrganizationPeriod,
  useDeleteTempOrganization,
} from 'domains/organization';
import { TreeState } from 'domains/common';

type Props = {
  activeOrgId: number;
  viewPeriod: OrganizationPeriod;
  setTreeState: Dispatch<SetStateAction<TreeState>>;
};

const DeleteButton: VFC<Props> = ({
  viewPeriod,
  activeOrgId,
  setTreeState,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const { mutate, status } = useDeleteTempOrganization(
    viewPeriod.id,
    setIsModalOpen,
    setTreeState,
  );
  const handleOk = () => {
    mutate({ periodId: viewPeriod.id, orgId: activeOrgId });
  };

  return (
    <>
      <Button
        variant="outlined"
        size="small"
        color="secondary"
        onClick={() => setIsModalOpen(true)}
      >
        削除
      </Button>
      <SnackBars
        status={status}
        loadingMsg="削除中..."
        successMsg="削除が完了しました"
        errorMsg="削除に失敗しました"
      />
      <BasicConfirmDialog
        title="組織の削除"
        confirmMsg="選択中の組織及びその下位組織を削除します。"
        isOpen={isModalOpen}
        handleOk={handleOk}
        handleCancel={handleCancel}
      />
    </>
  );
};

export default DeleteButton;
