import { forwardRef, MouseEventHandler, useState, VFC } from 'react';
import {
  useGetExistsNewModeData,
  useResetNewTempData,
} from 'domains/organization';
import ConfirmAnyActionDialog from 'components/03.organisms/Common/ConfirmAnyActionDialog';
import SnackBars from 'components/02.molecules/Common/SnackBars';
import Button from '@material-ui/core/Button';
import { useNavigate } from 'react-router-dom';
import EnhancedNewPeriodDialog from 'containers/02.molecules/Organization/Edit/NewPeriodDialog';
import CustomInputInDateSelect from 'components/01.atoms/Common/Basic/CustomInputInDateSelect';
import dateSelectWidth from 'baseCss/emotion/dateSelectWidth';

const NewPeriodJumpButton: VFC = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isSecondOpen, setIsSecondOpen] = useState(false);
  const [start, setStart] = useState(null as Date | null);

  const handleClose = () => {
    setIsOpen(false);
  };
  const navigate = useNavigate();
  const handleJump = () => {
    navigate('/system/organization/new');
  };

  const { data: hasTempData } = useGetExistsNewModeData(false);

  const {
    mutate,
    status,
    error = {
      response: {
        data: { errors: { start: '' } },
      },
    },
  } = useResetNewTempData(handleJump);
  const handleResetOk = () => {
    mutate({ start });
  };

  type CustomProps = {
    onClick?: MouseEventHandler<HTMLDivElement>;
  };

  const CustomStart = forwardRef<HTMLDivElement, CustomProps>(
    ({ onClick }, ref) => (
      <CustomInputInDateSelect
        addCss={dateSelectWidth}
        label="新しい期間の開始日"
        value={start}
        errorMsg={error?.response?.data?.errors.start}
        ref={ref}
        onClick={onClick}
      />
    ),
  );

  return (
    <>
      <Button
        variant="outlined"
        size="small"
        color="primary"
        onClick={() => setIsOpen(true)}
      >
        新規期間の作成
      </Button>
      <ConfirmAnyActionDialog
        isOpen={isOpen}
        anyElement={
          <>
            <Button
              fullWidth
              variant="text"
              color="default"
              disableElevation
              onClick={() => setIsSecondOpen(true)}
            >
              最初から編集を始める
            </Button>
            {hasTempData && (
              <Button
                fullWidth
                variant="text"
                color="default"
                disableElevation
                onClick={handleJump}
              >
                編集を再開する（編集中データを引継ぐ）
              </Button>
            )}
          </>
        }
        cancelButtonName="キャンセル"
        title="新規期間の作成への移行"
        confirmMsg="新しい組織管理期間の作成画面へ移行します。"
        handleCancel={handleClose}
      />
      <EnhancedNewPeriodDialog
        isOpen={isSecondOpen}
        handleOk={handleResetOk}
        handleCancel={() => setIsSecondOpen(false)}
        start={start}
        setStart={setStart}
        customStart={<CustomStart />}
      />
      <SnackBars
        status={status}
        loadingMsg="準備中..."
        successMsg="準備が完了しました"
        errorMsg="準備に失敗しました"
      />
    </>
  );
};

export default NewPeriodJumpButton;
