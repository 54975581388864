import { VFC, useState, useEffect, forwardRef, MouseEventHandler } from 'react';
import SnackBars from 'components/02.molecules/Common/SnackBars';
import EditPeriodDialog from 'containers/02.molecules/Organization/Edit/EditPeriodDialog';
import {
  OrganizationPeriod,
  useEditTempOrganizationPeriod,
} from 'domains/organization';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import { isSameDay, parse } from 'date-fns';
import { SYSTEM_USAGE_PERIOD } from 'utils/const';
import CustomInput from 'components/01.atoms/Common/Basic/CustomInputInDateSelect';
import BasicAlertDialog from 'components/03.organisms/Common/BasicAlertDialog';
import dateSelectWidth from 'baseCss/emotion/dateSelectWidth';

type Props = {
  viewPeriod: OrganizationPeriod;
  setViewPeriod: (arg: OrganizationPeriod) => void;
};

const EditPeriodButton: VFC<Props> = ({ viewPeriod, setViewPeriod }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isAlertOpen, setIsAlertOpen] = useState(false);
  const [start, setStart] = useState(null as Date | null);
  const [end, setEnd] = useState(null as Date | null);

  const resetForm = () => {
    setStart(null);
    setEnd(null);
  };
  useEffect(() => {
    resetForm();
  }, [viewPeriod]);
  const {
    mutate,
    status,
    reset: resetError,
    error = {
      response: {
        data: { errors: { start: '', end: '' } },
      },
    },
  } = useEditTempOrganizationPeriod(setIsOpen, setViewPeriod, resetForm);
  const handleOk = () => {
    mutate({
      periodId: viewPeriod.id,
      start,
      end,
    });
  };
  const handleCancel = () => {
    setIsOpen(false);
    resetError();
  };

  type CustomProps = {
    onClick?: MouseEventHandler<HTMLDivElement>;
  };

  const CustomStart = forwardRef<HTMLDivElement, CustomProps>(
    ({ onClick }, ref) => (
      <CustomInput
        addCss={dateSelectWidth}
        label="変更後の期間の開始日"
        value={start}
        errorMsg={error?.response?.data?.errors.start}
        ref={ref}
        onClick={onClick}
      />
    ),
  );
  const CustomEnd = forwardRef<HTMLDivElement, CustomProps>(
    ({ onClick }, ref) => (
      <CustomInput
        addCss={dateSelectWidth}
        label="変更後の期間の終了日"
        value={end}
        errorMsg={error?.response?.data?.errors.end}
        ref={ref}
        onClick={onClick}
      />
    ),
  );

  const isFirstPeriod = isSameDay(
    parse(viewPeriod.start, 'yyyy-MM-dd', new Date()),
    parse(SYSTEM_USAGE_PERIOD.START, 'yyyy/M/d', new Date()),
  );
  const isLastPeriod = isSameDay(
    parse(viewPeriod.end, 'yyyy-MM-dd', new Date()),
    parse(SYSTEM_USAGE_PERIOD.END, 'yyyy/M/d', new Date()),
  );
  const handleClick = () => {
    if (isFirstPeriod && isLastPeriod) {
      setIsAlertOpen(true);

      return;
    }

    setIsOpen(true);
  };

  return (
    <>
      <Tooltip title={<Typography variant="caption">期間の編集</Typography>}>
        <IconButton onClick={handleClick} aria-label="edit" color="primary">
          <EditIcon />
        </IconButton>
      </Tooltip>
      <EditPeriodDialog
        viewPeriod={viewPeriod}
        isOpen={isOpen}
        handleOk={handleOk}
        handleCancel={handleCancel}
        start={start}
        end={end}
        setStart={setStart}
        setEnd={setEnd}
        customStart={<CustomStart />}
        customEnd={<CustomEnd />}
      />
      <BasicAlertDialog
        title="変更不可の期間"
        confirmMsg={`組織管理期間データが1つのみの場合、\r\nその期間を変更することができません。`}
        isOpen={isAlertOpen}
        handleCancel={() => setIsAlertOpen(false)}
      />
      <SnackBars
        status={status}
        loadingMsg="変更中..."
        successMsg="変更が完了しました"
        errorMsg="変更に失敗しました"
      />
    </>
  );
};

export default EditPeriodButton;
