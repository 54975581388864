import { VFC, useState } from 'react';
import Button from '@material-ui/core/Button';
import SnackBars from 'components/02.molecules/Common/SnackBars';
import { PositionPeriod, useDeletePositionPeriod } from 'domains/position';
import DeletePeriodDialog from 'containers/02.molecules/Position/DeletePeriodDialog';

type Props = {
  viewPeriod: PositionPeriod;
  setViewPeriod: (arg: PositionPeriod) => void;
};

const DeletePeriodButton: VFC<Props> = ({ viewPeriod, setViewPeriod }) => {
  const [isOpen, setIsOpen] = useState(false);
  const {
    mutate,
    status,
    reset: resetError,
  } = useDeletePositionPeriod(setIsOpen, setViewPeriod);
  const handleOk = () => {
    mutate({
      periodId: viewPeriod.id,
    });
  };
  const handleCancel = () => {
    setIsOpen(false);
    resetError();
  };

  return (
    <>
      <Button
        fullWidth
        variant="outlined"
        color="secondary"
        disableElevation
        onClick={() => setIsOpen(true)}
      >
        期間の削除
      </Button>
      <DeletePeriodDialog
        isOpen={isOpen}
        handleOk={handleOk}
        handleCancel={handleCancel}
        viewPeriod={viewPeriod}
      />
      <SnackBars
        status={status}
        loadingMsg="削除中..."
        successMsg="削除が完了しました"
        errorMsg="削除に失敗しました"
      />
    </>
  );
};

export default DeletePeriodButton;
