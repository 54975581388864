import { FC } from 'react';
import { Navigate, Route, RouteProps } from 'react-router';
import { useCurrentUser } from 'domains/auth';

export const UnAuthRoute: FC<RouteProps> = ({
  path,
  element,
  children,
  caseSensitive,
}) => {
  const user = useCurrentUser();

  return user ? (
    <Route path={path} element={<Navigate to="/" />} />
  ) : (
    <Route path={path} element={element} caseSensitive={caseSensitive}>
      {children}
    </Route>
  );
};

export const AuthRoute: FC<RouteProps> = ({
  path,
  element,
  children,
  caseSensitive,
}) => {
  const user = useCurrentUser();

  return user ? (
    <Route path={path} element={element} caseSensitive={caseSensitive}>
      {children}
    </Route>
  ) : (
    <Route path={path} element={<Navigate to="/login" />} />
  );
};
