import { VFC, ReactElement } from 'react';
import { css } from '@emotion/react';
import Grid from '@material-ui/core/Grid';
import { Divider } from '@material-ui/core';

const overall = css`
  box-sizing: border-box;
  height: calc(100vh - 80px);
  padding: 1.5rem 1rem 0;
  overflow-y: auto;
`;

type Props = {
  basic: ReactElement;
  positions: ReactElement;
  mainOrgs: ReactElement;
  subOrgs: ReactElement;
};

const UserDetailsArea: VFC<Props> = ({
  basic,
  positions,
  mainOrgs,
  subOrgs,
}) => (
  <div>
    <Grid css={overall} container>
      <Grid
        item
        xs={4}
        css={css`
          padding-left: 10px;
          border-right: 1px solid rgba(0, 0, 0, 0.1);
        `}
      >
        <div>{basic}</div>
      </Grid>
      <Grid
        item
        xs={8}
        css={css`
          display: flex;
          flex-direction: column;
          row-gap: 20px;
          padding-top: 4px;
          padding-left: 20px;
        `}
      >
        <div>{positions}</div>
        <Divider
          light
          css={css`
            height: 0.01rem;
          `}
        />
        <div>{mainOrgs}</div>
        <Divider
          light
          css={css`
            height: 0.01rem;
          `}
        />
        <div>{subOrgs}</div>
      </Grid>
    </Grid>
  </div>
);

export default UserDetailsArea;
