import axios, { AxiosError } from 'axios';
import camelcaseKeys from 'camelcase-keys';
import format from 'date-fns/format';
import { isSuccessOnly, SuccessOnly } from 'domains/common';
import ErrorResponse from 'domains/common/models/error';
import { UseMutationResult, useMutation, useQueryClient } from 'react-query';

type FormProps = {
  userId: number;
  lastDate: Date | null;
};

const editLastDate = async (formData: FormProps): Promise<SuccessOnly> => {
  const response = await axios.post(
    `${process.env.REACT_APP_API_URL ?? ''}/api/system/user/editLastDate`,
    {
      userId: formData.userId,
      lastDate: formData.lastDate
        ? format(formData.lastDate, 'yyyy-M-d')
        : null,
    },
  );

  const success = (await camelcaseKeys(response.data, {
    deep: true,
  })) as unknown;

  if (!isSuccessOnly(success)) {
    throw Error('API type error');
  }

  return success;
};

const useEditLastDate = (
  userId: number,
  setIsOpen: (arg: boolean) => void,
): UseMutationResult<
  SuccessOnly,
  AxiosError<ErrorResponse>,
  FormProps,
  undefined
> => {
  const queryClient = useQueryClient();

  return useMutation(editLastDate, {
    onSuccess: async () => {
      await queryClient.invalidateQueries(['system', 'userDetail', userId]);
      void queryClient.invalidateQueries(['system', 'users']);
      setIsOpen(false);
    },
  });
};

export default useEditLastDate;
