import {
  VFC,
  useRef,
  useState,
  useEffect,
  LegacyRef,
  ChangeEvent,
} from 'react';
import FullCalendar, {
  DateSelectArg,
  EventClickArg,
  EventDropArg,
  EventInput,
} from '@fullcalendar/react';
import interaction, { EventResizeDoneArg } from '@fullcalendar/interaction';
import daygrid from '@fullcalendar/daygrid';
import timegrid from '@fullcalendar/timegrid';
import list from '@fullcalendar/list';
import { Global, css } from '@emotion/react';
import { format } from 'date-fns';
import {
  DailyWorkReport,
  getDailyWorkReport,
  useGetDailyAttachedInfo,
  WorkFormData,
} from 'domains/workReport';
import { MutationStatus, useQuery } from 'react-query';
import CircularProgress from '@material-ui/core/CircularProgress';
import SnackBars from 'components/02.molecules/Common/SnackBars';
import Typography from '@material-ui/core/Typography';
import { WORK_REPORT_STATUS } from 'utils/const';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import { UseFormReturn } from 'react-hook-form';
import WorkReportTable from 'components/02.molecules/WorkReport/Common/WorkReportTable';

const basicLabel = css`
  display: inline-block;
  flex-grow: inherit !important;
  margin: 0 0 1rem 0;
  font-size: 1.1em;
  font-weight: 700;
  color: rgba(0, 0, 0, 0.87);
  text-transform: none;
`;

const progressStyle = css`
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 1;
  margin-top: -45px;
  margin-left: -15px;
  color: rgb(158, 158, 158);
`;

type WorkReportInputProps = {
  events?: EventInput[];
  select: (arg: DateSelectArg) => void;
  eventClick: (arg: EventClickArg) => void;
  eventDrop: (arg: EventDropArg) => void;
  eventResize: (arg: EventResizeDoneArg) => void;
  viewDate: Date;
  statusWRT: MutationStatus;
  errorMsgWRDiv: string;
  setErrorMsgWRDiv: (arg: string) => void;
  setIsModalOpen: (arg: boolean) => void;
  workFormMethods: UseFormReturn<WorkFormData>;
};

const WorkReporLists: VFC<WorkReportInputProps> = ({
  viewDate,
  select,
  eventClick,
  eventDrop,
  eventResize,
  statusWRT,
  errorMsgWRDiv,
  setErrorMsgWRDiv,
  setIsModalOpen,
  workFormMethods,
}) => {
  const [events, setEvents] = useState([] as EventInput[]);
  const [isListMode, setIsListMode] = useState(false);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setIsListMode(event.target.checked);
  };

  const {
    data: attachedInfo = { status: 0, feedback: '' },
    status: statusDAI,
  } = useGetDailyAttachedInfo(viewDate, false);
  const calendarRef = useRef<FullCalendar>();

  const convertWorkReports = (original: DailyWorkReport[]): EventInput[] =>
    original.length === 0
      ? ([] as EventInput[])
      : (original.map((data) => ({
          id: data.id,
          start: data.startDatetime,
          end: data.endDatetime,
          title: data.description,
          extendedProps: {
            description: data.description,
            categoryId: data.categoryId,
            categoryName: data.categoryName,
            referenceTableId: data.referenceTableId,
          },
        })) as unknown as EventInput[]);

  const {
    data: workReportSet = { workReports: [], workingHours: '' },
    status,
  } = useQuery(
    ['workReports', format(viewDate, 'yyyy-M-d')],
    () => getDailyWorkReport({ viewDate: format(viewDate, 'yyyy-M-d') }),
    {
      suspense: false,
      onSuccess: (data) => {
        if (workReportSet !== data) {
          setEvents(convertWorkReports(data.workReports));
        }
        setErrorMsgWRDiv('');
      },
    },
  );

  useEffect(() => {
    if (status === 'success') {
      setEvents(convertWorkReports(workReportSet.workReports));
    }
  }, [workReportSet, status]);

  useEffect(() => {
    calendarRef?.current?.getApi()?.gotoDate(viewDate);
  }, [viewDate]);

  const isLoading =
    status === 'loading' || statusWRT === 'loading' || statusDAI === 'loading';

  const isSubmitted = attachedInfo.status >= WORK_REPORT_STATUS.SUBMITTED;

  return (
    <>
      <Global
        styles={css`
          ::-webkit-scrollbar {
            -webkit-appearance: none;
            width: 10px;
            height: 10px;
          }
          ::-webkit-scrollbar-thumb {
            cursor: pointer;
            background: rgba(0, 0, 0, 0.25);
            border-radius: 5px;
            transition: color 0.2s ease;
          }
          ::-webkit-scrollbar-track {
            background: rgba(0, 0, 0, 0.1);
            border-radius: 0;
          }
        `}
      />
      <div
        css={css`
          display: flex;
          justify-content: space-between;
        `}
      >
        <div
          css={css`
            display: inline-block;
          `}
        >
          <span css={basicLabel}>業務内容</span>
          {errorMsgWRDiv && (
            <FormHelperText
              error
              css={css`
                display: inline-block;
                padding-left: 8px;
              `}
            >
              {errorMsgWRDiv}
            </FormHelperText>
          )}
        </div>
        <FormControlLabel
          control={
            <Switch
              checked={isListMode}
              onChange={handleChange}
              name="isListMode"
              color="primary"
            />
          }
          label="リスト表示"
        />
      </div>
      <div
        css={css`
          position: relative;
          height: 90%;
        `}
      >
        {isListMode ? (
          <WorkReportTable
            rows={workReportSet.workReports}
            viewDate={viewDate}
            setIsModalOpen={setIsModalOpen}
            workFormMethods={workFormMethods}
          />
        ) : (
          <FullCalendar
            plugins={[interaction, daygrid, timegrid, list]}
            locale="ja"
            timeZone="local"
            initialView="timeGridDay"
            initialDate={viewDate}
            ref={calendarRef as LegacyRef<FullCalendar>}
            nowIndicator
            eventTextColor="#ffffff"
            height="100%"
            // contentHeight="auto"
            headerToolbar={false}
            /* headerToolbar={{
      start: "title",
      center: "",
      end: "today timeGridDay,listDay prev,next",
    }} */
            dayHeaders={false}
            allDaySlot={false}
            slotLabelFormat={{
              hour: 'numeric',
              minute: '2-digit',
              omitZeroMinute: false,
              hour12: false,
              meridiem: 'short',
            }}
            slotDuration="00:15:00"
            businessHours={{
              daysOfWeek: [0, 1, 2, 3, 4, 5, 6],
              startTime: process.env.REACT_APP_BUSINESS_HOUR_START,
              endTime: process.env.REACT_APP_BUSINESS_HOUR_END,
            }}
            slotMinTime={process.env.REACT_APP_BUSINESS_HOUR_START}
            slotMaxTime={process.env.REACT_APP_BUSINESS_HOUR_END}
            eventConstraint="businessHours"
            selectConstraint="businessHours"
            selectable={!isLoading && !isSubmitted}
            editable={!isLoading && !isSubmitted}
            eventResizableFromStart
            eventOverlap={false}
            selectOverlap={false}
            slotEventOverlap={false}
            selectMirror={false}
            select={select}
            eventClick={eventClick}
            eventDrop={eventDrop}
            eventResize={eventResize}
            events={events}
          />
        )}
        <div
          css={css`
            display: flex;
            justify-content: flex-end;
            padding-top: 10px;
            padding-right: 10px;
            padding-bottom: 10px;
          `}
        >
          <Typography
            gutterBottom
            variant="h6"
            css={css`
              color: #515151;
            `}
          >
            業務時間 {workReportSet?.workingHours || 'なし'}
          </Typography>
        </div>
        {isLoading && <CircularProgress size={50} css={progressStyle} />}
      </div>
      <SnackBars
        status={statusWRT}
        loadingMsg="更新中..."
        successMsg="更新が完了しました"
        errorMsg="更新に失敗しました"
      />
    </>
  );
};

export default WorkReporLists;
