import { VFC, MouseEvent } from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { Link } from 'react-router-dom';
import absoluteCenter from 'baseCss/emotion/absoluteCenter';
import { css } from '@emotion/react';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import ListSubheader from '@material-ui/core/ListSubheader';
import { RiComputerLine, RiLock2Fill } from 'react-icons/ri';
import { FaSignOutAlt } from 'react-icons/fa';
import { GoTriangleDown } from 'react-icons/go';

const brandStyle = css`
  padding-bottom: 0.3125rem;
  font-size: 1.2rem;
  line-height: inherit;
  color: black;
  text-decoration: none;
  &:hover {
    color: black;
  }
`;

const spaceBetween = css`
  justify-content: space-between;
`;

const iconMargin = css`
  padding-right: 0.5em;
`;

const inlineFlex = css`
  display: inline-flex;
  column-gap: 0.5em;
`;

type Props = {
  userName?: string;
  anchorEl: HTMLElement | null;
  handleOpen: (event: MouseEvent<HTMLButtonElement>) => void;
  handleClose: () => void;
  handleLogout: () => void;
  handleStartEditPassword: () => void;
  handleJumpToSystem: () => void;
};

const Header: VFC<Props> = ({
  userName = 'ユーザー名未取得',
  anchorEl,
  handleOpen,
  handleClose,
  handleLogout,
  handleStartEditPassword,
  handleJumpToSystem,
}) => (
  <AppBar
    position="sticky"
    css={css`
      background-color: white;
    `}
  >
    <Toolbar css={spaceBetween}>
      <Link css={[absoluteCenter, brandStyle]} to="/">
        {process.env.REACT_APP_SYSTEM_NAME}
      </Link>
      <Typography variant="h6" color="textPrimary">
        {process.env.REACT_APP_CORPORATE_NAME}
      </Typography>
      <div css={inlineFlex}>
        <Button
          aria-controls="header-menu"
          aria-haspopup="true"
          onClick={handleOpen}
        >
          <GoTriangleDown size="1.2em" css={iconMargin} />
          {userName}
        </Button>
        <Menu
          id="header-menu"
          anchorEl={anchorEl}
          getContentAnchorEl={null}
          keepMounted
          anchorOrigin={{
            vertical: 45,
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          open={Boolean(anchorEl)}
          onClose={handleClose}
          disableScrollLock
        >
          <MenuItem onClick={handleLogout}>
            <FaSignOutAlt size="1.3em" css={iconMargin} />
            ログアウト
          </MenuItem>
          <MenuItem onClick={handleStartEditPassword}>
            <RiLock2Fill size="1.3em" css={iconMargin} />
            パスワードの編集
          </MenuItem>
          <Divider light />
          <ListSubheader>システム管理者用</ListSubheader>
          <MenuItem onClick={handleJumpToSystem}>
            <RiComputerLine size="1.3em" css={iconMargin} />
            システム管理画面へ移動
          </MenuItem>
        </Menu>
      </div>
    </Toolbar>
  </AppBar>
);

export default Header;
