import axios from 'axios';
import camelcaseKeys from 'camelcase-keys';
import { WorkCategory, isWorkCategories } from '../models/workCategory';

export type FormData = {
  filterName?: string;
};

const getWorkCategories = async (
  formData: FormData,
): Promise<WorkCategory[]> => {
  const response = await axios.post(
    `${process.env.REACT_APP_API_URL ?? ''}/api/workReport/getWorkCategories`,
    formData,
  );
  const workCategories = (await camelcaseKeys(response.data, {
    deep: true,
  })) as unknown[];

  if (!isWorkCategories(workCategories)) {
    throw Error('API type error');
  }

  return workCategories;
};

export default getWorkCategories;
