import { FC, MouseEventHandler } from 'react';
import { css } from '@emotion/react';
import BasicButton from 'components/01.atoms/Common/Basic/BasicButton';

const addCss = css`
  width: 100%;
  font-family: Calibri, 'Yu Gothic UI', serif;
  font-size: 1.2rem;
  letter-spacing: 0.1rem;
`;

export type ButtonProps = {
  onClick?: MouseEventHandler<HTMLButtonElement>;
};

const HomeButton: FC<ButtonProps> = ({ onClick, children }) => (
  <BasicButton addCss={addCss} isPost={false} onClick={onClick}>
    {children}
  </BasicButton>
);

export default HomeButton;
