import { VFC } from 'react';
import CommonHeader from 'containers/03.organisms/Common/CommonHeader';
import HomeButtonGroup from 'containers/02.molecules/Home/HomeButtonGroup';
import { format } from 'date-fns';
import ja from 'date-fns/locale/ja';
import { css } from '@emotion/react';
import ApproachingTaskArea from 'containers/03.organisms/Home/ApproachingTaskArea';

const gridStyle = css`
  display: grid;
  grid-template-rows: 85px repeat(2, 300px) repeat(2, auto);
  grid-template-columns: max-content 1fr;
  grid-auto-flow: dense;
  grid-gap: 20px;
  & > div:nth-of-type(1) {
    grid-row: span 1;
    grid-column: span 2;
    align-self: center;
    justify-self: center;
    margin-top: 20px;
    font-family: Calibri, 'Yu Gothic UI', serif;
    font-size: 22px;
    transform: scale(1, 1.15);
  }
  & > div:nth-of-type(2) {
    grid-row: span 2;
    grid-column: span 1;
    justify-self: center;
    width: 400px;
  }
  & > div:nth-of-type(3) {
    grid-row: span 1;
    grid-column: span 1;
  }
  & > div:nth-of-type(4) {
    grid-row: span 1;
    grid-column: span 1;
  }
  & > div:nth-of-type(5) {
    grid-row: span 1;
    grid-column: span 2;
    max-height: 500px;
  }
  & > div:nth-of-type(6) {
    grid-row: span 1;
    grid-column: span 2;
  }
`;

const DashBoardPage: VFC = () => {
  const dateShow = format(new Date(), 'yyyy年M月d日 EE曜日', { locale: ja });

  return (
    <>
      <CommonHeader />
      <div css={gridStyle}>
        <div>{dateShow}</div>
        <HomeButtonGroup />
        <ApproachingTaskArea />
        <div />
        <div />
        <div />
      </div>
    </>
  );
};
export default DashBoardPage;
