import { VFC } from 'react';
import MainContentsDetails from 'components/03.organisms/Organization/MainContentsDetails';
import {
  OrganizationPeriod,
  useGetOrganizationDetail,
} from 'domains/organization';
import BasicDetailList from 'containers/02.molecules/Organization/BasicDetailList';
import Skeleton from '@material-ui/lab/Skeleton';
import LeaderDetailList from 'containers/02.molecules/Organization/LeaderDetailList';
import MemberDetailList from 'containers/02.molecules/Organization/MemberDetailList';
import SubOrgDetailList from 'containers/02.molecules/Organization/SubOrgDetailList';
import { css } from '@emotion/react';

type Props = {
  activeOrgId: number;
  viewPeriod: OrganizationPeriod;
};

const EnhancedMainContentsDetails: VFC<Props> = ({
  activeOrgId,
  viewPeriod,
}) => {
  const {
    data: detail = {
      basic: {
        orgId: 0,
        periodId: 0,
        name: '',
        masterName: '',
        orgType: 0,
      },
      leaders: [],
      members: [],
      subOrgs: [],
    },
    isLoading,
  } = useGetOrganizationDetail(activeOrgId, viewPeriod.id, false);

  return (
    <>
      {isLoading ? (
        <Skeleton
          variant="rect"
          css={css`
            width: 100%;
            height: 100%;
          `}
        />
      ) : (
        <MainContentsDetails
          orgType={detail.basic.orgType}
          basic={<BasicDetailList basic={detail.basic} />}
          leaders={<LeaderDetailList leaders={detail.leaders} />}
          members={<MemberDetailList members={detail.members} />}
          subOrgs={<SubOrgDetailList subOrgs={detail.subOrgs} />}
        />
      )}
    </>
  );
};

export default EnhancedMainContentsDetails;
