import { VFC, MouseEventHandler } from 'react';
import { css } from '@emotion/react';
import TreeNode from './TreeNode';

const nodeArea = css`
  &[data-org-type='2'] > :first-of-type {
    border-right: 8px ridge #6eaaf5;
  }
`;

const nodeBlock = css`
  color: #fff;
  background-color: #1976d2;
  border-color: #1976d2;
`;

export type Props = {
  orgId?: number;
  orgName?: string;
  canNextLoop?: boolean;
  hasSubOrg?: boolean;
  depthLevel?: number;
  orgType?: number;
  rowSpan: number;
  gridColumn: string;
  canExpand?: boolean;
  canFold?: boolean;
  onClick?: MouseEventHandler<HTMLDivElement>;
  onMouseEnter?: MouseEventHandler<HTMLDivElement>;
  onMouseLeave?: MouseEventHandler<HTMLDivElement>;
  handleExpand?: MouseEventHandler<HTMLButtonElement>;
  handleFold?: MouseEventHandler<HTMLButtonElement>;
};

const TreeNodeActive: VFC<Props> = ({
  orgId,
  orgName,
  canNextLoop,
  hasSubOrg,
  depthLevel,
  rowSpan,
  gridColumn,
  orgType,
  canExpand,
  canFold,
  onClick,
  onMouseEnter,
  onMouseLeave,
  handleExpand,
  handleFold,
}) => (
  <TreeNode
    orgId={orgId}
    orgName={orgName}
    canNextLoop={canNextLoop}
    hasSubOrg={hasSubOrg}
    depthLevel={depthLevel}
    orgType={orgType}
    rowSpan={rowSpan}
    gridColumn={gridColumn}
    canExpand={canExpand}
    canFold={canFold}
    onClick={onClick}
    onMouseEnter={onMouseEnter}
    onMouseLeave={onMouseLeave}
    handleExpand={handleExpand}
    handleFold={handleFold}
    addAreaCss={nodeArea}
    addBlockCss={nodeBlock}
  />
);

export default TreeNodeActive;
