import { Dispatch, SetStateAction, VFC } from 'react';
import MainContentsHeader from 'components/03.organisms/Organization/MainContentsHeader';
import { OrganizationPeriod } from 'domains/organization';
import { TreeState } from 'domains/common';

type Props = {
  viewPeriod: OrganizationPeriod;
  setViewPeriod: (arg: OrganizationPeriod) => void;
  setTreeState: Dispatch<SetStateAction<TreeState>>;
};

const EnhancedMainContentsHeader: VFC<Props> = ({
  viewPeriod,
  setViewPeriod,
  setTreeState,
}) => (
  <MainContentsHeader
    viewPeriod={viewPeriod}
    setViewPeriod={setViewPeriod}
    setTreeState={setTreeState}
  />
);

export default EnhancedMainContentsHeader;
