import { PositionPeriod, isPositionPeriod, isPositionPeriods } from './period';

export type PeriodSet = {
  viewPeriod: PositionPeriod;
  positionPeriods: PositionPeriod[];
};

const isPeriodSet = (arg: unknown): arg is PeriodSet => {
  const p = arg as PeriodSet;

  return (
    typeof isPositionPeriod(p?.viewPeriod) &&
    isPositionPeriods(p?.positionPeriods)
  );
};

export { isPeriodSet };
