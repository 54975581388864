import axios, { AxiosError } from 'axios';
import camelcaseKeys from 'camelcase-keys';
import { format } from 'date-fns';
import ErrorResponse from 'domains/common/models/error';
import { UseFormReset } from 'react-hook-form';
import { UseMutationResult, useMutation, useQueryClient } from 'react-query';
import { OrganizationPeriod } from '../models/period';
import { PeriodSet, isPeriodSet } from '../models/periodSet';

type FormProps = {
  periodId: number;
  start: Date | null;
  end: Date | null;
};

const editTempOrganizationPeriod = async (
  formData: FormProps,
): Promise<PeriodSet> => {
  const response = await axios.post(
    `${
      process.env.REACT_APP_API_URL ?? ''
    }/api/system/organization/editTempOrganizationPeriod`,
    {
      periodId: formData.periodId,
      start: formData.start ? format(formData.start, 'yyyy-M-d') : null,
      end: formData.end ? format(formData.end, 'yyyy-M-d') : null,
    },
  );

  const periodSet = (await camelcaseKeys(response.data, {
    deep: true,
  })) as unknown;

  if (!isPeriodSet(periodSet)) {
    throw Error('API type error');
  }

  return periodSet;
};

const useEditTempOrganizationPeriod = (
  setIsOpen: (arg: boolean) => void,
  setViewPeriod: (arg: OrganizationPeriod) => void,
  formReset: UseFormReset<FormProps>,
): UseMutationResult<
  PeriodSet,
  AxiosError<ErrorResponse>,
  FormProps,
  undefined
> => {
  const queryClient = useQueryClient();

  return useMutation(editTempOrganizationPeriod, {
    onSuccess: (data) => {
      queryClient.setQueryData('organizationPeriods', data.organizationPeriods);
      setViewPeriod(data.viewPeriod);
      setIsOpen(false);
      formReset();
    },
  });
};

export default useEditTempOrganizationPeriod;
