import axios, { AxiosError } from 'axios';
import camelcaseKeys from 'camelcase-keys';
import { useQuery, UseQueryResult } from 'react-query';
import { OrganizationPeriod, isOrganizationPeriod } from '../models/period';

const getNewTempPeriod = async (): Promise<OrganizationPeriod> => {
  const response = await axios.get(
    `${
      process.env.REACT_APP_API_URL ?? ''
    }/api/system/organization/getNewTempPeriod`,
  );

  const data = (await camelcaseKeys(response.data, {
    deep: true,
  })) as unknown;

  if (!isOrganizationPeriod(data)) {
    throw Error('API type error');
  }

  return data;
};

const useGetNewTempPeriod = (
  isSuspense: boolean,
): UseQueryResult<OrganizationPeriod, AxiosError> =>
  useQuery(
    ['system', 'organizations', 'new', 'viewPeriod'],
    () => getNewTempPeriod(),
    {
      suspense: isSuspense,
    },
  );

export default useGetNewTempPeriod;
