import { Dispatch, SetStateAction, useEffect, VFC } from 'react';
import { OrganizationUserState } from 'domains/user';
import { parse } from 'date-fns';
import OrganizationUserInputDataGrid from 'components/02.molecules/User/OrganizationUserInputDataGrid';
import { SYSTEM_USAGE_PERIOD } from 'utils/const';

type Props = {
  scopeStart: Date | null;
  scopeEnd?: Date | null;
  setUsersState: Dispatch<SetStateAction<OrganizationUserState[]>>;
  usersState: OrganizationUserState[];
  emptyMessage: string;
};

const EnhancedOrganizationUserInputDataGrid: VFC<Props> = ({
  scopeStart,
  scopeEnd,
  usersState,
  setUsersState,
  emptyMessage,
}) => {
  useEffect(() => {
    setUsersState([]);
    if (scopeStart) {
      setUsersState([
        {
          start: scopeStart,
          end:
            scopeEnd ?? parse(SYSTEM_USAGE_PERIOD.END, 'yyyy/M/d', new Date()),
          isContinued: true,
          rowNumber: 0,
          userId: 0,
          userName: '',
          isMain: true,
        },
      ]);
    }
  }, [scopeStart, scopeEnd, setUsersState]);

  return (
    <OrganizationUserInputDataGrid
      rows={usersState}
      setOrganizationState={setUsersState}
      scopeEnd={scopeEnd}
      emptyMessage={emptyMessage}
    />
  );
};

export default EnhancedOrganizationUserInputDataGrid;
