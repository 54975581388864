export type BasicDetail = {
  orgId: number;
  periodId: number;
  name: string;
  masterName: string;
  orgType: number;
};
const isBasicDetail = (arg: unknown): arg is BasicDetail => {
  const o = arg as BasicDetail;

  return (
    typeof o?.orgId === 'number' &&
    typeof o?.periodId === 'number' &&
    typeof o?.name === 'string' &&
    typeof o?.masterName === 'string' &&
    typeof o?.orgType === 'number'
  );
};

const isBasicDetails = (args: unknown[]): args is BasicDetail[] =>
  !args.some((arg) => !isBasicDetail(arg));

export { isBasicDetail, isBasicDetails };
