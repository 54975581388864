import axios, { AxiosError } from 'axios';
import camelcaseKeys from 'camelcase-keys';
import format from 'date-fns/format';
import ErrorResponse from 'domains/common/models/error';
import { UseMutationResult, useMutation, useQueryClient } from 'react-query';
import { OrganizationState } from '../models/orgOption';
import { PositionState } from '../models/positionOption';
import { isUsers, User } from '../models/user';

type FormProps = {
  employeeNumber: string;
  name: string;
  nameKana: string;
  email: string;
  joiningDate: Date | null;
  authorityLevel: number;
  positions: PositionState[];
  mainOrgs: OrganizationState[];
};

const addUser = async (formData: FormProps): Promise<User[]> => {
  const mainOrgs = formData.mainOrgs.map((data) => ({
    ...data,
    start: data.start ? format(data.start, 'yyyy-M-d') : null,
    end: data.end ? format(data.end, 'yyyy-M-d') : null,
  }));

  const response = await axios.post(
    `${process.env.REACT_APP_API_URL ?? ''}/api/system/user/addUser`,
    {
      ...formData,
      mainOrgs,
      joiningDate: formData.joiningDate
        ? format(formData.joiningDate, 'yyyy-M-d')
        : null,
    },
  );

  const users = (await camelcaseKeys(response.data, {
    deep: true,
  })) as unknown[];

  if (!isUsers(users)) {
    throw Error('API type error');
  }

  return users;
};

const useAddUser = (
  setIsOpen: (arg: boolean) => void,
  reset: () => void,
): UseMutationResult<
  User[],
  AxiosError<ErrorResponse>,
  FormProps,
  undefined
> => {
  const queryClient = useQueryClient();

  return useMutation(addUser, {
    onSuccess: (data) => {
      queryClient.setQueryData(['system', 'users'], data);
      setIsOpen(false);
      reset();
    },
  });
};

export default useAddUser;
