import { WorkingHours } from 'domains/workReport/models/workingHours';
import {
  DailyWorkReport,
  isDailyWorkReports,
} from 'domains/workReport/models/dailyWorkReport';

export type DailyWorkReportSet = WorkingHours & {
  workReports: DailyWorkReport[];
};

const isDailyWorkReportSet = (arg: unknown): arg is DailyWorkReportSet => {
  const d = arg as DailyWorkReportSet;

  return (
    typeof d?.workingHours === 'string' && isDailyWorkReports(d?.workReports)
  );
};

export { isDailyWorkReportSet };
