import { VFC } from 'react';
import PresentationPage from 'components/05.pages/Common/SideAndMainPage';
import HeaderBar from 'containers/03.organisms/Common/CommonHeader';
import SideMenu from 'containers/03.organisms/WorkReport/Common/CommonSideMenu';
import InputContents from 'containers/03.organisms/WorkReport/Input/MainContentsArea';
import ApproveContents from 'containers/03.organisms/WorkReport/Approve/MainContentsArea';
import { Route, Routes } from 'react-router-dom';

const EnhancedWorkReportPage: VFC = () => (
  <PresentationPage headerBar={<HeaderBar />} sideMenu={<SideMenu />}>
    <Routes>
      <Route path="/input" element={<InputContents />} />
      <Route path="/approve" element={<ApproveContents />} />
    </Routes>
  </PresentationPage>
);

export default EnhancedWorkReportPage;
