import { Dispatch, SetStateAction, useEffect, VFC } from 'react';
import PositionInputDataGrid from 'components/02.molecules/User/PositionInputDataGrid';
import { PositionState, useGetPositionOptions } from 'domains/user';

type Props = {
  joiningDate: Date | null;
  setPositionState: Dispatch<SetStateAction<PositionState[]>>;
  positionState: PositionState[];
};

const EnhancedPositionInputDataGrid: VFC<Props> = ({
  joiningDate,
  positionState,
  setPositionState,
}) => {
  const { mutate, isLoading } = useGetPositionOptions(
    joiningDate,
    setPositionState,
  );

  useEffect(() => {
    if (joiningDate) {
      mutate({ joiningDate });
    }
  }, [joiningDate, mutate]);

  return (
    <PositionInputDataGrid
      rows={positionState}
      isLoading={isLoading}
      setPositionState={setPositionState}
      emptyMessage="役職選択のために入社日の選択が必要です。"
    />
  );
};

export default EnhancedPositionInputDataGrid;
