import { VFC, MouseEvent } from 'react';
import { css } from '@emotion/react';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';

const buttonStyle = css`
  padding: 5px 10px;
`;

type Props = {
  isMain: boolean;
  handleChange: (event: MouseEvent<HTMLElement>, newValue: number) => void;
};

const IsMainToggleButtons: VFC<Props> = ({ isMain, handleChange }) => (
  <ToggleButtonGroup
    value={Number(isMain)}
    exclusive
    onChange={handleChange}
    aria-label="isMainSwitch"
  >
    <ToggleButton value={1} aria-label="isMain" css={buttonStyle}>
      主務
    </ToggleButton>
    <ToggleButton value={0} aria-label="isSub" css={buttonStyle}>
      兼務
    </ToggleButton>
  </ToggleButtonGroup>
);

export default IsMainToggleButtons;
