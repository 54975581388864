import { useState, VFC } from 'react';
import { useForm } from 'react-hook-form';
import MainContentsMainArea from 'containers/03.organisms/WorkReport/Approve/MainContentsMainArea';
import MainContentsHeader from 'components/03.organisms/WorkReport/Approve/MainContentsHeader';
import MainContentsSideArea from 'components/03.organisms/WorkReport/Approve/MainContentsSideArea';
import { OtherFormData } from 'domains/workReport';
import MainContentsArea from 'components/03.organisms/Common/MainContentsAreaPatternA';
import StaffSelectDialog from 'containers/02.molecules/WorkReport/Approve/StaffSelectDialog';
import { useLocation } from 'react-router-dom';

const EnhancedMainContentsArea: VFC = () => {
  type CustomizedState = {
    viewDate: Date;
    viewStaffId: number;
  };
  const location = useLocation();
  const state = location.state as CustomizedState;
  const initialPeriod =
    state ??
    ({
      viewDate: new Date(),
      viewStaffId: 0,
    } as CustomizedState);
  const [viewDate, setViewDate] = useState(initialPeriod.viewDate);
  const [viewStaffId, setViewStaffId] = useState(initialPeriod.viewStaffId);

  const otherFormMethods = useForm<OtherFormData>({
    shouldUnregister: false,
  });

  return (
    <>
      {viewStaffId ? (
        <MainContentsArea
          headerArea={<MainContentsHeader viewDate={viewDate} />}
          sideArea={
            <MainContentsSideArea
              viewDate={viewDate}
              setViewDate={setViewDate}
              viewStaffId={viewStaffId}
              setViewStaffId={setViewStaffId}
            />
          }
          mainArea={
            <MainContentsMainArea
              viewDate={viewDate}
              otherFormMethods={otherFormMethods}
              viewStaffId={viewStaffId}
            />
          }
        />
      ) : (
        <>
          <MainContentsArea
            headerArea={<MainContentsHeader viewDate={viewDate} />}
            sideArea={<div />}
            mainArea={<div />}
          />
          <StaffSelectDialog setViewStaffId={setViewStaffId} />
        </>
      )}
    </>
  );
};

export default EnhancedMainContentsArea;
