import axios, { AxiosError } from 'axios';
import camelcaseKeys from 'camelcase-keys';
import { SuccessOnly, isSuccessOnly } from 'domains/common';
import ErrorResponse from 'domains/common/models/error';
import { UseMutationResult, useMutation } from 'react-query';

type FormProps = {
  taskId: number;
};

const cancelCompleted = async (formData: FormProps): Promise<SuccessOnly> => {
  const response = await axios.post(
    `${process.env.REACT_APP_API_URL ?? ''}/api/task/project/cancelCompleted`,
    formData,
  );

  const success = (await camelcaseKeys(response.data, {
    deep: true,
  })) as unknown;

  if (!isSuccessOnly(success)) {
    throw Error('API type error');
  }

  return success;
};

const useCancelCompleted = (
  handleSuccess: () => Promise<void>,
): UseMutationResult<
  SuccessOnly,
  AxiosError<ErrorResponse>,
  FormProps,
  undefined
> =>
  useMutation(cancelCompleted, {
    onSuccess: handleSuccess,
  });

export default useCancelCompleted;
