import { VFC, useState } from 'react';
import UserTable from 'components/02.molecules/User/UserTable';
import BasicConfirmDialog from 'components/03.organisms/Common/BasicConfirmDialog';
import { useForm } from 'react-hook-form';
import { NumOnlyForm } from 'domains/common';
import SnackBars from 'components/02.molecules/Common/SnackBars';
import { useDeleteUser, useGetUsers } from 'domains/user';
import UserDetailDialog from 'containers/03.organisms/User/UserDetailDialog';

const EnhancedUserTable: VFC = () => {
  const { data: users = [], isLoading } = useGetUsers(false);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [isDetailOpen, setIsDetailOpen] = useState(false);
  const [detailViewUserId, setDetailViewUserId] = useState(0);
  const { mutate: mutateDEL, status: statusDEL } = useDeleteUser(
    setIsConfirmModalOpen,
  );
  const { getValues, setValue: setValueDEL } = useForm<NumOnlyForm>();
  const handleOk = () => {
    mutateDEL({
      userId: getValues('userId'),
    });
  };
  const handleCancel = () => {
    setIsConfirmModalOpen(false);
  };
  const handleDelete = (userId: number) => {
    setValueDEL('userId', userId);
    setIsConfirmModalOpen(true);
  };
  const handleCheckDetail = (userId: number) => {
    setDetailViewUserId(userId);
    setIsDetailOpen(true);
  };

  return (
    <>
      <UserTable
        rows={users}
        handleDelete={handleDelete}
        handleCheckDetail={handleCheckDetail}
        isLoading={isLoading}
      />
      <BasicConfirmDialog
        title="ユーザーの削除"
        confirmMsg="選択中のユーザーを削除しますか？"
        isOpen={isConfirmModalOpen}
        handleOk={handleOk}
        handleCancel={handleCancel}
      />
      <SnackBars
        status={statusDEL}
        loadingMsg="削除中..."
        successMsg="削除が完了しました"
        errorMsg="削除に失敗しました"
      />
      {!!detailViewUserId && (
        <UserDetailDialog
          isOpen={isDetailOpen}
          handleCancel={() => setIsDetailOpen(false)}
          viewUserId={detailViewUserId}
        />
      )}
    </>
  );
};

export default EnhancedUserTable;
