import { VFC, ReactElement } from 'react';
import { css } from '@emotion/react';

const overall = css`
  display: inline-flex;
  padding: 0 0.3rem 0;
  margin: 0;
  font-family: Lato, 'Helvetica Neue', Arial, Helvetica, sans-serif;
  font-weight: 700;
  line-height: 1.28571429em;
  color: rgba(0, 0, 0, 0.87);
  text-transform: none;
  border: none;

  & > svg:first-of-type {
    place-self: center;
    display: table-cell;
    width: 1.18em;
    height: 1em;
    padding-top: 0;
    margin: 0 0.25rem 0 0;
    font-size: 1.5em;
    font-style: normal;
    font-weight: 400;
    text-align: center;
    text-decoration: inherit;
    vertical-align: middle;
    opacity: 1;
    speak: none;
    -webkit-font-smoothing: antialiased;
    backface-visibility: hidden;
  }
`;

const titleStyle = css`
  display: table-cell;
  padding-left: 0.35rem;
  font-size: 1.71428571rem;
  vertical-align: middle;
`;

const subHeader = css`
  display: block;
  padding: 0;
  margin: 0;
  font-size: 1.14285714rem;
  font-weight: 400;
  line-height: 1.2em;
  color: rgba(0, 0, 0, 0.6);
`;

export type ButtonProps = {
  icon: ReactElement;
  title: string;
  description: string;
};

const IconHeader: VFC<ButtonProps> = ({ icon, title, description }) => (
  <h2 css={overall}>
    {icon}
    <div css={titleStyle}>
      {title}
      <div css={subHeader}>{description}</div>
    </div>
  </h2>
);

export default IconHeader;
