import { format } from 'date-fns';
import ja from 'date-fns/locale/ja';

const convertDateHourMinute = (data: Date | string): string => {
  if (typeof data === 'string') {
    return format(new Date(data), 'yyyy年M月d日(EE) H:mm', { locale: ja });
  }

  return format(data, 'yyyy年M月d日(EE) H:mm', { locale: ja });
};

export default convertDateHourMinute;
