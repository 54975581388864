import {
  OrganizationPeriod,
  isOrganizationPeriod,
  isOrganizationPeriods,
} from './period';

export type PeriodSet = {
  viewPeriod: OrganizationPeriod;
  organizationPeriods: OrganizationPeriod[];
};

const isPeriodSet = (arg: unknown): arg is PeriodSet => {
  const p = arg as PeriodSet;

  return (
    typeof isOrganizationPeriod(p?.viewPeriod) &&
    isOrganizationPeriods(p?.organizationPeriods)
  );
};

export { isPeriodSet };
