import { VFC, useState } from 'react';
import {
  PositionPeriod,
  useDeletePosition,
  useUpdatePosition,
  useGetPositions,
} from 'domains/position';
import PositionTable from 'components/02.molecules/Position/PositionTable';
import BasicConfirmDialog from 'components/03.organisms/Common/BasicConfirmDialog';
import { useForm } from 'react-hook-form';
import { NumOnlyForm, StrOrNumForm } from 'domains/common';
import BasicConfirmFormDialog from 'components/03.organisms/Common/BasicConfirmFormDialog';
import FormTextField from 'containers/01.atoms/Common/FormTextFiled';
import SnackBars from 'components/02.molecules/Common/SnackBars';

type Props = {
  viewPeriod: PositionPeriod;
};

const EnhancedPositionTable: VFC<Props> = ({ viewPeriod }) => {
  const { data: positions = [], isLoading } = useGetPositions(
    viewPeriod,
    false,
  );
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const { mutate: mutateDEL, status: statusDEL } = useDeletePosition(
    viewPeriod.id,
    setIsConfirmModalOpen,
  );
  const { getValues, setValue: setValueDEL } = useForm<NumOnlyForm>();
  const handleOk = () => {
    mutateDEL({
      periodId: viewPeriod.id,
      positionId: getValues('positionId'),
    });
  };
  const handleCancel = () => {
    setIsConfirmModalOpen(false);
  };
  const handleDelete = (positionId: number) => {
    setValueDEL('positionId', positionId);
    setIsConfirmModalOpen(true);
  };
  const [isInputModalOpen, setIsInputModalOpen] = useState(false);
  const {
    getValues: getValuesUPD,
    control: controlUPD,
    setValue: setValueUPD,
    reset: resetUPD,
  } = useForm<StrOrNumForm>();
  const {
    mutate: mutateUPD,
    status: statusUPD,
    reset: resetErrorUPD,
    error: errorUPD = {
      response: {
        data: { errors: { positionName: '' } },
      },
    },
  } = useUpdatePosition(viewPeriod.id, setIsInputModalOpen, resetUPD);
  const handleUpdateOk = () => {
    mutateUPD({
      periodId: viewPeriod.id,
      positionId: Number(getValuesUPD('positionId')),
      positionName: String(getValuesUPD('positionName')),
    });
  };
  const handleUpdateCancel = () => {
    setIsInputModalOpen(false);
    resetErrorUPD();
  };
  type EditProps = {
    positionId: number;
    positionName: string;
  };
  const handleEdit = ({ positionId, positionName }: EditProps) => {
    setValueUPD('positionId', positionId);
    setValueUPD('positionName', positionName);
    setIsInputModalOpen(true);
  };

  return (
    <>
      <PositionTable
        rows={positions}
        setValueDEL={setValueDEL}
        setValueUPD={setValueUPD}
        handleEdit={handleEdit}
        handleDelete={handleDelete}
        isLoading={isLoading}
      />
      <BasicConfirmDialog
        title="役職アイテムの削除"
        confirmMsg="選択中の役職アイテムを削除しますか？"
        isOpen={isConfirmModalOpen}
        handleOk={handleOk}
        handleCancel={handleCancel}
      />
      <BasicConfirmFormDialog
        title="役職アイテムの編集"
        confirmMsg="登録済みの役職名称を編集します。"
        isOpen={isInputModalOpen}
        handleOk={handleUpdateOk}
        handleCancel={handleUpdateCancel}
        okButtonName="変更"
        cancelButtonName="キャンセル"
        textField={
          <FormTextField
            label="役職名称"
            placeholder="役職名称をご記入ください"
            isMultiLine={false}
            isFullWidth
            variant="standard"
            margin="normal"
            name="positionName"
            control={controlUPD}
            errorMsg={errorUPD?.response?.data?.errors.positionName}
          />
        }
      />
      <SnackBars
        status={statusDEL}
        loadingMsg="削除中..."
        successMsg="削除が完了しました"
        errorMsg="削除に失敗しました"
      />
      <SnackBars
        status={statusUPD}
        loadingMsg="変更中..."
        successMsg="変更が完了しました"
        errorMsg="変更に失敗しました"
      />
    </>
  );
};

export default EnhancedPositionTable;
