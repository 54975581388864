import axios, { AxiosError } from 'axios';
import camelcaseKeys from 'camelcase-keys';
import { useQuery, UseQueryResult } from 'react-query';
import { User, isUsers } from '../models/user';

const getUsers = async (): Promise<User[]> => {
  const response = await axios.get(
    `${process.env.REACT_APP_API_URL ?? ''}/api/system/user/getUsers`,
  );

  const users = (await camelcaseKeys(response.data, {
    deep: true,
  })) as unknown[];

  if (!isUsers(users)) {
    throw Error('API type error');
  }

  return users;
};

const useGetUsers = (isSuspense: boolean): UseQueryResult<User[], AxiosError> =>
  useQuery(['system', 'users'], () => getUsers(), {
    suspense: isSuspense,
  });

export default useGetUsers;
