export type Staff = {
  staffId: number;
  staffName: string;
};

const isStaff = (arg: unknown): arg is Staff => {
  const u = arg as Staff;

  return typeof u?.staffId === 'number' && typeof u?.staffName === 'string';
};

const isStaffs = (args: unknown[]): args is Staff[] =>
  !args.some((arg) => !isStaff(arg));

export { isStaff, isStaffs };
