import { Dispatch, SetStateAction, VFC } from 'react';
import { css } from '@emotion/react';
import IconHeader from 'components/01.atoms/Common/Basic/IconHeader';
import AssignmentIcon from '@material-ui/icons/Assignment';
import FilterButton from 'components/01.atoms/Task/OwnTask/FilterButton';
import { FormProps } from 'domains/task/common/services/getOwnTasks';

const flexBox = css`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const rightGroup = css`
  display: inline-block;
  align-self: flex-end;
  margin-right: 27px;
`;

type Props = {
  setFilterAndSortInfo: Dispatch<SetStateAction<FormProps>>;
};

const MainContentsHeader: VFC<Props> = ({ setFilterAndSortInfo }) => (
  <div css={flexBox}>
    <IconHeader
      icon={<AssignmentIcon />}
      title="担当タスク"
      description="担当タスクの確認及び進捗状態を管理します"
    />
    <div css={rightGroup}>
      <FilterButton setFilterAndSortInfo={setFilterAndSortInfo} />
    </div>
  </div>
);

export default MainContentsHeader;
