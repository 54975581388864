import { useMemo } from 'react';
import { RequestingTask } from 'domains/task/common';
import { useQueryClient } from 'react-query';
import { User } from 'domains/auth';
import { TASK_STATUS_FOR_WORKER, TASK_TYPE } from 'utils/const';

type ArgType = {
  detail: RequestingTask;
};

type ReturnType = {
  canEdit: boolean;
  canApproveCompleted: boolean;
  canCancelCompleted: boolean;
  canDeleteTask: boolean;
};

const useRequestTaskPolicy = ({ detail }: ArgType): ReturnType => {
  const queryClient = useQueryClient();
  const userInfo = queryClient.getQueryData<User>('user');

  const isTaskCreator = useMemo(
    () => detail.creatorId === userInfo?.id,
    [detail, userInfo?.id],
  );
  const isAllDone = useMemo(
    () =>
      !detail.ownerTaskStatus.some(
        (owner) => owner.taskStatus !== TASK_STATUS_FOR_WORKER.DONE,
      ),
    [detail],
  );

  const canEdit = useMemo(
    () => isTaskCreator && !detail.isCompleted,
    [detail.isCompleted, isTaskCreator],
  );

  const canApproveCompleted = useMemo(() => {
    if (detail.taskType === TASK_TYPE.SPOT) {
      return isTaskCreator && !detail.isCompleted && isAllDone;
    }
    if (detail.taskType === TASK_TYPE.PROJECT) {
      return detail.isParentTaskOwner && !detail.isCompleted && isAllDone;
    }

    return false;
  }, [
    detail.isCompleted,
    detail.isParentTaskOwner,
    detail.taskType,
    isAllDone,
    isTaskCreator,
  ]);

  const canCancelCompleted = useMemo(() => {
    if (detail.taskType === TASK_TYPE.SPOT) {
      return isTaskCreator && detail.isCompleted;
    }
    if (detail.taskType === TASK_TYPE.PROJECT) {
      return detail.isParentTaskOwner && detail.isCompleted;
    }

    return false;
  }, [
    detail.isCompleted,
    detail.isParentTaskOwner,
    detail.taskType,
    isTaskCreator,
  ]);

  const canDeleteTask = useMemo(
    () => isTaskCreator && !detail.isCompleted,
    [detail.isCompleted, isTaskCreator],
  );

  return {
    canEdit,
    canApproveCompleted,
    canCancelCompleted,
    canDeleteTask,
  };
};

export default useRequestTaskPolicy;
