import axios, { AxiosError } from 'axios';
import camelcaseKeys from 'camelcase-keys';
import { SuccessOnly, isSuccessOnly } from 'domains/common';
import ErrorResponse from 'domains/common/models/error';
import { UseMutationResult, useMutation, useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';

type FormProps = {
  periodId: number;
};

const deleteOrganizationPeriod = async (
  formData: FormProps,
): Promise<SuccessOnly> => {
  const response = await axios.post(
    `${
      process.env.REACT_APP_API_URL ?? ''
    }/api/system/organization/deleteOrganizationPeriod`,
    formData,
  );

  const success = (await camelcaseKeys(response.data, {
    deep: true,
  })) as unknown;

  if (!isSuccessOnly(success)) {
    throw Error('API type error');
  }

  return success;
};

const useDeleteOrganizationPeriod = (): UseMutationResult<
  SuccessOnly,
  AxiosError<ErrorResponse>,
  FormProps,
  undefined
> => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  return useMutation(deleteOrganizationPeriod, {
    onSuccess: async () => {
      await queryClient.invalidateQueries('organizationPeriods', {
        exact: true,
      });
      navigate('/system/organization');
    },
  });
};

export default useDeleteOrganizationPeriod;
