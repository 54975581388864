import { VFC, useState } from 'react';
import SnackBars from 'components/02.molecules/Common/SnackBars';
import BasicConfirmDialog from 'components/03.organisms/Common/BasicConfirmDialog';
import Button from '@material-ui/core/Button';
import {
  OrganizationPeriod,
  useDeleteOrganizationPeriod,
} from 'domains/organization';
import BasicAlertDialog from 'components/03.organisms/Common/BasicAlertDialog';
import { isSameDay, parse } from 'date-fns';
import { SYSTEM_USAGE_PERIOD } from 'utils/const';

type Props = {
  viewPeriod: OrganizationPeriod;
};

const DeleteAllButton: VFC<Props> = ({ viewPeriod }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isAlertOpen, setIsAlertOpen] = useState(false);
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const { mutate, status } = useDeleteOrganizationPeriod();
  const handleOk = () => {
    mutate({ periodId: viewPeriod.id });
  };

  const isFirstPeriod = isSameDay(
    parse(viewPeriod.start, 'yyyy-MM-dd', new Date()),
    parse(SYSTEM_USAGE_PERIOD.START, 'yyyy/M/d', new Date()),
  );

  const handleClick = () => {
    if (isFirstPeriod) {
      setIsAlertOpen(true);

      return;
    }

    setIsModalOpen(true);
  };

  return (
    <>
      <Button
        variant="outlined"
        size="small"
        color="secondary"
        onClick={handleClick}
      >
        完全削除
      </Button>
      <SnackBars
        status={status}
        loadingMsg="完全削除中..."
        successMsg="完全削除が完了しました"
        errorMsg="完全削除に失敗しました"
      />
      <BasicConfirmDialog
        title="対象期間データの全削除"
        confirmMsg={`選択中の期間の組織管理データ及び関連データを全て削除します。\r\n（編集中のデータの破棄とは異なるので、ご注意ください）`}
        isOpen={isModalOpen}
        handleOk={handleOk}
        handleCancel={handleCancel}
      />
      <BasicAlertDialog
        title="完全削除不可"
        confirmMsg="最初の組織管理期間データは完全削除の対象外です。"
        isOpen={isAlertOpen}
        handleCancel={() => setIsAlertOpen(false)}
      />
    </>
  );
};

export default DeleteAllButton;
