import { css, SerializedStyles } from '@emotion/react';
import { VFC, useEffect, useState } from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import Slide from '@material-ui/core/Slide';
import { MutationStatus } from 'react-query';
import { Portal } from '@material-ui/core';

type Props = {
  status: MutationStatus;
  loadingMsg: string;
  successMsg: string;
  errorMsg: string;
  addCss?: SerializedStyles;
};

const SnackBars: VFC<Props> = ({
  status,
  loadingMsg,
  successMsg,
  errorMsg,
  addCss,
}) => {
  const snackBarStyle = css`
    & > * {
      width: max-content;
      min-width: 100px;
    }
  `;

  const isLoading = status === 'loading';

  const [isOpenSuccess, setIsOpenSuccess] = useState(false);
  const [isOpenError, setIsOpenError] = useState(false);
  useEffect(() => {
    if (status === 'success') {
      setIsOpenSuccess(true);
    }
    if (status === 'error') {
      setIsOpenError(true);
    }
  }, [status]);

  const handleCloseSuccess = (
    event: React.SyntheticEvent | React.MouseEvent,
    reason?: string,
  ) => {
    if (reason === 'clickaway') {
      return;
    }

    setIsOpenSuccess(false);
  };

  const handleCloseError = (
    event: React.SyntheticEvent | React.MouseEvent,
    reason?: string,
  ) => {
    if (reason === 'clickaway') {
      return;
    }

    setIsOpenError(false);
  };

  return (
    <Portal>
      <Snackbar
        css={[snackBarStyle, addCss]}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        open={isLoading}
        message={loadingMsg}
        TransitionComponent={Slide}
      />
      <Snackbar
        css={[snackBarStyle, addCss]}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        open={isOpenSuccess}
        onClose={handleCloseSuccess}
        autoHideDuration={3000}
        message={successMsg}
        TransitionComponent={Slide}
      />
      <Snackbar
        css={[snackBarStyle, addCss]}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        open={isOpenError}
        onClose={handleCloseError}
        autoHideDuration={3000}
        message={errorMsg}
        TransitionComponent={Slide}
      />
    </Portal>
  );
};
export default SnackBars;
