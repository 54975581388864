import { ChangeEvent, VFC } from 'react';
import { css } from '@emotion/react';
import IconHeader from 'components/01.atoms/Common/Basic/IconHeader';
import { FaUsers } from 'react-icons/fa';

const flexBox = css`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const labelGroup = css`
  align-self: flex-end;
  margin-right: 27px;
`;

const inlineBlock = css`
  display: inline-block;
`;

type Props = {
  isEditMode: boolean;
  clickSwitch: (event: ChangeEvent<HTMLInputElement>) => void;
};

const MainContentsHeader: VFC<Props> = () => (
  <div css={flexBox}>
    <IconHeader
      icon={<FaUsers />}
      title="ユーザー管理"
      description="システムのユーザー情報を管理します"
    />
    <div css={[inlineBlock, labelGroup]} />
  </div>
);

export default MainContentsHeader;
