import { UseMutationResult, useMutation, useQueryClient } from 'react-query';
import axios, { AxiosError } from 'axios';
import { format, addDays } from 'date-fns';
import camelcaseKeys from 'camelcase-keys';
import ErrorResponse from 'domains/common/models/error';
import {
  isDailyWorkReportSet,
  DailyWorkReportSet,
} from '../models/dailyWorkReportSet';
import { WorkFormData } from '../models/formData';

const updateWorkReport = async (
  formData: WorkFormData,
): Promise<DailyWorkReportSet> => {
  const thisDate = format(formData.viewDate, 'yyyy-M-d');
  const nextDate = format(addDays(formData.viewDate, 1), 'yyyy-M-d');
  const startSplitted = formData.start.split(':');
  const endSplitted = formData.end.split(':');
  const startHour = Number(startSplitted[0]);
  const endHour = Number(endSplitted[0]);
  const startDateTime =
    startHour < 24
      ? `${thisDate} ${formData.start}:00`
      : `${nextDate} ${startHour - 24}:${startSplitted[1]}:00`;
  const endDateTime =
    endHour < 24
      ? `${thisDate} ${formData.end}:00`
      : `${nextDate} ${endHour - 24}:${startSplitted[1]}:00`;

  const processingFormData = {
    ...formData,
    start: startDateTime,
    end: endDateTime,
    viewDate: thisDate,
  };

  const response = await axios.post(
    `${process.env.REACT_APP_API_URL ?? ''}/api/workReport/updateWorkReport`,
    processingFormData,
  );

  const workReportSet = (await camelcaseKeys(response.data, {
    deep: true,
  })) as unknown[];

  if (!isDailyWorkReportSet(workReportSet)) {
    throw Error('API type error');
  }

  return workReportSet;
};

const useUpdateWorkReport = (
  setIsModalOpen: (arg: boolean) => void,
  viewDate: Date,
): UseMutationResult<
  DailyWorkReportSet,
  AxiosError<ErrorResponse>,
  WorkFormData,
  undefined
> => {
  const queryClient = useQueryClient();

  return useMutation(updateWorkReport, {
    onSuccess: (data) => {
      queryClient.setQueryData(
        ['workReports', format(viewDate, 'yyyy-M-d')],
        data,
      );
      setIsModalOpen(false);
    },
  });
};

export default useUpdateWorkReport;
