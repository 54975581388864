import { VFC } from 'react';
import SubmitConfirmDialog from 'components/03.organisms/Common/BasicConfirmDialog';
import { format } from 'date-fns';
import { OtherFormData, useSubmitReport } from 'domains/workReport';
import { UseFormReturn } from 'react-hook-form';
import ja from 'date-fns/locale/ja';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { css } from '@emotion/react';
import SnackBars from 'components/02.molecules/Common/SnackBars';

export type Props = {
  viewDate: Date;
  isOpen: boolean;
  setIsOpen: (arg: boolean) => void;
  setErrorMsgWRDiv: (arg: string) => void;
  otherFormMethods: UseFormReturn<OtherFormData>;
};

const EnhancedSubmitConfirmDialog: VFC<Props> = ({
  viewDate,
  isOpen,
  setIsOpen,
  otherFormMethods,
  setErrorMsgWRDiv,
}) => {
  const { mutate, status, isLoading } = useSubmitReport(
    setIsOpen,
    viewDate,
    otherFormMethods,
    setErrorMsgWRDiv,
  );
  const formData = {
    otherReport1: otherFormMethods.getValues('otherReport1'),
    otherReport2: otherFormMethods.getValues('otherReport2'),
    viewDate: format(viewDate, 'yyyy-M-d'),
  };
  const handleOk = () => {
    mutate(formData);
  };
  const handleCancel = () => {
    setIsOpen(false);
  };

  return (
    <>
      <SubmitConfirmDialog
        title="日報の提出"
        confirmMsg={format(
          viewDate,
          'yyyy年M月d日（EEEE）の日報を提出しますか？',
          { locale: ja },
        )}
        isOpen={isOpen}
        handleOk={handleOk}
        handleCancel={handleCancel}
      />
      <Backdrop
        css={css`
          z-index: 1301;
          color: white;
        `}
        open={isLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <SnackBars
        status={status}
        loadingMsg="日報の提出中..."
        successMsg="日報の提出が完了しました"
        errorMsg="日報の提出に失敗しました"
      />
    </>
  );
};

export default EnhancedSubmitConfirmDialog;
