import { VFC } from 'react';
import DetailList from 'components/02.molecules/Common/DetailList';
import { BasicDetail } from 'domains/user';
import { AUTHORITY_LEVEL_NAME } from 'utils/const';
import EditUserNameButton from 'containers/01.atoms/User/EditUserNameButton';
import EditUserNameKanaButton from 'containers/01.atoms/User/EditUserNameKanaButton';
import EditEmployeeNumberButton from 'containers/01.atoms/User/EditEmployeeNumberButton';
import EditEmailButton from 'containers/01.atoms/User/EditEmailButton';
import EditAuthorityLevelButton from 'containers/01.atoms/User/EditAuthorityLevelButton';
import EditJoiningDateButton from 'containers/01.atoms/User/EditJoiningDateButton';
import parse from 'date-fns/parse';
import format from 'date-fns/format';
import EditLastDateButton from 'containers/01.atoms/User/EditLastDateButton';

export type Props = {
  basic: BasicDetail;
  viewUserId: number;
};

const BasicDetailList: VFC<Props> = ({ basic, viewUserId }) => {
  const joiningDate = basic.firstDate
    ? format(parse(basic.firstDate, 'yyyy-MM-dd', new Date()), 'yyyy年M月d日')
    : '未設定';
  const lastDate = basic.lastDate
    ? format(parse(basic.lastDate, 'yyyy-MM-dd', new Date()), 'yyyy年M月d日')
    : '未設定';

  const dataLists = [
    {
      key: 'userDetailEmployeeNumber',
      title: '社員番号',
      content: basic.employeeNumber,
      button: <EditEmployeeNumberButton viewUserId={viewUserId} />,
    },
    {
      key: 'userDetailName',
      title: '氏名(漢字・英字)',
      content: basic.name,
      button: <EditUserNameButton viewUserId={viewUserId} />,
    },
    {
      key: 'userDetailNamekana',
      title: '氏名(ふりがな)',
      content: basic.nameKana,
      button: <EditUserNameKanaButton viewUserId={viewUserId} />,
    },
    {
      key: 'userDetailEmail',
      title: 'メールアドレス',
      content: basic.email,
      button: <EditEmailButton viewUserId={viewUserId} />,
    },
    {
      key: 'userDetailAuthorityLevel',
      title: '権限レベル',
      content: AUTHORITY_LEVEL_NAME[basic.authorityLevel],
      button: <EditAuthorityLevelButton viewUserId={viewUserId} />,
    },
    {
      key: 'userDetailFirstDate',
      title: '入社日',
      content: joiningDate,
      button: <EditJoiningDateButton viewUserId={viewUserId} />,
    },
    {
      key: 'userDetailLastDate',
      title: '退職日',
      content: lastDate === '9999年12月31日' ? '未設定' : lastDate,
      button: <EditLastDateButton viewUserId={viewUserId} />,
    },
  ];

  return <DetailList label="基本項目" dataLists={dataLists} />;
};

export default BasicDetailList;
