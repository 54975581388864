import { VFC } from 'react';
import ButtonBase from '@material-ui/core/ButtonBase';
import Typography from '@material-ui/core/Typography';
import { css } from '@emotion/react';
import Divider from '@material-ui/core/Divider';

type Props = {
  title: string;
  subTitle: string;
  description: string;
  onClick?: () => void;
};

const LinkButton: VFC<Props> = ({ title, subTitle, description, onClick }) => (
  <div>
    <ButtonBase
      focusRipple
      key={title}
      onClick={onClick}
      css={css`
        display: inline-block;
        padding: 3rem;
        text-align: left;
        border: 1px solid #dee2e6;
        border-radius: 15px;
        transition: 0.7s;
        &:hover {
          background-color: #e3f2fd;
        }
      `}
    >
      <div
        css={css`
          height: 80px;
        `}
      >
        <Typography
          component="div"
          variant="h4"
          css={css`
            font-size: 1.8rem;
          `}
        >
          {title}
        </Typography>
        <Typography
          component="div"
          variant="subtitle1"
          css={css`
            font-size: 1.05rem;
          `}
        >
          {subTitle}
        </Typography>
      </div>
      <Divider
        variant="middle"
        light
        css={css`
          margin-top: 1.5rem;
          margin-bottom: 1.5rem;
        `}
      />
      <Typography
        component="div"
        variant="caption"
        css={css`
          height: 80px;
          font-size: 0.9rem;
        `}
      >
        {description}
      </Typography>
    </ButtonBase>
  </div>
);

export default LinkButton;
