import { useState, VFC, MouseEvent } from 'react';
import { useQueryClient } from 'react-query';
import { useLogout, User } from 'domains/auth';
import { useNavigate } from 'react-router-dom';
import CommonHeader from 'components/03.organisms/Common/CommonHeader';
import EditPasswordDialog from 'containers/03.organisms/Auth/EditPasswordDialog';

const Header: VFC = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const handleOpen = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const queryClient = useQueryClient();
  const userInfo = queryClient.getQueryData<User>('user');
  const { mutate } = useLogout();
  const handleLogout = () => {
    mutate(undefined);
  };

  const [isEditPasswordModalOpen, setIsEditPasswordModalOpen] = useState(false);
  const handleStartEditPassword = () => {
    setAnchorEl(null);
    setIsEditPasswordModalOpen(true);
  };

  const navigate = useNavigate();
  const handleJumpToSystem = () => {
    navigate('/system');
    setAnchorEl(null);
  };

  return (
    <>
      <CommonHeader
        userName={userInfo?.name}
        anchorEl={anchorEl}
        handleOpen={handleOpen}
        handleClose={handleClose}
        handleLogout={handleLogout}
        handleStartEditPassword={handleStartEditPassword}
        handleJumpToSystem={handleJumpToSystem}
      />
      <EditPasswordDialog
        isOpen={isEditPasswordModalOpen}
        setIsOpen={setIsEditPasswordModalOpen}
      />
    </>
  );
};

export default Header;
