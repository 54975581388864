import { Suspense, VFC } from 'react';
import { css } from '@emotion/react';
import StatusDescriptionGroup from 'components/02.molecules/WorkReport/Common/StatusDescriptionGroup';
import SubmitButton from 'containers/01.atoms/WorkReport/SubmitButton';
import AttachedInfo from 'containers/02.molecules/WorkReport/Input/AttachedInfo';
import DateSelector from 'containers/02.molecules/WorkReport/Common/ReportDateSelector';
import Skeleton from '@material-ui/lab/Skeleton';
import { UseFormReturn } from 'react-hook-form';
import { OtherFormData } from 'domains/workReport';

const adjustPadding = css`
  padding-top: 1rem;
`;

const verticalMenu = css`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const flexCenter = css`
  display: flex;
  justify-content: center;
`;

type Props = {
  viewDate?: Date;
  setViewDate: (arg: Date) => void;
  setErrorMsgWRDiv: (arg: string) => void;
  otherFormMethods: UseFormReturn<OtherFormData>;
};

const MainContentsSideArea: VFC<Props> = ({
  viewDate = new Date(),
  setViewDate,
  setErrorMsgWRDiv,
  otherFormMethods,
}) => (
  <div>
    <div css={[adjustPadding, verticalMenu]}>
      <SubmitButton
        viewDate={viewDate}
        setErrorMsgWRDiv={setErrorMsgWRDiv}
        otherFormMethods={otherFormMethods}
      />
      <div css={[flexCenter, adjustPadding]}>
        <DateSelector viewDate={viewDate} setViewDate={setViewDate} />
      </div>
      <div>
        <StatusDescriptionGroup />
      </div>
      <div css={[flexCenter]}>
        <Suspense
          fallback={
            <div>
              <Skeleton animation="wave" width={200} height={150} />
            </div>
          }
        >
          <AttachedInfo viewDate={viewDate} />
        </Suspense>
      </div>
    </div>
  </div>
);

export default MainContentsSideArea;
