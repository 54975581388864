import axios, { AxiosError } from 'axios';
import camelcaseKeys from 'camelcase-keys';
import { useQuery, UseQueryResult } from 'react-query';
import { HasInvalidData, isHasInvalidData } from '../models/hasInvalidData';

const getHasValidData = async (): Promise<HasInvalidData> => {
  const response = await axios.get(
    `${process.env.REACT_APP_API_URL ?? ''}/api/system/getHasInvalidData`,
  );

  const hasInvalid = (await camelcaseKeys(response.data, {
    deep: true,
  })) as unknown;

  if (!isHasInvalidData(hasInvalid)) {
    throw Error('API type error');
  }

  return hasInvalid;
};

const useGetHasValidData = (
  isSuspense: boolean,
): UseQueryResult<HasInvalidData, AxiosError> =>
  useQuery(['system', 'hasInvalid'], () => getHasValidData(), {
    suspense: isSuspense,
  });

export default useGetHasValidData;
