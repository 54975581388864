import {
  VFC,
  Children,
  MouseEventHandler,
  Dispatch,
  SetStateAction,
} from 'react';
import TreeNode from 'containers/01.atoms/Organization/TreeNode';
import TreeNodeActive from 'containers/01.atoms/Organization/TreeNodeActive';
import RecursionEmptyBlock from 'containers/02.molecules/Common/RecursionEmptyBlock';
import { Organization } from 'domains/organization';
import { TreeState } from 'domains/common';

type RecursiveProps = Pick<TreeState, 'activeNodeId'> & {
  orgs: Organization[];
  depth: number;
  setTreeState: Dispatch<SetStateAction<TreeState>>;
  displayedDepth: number;
  handleClick: MouseEventHandler<HTMLDivElement>;
  getCanNextLoop: (org: Organization, depthLevel: number) => boolean;
  getRowSpan: (
    org: Organization[],
    depth: number,
    canNextLoop: boolean,
  ) => number;
};

const RecursionOrganizationBlock: VFC<RecursiveProps> = ({
  orgs,
  depth,
  displayedDepth,
  activeNodeId,
  handleClick,
  setTreeState,
  getCanNextLoop,
  getRowSpan,
}) => (
  <>
    {Children.toArray(
      orgs.map((org) => {
        const canNextLoop = getCanNextLoop(org, depth);
        const rowSpan = getRowSpan(org.childOrgs, depth, canNextLoop);

        return (
          <>
            {activeNodeId === org.orgId ? (
              <TreeNodeActive
                orgId={org.orgId}
                orgName={org.orgName}
                orgType={org.orgType}
                canNextLoop={canNextLoop}
                hasSubOrg={org.childOrgs.length > 0}
                depthLevel={depth}
                rowSpan={rowSpan}
                gridColumn={`${depth} / ${depth + 1}`}
                onClick={handleClick}
                setTreeState={setTreeState}
              />
            ) : (
              <TreeNode
                orgId={org.orgId}
                orgName={org.orgName}
                orgType={org.orgType}
                canNextLoop={canNextLoop}
                hasSubOrg={org.childOrgs.length > 0}
                depthLevel={depth}
                rowSpan={rowSpan}
                gridColumn={`${depth} / ${depth + 1}`}
                onClick={handleClick}
                setTreeState={setTreeState}
              />
            )}
            {canNextLoop ? (
              <RecursionOrganizationBlock
                orgs={org.childOrgs}
                depth={depth + 1}
                displayedDepth={displayedDepth}
                activeNodeId={activeNodeId}
                handleClick={handleClick}
                setTreeState={setTreeState}
                getCanNextLoop={getCanNextLoop}
                getRowSpan={getRowSpan}
              />
            ) : (
              <RecursionEmptyBlock
                depth={depth}
                displayedDepth={displayedDepth}
              />
            )}
          </>
        );
      }),
    )}
  </>
);

export default RecursionOrganizationBlock;
