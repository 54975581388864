export type ReportStatus = {
  [key: string]: number;
};

const isReportStatusList = (arg: unknown): arg is ReportStatus => {
  const r = arg as ReportStatus;
  const errorfound = Object.keys(r).find(
    (value) => typeof r[value] !== 'number',
  );

  return !errorfound;
};

export { isReportStatusList };
