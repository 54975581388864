import { VFC } from 'react';
import {
  DataGrid,
  GridRowData,
  GridColDef,
  GridOverlay,
} from '@material-ui/data-grid';
import { css } from '@emotion/react';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Effect } from 'domains/position';
import DialogLabel from 'components/01.atoms/Common/Basic/DialogLabel';
import convertPeriodText from 'utils/function/convertPeriodText';

const customStyle = css`
  &.MuiDataGrid-root .MuiDataGrid-columnHeader:focus,
  &.MuiDataGrid-root .MuiDataGrid-columnHeader:focus-within,
  &.MuiDataGrid-root .MuiDataGrid-cell:focus,
  &.MuiDataGrid-root .MuiDataGrid-cell:focus-within {
    outline: none;
  }
  &.MuiDataGrid-root .MuiDataGrid-window {
    overflow-y: overlay !important;
  }
  &.MuiDataGrid-root {
    height: 200px;
  }
`;

const progressStyle = css`
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -12px;
  margin-left: -12px;
`;

type Props = {
  label: string;
  rows: Effect[];
  isLoading: boolean;
};

const columns: GridColDef[] = [
  {
    field: 'beforePeriod',
    headerName: '適用前の期間',
    flex: 1,
    sortable: false,
  },
  {
    field: 'afterPeriod',
    headerName: '適用後の期間',
    flex: 1,
    sortable: false,
  },
  {
    field: 'methodType',
    headerName: '処理の種類',
    width: 200,
    sortable: false,
  },
  { field: 'id', hide: true },
];

const convertPositions = (original: Effect[]): GridRowData[] =>
  original.length === 0
    ? ([] as GridRowData[])
    : original.map((data, index) => ({
        id: index,
        beforePeriod: `${
          data.beforePeriodStart && data.beforePeriodEnd
            ? convertPeriodText({
                start: data.beforePeriodStart,
                end: data.beforePeriodEnd,
              })
            : '-'
        }`,
        afterPeriod: `${
          data.afterPeriodStart && data.afterPeriodEnd
            ? convertPeriodText({
                start: data.afterPeriodStart,
                end: data.afterPeriodEnd,
              })
            : '-'
        }`,
        methodType: data.methodType,
      }));

const CustomNoRowsOverlay: VFC = () => (
  <GridOverlay>
    <div>現在の選択による他データへの影響はありません</div>
  </GridOverlay>
);

const EffectTable: VFC<Props> = ({ label, rows, isLoading }) => (
  <div
    css={css`
      position: relative;
      width: 100%;
    `}
  >
    <DialogLabel label={label} />
    <DataGrid
      css={customStyle}
      rows={convertPositions(rows)}
      columns={columns}
      hideFooter
      disableColumnMenu
      disableColumnSelector
      disableSelectionOnClick
      components={{
        NoRowsOverlay: CustomNoRowsOverlay,
      }}
    />
    {isLoading && <CircularProgress size={50} css={progressStyle} />}
  </div>
);
export default EffectTable;
