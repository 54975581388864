import { FC, MouseEventHandler } from 'react';
import { css } from '@emotion/react';
import BasicButton from 'components/01.atoms/Common/Basic/BasicButton';

const addCss = css`
  position: relative;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  width: 80px;
  padding: 0.2rem 0;
  margin: 0.25rem 0;
  overflow: visible;
  font-family: inherit;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #0070c0;
  text-align: center;
  text-transform: none;
  word-wrap: break-word;
  white-space: nowrap;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  border-color: #0070c0;
  border-width: 2px;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  -webkit-appearance: button;
`;

export type ButtonProps = {
  onClick?: MouseEventHandler<HTMLButtonElement>;
};

const ListViewButton: FC<ButtonProps> = ({ onClick, children }) => (
  <BasicButton addCss={addCss} isPost={false} onClick={onClick}>
    {children}
  </BasicButton>
);

export default ListViewButton;
