import { Dispatch, SetStateAction, VFC } from 'react';
import { css } from '@emotion/react';
import absoluteCenter from 'baseCss/emotion/absoluteCenter';
import IconHeader from 'components/01.atoms/Common/Basic/IconHeader';
import { GiOrganigram } from 'react-icons/gi';
import { OrganizationPeriod } from 'domains/organization';
import { TreeState } from 'domains/common';
import PeriodMenu from 'components/01.atoms/Organization/PeriodMenu';
import EditModeSwitch from 'containers/01.atoms/Organization/EditModeSwitch';
import NewPeriodJumpButton from 'containers/01.atoms/Organization/NewPeriodJumpButton';

const flexBox = css`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const labelGroup = css`
  align-self: flex-end;
  margin-right: 27px;
`;

const inlineBlock = css`
  display: inline-block;
`;

type Props = {
  viewPeriod: OrganizationPeriod;
  setViewPeriod: (arg: OrganizationPeriod) => void;
  setTreeState: Dispatch<SetStateAction<TreeState>>;
};

const MainContentsHeader: VFC<Props> = ({
  viewPeriod,
  setViewPeriod,
  setTreeState,
}) => (
  <div css={flexBox}>
    <div css={absoluteCenter}>
      <PeriodMenu
        viewPeriod={viewPeriod}
        setViewPeriod={setViewPeriod}
        setTreeState={setTreeState}
      />
    </div>
    <IconHeader
      icon={<GiOrganigram />}
      title="組織管理"
      description="ユーザーが所属する組織データを管理します"
    />
    <div css={[inlineBlock, labelGroup]}>
      <div
        css={css`
          display: inline-block;
          margin-right: 20px;
        `}
      >
        <NewPeriodJumpButton />
      </div>
      <EditModeSwitch viewPeriod={viewPeriod} />
    </div>
  </div>
);

export default MainContentsHeader;
