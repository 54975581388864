import { VFC } from 'react';
import { css } from '@emotion/react';
import IconHeader from 'components/01.atoms/Common/Basic/IconHeader';
import { RiComputerLine } from 'react-icons/ri';

const flexBox = css`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const MainContentsHeader: VFC = () => (
  <div css={flexBox}>
    <IconHeader
      icon={<RiComputerLine />}
      title="システム管理"
      description="システム利用のための各種設定を行います"
    />
    <div />
  </div>
);

export default MainContentsHeader;
