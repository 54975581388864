import { VFC, useState } from 'react';
import SnackBars from 'components/02.molecules/Common/SnackBars';
import BasicConfirmDialog from 'components/03.organisms/Common/BasicConfirmDialog';
import Button from '@material-ui/core/Button';
import { OrganizationPeriod, useReflectTempData } from 'domains/organization';

type Props = {
  viewPeriod: OrganizationPeriod;
};

const ReflectButton: VFC<Props> = ({ viewPeriod }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const { mutate, status } = useReflectTempData();
  const handleOk = () => {
    mutate({ periodId: viewPeriod.id });
  };

  return (
    <>
      <Button
        variant="outlined"
        size="small"
        color="primary"
        onClick={() => setIsModalOpen(true)}
      >
        変更の反映
      </Button>
      <SnackBars
        status={status}
        loadingMsg="反映処理中..."
        successMsg="反映処理が完了しました"
        errorMsg="反映処理に失敗しました"
      />
      <BasicConfirmDialog
        title="変更の反映"
        confirmMsg="編集中のデータを確定しますか？"
        isOpen={isModalOpen}
        handleOk={handleOk}
        handleCancel={handleCancel}
      />
    </>
  );
};

export default ReflectButton;
