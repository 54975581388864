export type DailyAttachedInfo = {
  status: number;
  feedback: string;
};

const isDailyAttachedInfo = (arg: unknown): arg is DailyAttachedInfo => {
  const d = arg as DailyAttachedInfo;

  return typeof d?.status === 'number' && typeof d?.feedback === 'string';
};

const isDailyAttachedInfoList = (
  args: unknown[],
): args is DailyAttachedInfo[] =>
  !args.some((arg) => !isDailyAttachedInfo(arg));

export { isDailyAttachedInfo, isDailyAttachedInfoList };
