import { VFC } from 'react';
import { css } from '@emotion/react';
import absoluteCenter from 'baseCss/emotion/absoluteCenter';
import IconHeader from 'components/01.atoms/Common/Basic/IconHeader';
import { GiOrganigram } from 'react-icons/gi';
import Typography from '@material-ui/core/Typography';
import ViewModeSwitch from 'containers/01.atoms/Organization/ViewModeSwitch';
import { OrganizationPeriod } from 'domains/organization';
import convertPeriodText from 'utils/function/convertPeriodText';
import ReflectButton from 'containers/01.atoms/Organization/ReflectButton';
import EditPeriodButton from 'containers/01.atoms/Organization/EditPeriodButton';
import DeleteAllButton from 'containers/01.atoms/Organization/DeleteAllButton';

const dateStyle = css`
  padding: 0 0;
  font-family: Lato, 'Helvetica Neue', Arial, Helvetica, sans-serif;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1.28571429em;
  color: rgba(0, 0, 0, 0.87);
  text-transform: none;
  border: none;
`;

const flexBox = css`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const labelGroup = css`
  align-self: flex-end;
  margin-right: 27px;
`;

const inlineBlock = css`
  display: inline-block;
`;

type Props = {
  viewPeriod: OrganizationPeriod;
  setViewPeriod: (arg: OrganizationPeriod) => void;
};

const MainContentsHeader: VFC<Props> = ({ viewPeriod, setViewPeriod }) => (
  <div css={flexBox}>
    <div css={absoluteCenter}>
      <div
        css={css`
          display: flex;
          align-items: center;
          justify-content: center;
        `}
      >
        <EditPeriodButton
          viewPeriod={viewPeriod}
          setViewPeriod={setViewPeriod}
        />
        <Typography css={dateStyle} variant="h5" color="textPrimary">
          {convertPeriodText({
            start: viewPeriod.tempStart ?? undefined,
            end: viewPeriod.tempEnd ?? undefined,
          })}
        </Typography>
      </div>
    </div>
    <IconHeader
      icon={<GiOrganigram />}
      title="組織管理"
      description="ユーザーが所属する組織データを管理します"
    />
    <div css={[inlineBlock, labelGroup]}>
      <div
        css={css`
          display: inline-block;
          margin-right: 10px;
        `}
      >
        <DeleteAllButton viewPeriod={viewPeriod} />
      </div>
      <div
        css={css`
          display: inline-block;
          margin-right: 20px;
        `}
      >
        <ReflectButton viewPeriod={viewPeriod} />
      </div>
      <ViewModeSwitch viewPeriod={viewPeriod} />
    </div>
  </div>
);

export default MainContentsHeader;
