import { ReactElement } from 'react';
import { useController, UseControllerProps } from 'react-hook-form';
import FormTextField from 'components/01.atoms/Common/Basic/FormTextFiled';
import { PropTypes } from '@material-ui/core';

type AddProps = {
  label?: string;
  errorMsg?: string;
  placeholder?: string;
  isDisabled?: boolean;
  isMultiLine: boolean;
  rows?: number;
  isFullWidth: boolean;
  variant?: 'standard' | 'filled' | 'outlined';
  margin?: PropTypes.Margin;
};

const EnhancedFormTextField = <T,>({
  label,
  isDisabled,
  errorMsg,
  placeholder,
  rows,
  isMultiLine,
  isFullWidth,
  variant,
  margin,
  ...props
}: UseControllerProps<T> & AddProps): ReactElement => {
  const { field } = useController(props);

  return (
    <FormTextField
      variant={variant}
      margin={margin}
      rows={rows}
      label={label}
      placeholder={placeholder}
      isDisabled={isDisabled}
      errorMsg={errorMsg}
      isMultiLine={isMultiLine}
      isFullWidth={isFullWidth}
      name={field.name}
      onBlur={field.onBlur}
      onChange={field.onChange}
      ref={field.ref}
      value={field.value}
    />
  );
};

EnhancedFormTextField.defaultProps = {
  label: '入力フォーム',
  errorMsg: '',
  placeholder: '内容をご記入ください。',
  isDisabled: false,
  variant: 'outlined',
  rows: 9,
  margin: 'normal',
};

export default EnhancedFormTextField;
