import { VFC, useState, useCallback, Suspense } from 'react';
import {
  useForm,
  FormProvider,
  SubmitHandler,
  UseFormReturn,
} from 'react-hook-form';
import { css } from '@emotion/react';
import { format } from 'date-fns';
import useDailyWorkReport from 'hooks/useDailyWorkReport';
import OtherReports from 'components/02.molecules/WorkReport/Input/OtherReportForms';
import WorkReportInput from 'components/02.molecules/WorkReport/Input/WorkReporLists';
import WorkReportDialog from 'components/01.atoms/WorkReport/Input/WorkReportDialog';
import FallBackForm from 'components/02.molecules/Common/Fallback/OtherReportForms';
import Grid from '@material-ui/core/Grid';
import {
  OtherFormData,
  useUpdateOtherReport,
  useUpdateWorkReportTime,
  WorkFormData,
} from 'domains/workReport';

const overall = css`
  padding: 0 1rem;
  margin-top: 0.5rem;
`;

const basicLabel = css`
  display: block;
  flex-grow: inherit !important;
  margin: 0 0 1rem 0;
  font-size: 1.1em;
  font-weight: 700;
  color: rgba(0, 0, 0, 0.87);
  text-transform: none;
`;

type Props = {
  viewDate: Date;
  errorMsgWRDiv: string;
  setErrorMsgWRDiv: (arg: string) => void;
  otherFormMethods: UseFormReturn<OtherFormData>;
};

const MainContentsMainArea: VFC<Props> = ({
  viewDate,
  otherFormMethods,
  errorMsgWRDiv,
  setErrorMsgWRDiv,
}) => {
  const workFormMethods = useForm<WorkFormData>();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const { mutate: mutateWRT, status: statusWRT } =
    useUpdateWorkReportTime(viewDate);
  const { select, eventClick, eventDrop, eventResize } = useDailyWorkReport(
    viewDate,
    setIsModalOpen,
    workFormMethods,
    mutateWRT,
  );

  const { mutate: mutateOR, status: statusOR } = useUpdateOtherReport(viewDate);

  const handleOtherReport: SubmitHandler<OtherFormData> = useCallback(
    (data) => {
      const addViewDate = { viewDate: format(viewDate, 'yyyy-M-d') };
      const postData = { ...data, ...addViewDate };
      mutateOR(postData);
    },
    [mutateOR, viewDate],
  );

  /* eslint-disable  react/jsx-props-no-spreading */
  return (
    <div>
      <Grid css={overall} container spacing={2}>
        <Grid item xs={6}>
          <WorkReportInput
            viewDate={viewDate}
            select={select}
            eventClick={eventClick}
            eventDrop={eventDrop}
            eventResize={eventResize}
            statusWRT={statusWRT}
            errorMsgWRDiv={errorMsgWRDiv}
            setErrorMsgWRDiv={setErrorMsgWRDiv}
            setIsModalOpen={setIsModalOpen}
            workFormMethods={workFormMethods}
          />
          <FormProvider {...workFormMethods}>
            <WorkReportDialog
              isOpen={isModalOpen}
              setIsOpen={setIsModalOpen}
              workFormMethods={workFormMethods}
              viewDate={viewDate}
            />
          </FormProvider>
        </Grid>
        <Grid item xs={6}>
          <span css={basicLabel}>報告事項</span>
          <Suspense fallback={<FallBackForm hasButton />}>
            <FormProvider {...otherFormMethods}>
              <form onSubmit={otherFormMethods.handleSubmit(handleOtherReport)}>
                <OtherReports
                  status={statusOR}
                  viewDate={viewDate}
                  errorMsg1={
                    otherFormMethods?.formState?.errors?.otherReport1?.message
                  }
                />
              </form>
            </FormProvider>
          </Suspense>
        </Grid>
      </Grid>
    </div>
  );
  /* eslint-enable  react/jsx-props-no-spreading */
};

export default MainContentsMainArea;
