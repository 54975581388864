import { VFC } from 'react';
import DetailList from 'components/02.molecules/Common/DetailList';
import { Member } from 'domains/organization';
import convertPeriodText from 'utils/function/convertPeriodText';

export type Props = {
  members: Member[];
};

const MemberDetailList: VFC<Props> = ({ members }) => {
  const dataLists = members?.map((member, index) => ({
    key: `memberDetail${index}`,
    title: convertPeriodText({
      start: member.startDate,
      end: member.endDate,
    }),
    content: member.memberName,
  }));

  return <DetailList label="チームメンバー" dataLists={dataLists} />;
};

export default MemberDetailList;
