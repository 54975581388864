import { VFC } from 'react';
import TermsOrganizationsHasNoUsersTable from 'components/02.molecules/System/TermsOrganizationsHasNoUsersTable';
import useConvertInvalidListData from 'hooks/System/use-convertInvalidListData';
import { useGetTermsOrganizationsHasNoUsers } from 'domains/system';
import InvalidDataLabel from 'components/01.atoms/System/InvalidDataLabel';

const EnhancedTermsOrganizationsHasNoUsersTable: VFC = () => {
  const { data = [], isLoading } = useGetTermsOrganizationsHasNoUsers(false);
  const convertedData = useConvertInvalidListData({ original: data });

  return (
    <div>
      <InvalidDataLabel label="組織の所属者の未登録期間（有効期間に所属者が存在しない期間）" />
      <TermsOrganizationsHasNoUsersTable
        data={convertedData}
        isLoading={isLoading}
      />
    </div>
  );
};

export default EnhancedTermsOrganizationsHasNoUsersTable;
