import axios, { AxiosError } from 'axios';
import camelcaseKeys from 'camelcase-keys';
import { isSuccessOnly, SuccessOnly } from 'domains/common';
import ErrorResponse from 'domains/common/models/error';
import { UseMutationResult, useMutation, useQueryClient } from 'react-query';

type FormProps = {
  userId: number;
  authorityLevel: number;
};

const editAuthorityLevel = async (
  formData: FormProps,
): Promise<SuccessOnly> => {
  const response = await axios.post(
    `${process.env.REACT_APP_API_URL ?? ''}/api/system/user/editAuthorityLevel`,
    formData,
  );

  const success = (await camelcaseKeys(response.data, {
    deep: true,
  })) as unknown;

  if (!isSuccessOnly(success)) {
    throw Error('API type error');
  }

  return success;
};

const useEditAuthorityLevel = (
  userId: number,
  setIsOpen: (arg: boolean) => void,
): UseMutationResult<
  SuccessOnly,
  AxiosError<ErrorResponse>,
  FormProps,
  undefined
> => {
  const queryClient = useQueryClient();

  return useMutation(editAuthorityLevel, {
    onSuccess: async () => {
      await queryClient.invalidateQueries(['system', 'userDetail', userId]);
      void queryClient.invalidateQueries(['system', 'users']);
      setIsOpen(false);
    },
  });
};

export default useEditAuthorityLevel;
