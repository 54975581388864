export type OrganizationUserOption = {
  userId: number;
  userName: string;
  nameKana: string;
  mustAdjust: boolean;
};

export type OrganizationUserState = {
  rowNumber: number;
  userId: number;
  userName: string;
  start: Date;
  end: Date | null;
  isContinued: boolean;
  isMain: boolean;
};

const isOrganizationUserOption = (
  arg: unknown,
): arg is OrganizationUserOption => {
  const o = arg as OrganizationUserOption;

  return (
    typeof o?.userId === 'number' &&
    typeof o?.userName === 'string' &&
    typeof o?.nameKana === 'string' &&
    typeof o?.mustAdjust === 'boolean'
  );
};

const isOrganizationUserOptions = (
  args: unknown[],
): args is OrganizationUserOption[] =>
  !args.some((arg) => !isOrganizationUserOption(arg));

export { isOrganizationUserOption, isOrganizationUserOptions };
