import { useCallback } from 'react';
import { useQueryClient, UseMutationResult, useMutation } from 'react-query';
import axios, { AxiosError } from 'axios';
import { SuccessOnly, isSuccessOnly } from 'domains/common';
import { useNavigate } from 'react-router-dom';

const logout = async (): Promise<SuccessOnly> => {
  const response = await axios.post(
    `${process.env.REACT_APP_API_URL ?? ''}/api/logout`,
  );
  const success = (await response.data) as unknown;

  if (!isSuccessOnly(success)) {
    throw Error('API type error');
  }

  return success;
};

const useLogout = (): UseMutationResult<
  SuccessOnly,
  AxiosError,
  unknown,
  undefined
> => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const handleMove = useCallback(() => {
    navigate('/login');
  }, [navigate]);

  return useMutation(logout, {
    onSuccess: () => {
      queryClient.removeQueries();
      queryClient.setQueryData('user', null);
      handleMove();
    },
  });
};

export default useLogout;
