import { VFC } from 'react';
import MainContentsHeader from 'components/03.organisms/Organization/Edit/MainContentsHeader';
import { OrganizationPeriod } from 'domains/organization';

type Props = {
  viewPeriod: OrganizationPeriod;
  setViewPeriod: (arg: OrganizationPeriod) => void;
};

const EnhancedMainContentsHeader: VFC<Props> = ({
  viewPeriod,
  setViewPeriod,
}) => (
  <MainContentsHeader viewPeriod={viewPeriod} setViewPeriod={setViewPeriod} />
);

export default EnhancedMainContentsHeader;
