import { VFC, useState, Suspense } from 'react';
import { useForm, FormProvider, UseFormReturn } from 'react-hook-form';
import { css } from '@emotion/react';
import OtherReportViews from 'containers/02.molecules/WorkReport/Approve/OtherReportViews';
import WorkReportLists from 'components/02.molecules/WorkReport/Approve/WorkReportLists';
import WorkReportDialog from 'components/01.atoms/WorkReport/Approve/WorkReportDialog';
import FallBackForm from 'components/02.molecules/Common/Fallback/OtherReportForms';
import Grid from '@material-ui/core/Grid';
import { OtherFormData, WorkFormData } from 'domains/workReport';

const overall = css`
  padding: 0 1rem;
  margin-top: 0.5rem;
`;

const basicLabel = css`
  display: block;
  flex-grow: inherit !important;
  margin: 0 0 1rem 0;
  font-size: 1.1em;
  font-weight: 700;
  color: rgba(0, 0, 0, 0.87);
  text-transform: none;
`;

type Props = {
  viewDate: Date;
  viewStaffId: number;
  otherFormMethods: UseFormReturn<OtherFormData>;
};

const MainContentsMainArea: VFC<Props> = ({ viewDate, viewStaffId }) => {
  const workFormMethods = useForm<WorkFormData>();
  const [isModalOpen, setIsModalOpen] = useState(false);

  /* eslint-disable  react/jsx-props-no-spreading */
  return (
    <div>
      <Grid css={overall} container spacing={2}>
        <Grid item xs={6}>
          {viewStaffId && (
            <WorkReportLists
              viewDate={viewDate}
              viewStaffId={viewStaffId}
              setIsModalOpen={setIsModalOpen}
              workFormMethods={workFormMethods}
            />
          )}
          <FormProvider {...workFormMethods}>
            <WorkReportDialog
              viewDate={viewDate}
              isOpen={isModalOpen}
              setIsOpen={setIsModalOpen}
              workFormMethods={workFormMethods}
            />
          </FormProvider>
        </Grid>
        <Grid item xs={6}>
          <span css={basicLabel}>報告事項</span>
          <Suspense fallback={<FallBackForm hasButton={false} />}>
            <OtherReportViews viewDate={viewDate} viewStaffId={viewStaffId} />
          </Suspense>
        </Grid>
      </Grid>
    </div>
  );
  /* eslint-enable  react/jsx-props-no-spreading */
};

export default MainContentsMainArea;
