import { Suspense, useState, useRef, VFC } from 'react';
import Button from '@material-ui/core/Button';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';
import RadioGroup from '@material-ui/core/RadioGroup';
import CategoryRadioOptions from 'containers/02.molecules/WorkReport/Input/CategoryRadioOptions';
import Skeleton from '@material-ui/lab/Skeleton';

type CategoryProps = {
  value?: string;
  tableId?: string;
  categoryId?: string;
  categoryName?: string;
};

type ConfirmationDialogRawProps = {
  id: string;
  keepMounted: boolean;
  value: string;
  open: boolean;
  onClose: (props?: CategoryProps) => void;
};

const WorkCategorySelectionDialog: VFC<ConfirmationDialogRawProps> = (
  props,
) => {
  const { onClose, value: valueProp, open, ...other } = props;
  const [targetCategory, setTargetCategory] = useState({
    value: valueProp,
    categoryName: '',
    tableId: '',
    categoryId: '',
  });
  const radioGroupRef = useRef<HTMLElement>(null);

  const handleEntering = () => {
    if (radioGroupRef.current != null) {
      radioGroupRef.current.focus();
    }
  };

  const handleCancel = () => {
    onClose();
  };

  const handleOk = () => {
    onClose(targetCategory);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedElement = event.target;
    const closestElement = event.target.closest('.MuiFormControlLabel-root');
    setTargetCategory({
      value: selectedElement.value,
      categoryName: closestElement?.getAttribute('data-category-name') ?? '',
      tableId: closestElement?.getAttribute('data-table-id') ?? '',
      categoryId: closestElement?.getAttribute('data-category-id') ?? '',
    });
  };

  return (
    <Dialog
      disableBackdropClick
      disableEscapeKeyDown
      maxWidth="lg"
      onEntering={handleEntering}
      aria-labelledby="confirmation-dialog-title"
      open={open}
      id={other.id}
      keepMounted={other.keepMounted}
    >
      <DialogTitle id="confirmation-dialog-title">
        業務カテゴリーの選択
      </DialogTitle>
      <DialogContent dividers>
        <Suspense
          fallback={
            <>
              {[...Array(4).keys()].map((v) => (
                <Skeleton animation="wave" width={200} height={42} key={v} />
              ))}
            </>
          }
        >
          <RadioGroup
            ref={radioGroupRef}
            aria-label="workCategoryRadioGroup"
            name="workCategoryRadioGroup"
            value={targetCategory.value}
            data-category-name={targetCategory.categoryName}
            data-table-id={targetCategory.tableId}
            data-category-id={targetCategory.categoryId}
            onChange={handleChange}
          >
            <CategoryRadioOptions />
          </RadioGroup>
        </Suspense>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleOk} color="primary">
          選択
        </Button>
        <Button autoFocus onClick={handleCancel} color="primary">
          取消
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default WorkCategorySelectionDialog;
