export type SubOrg = {
  orgId: number;
  orgName: string;
};
const isSubOrg = (arg: unknown): arg is SubOrg => {
  const o = arg as SubOrg;

  return typeof o?.orgId === 'number' && typeof o?.orgName === 'string';
};
const isSubOrgs = (args: unknown[]): args is SubOrg[] =>
  !args.some((arg) => !isSubOrg(arg));

export { isSubOrg, isSubOrgs };
