import { isBasicDetail, BasicDetail } from './basicDetail';
import { isLeaders, Leader } from './leader';
import { isMembers, Member } from './member';
import { isSubOrgs, SubOrg } from './subOrg';

export type OrganizationDetail = {
  basic: BasicDetail;
  leaders: Leader[];
  members: Member[];
  subOrgs: SubOrg[];
};

const isOrganizationDetail = (arg: unknown): arg is OrganizationDetail => {
  const o = arg as OrganizationDetail;

  return (
    isBasicDetail(o?.basic) &&
    isLeaders(o?.leaders) &&
    isMembers(o?.members) &&
    isSubOrgs(o?.subOrgs)
  );
};

const isOrganizationDetails = (args: unknown[]): args is OrganizationDetail[] =>
  !args.some((arg) => !isOrganizationDetail(arg));

export { isOrganizationDetail, isOrganizationDetails };
