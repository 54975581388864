import { forwardRef, MouseEventHandler } from 'react';
import CustomInputInDateTimeSelect from 'components/01.atoms/Common/Basic/CustomInputInDateTimeSelect';
import { SerializedStyles } from '@emotion/react';

type CustomProps = {
  label?: string;
  variant?: 'standard' | 'filled' | 'outlined';
  dateValue: Date | null;
  errorMsg?: string;
  onClick?: MouseEventHandler<HTMLDivElement>;
  addCss?: SerializedStyles;
};

const CustomDate = forwardRef<HTMLDivElement, CustomProps>(
  ({ onClick, dateValue, errorMsg, label, variant, addCss }, ref) => (
    <CustomInputInDateTimeSelect
      addCss={addCss}
      label={label}
      variant={variant}
      value={dateValue}
      ref={ref}
      onClick={onClick}
      errorMsg={errorMsg}
    />
  ),
);

export default CustomDate;
