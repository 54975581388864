import { useState, useEffect, VFC, ChangeEvent } from 'react';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { css } from '@emotion/react';
import SnackBars from 'components/02.molecules/Common/SnackBars';
import BasicConfirmFormDialog from 'components/03.organisms/Common/BasicConfirmFormDialog';
import FormTextField from 'containers/01.atoms/Common/FormTextFiled';
import { useForm } from 'react-hook-form';
import { StrOrNumForm } from 'domains/common';
import {
  OrganizationDetailTemp,
  OrganizationPeriod,
  useAddTempSubOrganization,
} from 'domains/organization';
import { useQueryClient } from 'react-query';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { Divider } from '@material-ui/core';
import LabelAndElement from 'components/01.atoms/Common/Basic/LabelAndElement';
import ErrorResponse from 'domains/common/models/error';

export type Props = {
  activeOrgId: number;
  viewPeriod: OrganizationPeriod;
  isOpen: boolean;
  setIsOpen: (arg: boolean) => void;
};

const EnhancedAddSubOrgDialog: VFC<Props> = ({
  activeOrgId,
  viewPeriod,
  isOpen,
  setIsOpen,
}) => {
  const [parentOrgName, setParentOrgName] = useState('');
  const [isBottom, setIsBottom] = useState(false);
  const { getValues, reset, control } = useForm<StrOrNumForm>({
    defaultValues: {
      masterName: '',
      periodName: '',
    },
  });
  const {
    mutate,
    status,
    isLoading,
    error = {
      response: {
        data: {} as ErrorResponse,
      },
    },
  } = useAddTempSubOrganization(viewPeriod.id, activeOrgId, setIsOpen, reset);
  const formData = {
    periodId: viewPeriod.id,
    orgId: activeOrgId,
  };
  const handleOk = () => {
    mutate({
      ...formData,
      masterName: String(getValues('masterName')),
      periodName: String(getValues('periodName')),
      isBottomTeam: isBottom,
    });
  };
  const handleCancel = () => {
    setIsOpen(false);
  };

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setIsBottom(event.target.checked);
  };

  const queryClient = useQueryClient();
  useEffect(() => {
    const data = queryClient.getQueryData<OrganizationDetailTemp>([
      'system',
      'organizationDetail',
      'temp',
      activeOrgId,
      viewPeriod.id,
    ]);
    setParentOrgName(data?.basic.name ?? '');
    reset();
  }, [reset, queryClient, activeOrgId, viewPeriod.id]);

  return (
    <>
      <BasicConfirmFormDialog
        title="サブ組織の追加"
        confirmMsg="登録するサブ組織の情報をご入力ください。"
        isOpen={isOpen}
        handleOk={handleOk}
        handleCancel={handleCancel}
        okButtonName="登録"
        cancelButtonName="キャンセル"
        textField={
          <>
            <LabelAndElement
              label="追加先の親組織の名称"
              element={<span>{parentOrgName}</span>}
            />
            <Divider />
            <div
              css={css`
                margin-top: 15px;
              `}
            >
              <FormControlLabel
                control={
                  <Checkbox
                    checked={isBottom}
                    onChange={handleChange}
                    name="isBottomTeam"
                    color="primary"
                  />
                }
                label="最下層レベル"
              />
              <FormTextField
                label="マスター名称(全期間共通の管理用)"
                placeholder=""
                isMultiLine={false}
                isFullWidth
                variant="standard"
                margin="normal"
                name="masterName"
                control={control}
                errorMsg={error?.response?.data?.errors?.masterName}
              />
              <FormTextField
                label="期間名称(選択中の期間表示用)"
                placeholder=""
                isMultiLine={false}
                isFullWidth
                variant="standard"
                margin="normal"
                name="periodName"
                control={control}
                errorMsg={error?.response?.data?.errors?.periodName}
              />
            </div>
          </>
        }
      />
      <Backdrop
        css={css`
          z-index: 1301;
          color: white;
        `}
        open={isLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <SnackBars
        status={status}
        loadingMsg="登録中..."
        successMsg="登録が完了しました"
        errorMsg="登録に失敗しました"
      />
    </>
  );
};

export default EnhancedAddSubOrgDialog;
