import { VFC, ChangeEvent, SetStateAction, Dispatch } from 'react';
import OwnerSelectRadios from 'components/02.molecules/Task/Project/OwnerSelectRadios';
import { Member } from 'domains/task/projectTree';

export type Props = {
  ownerId: number;
  ownerOptions: Member[];
  setOwnerIds: Dispatch<SetStateAction<number[]>>;
  errorMessage?: string;
  isFetching?: boolean;
};

const EnhancedOwnerSelectRadios: VFC<Props> = ({
  ownerId,
  ownerOptions,
  setOwnerIds,
  errorMessage,
  isFetching = false,
}) => {
  const handleOwnerSelect = (event: ChangeEvent<HTMLInputElement>) => {
    setOwnerIds([Number(event.currentTarget.value)]);
  };

  return (
    <OwnerSelectRadios
      ownerId={ownerId}
      ownerOptions={ownerOptions}
      handleOwnerSelect={handleOwnerSelect}
      errorMessage={errorMessage}
      isFetching={isFetching}
    />
  );
};

export default EnhancedOwnerSelectRadios;
